import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Doughnut, Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import MissingDataLoader from "../../component/Loader/MissingDataLoader";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import MissingPIDLoader from "../../component/Loader/MissingPIDLoader";
Chart.register(CategoryScale);

// HEADERS
const useStyles = makeStyles((theme) => ({
  scrollableList: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  arrowButton: {
    minWidth: "unset",
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const Dashboard = () => {
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  const [cookies, setCookie] = useCookies(["user"]);
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const [fromDate, setFromDate] = useState(decodeURIComponent(cookies.from));
  const [toDate, setToDate] = useState(decodeURIComponent(cookies.to));
  // const [selectedYear, setSelectedYear] = useState(years[2]);
  // const [selectedMonth, setSelectedMonth] = useState(months[0].id);
  const [scope1, setScope1] = useState(0);
  const [scope2, setScope2] = useState(0);
  const [scope3, setScope3] = useState(0);
  const [total, setTotal] = useState(0);
  const [missingLoader, setMissingLoader] = useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [profileStatus, setProfileStatus] = React.useState(false);
  // dashboard date state

  const [emissionDashboardData, setEmissionDashboardData] = React.useState([]); // emission dashboard api state

  let donutChartData = {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    datasets: [
      {
        data: [scope1, scope2, scope3],
        backgroundColor: ["#003E35", "#0C6659", "#46AD8D"],
      },
    ],
  };

  const [scopeImpact, setScopeImpact] = useState({
    "Scope 1": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 2": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 3": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [highestImpactItems, setHighestImpactItems] = useState([]);
  const fetchDataMapping = async (data) => {
    console.log("data in fetchDataMapping : - ", data);
    try {
      if (data) {
        setScope1(data.Emissions.Scope1 ?? 0);
        setScope2(data.Emissions.Scope2 ?? 0);
        setScope3(data.Emissions.Scope3 ?? 0);
        if (data.ImpactOverTime) {
          setScopeImpact(data.ImpactOverTime);
        }
        setTotal(data.Total);
        setHighestImpactItems(data.highestImpactingItems);
      } else {
        console.log(data, "this is data");
        setMissingLoader(true);
      }
    } catch (err) {
      setMissingLoader(true);
      console.log(err, "err in get api");
    }
  };
  let lineChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    //["#003E35", "#0C6659", "#46AD8D"],
    datasets: [
      {
        label: "Scope 1",
        data: scopeImpact["Scope 1"],
        backgroundColor: "rgba(0, 62, 53, 0.5)",
        borderColor: "#003E35",
        fill: true,
      },
      {
        label: "Scope 2",
        data: scopeImpact["Scope 2"],
        backgroundColor: "rgba(12, 102, 89,0.5)",
        borderColor: "#0C6659",
        fill: true,
      },
      {
        label: "Scope 3",
        data: scopeImpact["Scope 3"],
        backgroundColor: "rgba(0, 135, 132,0.5)",
        borderColor: "#46AD8D",
        fill: true,
      },
    ],
  };

  // post api for selected dates dashboard emissions
  const getDashboardEmissions = async (from, to) => {
    try {
      setBackdropOpen(true)
      const dashboardBody = {
        From: from,
        To: to,
      };
      const { data } = await axios.post(
        API_URL + `/ef/getDashboard`,
        dashboardBody,
        headers
      );
      console.log(data, "emission data found");
      if (!data) {
        setEmissionDashboardData([]);
      } else {
        setEmissionDashboardData(data.data);
        fetchDataMapping(data.data);
        console.log(data.data, "api entered successfully emission");
      }
    } catch (err) {
      console.log(err, "err in get dashboard emissions");
    }
    setBackdropOpen(false)
  };

  // Update chart data on selected year and month change
  useEffect(() => {
    console.log("Fetching new data");

    console.log(fromDate, "from");
    console.log(toDate, "to");
    getDashboardEmissions(fromDate, toDate);
  }, [cookies.from]);

  useEffect(() => {
    checkMissingPID();
  }, []);

  const checkMissingPID = async () => {
    try {
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      if (user?.data?.PID && user?.data?.PID !== "") {
        setProfileStatus(true);
      }
    } catch (error) {
      console.log("Error inside checkMissingPID function : - ", error.stack);
    }
  }

  return (
    <>
      {backdropOpen && <FullScreenLoader />}
      {missingLoader && <MissingDataLoader />}
      {!profileStatus && <MissingPIDLoader />}
      <Grid container spacing={3} style={{ paddingLeft: "30px" }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h5" style={{ margin: "1rem" }}>
              <strong>Emissions Statistics</strong>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
        {/* Year */}


        {/* Total */}
        <Grid item xs={6} sm={6} md={3}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ width: "80px", height: "80px", marginRight: "10px" }}
              >
                <Doughnut
                  data={donutChartData}
                  options={{
                    maintainAspectRatio: false,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
              <div>
                <Typography variant="h6" component="div">
                  <strong>Total</strong>
                </Typography>
                <Typography variant="body2">Dataset of all 3 scopes</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Scope 1 */}
        <Grid item xs={6} sm={6} md={3}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ width: "80px", height: "80px", marginRight: "10px" }}
              >
                <Doughnut
                  data={{
                    labels: ["Scope 1", "Total"],
                    datasets: [
                      {
                        data: [
                          donutChartData.datasets[0].data[0],
                          donutChartData.datasets[0].data[1] +
                          donutChartData.datasets[0].data[2],
                        ],
                        backgroundColor: ["#003E35", "#ccc"],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
              <div>
                <Typography variant="h6" component="div">
                  <strong>Scope 1</strong>
                </Typography>
                <Typography variant="body2">Scope 1 Emissions</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Scope 2 */}
        <Grid item xs={6} sm={6} md={3}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ width: "80px", height: "80px", marginRight: "10px" }}
              >
                <Doughnut
                  data={{
                    labels: ["Scope 2", "Total"],
                    datasets: [
                      {
                        data: [
                          donutChartData.datasets[0].data[1],
                          donutChartData.datasets[0].data[0] +
                          donutChartData.datasets[0].data[2],
                        ],
                        backgroundColor: ["#0C6659", "#ccc"],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
              <div>
                <Typography variant="h6" component="div">
                  <strong>Scope 2</strong>
                </Typography>
                <Typography variant="body2">Scope 2 Emissions</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Scope 3 */}
        <Grid item xs={6} sm={6} md={3}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ width: "80px", height: "80px", marginRight: "10px" }}
              >
                <Doughnut
                  data={{
                    labels: ["Scope 3", "Total"],
                    datasets: [
                      {
                        data: [
                          donutChartData.datasets[0].data[2],
                          donutChartData.datasets[0].data[1] +
                          donutChartData.datasets[0].data[0],
                        ],
                        backgroundColor: ["#46AD8D", "#ccc"],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
              <div>
                <Typography variant="h6" component="div">
                  <strong>Scope 3</strong>
                </Typography>
                <Typography variant="body2">Scope 3 Emissions</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Impact */}
        <Grid item xs={12} md={4}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h6" component="div">
                  <strong>Total Impact</strong>
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h6">

                  <strong>{total.toFixed(2)} ton CO2-eq</strong>
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Doughnut
                  data={donutChartData}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Highest Impact Items */}

        {/* Highest Impact Items */}
        <Grid item xs={12} md={8}>
          <Card style={{ height: "380px", overflow: "auto", borderRadius: "1rem" }}>
            <CardContent style={{ padding: 8 }}>
              <Typography variant="h6" gutterBottom style={{ marginBottom: "1rem" }}>
                <strong>Highest Impact Items</strong>
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Activity</TableCell>
                      <TableCell>CO2</TableCell>
                      <TableCell>Percentage</TableCell>
                      <TableCell>Source Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  {highestImpactItems.length > 0 && (
                    <TableBody>
                      {highestImpactItems?.map((activity, index) => (
                        <React.Fragment key={index}>
                          <TableRow key={`${index}`}>
                            <TableCell>
                              {activity.category}
                            </TableCell>
                            <TableCell>
                              {activity.subActivity || activity['_id'].category}
                            </TableCell>
                            <TableCell>{activity.total.toFixed(2)}</TableCell>
                            <TableCell>{`${(
                              ((activity.total ?? 0) /
                                (emissionDashboardData.Total ?? 0)) *
                              100 ??
                              0
                            ).toFixed(2) ?? 0
                              }% of total`}</TableCell>
                            <TableCell>{`${activity.sourceQuantity}  ${activity.units}`}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>


        {/* Impact Over Time */}
        <Grid item xs={12}>
          <Card style={{ borderRadius: "1rem" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <strong>Impact Over Time</strong>
              </Typography>
              <div style={{ width: "100%", height: "300px" }}>
                <Line
                  data={lineChartData}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
