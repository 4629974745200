import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Fab, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Card, CardContent } from "@mui/material";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import Report component
import ReportCopy from "../VCarbonPages/ReportCopy";
// import Eco Label component
import EcoLabelPage from "../EcoLabelPages/EcoLabelPage";
import axios from "axios";
// import Headers and API URL
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import MUIDataTable from "mui-datatables";
import { BulkInvite } from "./BulkInvite";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { InviteUserDialog } from "./InviteUserDialog";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import MissingPIDLoader from "../../component/Loader/MissingPIDLoader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Sites = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [tabledata, setTableData] = React.useState([]);
  const [ecoLabelData, setEcoLabelData] = React.useState([]); // ecolabel api state
  const [reportsData, setReportsData] = React.useState([]); // report api state
  const [validatedData, setValidatedData] = React.useState(0);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [profileStatus, setProfileStatus] = React.useState(false);

  const handleSnackbarClose = async () => {
    setSnackbarOpen(false);
  }
  const handleInviteUser = () => {
    // Open the invite user dialog
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    // Close the invite user dialog
    setOpenInviteDialog(false);
  };

  const handleSaveUser = async (userData) => {
    try {
      // Handle saving the user data (call your API, etc.)
      if(tabledata.length>=3){
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("You have access to add 3 sites only, please contact support");
        return;
      }
      setBackdropOpen(true)
      const inviteSiteResult = await axios.post(API_URL + `/sites/invite`, { siteList: [userData] }, headers);
      if (!inviteSiteResult) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to upload file, make sure it is in the correct format");
        return [];
      } else {
        console.log("upload vendor result : - ", inviteSiteResult);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Non existing sites added and admins invited successfully");
        getSitesByPID();
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to upload file, make sure it is in the correct format");
    }
    setBackdropOpen(false)
  };
  const columns = [
    // Define your columns here based on your data structure
    { name: 'id', label: 'ID' },
    { name: 'name', label: 'Name' },
    { name: 'address', label: 'Address' },
    { name: 'type', label: 'Type' },
    // { name: 'Product', label: 'Product' },
    // Add more columns as needed
  ];
  const options = {
    filter: true,
    selectableRows: 'single',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
    onRowsDelete: async (rowsDeleted, newTableData) => {
      setBackdropOpen(true)
      let deletedItemIds = [];
      console.log("newTableData : - ", newTableData);
      console.log("rowsDeleted : - ", rowsDeleted);
      let tableItems = { ...tabledata }
      rowsDeleted.data.map((value) => {
        console.log("value in map : - ", value);
        deletedItemIds.push({ _id: tableItems[value.index][0] })
      });
      console.log("deletedItemIds : - ", deletedItemIds);
      setTableData(newTableData);

      let reqObj = {
        deletedItemIds: deletedItemIds
      };
      console.log("deleted Objects ; - ", reqObj);
      let { data, status } = await axios.put(API_URL + `/vendor/deleteSites`, reqObj, headers);
      if (data && status===200) {
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Sites removed successfully");
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Unable to remove sitess");
      }
      setBackdropOpen(false)
    },
  };

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // reports api by P.I.D data
  const getSitesByPID = async () => {
    try {
      setBackdropOpen(true)
      const { data } = await axios.get(API_URL + `/participant/getParticipantSitesRelations`, headers);
      if (!data || !data[0].Sites) {
        setTableData([]);
      } else {
        if (data[0].Sites.length > 0) {
          let dataRes = data[0].Sites.reduce((acc, curr) => {
            let arr = [curr._id, curr.Name, curr.Address, curr.Type];
            acc.push(arr);
            return acc;
          }, []);
          setTableData(dataRes);
        } else {
          setTableData([]);
        }
      }
    } catch (err) {
      console.log(err, "err in reports api");
    }
    setBackdropOpen(false)
  }

  React.useEffect(() => {
    checkMissingPID();
    getSitesByPID(); // call an reports api
    // getAllValidatedReports(); // call an api 
  }, []);

  const checkMissingPID = async () => {
    try {
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      if (user?.data?.PID && user?.data?.PID !== "") {
        setProfileStatus(true);
      }
    } catch (error) {
      console.log("Error inside checkMissingPID function : - ", error.stack);
    }
  }

  return (
    <div>
      {backdropOpen && <FullScreenLoader />}
      {!profileStatus && <MissingPIDLoader />}
      <Typography variant="h5" style={{ margin: "1rem" }}>
        <strong>Sites Management</strong>
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} ml={1}>

          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px", padding: "0rem", marginTop: '8px' }}>
              <MUIDataTable
                title={
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                    <strong style={{ margin: '0', padding: '0', fontSize: '1rem' }}>Sites</strong>
                    <Button
                      variant="contained"
                      style={{ marginLeft: '8px', marginRight: '8px' }}
                      startIcon={<PersonAddIcon />}
                      onClick={handleInviteUser}
                    >
                      Add New
                    </Button>
                    {/* <BulkInvite refresh={getSitesByPID} l={tabledata?.length??0}/> */}
                  </div>
                }
                data={tabledata}
                columns={columns}
                options={options}
              />
            </Item>
          </Grid>
          <InviteUserDialog
            open={openInviteDialog}
            onClose={handleCloseInviteDialog}
            onSave={handleSaveUser}
          />
        </Grid>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Sites;
