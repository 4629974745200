/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TextField from '@mui/material/TextField';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
// snackbar notifications
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import login component
import Login from "../Login/Login";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
// import API_URL and Cookies
import { CONSTANTS, HEADERS2 } from "../utils/constants";
// import Loader
import FullScreenLoader from "../Loader/FullScreenLoader";
// import Register company c.s.s
import "./RegisterCompany.css";
import { Autocomplete } from "@mui/material";
import { countries } from "country-list-json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    paddingLeft: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterCompany = (props) => {
  const classes = useStyles();
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [tezosappid, setTezosAppId] = useState(1);
  const [appnetworkid, setAppNetworkId] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [response, setResponse] = useState();
  const [responseCode, setResponseCode] = useState();
  const [redirect, setRedirect] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [userCreate, setUserCreate] = useState(false);
  const [fullscreenloader, setFullScreenLoader] = useState(false);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(company, city, country, role, email, tezosappid, appnetworkid);
  };

  const handleUserReg = async () => {
    try {
      props.checkNew(true);
      props.page();
      props.pstate("login");
    } catch (e) {
      console.log(e);
      setErrMsg(`Error ${JSON.parse(JSON.stringify(e)).status}`);
    }
  };

  const handleCreate = async () => {
    try {
      if (company === "" || company.length === 0) {
        setErrMsg("Company Name is required");
      } else if (city === "" || city.length === 0) {
        setErrMsg("City Name is required");
      } else if (country === "" || country.length === 0) {
        setErrMsg("Country Name is required");
      } else if (email === "" || email.length === 0) {
        setErrMsg("Email is required");
      } else if (role === "" || role.length === 0) {
        setErrMsg("Role is required");
      } else {
        setFullScreenLoader(true);
        const body = {
          company,
          city,
          country,
          email,
          role,
          address,
          shippingAddress,
          TezosAppID: tezosappid,
          AppNetworkID: [cookies.Nid],
        };
        const { data, status } = await axios.post(
          API_URL + "/participant/create",
          body,
          headers
        );
        console.log(data, "register data");
        setResponseCode(status);
        setResponse(data);
        setOpen(true);
        setFullScreenLoader(false);
        setCookie("PID", data.PID, { path: "/" });
        if (status === 200) {
          setUserCreate(true);
          // window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
      setErrMsg(`Error ${JSON.parse(JSON.stringify(e)).status}`);
      setResponseCode(JSON.parse(JSON.stringify(e)).status);
      setResponse(
        "Unable to reach the server, please check your internet connection"
      );
      setOpen(true);
      setFullScreenLoader(false);
    }
  };

  return (
    <>
      {fullscreenloader && <FullScreenLoader />}
      {!fullscreenloader && (
        <>
          {/* {userCreate && (
            <Card className="cardSmall">
              <form
                onSubmit={handleSubmit}
                className={classes.root}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <Typography
                  style={{
                    color: "#0099ff",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                >
                  VChained
                </Typography>

                <Typography
                  style={{
                    marginTop: "5%",
                    marginBottom: "5%",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  Logging in for the first time? <br />
                  Let us help you setup
                </Typography>

                <Button
                  type="submit"
                  onClick={handleUserReg}
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Create user
                </Button>
              </form>
            </Card>
          )} */}

          {!userCreate && (
            <>
              <form className={classes.root} onSubmit={handleSubmit}>
                <Typography
                  style={{
                    // color: "#0099ff",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  Register A New Company
                </Typography>

                <div className="textfield">
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />

                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="textfield">
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={(option) =>
                      option ? option.name : "Select A Country"
                    }
                    name="PID"
                    onChange={(event, newValue) => {
                      setCountry(newValue.name);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select A Country"
                        variant="outlined"
                        // fullWidth
                      />
                    )}
                  />
                  <TextField
                    label="City"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value.toLowerCase())}
                    required
                  />
                </div>

               <div className="textfield">
                <TextField
                  label="Address"
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                /> 

                <TextField
                  label="Shipping Address"
                  variant="outlined"
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                  required
                /> 
               </div>

                <div className="textfield">
                  {/* <TextField
                    label="App Network ID"
                    variant="outlined"
                    value={cookies.Nid}
                    disabled
                    // onChange={(e) => setAppNetworkId(e.target.value)}
                    required
                  /> */}

                  {/* <TextField
                  label="TezosApp Id"
                  variant="outlined"
                  value={tezosappid}
                  onChange={(e) => setTezosAppId(1)}
                // required
                /> */}
                
                  <TextField
                    label="Enter Your Role"
                    variant="outlined"
                    helperText="Assign company role: Logistics, Retailer etc."
                    value={role}
                    onChange={(e) =>
                      setRole(e.target.value.toLocaleLowerCase())
                    }
                    required
                  />
                </div>
                {/* <div className="lastTextfield">

                <TextField
                  label="Enter Your Role"
                  variant="outlined"
                  helperText="Assign company role: Logistics, Retailer etc."
                  value={role}
                  onChange={(e) => setRole(e.target.value.toLocaleLowerCase())}
                  required
                />

              </div> */}

                {errMsg && (
                  <Typography style={{ color: "red", textAlign: "center" }}>
                    {errMsg}
                  </Typography>
                )}
                <div className="buttons">
                  <Button
                    type="submit"
                    onClick={handleCreate}
                    variant="contained"
                    style={{backgroundColor:"black", color: "white"}}
                  >
                    Register
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => props.pstate("login")}
                    variant="contained"
                    style={{backgroundColor:"black", color: "white"}}
                  >
                    Go Back
                  </Button>
                </div>
                {response && !errMsg && (
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <Snackbar
                      open={open}
                      autoHideDuration={10000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={
                          responseCode === 200 || responseCode === 201
                            ? "success"
                            : "warning"
                        }
                        sx={{ width: "100%" }}
                      >
                        {responseCode === 200 || responseCode === 201
                          ? "Profile updated successfully"
                          : "Failed to update request"}
                      </Alert>
                    </Snackbar>
                  </Stack>
                )}
              </form>
              {redirect && <Login redirect={() => setRedirect(!redirect)} />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegisterCompany;
