import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useCookies } from "react-cookie";
import { Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Card, CardContent } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
// import Report component
import ReportCopy from "../VCarbonPages/ReportCopy";
// import User Access Component
import UserAccessCopy from "./UserAccessCopy";
// import Signed Contract Component
import SignedContract from "../SignedContract/SignedContract";
// import Sites Copy Component
import SitesCopy from "./SitesCopy";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const years = [2021, 2022, 2023];

const Organizations = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [scope1, setScope1] = React.useState(0);
  const [scope2, setScope2] = React.useState(0);
  const [scope3, setScope3] = React.useState(0);
  const [highestImpactItems, setHighestImpactItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [emissionDashboardData, setEmissionDashboardData] = React.useState([]); // emission dashboard api state
  const [participantDetials, setParticipantDetails] = React.useState([]); // api state
  const [contractDetailsData, setContractDetailsData] = React.useState([]); // contract api state
  const [missingLoader, setMissingLoader] = React.useState(false);
  const [fromDate, setFromDate] = React.useState("1 Jan 2023");
  const [toDate, setToDate] = React.useState("31 Dec 2023");
  const [selectedYear, setSelectedYear] = React.useState(years[2]);

  const handleYearChange = (year) => {
    if (!year) {
      setMissingLoader(true);
    }
    setSelectedYear(year);
    let tom = "31 Dec " + year;
    let fom = "1 Jan " + year;
    setFromDate(fom);
    setToDate(tom);
    getDashboardEmissions(fom, tom);
  };

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // header
  const headers = HEADERS2(cookies.token, cookies.Nid);

  let donutChartData = {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    datasets: [
      {
        data: [scope1, scope2, scope3],
        backgroundColor: ["#003E35", "#0C6659", "#46AD8D"],
      },
    ],
  };

  const [scopeImpact, setScopeImpact] = React.useState({
    "Scope 1": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 2": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 3": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });

  const fetchDataMapping = async (data) => {
    try {
      // Perform API call or data fetching logic here to get the data mapping for the selected month and year
      // const { data } = await axios.get(API_URL +
      //   `/emissions/get?year=${selectedYear}&&month=${selectedMonth}`,
      //    headers
      // )
      if (data) {
        setScope1(data.Emissions.Scope1 ?? 0);
        setScope2(data.Emissions.Scope2 ?? 0);
        setScope3(data.Emissions.Scope3 ?? 0);
        if (data.ImpactOverTime) {
          setScopeImpact(data.ImpactOverTime);
        }
        setTotal(data.Total);
        // let t = getMaxTotalValues(data)
        setHighestImpactItems(data.highestImpactingItems);
      } else {
        console.log(data, "this is data");
        setMissingLoader(true);
      }
    } catch (err) {
      setMissingLoader(true);
      console.log(err, "err in get api");
    }
  };
  let lineChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    //["#003E35", "#0C6659", "#46AD8D"],
    datasets: [
      {
        label: "Scope 1",
        data: scopeImpact["Scope 1"],
        backgroundColor: "rgba(0, 62, 53, 0.5)",
        borderColor: "#003E35",
        fill: true,
      },
      {
        label: "Scope 2",
        data: scopeImpact["Scope 2"],
        backgroundColor: "rgba(12, 102, 89,0.5)",
        borderColor: "#0C6659",
        fill: true,
      },
      {
        label: "Scope 3",
        data: scopeImpact["Scope 3"],
        backgroundColor: "rgba(0, 135, 132,0.5)",
        borderColor: "#46AD8D",
        fill: true,
      },
    ],
  };

  // post api for selected dates dashboard emissions
  const getDashboardEmissions = async (from, to) => {
    try {
      const dashboardBody = {
        From: decodeURIComponent(cookies.from),
        To: decodeURIComponent(cookies.to),
      };
      const { data } = await axios.post(
        API_URL + `/ef/getDashboard`,
        dashboardBody,
        headers
      );
      if (!data) {
        setEmissionDashboardData([]);
      } else {
        setEmissionDashboardData(data.data);
        fetchDataMapping(data.data);
      }
    } catch (err) {
      console.log(err, "err in get dashboard emissions");
    }
  };

  // Call an Get_Department DID Details
  const getParticipantDetails = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/participant/getParticipant`,
        headers
      );
      if (!data) {
        setParticipantDetails([]);
      } else {
        setParticipantDetails(data);
        console.log(data, "all participant data here...");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get all contracts api
  const getAllContracts = async () => {
    try {
      const { data } = await axios.get(API_URL + `/contracts/getAll`, headers);
      if (!data) {
        setContractDetailsData([]);
      } else {
        setContractDetailsData(data);
        console.log(data, "get all contract data");
      }
    } catch (err) {
      console.log(err, "err in get all contract");
    }
  };

  React.useEffect(() => {
    getParticipantDetails(); // call an participant api
    getAllContracts(); // call an contract api
    getDashboardEmissions(fromDate, toDate); // call an api
    handleYearChange(selectedYear);
  }, []);

  return (
    <div>
      <Typography variant="h5" style={{ margin: "1rem" }}>
        <strong>Organization Analytics</strong>
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} ml={1}>
          <Grid item xs={4}>
            {/* <Item> */}
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent sx={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ width: "80px", height: "80px", marginRight: "10px" }}
                >
                  <Doughnut
                    data={donutChartData}
                    options={{
                      maintainAspectRatio: false,
                      plugins: { legend: { display: false } },
                    }}
                  />
                </div>
                <div>
                  <Typography variant="h6" component="div">
                    <strong>Emissions Accuracy</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    30%
                  </Typography>
                </div>
              </CardContent>
            </Card>
            {/* </Item> */}
          </Grid>
          <Grid item xs={4}>
            {/* <Item> */}
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent sx={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "10px",
                  }}
                >
                  <Doughnut
                    data={donutChartData}
                    options={{
                      maintainAspectRatio: false,
                      plugins: { legend: { display: false } },
                    }}
                  />
                </div>
                <div>
                  <Typography variant="h6" component="div">
                    <strong>Participants</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    3
                  </Typography>
                </div>
              </CardContent>
            </Card>
            {/* </Item> */}
          </Grid>
          <Grid item xs={4}>
            {/* <Item> */}
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent sx={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    marginLeft: "10px",
                  }}
                >
                  <Doughnut
                    data={donutChartData}
                    options={{
                      maintainAspectRatio: false,
                      plugins: { legend: { display: false } },
                    }}
                  />
                </div>
                <div>
                  <Typography variant="h6" component="div">
                    <strong>Participant Breakdown</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    $24322
                  </Typography>
                </div>
              </CardContent>
            </Card>
            {/* </Item> */}
          </Grid>

          {/* Emissions Breakdown start here */}
          <Grid item xs={4}>
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6" component="div">
                    <strong>Emissions Breakdown</strong>
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">
                    <strong>{total.toFixed(2)} ton CO2-eq</strong>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Doughnut
                    data={donutChartData}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* Emissions breakdown ends here */}

          {/* Emissions By Category start here */}
          <Grid item xs={8}>
            <Card
              style={{
                height: "380px",
                overflow: "auto",
                borderRadius: "1rem",
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <strong>Emissions By Category</strong>
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Co2</TableCell>
                        <TableCell>Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    {highestImpactItems?.length > 0 && (
                      <TableBody>
                        {highestImpactItems?.map((hImpactDetails, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {hImpactDetails?.category ?? ""}
                            </TableCell>
                            <TableCell>{hImpactDetails?.total ?? ""}</TableCell>
                            <TableCell>{`${
                              (
                                (hImpactDetails.total ??
                                  0 / emissionDashboardData.Total ??
                                  0) * 100 ?? 0
                              ).toFixed(2) ?? 0
                            }% of total`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          {/* Emissions By Category ends here */}

          {/* Expense Breakdown start here */}
          <Grid item xs={4}>
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6" component="div">
                    <strong>Expense Breakdown</strong>
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">
                    <strong>{total.toFixed(2)} ton CO2-eq</strong>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Doughnut
                    data={donutChartData}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* Expense Breakdown ends here */}

          {/* Particiapnt Emissiosn start here */}
          <Grid item xs={8}>
            <Card
              style={{
                height: "380px",
                overflow: "auto",
                borderRadius: "1rem",
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <strong>Participant Emissions</strong>
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>CO2</TableCell>
                        <TableCell>Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    {highestImpactItems.length > 0 && (
                      <TableBody>
                        {highestImpactItems?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item["_id"]}</TableCell>
                            <TableCell>{item["subActivity"]}</TableCell>
                            <TableCell>{item.total}</TableCell>
                            <TableCell>{`${
                              (
                                (item.total ??
                                  0 / emissionDashboardData.Total ??
                                  0) * 100 ?? 0
                              ).toFixed(2) ?? 0
                            }% of total`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          {/* Participant Emissions ends here */}

          {/* Participant Declarelations start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" }}>
              <UserAccessCopy />
            </Item>
          </Grid>
          {/* Participant Declareations ends here */}

          {/* Signed Contract start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" }}>
              <SignedContract />
            </Item>
          </Grid>
          {/* Signed Contract ends here */}

          {/* Cumulative Reports start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" }}>
              <ReportCopy />
            </Item>
          </Grid>
          {/* Cumulutive Reports ends here */}

          {/* Sites Component  start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" }}>
              <SitesCopy />
            </Item>
          </Grid>
          {/* Sites Component ends here */}
        </Grid>
      </Box>
    </div>
  );
};

export default Organizations;
