import {
  HomeIcon,
  SearchIcon,
  ChartBarIcon,
  CalculatorIcon,
  KeyIcon,
  UserGroupIcon,
  BriefcaseIcon,
} from "@heroicons/react/outline";
import {
  AnalyticsOutlined,
  CalendarMonthOutlined,
  MapsHomeWorkOutlined,
} from "@mui/icons-material";

export const sideMenu = [
  {
    label: "Dashboard",
    Icon: HomeIcon,
    to: "/",
  },
  {
    label: "Calculate",
    Icon: CalculatorIcon,
    to: "/calculate",
  },
  {
    label: "Monitor",
    Icon: UserGroupIcon,
    to: "/monitor",
    children: [
      {
        label: "Emissions Analytics",
        Icon: ChartBarIcon,
        to: "emission",
      },
      {
        label: "Sites",
        Icon: MapsHomeWorkOutlined,
        to: "sites",
      },
      // {
      //   label: "Vendors",
      //   Icon: MapsHomeWorkOutlined,
      //   to: "vendors",
      // },
    ],
  },
  {
    label: "Disclosures",
    Icon: KeyIcon,
    to: "/disclosures",
  },
  {
    label: "Explore GDP",
    Icon: SearchIcon,
    to: "/exploreGDP",
  },
  {
    label: "Manage",
    Icon: UserGroupIcon,
    to: "/manage",
    children: [
      {
        label: "Employees",
        Icon: UserGroupIcon,
        to: "employees",
      },
      {
        label: "Vendors",
        Icon: BriefcaseIcon,
        to: "vendors",
      },
      {
        label: "Sites",
        Icon: MapsHomeWorkOutlined,
        to: "sites",
      },
    ],
  },
  {
    label: "Plan A Consult",
    Icon: CalendarMonthOutlined,
    to: "/plan",
  },
];
