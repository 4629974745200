import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
// All import Component
import Dashboard from "./OrganizationAdminstation/Dashboard";
import UserAccess from "./OrganizationAdminstation/UserAccess";
import Employees from "./OrganizationAdminstation/Employees";
import Sites from "./OrganizationAdminstation/Sites";
import Vehicles from "./TransportationPages/Vehicles";
import Organizations from "./OrganizationAdminstation/Organizations";

const Adminstration = () => {
    let {path} = useRouteMatch();
    return(
        <>
         <Switch>
            <Route path={`${path}/dasboard`}>
                <Dashboard />
             </Route>
             <Route path={`${path}/organization`}>
                <Organizations />
             </Route>
             <Route path={`${path}/participant`}>
                <UserAccess />
             </Route>
             <Route path={`${path}/employees`}>
                <Employees />
             </Route>
             <Route path={`${path}/sites`}>
                <Sites />
             </Route>
             <Route path={`${path}/vehicles`}>
                <Vehicles />
             </Route>
         </Switch>
        </>
    )
}


export default Adminstration;