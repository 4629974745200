const url = process.env.API_URL ?? 'https://app.votan.eco'
export const CONSTANTS = {
  API_URLS: url + "/api",
  API_URL: url + "/api",
  UPLOAD_URL: url + "/api",
  JOIN_LAUNCHPAD_URL: "https://votan.eco/join-launchpad"
};

export const HEADERS = (token) => {
  return {
    headers: {
      "x-access-token": token,
    },
  };
};
export const HEADERS2 = (token, Nid) => {
  return {
    headers: {
      "x-access-token": token,
      netid: Nid,
    },
  };
};
export const HEADERSUPLOAD = (token, Nid) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": token,
      netid: Nid,
    },
  };
};

export const INR_TO_EUR = (A) => { return (A * .0121) || 0; }
export const EUR_TO_INR = (A) => { return (A / .0121) || 0; }
