// import react
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import React Cookie
import { useCookies } from "react-cookie";
import VotanLogo from "../../assets/images/votan_text_white.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import axios
import axios from "axios";
// import headers
import { CONSTANTS, HEADERS2 } from "../utils/constants";
// import Navlink
import { NavLink, useHistory } from "react-router-dom";
// import Notification from "../NotificationMessage/Notification";
import { Chip } from "@material-ui/core";
// toaster snackbar
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
// import Your Profile Component
import YourProfile from "../../Pages/YourProfile/YourProfile";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// search box icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Notification from "../../Pages/Notifications/Notification";
import { DateRangePicker } from "react-date-range";
import { parse } from "date-fns";
import moment from "moment";
import { Button } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

// Search box start here
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));
// Search box ends here

export default function PrimarySearchAppBar(props) {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileStatus, setProfileStatus] = React.useState(false);

  const [anchorNl, setAnchorNl] = React.useState(null);
  const [anchorAl, setAnchorAl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [mobileMoreAnchorNl, setMobileMoreAnchorNl] = React.useState(null);
  const [mobileMoreAnchorAl, setMobileMoreAnchorAl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [opens, setOpens] = React.useState(false);
  // search bar state
  const [searchValue, setSearchValue] = React.useState("");

  const handleCookie = () => {
    removeCookie("token", { path: "/" });
    removeCookie("email", { path: "/" });
    removeCookie("id", { path: "/" });
    removeCookie("user_id", { path: "/" });
    removeCookie("UserID", { path: "/" });
    removeCookie("PID", { path: "/" });
    removeCookie("profileStatus", { path: "/" });
    removeCookie("terms", { path: "/" });
    // removeCookie("from", { path: "/" });
    // removeCookie("to", { path: "/" });

    history.push("/");
    window.location.reload();
  };

  const [date, setDate] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // get participant by id
  React.useEffect(() => {
    getParticipantById();
    // console.log(formatAsyyyyMMDD(new Date()),'this is date')
  }, []);

  const getParticipantById = async () => {
    try {
      let temp = [];
      const { data } = await axios.get(API_URL + `/participant/id`, headers);
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      if (cookies.from && cookies.to) {
        try {
          let fDate = decodeURIComponent(cookies.from)
          let tDate = decodeURIComponent(cookies.to)
          console.log(fDate + ' - ' + tDate, 'test')

          // Parse the date strings
          const formattedFromDate = parse(fDate, 'yyyy/MM/dd', new Date());
          const formattedToDate = parse(tDate, 'yyyy/MM/dd', new Date());

          // // Format the parsed dates into "yyyy/MM/dd" format
          console.log(formattedFromDate + ' - ' + formattedToDate, 'test')
          handleChange({ selection: { startDate: formattedFromDate, endDate: formattedToDate } })

        } catch (error) {
          console.error('Error parsing dates:', error);
          // Handle the error, maybe set default values or display an error message
        }
      }

      if (
        data?.error ||
        !data?.Notifications ||
        data?.Notifications.length === 0
      ) {
        console.log(data, user, "data & user");
        temp = [];
      } else {
        temp = data?.Notifications;
      }
      if (user?.data?.PID && user?.data?.PID !== "") {
        setProfileStatus(true);
        setCookie("profileStatus", "complete", { path: "/" });
      }
      if (user?.data?.role === "admin") {
        temp.concat(data.adminNotifications);
      }
      console.log(temp);
      // setRows(temp);
      // setSuccess('Data refreshed successfully')
      // setOpens(true)
    } catch (err) {
      setOpens(true)
      setError('Failed to get data from the server')
      // handleCookie();
      setError(err.message)
      if (err.response?.data?.error === "login again") {
        handleCookie();
      }
      console.log(JSON.stringify(err), "err in participant by id api");
    }
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationOpen = Boolean(anchorNl);
  const isMobileNotificationOpen = Boolean(mobileMoreAnchorNl);
  const isAccountBalanceWalletOpen = Boolean(anchorAl);
  const isMobileAccountBalanceWalletOpen = Boolean(mobileMoreAnchorAl);

  const handleChange = (ranges) => {
    try {
      console.log(ranges)
      // setDate(ranges.selection);
      let newState = ranges.range1 ?? ranges.selection;
      newState.From = moment(newState.startDate).format("yyyy/MM/DD");
      newState.To = moment(newState.endDate).format("yyyy/MM/DD");
      setDate(newState);
      setCookie("from", newState.From, { path: "/" });
      setCookie("to", newState.To, { path: "/" });
    } catch (e) {
      console.log(e, 'Error reported')
    }

  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileNotificationOpen = (event) => {
    setAnchorNl(event.currentTarget);
  };

  const handleWalletNetworkOpen = (event) => {
    setAnchorAl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // add history push method to redirect to new page
    history.push("/admin/profile"); // navigate profile page
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorNl(null);
    handleMobileNotificationClose();
  };

  const handleMobileNotificationClose = () => {
    setMobileMoreAnchorNl(null);
  };

  const handleAccountBalanceWalletClose = () => {
    setAnchorAl(null);
    handleMobileAccountBalanceWalletClose();
    window.location.reload();
  };

  const handleMobileAccountBalanceWalletClose = () => {
    setMobileMoreAnchorAl(null);
  };

  // Search bar handle navigation
  const handleNavigateTracker = () => {
    history.push(`/tracker?query=${searchValue}`);
  };

  // handle serach bar function
  const handleSearchBar = (event) => {
    console.log(event.target.value, "event target");
    setSearchValue(event.target.value);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchororigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleCookie}>Logout</MenuItem>
    </Menu>
  );

  // wallet
  const walletId = "primary-search-wallet-network";
  const renderWalletNetwork = (
    <>
      <Menu
        style={{ marginTop: "2rem" }}
        anchorEl={anchorAl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={walletId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isAccountBalanceWalletOpen}
        onClose={handleAccountBalanceWalletClose}
      >
        <DateRangePicker
          value={date}
          onChange={handleChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[date]}
          maxDate={new Date()}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="forwards"
          color='#3A7B6E'
          rangeColors={['#3A7B6E']}
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
          <Button onClick={handleAccountBalanceWalletClose} variant="contained" color="primary">
            Save Changes
          </Button>
        </div>
      </Menu>
    </>
  );

  // mobile wallet
  const mobileMobileWalletId = "primary-search-wallet-network-mobile";
  const renderMobileWalletNetworkMenu = (
    <Menu
      mobilemoreanchoral={mobileMoreAnchorAl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMobileWalletId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileAccountBalanceWalletOpen}
      onClose={handleMobileAccountBalanceWalletClose}
    >
      <MenuItem onClick={handleWalletNetworkOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccessTimeIcon />
          {/* <Typography>Network</Typography> */}
          {/* <Chip icon={
          <AccountBalanceWallet 
            style={{color:'black'}} />} 
            label="Switch Network" 
            onClick={()=>console.log('clicked')} 
          /> */}
        </IconButton>
        <Typography>Wallet</Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Typography>Profiles</Typography>
      </MenuItem> */}
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleCookie}>Logout</MenuItem>
    </Menu>
  );

  //   notifications
  const notificationId = "primary-search-account-notification";
  const renderNotification = (
    <Menu
      style={{ width: "50%", height: "50%", marginTop: "1.6rem" }}
      anchorEl={anchorNl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={notificationId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isNotificationOpen}
      onClose={handleNotificationClose}
    >
      {rows?.map((m, index) => (
        <div key={index}>
          <MenuItem style={{ overflowX: "scroll" }}>{m}</MenuItem>
          <hr />
        </div>
      ))}
      {rows.length > 0 && (
        <NavLink
          to="/notification"
          style={{ textDecoration: "none", color: "#3b7b6f" }}
        >
          <MenuItem style={{ fontSize: "14px" }}>View All</MenuItem>
        </NavLink>
      )}
      {rows.length === 0 && (
        <NavLink
          to="/notification"
          style={{ textDecoration: "none", color: "#3b7b6f" }}
        >
          {/* <MenuItem style={{ fontSize: "14px" }}> */}
          {/* notification empty message component start here */}
          {/* <Notification /> */}
          <Notification />
          {/* notification empty message component ends here */}
          {/* </MenuItem> */}
        </NavLink>
      )}
    </Menu>
  );

  // mobile notifications
  const mobileNotificationId = "primary-search-account-menu-notification";
  const renderNotificationMenu = (
    <Menu
      mobilemoreanchorel={mobileMoreAnchorNl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileNotificationId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileNotificationOpen}
      onClose={handleMobileNotificationClose}
    >
      <MenuItem onClick={handleProfileNotificationOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <NotificationsIcon />
        </IconButton>
        <Typography>Notification</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={classes.grow}>
        <AppBar position="static" style={{ background: "#3b7b6f" }}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => props.clicker(!props.state)}
            >
              <MenuIcon />
            </IconButton>

            <NavLink to="/">
              <img
                style={{ width: "180px", height: "40px" }}
                src={VotanLogo}
                alt="logo"
              />
            </NavLink>

            {/* Search bar component start here */}
            <Search
              value={searchValue || ""}
              onChange={(e) => handleSearchBar(e)}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  // Do code here
                  history.push(`/tracker?query=${searchValue}`);
                  ev.preventDefault();
                }
              }}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
              <ArrowForwardIosIcon onClick={handleNavigateTracker} />
            </Search>
            {/* Search bar component ends here */}

            <div className={classes.grow} />
            {/* {renderWalletNetwork} */}
            <div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={walletId}
                aria-haspopup="true"
                onClick={handleWalletNetworkOpen}
                color="inherit"
              >
                {/* <Badge badgeContent={rows.length} color="error"> */}
                {/* <AccountBalanceWallet  />  */}
                <Chip
                  icon={<AccessTimeIcon style={{ color: "black" }} />}
                  label={(date.From && date.To) ? date.From + '-' + date.To : 'select a date range'}
                />
                {/* </Badge> */}
              </IconButton>
            </div>

            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {/*Component calling here  */}
        {!profileStatus && <YourProfile />}
        {/* Component ends here  */}
        {renderWalletNetwork}
        {renderMobileWalletNetworkMenu}
        {renderNotificationMenu}
        {renderNotification}
        {renderMobileMenu}
        {renderMenu}
      </div>
      {(error || success) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar open={opens} autoHideDuration={2000} onClose={handleCloses}>
            <Alert
              onClose={handleCloses}
              autoHideDuration={2000}
              severity={error ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {error ? error : success}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </React.Fragment>
  );
}
