import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import ListAltIcon from '@mui/icons-material/ListAlt';
// import image
// import CubeImage from "../../assets/images/cubes.png";
// import Loader component
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// import Empty Table Message component
import EmptyTable from "../../component/EmptyTable/EmptyTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    timeline: {
      transform: "rotate(90deg)"
    },
    timelineContentContainer: {
    //   textAlign: "left"
      textAlign: "right"
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(-90deg)",
      textAlign: "center",
      minWidth: 50,
    //   border:"2px solid red"
    },
    timelineIcon: {
      transform: "rotate(-90deg)"
    }
  });

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    width: "100%",
  },
}));

const ReportView = (props) => {
  const classes = useStyles();
  const [rows, setRows] = React.useState({});
  const [selected, setSelected] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const timeline = [
  {
    label:'Requested',
    status:'requested'
  },
  {
    label:'In Process',
    status:'inProcess'
  },
  {
    label:'Verifying data',
    status:'verifying'
  },
  {
    label:'Finalizing & Generating report',
    status:'finalizing'
  },
  {
    label:'Completed',
    status:'completed'
  }
]

  // useEffect
  React.useEffect(() => {
    console.log(props.obj,"props obj here")
    console.log(rows, "track rows here");
    console.log(props?.obj?.row, "props job lifecycle data here");
    // getBlockchainHistory(props.obj.id);
    props.obj ? setRows(props?.obj?.row) : setRows([]);
    setIsLoading(false);
  }, [props?.obj]);

  const getSelected = async (i) => {
    try {
      console.log("hello selected", i);
      setSelected(i);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {!isLoading && (
        <Grid container direction="row">
          {timeline?.length === 0 && <EmptyTable />}
          {timeline?.length > 0 && (
            <Grid xs={10} style={{ marginLeft:"auto", marginRight:"auto" }}>
              <Item style={{ height: "auto" }}>
                <h5>
                  Report Timeline{" "}
                </h5>
                <Timeline 
                  // className={classes.timeline} 
                  position="alternate">
                  {timeline?.map((reportInfo, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                        // className={classes.timelineContent}
                      >
                        {reportInfo?.label}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                        //   className={classes.timelineIcon}
                          style={{ 
                            backgroundColor: 
                              rows.status === reportInfo.status ? '#1f398a' : 'black' 
                            }}
                          onClick={() => getSelected(reportInfo?.label)}
                        >
                          <Grid xs={4}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Item>
                                    <Typography color="inherit"></Typography>
                                      click to view details
                                  </Item>
                                </React.Fragment>
                              }
                            >
                              <ListAltIcon />
                            </HtmlTooltip>
                          </Grid>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent  
                          // className={classes.timelineContentContainer}
                          >
                        <Typography variant="p" component="span">
                          <strong>{reportInfo.From}</strong>
                        </Typography>
                        <Typography>{reportInfo.To}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
                {/* hover */}
              </Item>
            </Grid>
          )}

        </Grid>
      )}
    </>
  );
};

export default ReportView;
