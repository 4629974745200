import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import { useCookies } from "react-cookie";
import { Typography } from '@mui/material';
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
export const BulkInvite = (props) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const API_URL = CONSTANTS.API_URL;
  const handleSnackbarClose = async () => {
    setSnackbarOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    try {// Call your API to handle the file upload here
      // Pass 'file' to your API endpoint
      console.log('File uploaded:', file);
      console.log('cookies : - ', cookies);

      if (file) {
        // Check file type
        const allowedFileTypes = ["csv", "xlsx", "numbers"];
        const fileType = file.name.split(".").pop().toLowerCase();
        if (!allowedFileTypes.includes(fileType)) {
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Invalid file type. Please upload a CSV or XLSX file.");
          return;
        }

        // Check file size
        const maxSize = 4 * 1024 * 1024; // 4 MB
        if (file.size > maxSize) {
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("File size exceeds the maximum limit of 4 MB.");
          console.error("File size exceeds the maximum limit of 4 MB.");
          return;
        }
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          console.log("JSON : - ", json);
          await makeUploadData(json);
        };
        reader.readAsBinaryString(file);
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to parse through the file uploaded");
    }

    // Close the dialog
    handleClose();
  };

  const makeUploadData = async (jsonArr) => {
    try {
      let uploadData = [];
      let keys = Object.values(jsonArr[0]);
      console.log("Keys : - ", keys);
      for (let i = 1; i < jsonArr.length; i++) {
        let values = Object.values(jsonArr[i]);
        console.log("values : - ", values);
        let singleRow = {};
        for (let j = 0; j < keys.length; j++) {
          singleRow[keys[j]] = values[j];
        }
        uploadData.push(singleRow);
      }
      let reqObj = uploadData;
      console.log("req object for bulk upload : - ", reqObj)
      const uploadVendorsResult = await axios.post(API_URL + `/mailer/bulkInvite`, reqObj, headers);
      if (!uploadVendorsResult) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to upload file, make sure it is in the correct format");
        return [];
      } else {
        console.log("upload vendor result : - ", uploadVendorsResult);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("File uploaded and employees invited successfully");
        props.refresh();
        // return uploadVendorsResult.data;
      }
      console.log("uploadData : - ", uploadData);
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to parse through the file uploaded");
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<CloudUploadIcon />}
      >
        Bulk Invite
      </Button>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle><strong>Bulk Invite Employees</strong><br /> <Typography> Upload your csv file here to invite your vendors in one click</Typography></DialogTitle>

        <DialogContent style={{ textAlign: 'center', padding: '20px' }}>

          <div
            id="file-drop-box"
            style={{
              border: '2px dotted #aaa',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onDrop={(e) => {
              e.preventDefault();
              handleFileChange({ target: { files: e.dataTransfer.files } });
            }}
            onDragOver={(e) => e.preventDefault()}
          >

            <label htmlFor="file-upload" style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                id="file-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                component="span"
                variant="contained"
                color="primary"
                style={{ marginTop: '8px' }}
              >
                Select A File
              </Button>
            </label>
            <Typography> Drag your file and drop them here </Typography>
            {file && (
              <p style={{ marginTop: '8px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                Selected File: {file.name}
              </p>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: '16px' }}
            disabled={!file}
          >
            Upload
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
