import React, { useState } from "react";
import axios from "axios";
// React Cookies
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
// import API_URL
import { CONSTANTS, HEADERS2 } from "../utils/constants";
// import Loader
import FullScreenLoader from "../Loader/FullScreenLoader";
// import c.s.s
import "./login.css";
import { Button, TextField } from "@mui/material";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
    newaccount: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [newParticipant, setNewParticipant] = React.useState(true);
  const [checkNew, setCheckNew] = React.useState(false);
  const [newUser, setNewUser] = React.useState(true);
  const [fullscreenloader, setFullScreenLoader] = React.useState(false);
  const [errmsg, setErrMsg] = React.useState("");
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [responseCode, setResponseCode] = useState();
  const [response, setResponse] = useState();
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [backdropOpen, setBackdropOpen] = React.useState(false);


  const oauthResponseMessage = async (response) => {
    try {
      const USER_CREDENTIAL = jwtDecode(response.credential);
      const firstname = USER_CREDENTIAL.given_name;
      const lastname = USER_CREDENTIAL.family_name;
      const email = USER_CREDENTIAL.email;
      const PID = "";
      const password = "Test123$";
      const tnc = false;
      const { data, status } = await axios.post(API_URL + "/user/register", {
        firstname,
        lastname,
        email,
        PID: PID,
        password,
        tnc,
      });
      setEmail(USER_CREDENTIAL.email);
      setPassword(USER_CREDENTIAL.password);

      removeCookie("token", { path: "/" });
      removeCookie("email", { path: "/" });
      removeCookie("id", { path: "/" });
      removeCookie("user_id", { path: "/" });
      removeCookie("PID", { path: "/" });
      removeCookie("terms", { path: "/" });
      removeCookie("user", { path: "/" });

      setCookie("email", USER_CREDENTIAL.email, { path: "/" });

      setResponseCode(status);
      setResponse(data);
      setOpen(true);
      if (status === 200) {
        setTimeout(handleLogin(email, password), 100);
      }
    } catch (err) {
      let errorMessage = err.response.data.error
      setErrMsg(errorMessage);
    }
  };

  const oauthErrorMessage = (err) => {
    let errorMessage = err.response.data.error;
    setErrMsg(errorMessage);
  };

  const handleSnackbarClose = async () => {
    setSnackbarOpen(false);
  }

  const handleUserCheck = async () => {
    if (cookies.PID) {
      const { data, status } = await axios.get(
        API_URL + `/user/PID/${cookies.PID}/get`,
        headers
      );
      console.log("data", data);
      if (!data?.error && data.length > 0) {
        setNewUser(false);
      }
    }
  };
  // API URL
  const API_URL = CONSTANTS.API_URL;
  // header
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleLogin = async (email, password) => {
    try {
      const { data } = await axios.post(
        API_URL + "/user/login",
        {
          email,
          password,
        },
        headers
      );

      setCookie("email", email, { path: "/" });
      setCookie("token", data.Token, { path: "/" });
      setCookie("user_id", data.UserID, { path: "/" });
      setCookie("UserID", data.UserID, { path: "/" });
      setCookie("terms", data.Tnc, { path: "/" });
      var newUrl = window.location.origin + "/admin/profile";

      window.location = newUrl
      window.location.reload();
    } catch (err) {
      let errorMessage = err.response.data.error
      setErrMsg(errorMessage);
    }
  };
  const handleCookie = async () => {
    try {
      setBackdropOpen(true);
      if (email === "" || email.length === 0) {
        setErrMsg("Email Id is required");
      } else if (password === "" || password === 0) {
        setErrMsg("Password is required");
      } else if (password.length < 6) {
        setErrMsg("Password should be minimum 6 characters");
      } else {
        setErrMsg("Please Wait");
        // setFullScreenLoader(true);
        const { data,status } = await axios.post(
          API_URL + "/user/login",
          {
            email,
            password,
          },
          headers
        );
        setCookie("email", email, { path: "/" });
        setCookie("token", data?.Token, { path: "/" });
        setCookie("user_id", data?.UserID, { path: "/" });
        setCookie("UserID", data?.UserID, { path: "/" });
        setBackdropOpen(false);
        window.location.reload();
      }
    } catch (err) {
      setBackdropOpen(false);
      console.log(err.message)
      setErrMsg(err?.response?.data?.error??'Failed to login, please try again..');
      setSnackbarOpen(true)
      setSnackbarSeverity('error')
      setSnackbarMessage(err?.response?.data?.error??'Failed to login, please try again..')
    }
  };

  const handleParticipants = async () => {
    try {
      const { data } = await axios.get(API_URL + `/participant/getParticipant`);
      if (data.length > 0) {
        setNewParticipant(false);
        const usersList = await axios.get(
          API_URL + `/user/PID/${cookies.PID}/get`,
          headers
        );
        console.log("data in user list : - ", usersList.data);
        // handleUserCheck();
      }
    } catch (err) {
      console.log(err, "err found");
    }
  };

  const handleForget = async () => {
    try {
      setBackdropOpen(true);
      if (email || !email === "") {
        let reqObj = {
          email: email,
          requestType: "forgetPasswordEmail",
        }

        console.log("email ", email);
        console.log("cookies.PID ", cookies.PID);
        const { data, status } = await axios.post(API_URL + "/user/register", reqObj);
        console.log("data in /user/register : - ", data);
        if (status === 200) {
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSnackbarMessage("Please check your registered email for a link to reset your password!");
        } else {
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Failed to send reset link on email, Please try again!");
        }
        setBackdropOpen(false);

      } else {
        console.log("email id does not exists");
        setBackdropOpen(false);
      }
      // window.location.reload()
    } catch (err) {
      console.log(err.response?.data?.message, "err in forgot password");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(err.response?.data?.message??"Failed to send reset link on email, Please try again!");
      setBackdropOpen(false);
    }
  };

  const handleNetwork = () => {
    removeCookie("Nid", { path: "/" });
    removeCookie("PID", { path: "/" });
    window.location.reload();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleParticipantPage = () => {
    props.page();
    props.pstate("register");
  };

  const handleUserPage = () => {
    props.page();
    props.pstate("login");
  };

  React.useEffect(() => {
    console.log("i am login", props);
    handleParticipants();
    if (props.newUser) {
      setCheckNew(true);
      props.page();
    }
  }, []);

  return (
    <>
      {backdropOpen && <FullScreenLoader />}
      {!backdropOpen && (
        <>

          <form className={classes.root} onSubmit={handleSubmit}>
            <Typography style={{ fontSize: "20px" }}>
              <strong>Welcome Back!</strong>
            </Typography>

            <Typography
              style={{
                margin: "0.5rem",
                fontSize: "20px",
              }}
            >

            </Typography>

            <TextField
              label="Email"
              variant="filled"
              type="email"
              email={cookies.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <TextField
                variant="filled"
                label="Password"
                id="filled-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                password={cookies.password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <div>
              <Button
                type="submit"
                onClick={handleCookie}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </div>

            {errmsg && (
              <div
                style={{
                  textAlign: "center",
                  color: errmsg === "Please Wait" ? "#003e35" : "red",
                }}
              >
                {errmsg}
              </div>
            )}

            <div style={{ display: "grid", placeItems: "center" }}>
              <Link
                style={{ margin: "5px", fontSize: "18px", color: "#003e35" }}
                component="button"
                variant="body2"
                onClick={() => handleForget()}
              >
                Forgot Password?
              </Link>
              {/* <GoogleOAuthProvider clientId="500904072247-it6hc9c900vbgjvvcovcf1eg1d6ni5gs.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={oauthResponseMessage}
                      onError={oauthErrorMessage}
                    />
                  </GoogleOAuthProvider> */}

            </div>
            <div style={{ display: "grid", placeItems: "center" }}>
              <Typography style={{ fontSize: "18px", margin: "10px" }}>
                New User?
                <a
                  component="button"
                  variant="body2"
                  href='https://votan.eco/join-waitlist'
                  target="_blank" 
                  // rel="noopener noreferrer"
                  style={{
                    fontSize: "18px",
                    margin: "4px",
                    cursor: "pointer",
                    color: "#003e35",
                  }}
                >
                  Sign Up here
                </a>
              </Typography>
            </div>
          </form>

          <Snackbar open={snackbarOpen} onClose={handleSnackbarClose}>
            <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default Login;
