import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from "@mui/material/Unstable_Grid2";
// import image
// import Loader component
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// import Empty Table Message component
import EmptyTable from "../../component/EmptyTable/EmptyTable";
// import Verified Icon
import VerifiedIcon from "@mui/icons-material/Verified";
// tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useCookies } from "react-cookie";
import axios from "axios";
// import API_URL and Cookies
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import js pdf
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import GHGReportDetails from "../VCarbonPages/ReportDetails";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const TrackerPage = (props) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const [value, setValue] = React.useState(0);
  const [id, setId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [urlFound, setUrlFound] = React.useState(true);
  const [chgReportData, setChgReportData] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect
  React.useEffect(() => {
    console.log(props?.obj?.row, "props row");
    const searchParams = new URLSearchParams(window.location.search);
    setId(searchParams.get("query"));
    console.log(window.location.href, "params query");
    getChgReportById(searchParams.get("query"))
  }, [props?.obj, window.location.search]);


  const printQRDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      console.log('props.,', chgReportData.title)
      pdf.save(chgReportData.title + "-downloadQR.pdf");
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      console.log('URL copied to the clipboard.');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  const getChgReportById = async (getId) => {
    try {
      const { data } = await axios.get(
        API_URL + `/activityReport/getReportData?id=${getId}`,
        headers
      );
      console.log('I am data', data)
      if (!data) {
        setChgReportData([]);
        setUrlFound(false);
      } else {
        setChgReportData(data);
        setUrlFound(true);
        console.log(data, "chg report data");
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err, "err in chg report");
      setIsLoading(false)
    }
  };

  return (
    <>
        <Card
          style={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "#3b7b6f",
            color: "#003E35",
          }}
        >
          {/* Main div start here */}
          {/* Main Grid container start here */}
        <Grid container spacing={2} direction="row" justify="space-between" alignItems="stretch">
          {/* First Grid item start here */}
          <Grid item xs={12} sm={6}>
              {/* {checkEcoLabel &&  */}
              {!(urlFound) && <img
                src={
                  require("../../assets/images/Error-404.png")
                }
                style={{
                  width: "95%",
                  height: "80vh",
                  borderRadius: "20px",
                  border: "5px solid #003E35",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                alt="product_image"
              />}
              {(urlFound) &&
                <div
                  style={{
                    width: "95%",
                    height: "calc(85vh)",
                    borderRadius: "20px",
                    border: "5px solid #003E35",
                    padding: 20,
                    overflow: "auto", // Hide overflow
                    backgroundColor: "#f4f3ee", // Background color
                    boxSizing: "border-box",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <GHGReportDetails data={chgReportData} />
                </div>
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              {isLoading && <FullScreenLoader />}
              {!isLoading && (
                <Grid container direction="row" style={{ overflow: "auto" }}>
                  {!urlFound && <EmptyTable />}

                  {urlFound && (
                    <>
                      <Grid
                        xs={10}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bolder", color: "#F4F3ED" }}
                        >
                          Verified by Votan on Blockchain <VerifiedIcon />
                        </Typography>

                        <Card
                          style={{
                            backgroundColor: "#F4F3ED",
                            overflow: "auto",
                            height: "150px",
                          }}
                        >
                          <div style={{ marginTop: "20px" }}>
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                marginLeft: "10px",
                              }}
                            >
                              {chgReportData.title} <VerifiedIcon />
                            </Typography>
                          </div>

                          <div style={{ marginTop: "20px" }}>
                            <Typography
                              style={{
                                fontWeight: "lighter",
                                marginLeft: "10px",
                              }}
                            >
                              Baseline: {chgReportData.baseYear}
                            </Typography>
                          </div>

                          <div
                            style={{ marginBottom: "20px" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "normal",
                                marginLeft: "10px",
                              }}
                            >
                              Report generated by Votan for the period {chgReportData.from} - {chgReportData.to}
                            </Typography>
                          </div>
                        </Card>

                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              TabIndicatorProps={{
                                style: {
                                  backgroundColor: "#fff",
                                  color: "#fff",
                                }
                              }}
                            >
                              <Tab
                                label="Details"
                                style={{ color: "#fff" }}
                                {...a11yProps(0)}
                              />

                              <Tab
                                label="Non GHG Activities"
                                style={{ color: chgReportData?.OtherActivities?.length > 0 ? "#fff" : "#adadad" }}
                                disabled={!(chgReportData?.OtherActivities?.length > 0)}
                                {...a11yProps(1)}
                              />

                              <Tab
                                label="GHG Activities"
                                style={{ color: chgReportData?.GHGActivities?.length > 0 ? "#fff" : "#adadad" }}
                                disabled={!(chgReportData?.GHGActivities?.length > 0)}
                                {...a11yProps(2)}
                              />
                              <Tab
                                label="Gases Used"
                                style={{ color: chgReportData?.GHGActivities?.length > 0 ? "#fff" : "#adadad" }}
                                disabled={!(chgReportData?.GHGActivities?.length > 0)}
                                {...a11yProps(3)}
                              />

                            </Tabs>

                          </Box>

                          <CustomTabPanel value={value} index={0}>
                            <div
                              style={{
                                marginTop: "1rem",
                                height: "calc(30vh)",
                                overflow: "auto",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#F4F3ED",
                                  height: "300px",
                                }}
                              >
                                <Typography
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Report ID: {id}
                                </Typography>

                                <Typography
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  The following report is fetched directly from Blockchain in encrypted format in accordance to Q42 standards complimentary to Arc42 architecture.
                                  The data collected is in accordance to globally recognized GRI & ISO-14064 standards, and can be used for any kind of reportings: GHG, ESG, HIGG, BRSR and Sustainability Reporting.
                                </Typography>
                              </Card>
                            </div>

                            <Card
                              style={{ marginTop:'10px',padding: "20px", backgroundColor: "#F4F3ED", }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div id="divToPrint">
                                  <QRCodeSVG
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                      color: "white",
                                    }}
                                    value={window.location.href}
                                  />
                                </div>
                                <div style={{ marginLeft: '20px' }}>
                                  <Button
                                    style={{ marginRight: '10px', marginTop: '20px' }}
                                    variant="contained"
                                    onClick={handleCopy}
                                  >
                                    Copy to Clipboard <FileCopyIcon />
                                  </Button>
                                  <Button
                                    style={{ marginTop: '20px' }}
                                    variant="contained"
                                    onClick={printQRDocument}
                                  >
                                    Download QR code <GetAppIcon />
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={2}>
                            {chgReportData?.GHGActivities?.length > 0 && (<Card
                              style={{
                                marginTop: "1rem",
                                backgroundColor: "#f4f3ee",
                                height: "320px",
                                borderRadius: "10px",
                                overflow: "auto",
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell><strong>Category</strong></TableCell>
                                    <TableCell><strong>Activity</strong></TableCell>
                                    <TableCell><strong>kgCO2e</strong></TableCell>
                                    <TableCell><strong>Percentage</strong></TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {chgReportData?.GHGActivities?.map((activity, index) => (
                                    <React.Fragment key={index}>
                                      <TableRow key={`${index}`}>
                                        <TableCell>{activity.category}</TableCell>
                                        <TableCell>{activity.subActivity || activity.category}</TableCell>
                                        <TableCell>{(activity.emissions ?? 0).toFixed(2)}</TableCell>
                                        <TableCell>{
                                        `${(
                                          ((activity.emissions ?? 0) / (chgReportData.total ?? 0)) * 100 
                                        ).toFixed(2) ?? 0}% of total`}
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </Card>
                            )}
                            {/* new card ends here */}
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <Card
                              style={{
                                marginTop: "1rem",
                                backgroundColor: "#f4f3ee",
                                height: "320px",
                                borderRadius: "10px",
                                overflow: "auto",
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell><strong>Gas Name</strong></TableCell>
                                    <TableCell><strong>Conversion To</strong></TableCell>
                                  </TableRow>
                                </TableHead>
                                {chgReportData?.gasesIncluded?.length > 0 && (
                                  <TableBody>
                                    {chgReportData?.gasesIncluded?.map((activity, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow key={`${index}`}>
                                          <TableCell>{activity}</TableCell>
                                          <TableCell>kgCO2e</TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                )}
                              </Table>
                            </Card>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={1}>
                            <Card
                              style={{
                                marginTop: "1rem",
                                backgroundColor: "#f4f3ee",
                                height: "320px",
                                borderRadius: "10px",
                                overflow: "auto",
                              }}
                            >

                              {chgReportData?.OtherActivities?.length > 0 && (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell><strong>Type</strong></TableCell>
                                      <TableCell><strong>Quantity</strong></TableCell>
                                      <TableCell><strong>Units</strong></TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {chgReportData?.OtherActivities?.map((activity, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow key={`${index}`}>
                                          <TableCell>{activity.sourceType}</TableCell>
                                          <TableCell>{(activity.sourceQuantity ?? 0).toFixed(2)}</TableCell>
                                          <TableCell>{activity.units}</TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                  </TableBody>

                                </Table>)}
                              {/* hover */}

                            </Card>
                          </CustomTabPanel>
                          {/* )} */}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            {/* Second div ends here */}
          </Grid>
          {/* Main div ends here */}
        </Card>
    </>
  );
};

export default TrackerPage;
