import React from "react";
import Welcome from "../../calculator/Welcome";
import TermsConditions from "../../calculator/TermsConditions";
import Company from "../../calculator/Company";
import CompanyStepper from "../../calculator/CompanyStepper";
import CompanyReport from "../../calculator/CompanyReport";
import { useCookies } from "react-cookie";
import { Typography } from "@material-ui/core";
const QuestionnairePage = () => {  
  const [cookies, setCookie] = useCookies(["user"]);
  React.useEffect(() => {
  }, []);

  return (
    <>
    { <Welcome />}
    {/* {JSON.parse(cookies.terms) && <CompanyStepper />} */}
    {/* <CompanyReport /> */}
    {/* <TermsConditions /> */}
    {/* <Company /> */}
    {/* <CompanyStepper /> */}
    {/* calculator welcome page ends here */}
  </>
  );
};

export default QuestionnairePage;
