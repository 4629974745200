import React, { useState } from "react";
import {
  Button,
  Typography,
  Chip,
} from "@mui/material";
import TextField from "@mui/material/TextField";
// import icons
// radio button
// accordion
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { CONSTANTS, HEADERS2, INR_TO_EUR } from "../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
// import Create new Invoice Start here
import Stack from "@mui/material/Stack";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import Icon
import { makeStyles } from "@material-ui/core";
// import Progress Bar
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import classes from "./CompanyStepper.css";
import classes from "./welcome.module.css";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import HvacIcon from '@mui/icons-material/Hvac';
import WaterIcon from '@mui/icons-material/Water';
import Co2Icon from '@mui/icons-material/Co2';
import ScienceIcon from '@mui/icons-material/Science';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import TrainIcon from '@mui/icons-material/Train';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MUIDataTable from "mui-datatables";
import countries from 'country-list';
import FullScreenLoader from "../component/Loader/FullScreenLoader";
import MissingPIDLoader from "../component/Loader/MissingPIDLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  flex: 'column',
  color: theme.palette.text.secondary,
  // height: 60,
  lineHeight: '100px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });

function CompanySteps(props) {

  const [cookies, setCookie] = useCookies(["user"]);
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const [error, setError] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activityRequired, setActivityRequired] = React.useState(true);
  const [subActivityRequired, setSubActivityRequired] = React.useState(true);
  const [regionRequired, setRegionRequired] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const API_URL = CONSTANTS.API_URL;
  const useStyles = makeStyles((theme) => ({
    stepper: {
      backgroundColor: "#000000",
    },
    background: {
      paper: '#9ca19d',
    },
    stepLabel: {
      color: "#ffffff",
    },
    customAccordion: {
      backgroundColor: "#fff",
      color: "#fff", // Change this color to your desired background color
    },
    stepIcon: {
      color: "#cccc", // Gray color for inactive dots
    },
    active: {
      color: "#ffffff !important", // White color for active step text
    },
    completed: {
      color: "#ffffff !important", // White color for active step text
    },
  }));
  const formatDate = () => {
    let d = new Date()
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const [state, setState] = React.useState({
    From: formatDate(),
    To: formatDate(),
    Units: "",
    Quantity: "",
    Scope: "",
    Category: "",
    Activity: "",
    SubActivity: "",
    Region: "",
    Revenue: 0,
    Expenses: 0,
    NoOfDays: 0,
    Currency: "INR",
    method: "activity",
    dropdown1: [],
    dropdown2: []
  });
  const emptyState = {
    From: state.From,
    To: state.From,
    Units: "",
    Quantity: "",
    Remarks: "",
    Scope: "",
    Category: "",
    Activity: "",
    SourceType: "",
    SubActivity: "",
    Revenue: 0,
    Expenses: 0,
    NoOfDays: 0,
    Currency: "INR",
    method: "activity",
    dropdown1: [],
    dropdown2: []
  }

  const columnsForGHG = ["Activity ID", "Activity", "Sub-Activity", "Units", "Quantity"];
  const columnsForHigg = ["Activity ID", "Units", "Quantity"];
  const [activityOptions, setActivityOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [subActivityOptions, setSubActivityOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedSubActivity, setSelectedSubActivity] = useState('');
  const [unitOptions, setUnitOptions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [other, setOther] = useState(false);
  const [propInformation, setPropInformation] = useState({});
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [profileStatus, setProfileStatus] = React.useState(false);



  const getVendorsByPID = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/vendor/id`,
        headers
      );
      console.log("data in getVendorsByPID function : - ", data?.Vendors);
      if (!data) {
        setVendorOptions([]);
      } else {
        setVendorOptions(data?.Vendors || []);
      }
    } catch (err) {
      console.log(err, "err in reports api");
    }
  }
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const data = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
  ];
  // dummy data ends


  const options = {
    filterType: 'checkbox',
    onRowsDelete: async (rowsDeleted, newTableData) => {
      let deletedItemIds = [];
      console.log("newTableData : - ", newTableData);
      setTableData(newTableData);
      rowsDeleted.data.map((value) => {
        deletedItemIds.push({ _id: tableData[value.index][0] })
      });
      console.log("deletedItemIds : - ", deletedItemIds);

      let reqObj = {
        deletedItemIds: deletedItemIds
      };
      const { data } = await axios.post(API_URL + `/ef/deleteActivities`, reqObj, headers);
      if (data.status === true) {
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Activities Deleted!");
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Activities Not Deleted!");
      }
    },
  };

  const handleItemClicked = async (event) => {
  };

  const handleStateChange = (event, name, value) => {
    let newState = { ...state };
    if (name === 'Date' || event.target.name === 'Date') {
      newState['From'] = value ?? event.target.value
      newState['To'] = value ?? event.target.value
    } else {
      newState[name ?? event.target.name] = value ?? event.target.value;
    }
    setState(newState);
  };

  const handleClickOpen = (event, section, propInfo) => {
    setColumns(columnsForGHG);
    setPropInformation(propInfo);
    let activityRequired = true;
    if (propInfo.category === 'District heating' || propInfo.category === 'Electricity' || section.scope === "NonGHG" || propInfo.category === "Business stay") {
      setActivityRequired(false);
      setSubActivityRequired(false);
      setColumns(columnsForHigg);
      activityRequired = false;
    }
    if (propInfo.category === 'Business stay') {
      setRegionRequired(true);
    }
    if (propInfo.category === "Direct GHG emissions" || propInfo.category === "Electric vehicles") {
      setSubActivityRequired(false);
    }
    setOpenDialog(true);
    handleTableData(propInfo, activityRequired);
    handleDialogRenderOperations(event, propInfo);
  };
  const [expandedSections, setExpandedSections] = useState({});

  const handleTableData = async (propInfo, activityRequired) => {

    let reqObj = {
      Category: propInfo.category,
      From: decodeURIComponent(cookies.from),
      To: decodeURIComponent(cookies.to)
    };
    let tdata = [];
    const data = await axios.post(API_URL + `/ef/getHistoryByCategory`, reqObj, headers);
    console.log("data in get history : - ", data);
    if (!data.data) {
      if (!cookies.from) {
        tdata.push(["select date range"]);
        setTableData(tdata);
      } else {
        return [];
      }
    } else {
      if (activityRequired === true) {
        data.data.map((d) => {
          tdata.push([d._id, d.activity, d.subActivity, d.units, d.sourceQuantity]);
        });
      } else {
        data.data.map((d) => {
          tdata.push([d._id, d.units, d.sourceQuantity]);
        });
      }
      setTableData(tdata);
      return data.data;
    }
  }

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const renderButtons = (data, index) => {
    const isExpanded = expandedSections[index];
    const showExpandCollapse = data.length > 4;

    return (
      <>
        {data.slice(0, isExpanded ? data.length : 4).map((item, i) => (
          <Button
            key={item.name}
            variant="outlined"
            size="large"
            className={classes.mapItem}
            onClick={(event) => handleClickOpen(event, item.title, item.propInfo)}
            sx={{
              textTransform: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>{item.endIcon}</span>
            <span style={{ fontSize: '0.8em' }}>{item.name}</span>
            <Chip label={item.chip} color={getScopeChipColor(item.title)} size="small" />
          </Button>
        ))}
        {showExpandCollapse && (
          <Button
            variant="text"
            color="primary"
            onClick={() => toggleSection(index)}
            sx={{ textTransform: 'none' }}
          >
            {isExpanded ? 'Collapse' : 'Expand'}
          </Button>
        )}
      </>
    );
  };
  const getScopeChipColor = (scope) => {
    switch (scope) {
      case 'Fuel':
        return 'primary';
      case 'Energy & Heating':
        return 'secondary';
      case 'Non GHG':
        return 'warning';
      default:
        return 'default';
    }
  };
  const handleDialogRenderOperations = async (event, propInfo) => {
    let fuelState = { ...state };
    fuelState.Scope = propInfo.scope;
    fuelState.Category = propInfo.category;
    fuelState.SourceType = propInfo.sourceType;
    fuelState.Activity = propInfo.activity;
    fuelState.SubActivity = propInfo.subActivity;
    let dp1 = [];
    if (propInfo.category === "Direct GHG emissions" || propInfo.category === "Electric vehicles") {
      dp1 = await getDropdownsDatas('activity', { scope: propInfo.scope, category: propInfo.category });
      setActivityOptions(dp1);
      await handleUnitsWithoutActivity({ scope: propInfo.scope, category: propInfo.category });
    }
    else if (propInfo.scope !== 'Scope 2' && propInfo.scope !== 'NonGHG' && propInfo.category !== 'Business stay') {
      dp1 = await getDropdownsDatas('activity', { scope: propInfo.scope, category: propInfo.category });
      setActivityOptions(dp1);
    } else {
      await handleUnitsWithoutActivity(propInfo);
      fuelState.Activity = ''
      fuelState.SubActivity = ''
    }
    setState(fuelState);
  }

  const handleUnitsWithoutActivity = async (propInfo) => {
    if (propInfo.scope === "NonGHG") {
      if (propInfo.category === "Water" || propInfo.category === "Waste Water") {
        setUnitOptions(['litres']);
      } else {
        setUnitOptions(['litres', 'kg']);
      }
    } else {
      const fetchedUnits = await getDropdownsDatas('units', {
        scope: propInfo.scope,
        category: propInfo.category
      });
      setUnitOptions(fetchedUnits);
    }
  }

  const validateDataForSubmission = async (submitState) => {
    if (submitState.From === '') {
      setBackdropOpen(false)
      return {
        status: false,
        message: "Please select a date"
      }
    }
    if (submitState.SourceType !== 'Hotel stay') {
      if ((activityRequired && !submitState.Activity) || (subActivityRequired && !submitState.SubActivity)) {
        setBackdropOpen(false)
        return {
          status: false,
          message: "Please select Activity & Sub-activity"
        }
      }
      if (!submitState.Units || !submitState.Quantity || !submitState.Expenses) {
        setBackdropOpen(false)
        return {
          status: false,
          message: "Please enter Units, Quantity & Expenses"
        }
      }
    } else {
      if (!submitState.Region || !submitState.Expenses) {
        setBackdropOpen(false)
        return {
          status: false,
          message: "Select Region & Enter Expenses!"
        }
      }
    }
    return {
      status: true
    };
  }

  const handleDataSubmission = async (event) => {
    setBackdropOpen(true)
    let submitState = { ...state }
    submitState.From = state.From;
    submitState.To = state.From;
    submitState.Activity = selectedActivity;
    submitState.SubActivity = selectedSubActivity;
    submitState.Expenses = submitState.Expenses ?? 0;
    try {
      if (regionRequired) {
        submitState.Quantity = INR_TO_EUR(submitState.Expenses ?? 0) || 0;
      }
      let validate = await validateDataForSubmission(submitState);
      if (!validate.status) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(validate.message);
        return
      }
      const { data } = await axios.post(API_URL + `/ef/submitData`, submitState, headers);
      if (!data) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Activity Submission Failed!");
        setBackdropOpen(false)
        return [];
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Activity Submitted!");
        handleTableData(propInformation, activityRequired);
        setBackdropOpen(false)
        return data.data;
      }
    } catch (err) {
      console.log(err, "err in all get emission");
      setBackdropOpen(false)
    }

  }
  const handleSubActivity = async (newValue) => {
    setSelectedSubActivity(newValue);
    const fetchedUnits = await getDropdownsDatas('units', {
      scope: state.Scope,
      category: state.Category,
      activity: state.Activity,
      subActivity: newValue
    });
    setUnitOptions(fetchedUnits);
  };
  const handleActivity = async (newValue) => {
    setSelectedActivity(newValue);
    const fetchedUnits = await getDropdownsDatas('units', {
      scope: state.Scope,
      category: state.Category,
      activity: newValue ?? undefined
    });
    setUnitOptions(fetchedUnits)
  };
  const handleRegions = async (newValue) => {
    const fetchedRegions = await getDropdownsDatas('region', {
      scope: 'Scope 3',
      category: 'Business stay',
      activity: 'Hotel stay'
    })
    const countryList = fetchedRegions.map((code) => {
      const countryName = countries.getName(code);
      return { name: countryName || 'Unknown', code };
    });
    setRegionOptions(countryList)
  }


  const handleClose = () => {
    setOpenDialog(false);
    setState(emptyState);
    setActivityRequired(true);
    setSubActivityRequired(true);
    setActivityOptions([]);
    setSubActivityOptions([]);
    setSelectedActivity('');
    setSelectedSubActivity('');
    setUnitOptions([]);
    setColumns([]);
    setTableData([]);
    setRegionRequired(false);
    setPropInformation({});
  };

  React.useEffect(() => {

    const fetchSubActivityOptions = async () => {
      if (selectedActivity && subActivityRequired) {
        const fetchedSubActivityOptions = await getDropdownsDatas('subActivity', {
          scope: state.Scope,
          category: state.Category,
          activity: selectedActivity,
        });
        setSubActivityOptions(fetchedSubActivityOptions);
      }
    };

    fetchSubActivityOptions();
    getVendorsByPID();
  }, [selectedActivity]);

  React.useEffect(() => {
    checkMissingPID();
  },[]);

  const checkMissingPID = async () => {
    try {
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      if (user?.data?.PID && user?.data?.PID !== "") {
        setProfileStatus(true);
      }
    } catch (error) {
      console.log("Error inside checkMissingPID function : - ", error.stack);
    }
  }

  const getDropdownsDatas = async (filter, obj) => {
    try {
      obj.filter = filter;
      const data = await axios.post(API_URL + `/ef/get-data`, obj, headers);
      if (!data) {
        return [];
      } else {
        return data.data;
      }
    } catch (err) {
      console.log(err, "err in all get emission");
      return []
    }
  }
  const handleSnackbarClose = async () => {
    setSnackbarOpen(false);
  }

  const getOptions = (sourceType) => {
    console.log("sourceType : - ", sourceType)
    switch (sourceType) {
      case 'Machinery':
      case 'Fuel':
      case 'Vehicles':
        return ['litres']
      case 'Electricity':
        return ['kWh'];
      case 'Heating':
        return ['BTU']
      case 'Water':
      case 'Waste Water':
      case 'Waste':
      case 'Chemical':
        return ['Litres']
      case 'Business Travel':
        return ['passenger-kms']
      case 'Hotel Stay':
        return ['days']
      case 'Transport':
        return ['kms']
      case 'Material Inventory':
      case 'Waste Treatment':
        return ['EUR', 'INR', 'USD']
      // Add more cases based on your sourceType values
      default:
        return [];
    }
  };
  const fuelTypeObjects = [
    {
      name: "Machinery",
      endIcon: <PrecisionManufacturingIcon color="primary" />,
      propInfo: {
        scope: "Scope 1",
        category: "Fuel combustion",
        sourceType: "Machinery",
        method: "activity",
      },
    },
    {
      name: "Vehicles",
      endIcon: <LocalShippingIcon color="primary" />,
      propInfo: {
        scope: "Scope 1",
        category: "IC vehicles",
        activity: "",
        sourceType: "Fuel",
        subActivity: "",
        method: "activity"
      },
    },
    {
      name: "Direct Gas",
      endIcon: <Co2Icon color="primary" />,
      propInfo: {
        scope: "Scope 1",
        category: "Direct GHG emissions",
        activity: "",
        sourceType: "Direct GHG emissions",
        subActivity: "",
        method: "activity"
      },
    },
    {
      name: "Refrigerant & other",
      endIcon: <Co2Icon color="primary" />,
      propInfo: {
        scope: "Scope 1",
        category: "Refrigerant & other",
        activity: "",
        sourceType: "Refrigerant & other",
        subActivity: "",
        method: "activity"
      },
    }
  ]

  const energyTypeObjects = [
    {
      name: "Electricity",
      endIcon: <ElectricMeterIcon color="primary" />,
      propInfo: {
        scope: "Scope 2",
        category: "Electricity",
        activity: "",
        subActivity: "",
        sourceType: "Electricity"
      },
    },
    {
      name: "Heating",
      endIcon: <HvacIcon color="primary" />,
      propInfo: {
        scope: "Scope 2",
        category: "District heating",
        activity: "",
        subActivity: "",
        sourceType: "District heating"
      },
    },
    {
      name: "EV",
      endIcon: <ElectricCarIcon color="primary" />,
      propInfo: {
        scope: "Scope 2",
        category: "Electric vehicles",
        activity: "",
        subActivity: "",
        sourceType: "Electric vehicles"
      },
    }
  ]

  const higgTypeObjects = [
    {
      name: "Water",
      endIcon: <WaterIcon color="primary" />,
      propInfo: {
        scope: "NonGHG",
        category: "Water",
        activity: "",
        subActivity: "",
        sourceType: "Water",
      },
    },
    {
      name: "Waste Water",
      endIcon: <LocalDrinkIcon color="primary" />,
      propInfo: {
        scope: "NonGHG",
        category: "Waste Water",
        activity: "",
        subActivity: "",
        sourceType: "Waste Water",
      },
    },
    {
      name: "Waste",
      endIcon: <CleaningServicesIcon color="primary" />,
      propInfo: {
        scope: "NonGHG",
        category: "Waste",
        activity: "",
        subActivity: "",
        sourceType: "Waste",
      },
    },
    {
      name: "Chemical",
      endIcon: <ScienceIcon color="primary" />,
      propInfo: {
        scope: "NonGHG",
        category: "Chemical",
        activity: "",
        subActivity: "",
        sourceType: "Chemical",
      },
    },
  ];

  const scope3TypeObjects = [
    {
      name: "Business Travel",
      endIcon: <FlightIcon color="primary" />,
      propInfo: {
        scope: "Scope 1/3",
        category: "Business travel",
        activity: "",
        subActivity: "",
        sourceType: "Business travel",
      },
    },
    {
      name: "Hotel Stay",
      endIcon: <HotelIcon color="primary" />,
      propInfo: {
        scope: "Scope 3",
        category: "Business stay",
        activity: "",
        subActivity: "",
        sourceType: "Hotel stay",
      },
    },
    {
      name: "Transport Distribute",
      endIcon: <TrainIcon color="primary" />,
      propInfo: {
        scope: "Scope 3",
        category: "Upstream transportation and distribution",
        activity: "",
        subActivity: "",
        sourceType: "Upstream transportation and distribution",
      },
    },
    {
      name: "Material Inventory",
      endIcon: <WarehouseIcon color="primary" />,
      propInfo: {
        scope: "Scope 3",
        category: "Purchased goods and services",
        activity: "",
        subActivity: "",
        sourceType: "Purchased goods and services",
      },
    },
    {
      name: "Waste",
      endIcon: <DeleteForever color="primary" />,
      propInfo: {
        scope: "Scope 3",
        category: "Waste generated in operations",
        activity: "",
        subActivity: "",
        sourceType: "Waste generated in operations",
      },
    },
  ];


  return (
    <div >
      {backdropOpen && <FullScreenLoader />}
      <Typography
        variant="h5"
        style={{ marginTop: "1rem", marginLeft: "2rem" }}
      ><strong>Calculator</strong></Typography>
      <>
        {[
          { title: 'Fuel', data: fuelTypeObjects, chip: 'Scope 1', scope: 'Scope 1' },
          { title: 'Energy & Heating', data: energyTypeObjects, chip: 'Scope 2', scope: 'Scope 2' },
          { title: 'Scope 3 - Others', data: scope3TypeObjects, chip: 'Scope 3', scope: 'Scope 3' },
          { title: 'Non GHG', data: higgTypeObjects, chip: 'higg', scope: 'NonGHG' },
        ].map((section, index) => (
          <Stack
            key={index}
            direction="column"
            spacing={{ xs: 2, sm: 2, md: 4 }}
            justifyContent="flex-start"
            alignItems="stretch"
            // spacing={1}
            divider={1}
            className={classes.stack}
          >
            <Grid
              xs={12}
              spacing={{ xs: 1, sm: 2, md: 1 }}
              className={classes.menuItems}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid
                item
                xs={12}
              >
                <h6
                  className={classes.stackHeading}
                  style={{
                    fontWeight: 'bold',
                    margin: '10px 0',
                    color: '#333',
                  }}
                >
                  {section.title}
                </h6>
              </Grid>
              <Grid
                container
                xs={12}
                direction='row'
                // direction={{ xs: 'row', sm: 'column' }}
                spacing={{ xs: 1, sm: 4, md: 2 }}
                justifyContent="flex-start"
                alignItems="stretch"
              >
                {/* <Box
                // sx={{
                //   p: 2,
                //   borderRadius: 4,
                //   bgcolor: 'background.default',
                //   display: 'flex',
                //   flexDirection: 'row',
                //   // flexDirection: {{ xs: 'row', sm: 'row' }}
                //   // display: 'grid',
                //   // gridTemplateColumns: 'repeat(5, 1fr)',
                //   gap: 2,
                // }}
                > */}
                {section.data.map((item, i) => (
                  <Grid
                    item
                    key={item.name + '00' + i}
                  >
                    <Button
                      key={item.name + i}
                      variant="outlined"
                      size="small"
                      onClick={(event) =>
                        handleClickOpen(event, section, item.propInfo)
                      }
                      sx={{
                        margin: '1em',
                        display: 'flex',
                        width: '12em',
                        height: '10em',
                        flexDirection: 'column',
                        alignItems: 'center',
                        visibility:
                          i > 4 && !expandedSections[index]
                            ? 'hidden'
                            : 'visible',
                      }}
                    >
                      <span style={{ fontSize: '1em', marginBottom: '0.5em' }}>
                        {item.endIcon}
                      </span>
                      <strong><span style={{ fontSize: '0.8em' }}>{item.name}</span></strong>
                      <Chip
                        label={<span style={{ fontSize: '0.8em' }}>{section.chip}</span>}
                        color={getScopeChipColor(section.title)}
                        size="small"
                      />
                    </Button>
                  </Grid>
                ))}
                {section.data.length > 5 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => toggleSection(index)}
                    sx={{
                      textTransform: 'none',
                      visibility: !expandedSections[index]
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    {expandedSections[index] ? 'Collapse' : 'Expand'}
                  </Button>
                )}
                {/* </Box> */}
              </Grid>
            </Grid>
          </Stack>
        ))}
      </>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        scroll='paper'
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '70%',
            margin: 'auto',
            overflowX: 'hidden'
          },
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {state.SourceType} Consumption Details
            </Typography>

          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          className={classes.dialogGrid}
        >
          {/* <FormControl defaultValue="" required> */}

          {/* <div className={classes.vrline}></div> */}
          <Grid container justifyContent="center" sx={{ padding: 4 }}>
            {/* Heading */}
            <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: 2 }}>
              <Typography variant="h4">Create a new entry</Typography>
              <Typography variant="subtitle1">Fill in the data below and configure your activity input</Typography>
            </Grid>

            {/* Row 1: Date and Vendor Name */}
            <Grid item container spacing={3} xs={12} sx={{ marginBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
              {/* Column 1: Date */}
              <Grid item xs={12} md={6}>
                <TextField
                  id="date-input"
                  label="Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  variant="outlined"
                  name="Date"
                  value={state.From}
                  InputProps={{
                    inputProps: {
                      inputformat: 'yyyy/MM/dd',
                    },
                  }}
                  onChange={(event) => handleStateChange(event, 'Date', event.target.value)}
                />
              </Grid>

              {/* Column 2: Vendor Name */}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-vendor"
                  name="Vendor"
                  options={['unlisted', ...vendorOptions.map((option) => option.Name)]}
                  sx={{ width: '100%' }}
                  onChange={(event, newValue) => {
                    if (newValue===null||newValue===undefined || newValue === 'unlisted') {
                      handleStateChange(event, 'Vendor', '')
                    } else {
                      const selectedVendor = vendorOptions.find((option) => option.Name === newValue);
                      handleStateChange(event, 'Vendor', selectedVendor?.PID??'')
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Vendor Name (Optional)" InputLabelProps={{
                    shrink: true,
                  }} />}
                />
              </Grid>
            </Grid>

            {/* Row 2: Activity and SubActivity */}
            <Grid item container spacing={2} xs={12} sx={{ marginBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
              {/* Column 1: Activity */}
              {activityRequired && <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-activity"
                  name="Activity"
                  options={activityOptions}
                  sx={{ width: '100%' }}
                  onChange={(event, newValue) => {
                    handleActivity(newValue);
                  }}
                  renderInput={(params) => <TextField required {...params} label="Activity" InputLabelProps={{
                    shrink: true,
                  }} />}
                />
              </Grid>}

              {/* Column 2: SubActivity */}
              {
                subActivityRequired &&
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-subactivity"
                    name="SubActivity"
                    options={subActivityOptions}
                    sx={{ width: '100%' }}
                    onChange={(event, newValue) => {
                      handleSubActivity(newValue)
                    }}
                    renderInput={(params) => <TextField required {...params} label="SubActivity" InputLabelProps={{
                      shrink: true,
                    }} />}
                  />
                </Grid>
              }
              {
                regionRequired &&
                <Grid item container spacing={2} xs={12} sx={{ marginBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
                  <Grid item xs={6} md={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-subactivity"
                      name="Region"
                      options={regionOptions.map((option) => option.name)}
                      sx={{ width: '100%', marginBottom: 2 }}
                      onClickCapture={(event, newValue) => {
                        handleRegions(newValue)
                      }}
                      onChange={(event, newValue) => {
                        const selectedRegion = regionOptions.find((option) => option.name === newValue);
                        handleStateChange(event, 'Region', selectedRegion.code)
                      }}

                      renderInput={(params) => <TextField required {...params} label="Region" InputLabelProps={{
                        shrink: true,
                      }} />}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      required
                      id="expenses-input"
                      sx={{ width: '100%', marginLeft: '15px' }}
                      label="Expenses"
                      InputProps={{ inputProps: { min: 0 } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="Expenses"
                      type="number"
                      onChange={handleStateChange}
                    />
                  </Grid>
                </Grid>
              }
            </Grid>

            {/* Row 3: Quantity and Units */}
            <Grid item container spacing={2} xs={12} sx={{ marginBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
              {/* Column 1: Quantity */}
              {regionRequired ? null : <Grid item xs={12} md={4}>
                <TextField
                  required
                  id="quantity-input"
                  sx={{ width: '100%' }}
                  label="Quantity"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  name="Quantity"
                  onChange={handleStateChange}
                />
              </Grid>}

              {/* Column 2: Units */}
              {regionRequired ? null : <Grid item xs={12} md={4}>
                <Autocomplete
                  required
                  disablePortal
                  id="combo-box-units"
                  name="Units"
                  options={unitOptions}
                  sx={{ width: '100%' }}
                  onChange={(event, newValue) => handleStateChange(event, 'Units', newValue)}
                  renderInput={(params) => <TextField {...params} label="Units" InputLabelProps={{
                    shrink: true,
                  }} />}
                />
              </Grid>}
              {regionRequired ? null : <Grid item xs={12} md={4}>
                <TextField
                  id="expenses-input"
                  sx={{ width: '100%' }}
                  required
                  label="Expenses"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  name="Expenses"
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  onChange={handleStateChange}
                />
              </Grid>}
            </Grid>

            {/* Row 5: Save Entry and Cancel Buttons */}
            <Grid item container spacing={2} xs={12} sx={{ justifyContent: 'center' }}>
              {/* Button 1: Save Entry */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => handleDataSubmission(event)}
                  style={{ borderRadius: '20px' }}
                >
                  Save Entry
                </Button>
              </Grid>

              {/* Button 2: Cancel */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  style={{ borderRadius: '20px' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>


          {/* <Grid item container justifyContent="center">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.unitsContainer}
              style={{ textAlign: 'center' }}
            >
              <Typography variant="h4">
                Entering Details for Range {cookies.from} - {cookies.to}
              </Typography><br />
              <Box width="50%">
                <TextField
                  id="quantity-input"
                  sx={{ width: '100%', marginBottom: 1 }}
                  label="Quantity"
                  type="Number"
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  name="Quantity"
                  onChange={handleStateChange}
                />
                {
                  activityRequired ?
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="Activity"
                      // options={[]}
                      options={activityOptions}
                      sx={{ width: '100%', marginBottom: 1 }}
                      onChange={(event, newValue) => {
                        handleActivity(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} label="Activity" />}
                    /> :
                    null
                }
                {
                  subActivityRequired &&
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="SubActivity"
                    // options={[]}
                    options={subActivityOptions}
                    sx={{ width: '100%', marginBottom: 1 }}
                    onChange={(event, newValue) => {
                      handleSubActivity(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label="SubActivity" />}
                  />
                }
                {
                  regionRequired &&
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="Region"
                    // options={regionOptions}
                    options={regionOptions.map((option) => option.name)}
                    sx={{ width: '100%', marginBottom: 1 }}
                    onClickCapture={(event, newValue) => {
                      handleRegions(newValue)
                    }}
                    onChange={(event, newValue) => {
                      const selectedRegion = regionOptions.find((option) => option.name === newValue);
                      handleStateChange(event, 'Region', selectedRegion.code)
                    }}
                    renderInput={(params) => <TextField {...params} label="Region" />}
                  />
                }
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="Units"
                  options={unitOptions}
                  sx={{ width: '100%', marginBottom: 1 }}
                  onChange={(event, newValue) => {
                    handleStateChange(event, "Units", newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Units" />}
                />
                <TextField
                  id="remarks-input"
                  sx={{ width: '100%' }}
                  label="Expenses"
                  variant="outlined"
                  name="Expenses"
                  type="Number"
                  onChange={handleStateChange}
                />
                <Button variant="contained" color="primary" onClick={event => handleDataSubmission(event)}
                  style={{
                    marginRight: "2rem",
                    marginTop: "1rem",
                    borderRadius: "20px",
                  }}>
                  Save Entry
                </Button>
              </Box>
            </Grid>
          </Grid> */}
          {/* </FormControl> */}
        </Grid>
        <div className={classes.hrline}></div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          className={classes.historyTable}
        >
          <MUIDataTable
            title={"History of Submitted Data"}
            data={tableData}
            columns={columns}
            options={options}
          // responsive
          />
        </Grid>

      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {!profileStatus && <MissingPIDLoader />}
    </div>
  );
}

export default CompanySteps;
