import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Card, CardContent } from "@mui/material";
// import Report component
import ReportCopy from "../VCarbonPages/ReportCopy";
// import Eco Label component
import EcoLabelPage from "../EcoLabelPages/EcoLabelPage";
import axios from "axios";
// import Headers and API URL
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Disclosures = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [scope1, setScope1] = React.useState(0);
  const [scope2, setScope2] = React.useState(0);
  const [scope3, setScope3] = React.useState(0);
  const [highestImpactItems, setHighestImpactItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [emissionDashboardData, setEmissionDashboardData] = React.useState([]); // emission dashboard api state
  const [ecoLabelData, setEcoLabelData] = React.useState([]); // ecolabel api state
  const [reportsData, setReportsData] = React.useState([]); // report api state
  const [siteReportsData, setSiteReportsData] = React.useState([]); // report api state
  const [validatedData, setValidatedData] = React.useState(0);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  let donutChartData = {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    datasets: [
      {
        data: [scope1, scope2, scope3],
        backgroundColor: ["#003E35", "#0C6659", "#46AD8D"],
      },
    ],
  };

  // eco label api by P.I.D data
  const getEcoLabelDataByPID = async () => {
    try{
      const { data } = await axios.get(
        API_URL + `/ecoLabel/getAllEcoLabel/PID`,
        headers
      );
      if(!data){
        setEcoLabelData([]);
      }else {
        setEcoLabelData(data);
        console.log(data,"found eco label data");
      }
    }catch(err){
      console.log(err,"err in eco label data");
    }
  }

  // reports api by P.I.D data
  const getReportsDataByPID = async () => {
    try{
      setBackdropOpen(true)
      const { data } = await axios.get(
        API_URL + `/activityReport/getReportData/PID`,
        headers
      );
      
      if(!data){
        setReportsData([]);
      }else {
        setReportsData(data);
        let disclosedCount = 0;
        data.forEach(report => {
          // Checking if the "verified" property is true
          if (report.status==='disclosed') {
            disclosedCount++;
          }
        });
        setValidatedData(disclosedCount)
        console.log(data,"data founds for reports");
      }
    }catch(err){
      console.log(err,"err in reports api");
    }
    setBackdropOpen(false)
  }
  const getSiteReportsDataByPID = async () => {
    try{
      setBackdropOpen(true)
      const { data } = await axios.get(
        API_URL + `/activityReport/getSiteReportData/PID`,
        headers
      );
      if(!data){
        setSiteReportsData([]);
      }else {
        setSiteReportsData(data);
        let disclosedCount = 0;
        data.forEach(report => {
          // Checking if the "verified" property is true
          if (report.status==='disclosed') {
            disclosedCount++;
          }
        });
        setValidatedData(disclosedCount)
        
      }
    }catch(err){
      console.log(err,"err in reports api");
    }
    setBackdropOpen(false)
  }

  // get all validated reports api
  // const getAllValidatedReports = async () => {
  //   try{
  //     const { data } = await axios.get(
  //       API_URL + `/chgReport/getReportData/PID`,
  //       headers
  //     );
  //     if(!data){
  //       setValidatedData([]);
  //     }else {
  //       setValidatedData(data);
  //       console.log(data,"data found validated reports");
  //     }
  //   }catch(err){
  //     console.log(err,"err in validated reports api");
  //   }
  // }

  React.useEffect(() => {
    // getEcoLabelDataByPID(); // call an eco label api
    getReportsDataByPID(); // call an reports api
    getSiteReportsDataByPID();
    // getAllValidatedReports(); // call an api 
  },[]);

  return (
    <div>
      {backdropOpen && <FullScreenLoader />}
      <Typography variant="h5" style={{ margin: "1rem" }}>
        <strong>Disclosures</strong>
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid  container spacing={1} ml={1} mb={1}>
          <Grid item xs={4}>
            {/* <Item> */}
            <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center",marginBottom:'8px' }}>
                  <Typography variant="h6" component="div">
                    <strong>Disclosures</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {`${validatedData ?? '' + validatedData ?? ''}`}     
                  </Typography>
                </div>
          </Grid>
         
          <Grid item xs={4}>
                 <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <Typography variant="h6" component="div">
                    <strong>Self Reports</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {reportsData?.length ?? ''}
                  </Typography>
                </div>
          </Grid>
          <Grid item xs={4}>
          <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <Typography variant="h6" component="div">
                    <strong>Ecolabel</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {ecoLabelData?.ecoLabel?.length ?? 0}
                  </Typography>
                </div>
          </Grid>

          {/* Cumulative Reports start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" , padding: "0rem" }}>
              <ReportCopy data={reportsData} siteData={siteReportsData} refresh={getReportsDataByPID}/>
            </Item>
          </Grid>
          {/* Cumulutive Reports ends here */}

          {/* Eco Label Component start here */}
          {/* <Grid item xs={12}>
            <Item style={{ borderRadius: "10px" , padding: "0rem" }}>
              <EcoLabelPage />
            </Item>
          </Grid> */}
          {/* Eco Label Component ends here */}
        </Grid>
      </Box>
    </div>
  );
};

export default Disclosures;
