import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
// import Profile Component
import Profile from './Profile';

const ProfileRoute = () => {
    let {path} = useRouteMatch();
  return (
    <>
    <Switch>
            <Route path={`${path}/profile`}>
                <Profile />
             </Route>
         </Switch>
    </>
  )
}

export default ProfileRoute;