import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import { CONSTANTS, HEADERS } from '../../component/utils/constants';
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),

        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "300px",
        },
        "& .MuiButtonBase-root": {
            margin: theme.spacing(2),
        },
        newaccount: {
            display: "flex",
            alignItems: "center",
        },
    },
}));

const AcceptRejectRelation = () => {
    // const classes = useStyles();
    const history = useHistory()
    const classes = useStyles();
    const [state, setState] = useState({})
    const [inviterPID, setInviterPID] = useState('');
    const [inviteePID, setInviteePID] = useState('');
    const [token, setToken] = useState('');
    const [errMsg, setErrMsg] = useState();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    // API_URL
    const API_URL = CONSTANTS.API_URL;
    // headers


    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        let t = searchParams.get('c')
        setToken(t);
        const pathSegments = window.location.pathname.split('/');
        let pids = pathSegments[2].split('::');
        console.log("PIDS : - ", pids);
        setInviterPID(pids[0]);
        setInviteePID(pids[1]);
        handleGetDetails(t, pids[0])
    }, []);

    const handleSnackbarClose = async () => {
        setSnackbarOpen(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const handleGetDetails = async (t, p) => {
        try {
            console.log("t ", t);
            const headers = HEADERS(t);
            console.log("Headers in handle get details : - ", headers);
            // console.log('this is the body to send',{password:password1,PID:PID,token:token})
            const { data } = await axios.get(
                API_URL + `/vendor/getForInvitation?c=${p}`,
                headers
            );
            setState(data)
            console.log(data, 'invitor data')
        } catch (e) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to fetch details, ask the sender to share the invite again if this issue persists");
            console.log(e, 'error')
        }

    }
    const handleDashboardRedirect = (e) => {
        history.push('/');
    };

    const handleRejectAction = async () => {
        try {
            const headers = HEADERS(token);
            console.log("inviterPID ", inviterPID);
            console.log("inviteePID ", inviteePID);
            let reqObj = {
                inviterPID: inviterPID,
                inviteePID: inviteePID
            }
            const { data, status } = await axios.post(
                API_URL + `/vendor/rejectVendorRelation`,
                reqObj,
                headers
            );
            if(status === 200) {
                setSnackbarOpen(true);
                setSnackbarSeverity("success");
                setSnackbarMessage("You removed yourself as vendor");
            } else {
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage("Failed to remove... Please try again after sometime");
            }
            console.log(data, 'invitor data')
            
        } catch (error) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to submit rejection");
            console.log("Error inside handleRejctAction functoin : - ", error.stack);
        }
    }

    return (
        <>

            <form className={classes.root} onSubmit={handleSubmit}>
                <Typography variant="h5" align="center" gutterBottom>
                   {state.Name} added you as their new Vendor 
                </Typography>

                <Typography variant="body1" align="center" padding={2} paragraph>
                    You have been invited by <b>{state.Name}</b> for collaboration.
                    If you think this is a mistake, <br /><b>Please click the button below</b>
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRejectAction}
                    className={classes.button}
                >
                    Reject
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDashboardRedirect}
                    className={classes.button}
                >
                    Go to Dashboard
                </Button>
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default AcceptRejectRelation;
