import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import { Container } from "@material-ui/core";
import EmptyTable from "../../component/EmptyTable/EmptyTable";
import ProductOverview from "../../Pages/ProductOverview";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import IconButton from "@mui/material/IconButton";
// import Product Details C.S.S
import styles from "../../component/CSS/Product.module.css";
// import component
import UploadDocs from "../../component/Blockchain/UploadDocs";
import VehicleSummary from "./VehicleSummary";
import InHouseDescriptionPopUp from "./InHouseDescriptionPopUp";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const InHouseVehiclePopUp = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState();
  const [productIddata, setProductIdData] = React.useState([]);
  const [image, setImage] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const InventoryDataById = (id) => {
    setProductIdData(props.obj);
  };

  React.useEffect(() => {
    InventoryDataById();
    console.log(props.obj, "props obj master pop up");
    console.log(props.orders,"props row orders");
  }, []);

  React.useEffect(() => {
    if (props.obj !== undefined) {
      setData({
        id: props.obj.productId,
        row: props.obj,
        obj: props.obj,
      });
      setProductIdData(props.obj);
    }
  }, []);

  const jsonDomInternal = (json) => {
    return Object.keys(json).map((child, c) => {
      if (child !== "color") {
        if (typeof json[child] === "object" && !Array.isArray(json[child])) {
          return jsonDomInternal(json[child]);
        } else {
          if (
            child.startsWith("_") ||
            (Array.isArray(json[child]) && json[child].length === 0) ||
            child === "user_id" ||
            child === "PID" ||
            child === "manfacturer" ||
            json[child] === "" ||
            child === "blockchainId"
          ) {
            return;
          }
          return (
            <div className="full" key={c}>
              <div className="left">
                <strong>{child}</strong>
              </div>
              <div className="right" style={{ float: "right" }}>
                {Array.isArray(json[child])
                  ? JSON.stringify(json[child])
                  : json[child]}
              </div>
            </div>
          );
        }
      }
    });
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense" style={{ backgroundColor:'black' }}>
            <Typography variant="h6" color="inherit">
              In House Vehicle Details
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto" }}
              onClick={props.close}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ backgroundColor:'black' }}
            TabIndicatorProps={{style: { backgroundColor: "white" }}}
          >
            <Tab label="InHouse Vehicle Summary" {...a11yProps(0)} />
            <Tab label="InHouse Vehicle Description" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} key={0}>
          <div style={{ marginTop: "14px" }}>
            {/*new component calling here */}
            <VehicleSummary obj={data} />
            {/* component ends here */}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} key={1}>
          <div>
            <CardActions style={{ marginTop: "10px" }}>
              <Grid container spacing={2} direction="row">
                {!image && (
                  <Grid xs={6}>
                    <Item>
                      {/* tab1 component calling here */}
                      <ProductOverview
                        images={props.obj?.images ? props.obj.images : []}
                      />
                      {/* component ends here */}
                      <Button
                        style={{ margin: "10px" }}
                        variant="contained"
                        onClick={() => setImage(true)}
                      >
                        Upload Docs
                      </Button>
                    </Item>
                  </Grid>
                )}

                {image && (
                  <Grid xs={6} className={styles.product_card}>
                    <Item className={styles.info_item}>
                      {/* Upload Docs component calling here */}
                      <UploadDocs
                        back={() => {
                          setImage(false);
                        }}
                        setData={setImage}
                        id={props.obj.productId}
                        table="master"
                      />
                    </Item>
                  </Grid>
                )}

                {!props.obj && <EmptyTable />}
                {props.obj && (
                  <Grid xs={6}>
                    <Item style={{ width: "38vw" }}>
                      <div style={{ float: "right" }}>
                        <InHouseDescriptionPopUp obj={data} id={props.id} load={props.load} />
                      </div>
                      <br />
                      <Typography>
                        <strong>View In House Vehicle Details</strong>
                      </Typography>
                      <div>{jsonDomInternal(props.obj)}</div>
                    </Item>
                  </Grid>
                )}
              </Grid>
            </CardActions>
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default InHouseVehiclePopUp;
