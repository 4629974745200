import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
// modal overlay
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Grid from "@mui/material/Grid";
// import React Cookie from cookie
import { useCookies } from "react-cookie";
// import API_URL and Cookies
import { CONSTANTS, HEADERS } from "../../component/utils/constants";
// import icons
import Fab from "@mui/material/Fab";
import RefreshIcon from "@mui/icons-material/Refresh";
// import Loader
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import Autocomplete from "@mui/material/Autocomplete";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Toaster from "../../component/Toaster/Toaster";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
// Fab for styling
// import ReplyAllIcon from "@mui/icons-material/ReplyAll";
// import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import Stack from "@mui/material/Stack";
// import snackbar toaster material ui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ParticipantPopUp from "./ParticipantPopUp";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import IconButton from '@mui/material/IconButton';
// import NOT FOUND PID Component
import NotFoundPID from "../../component/NotFoundPID/NotFoundPID";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  borderRadius: "10px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const selectSurvey = [
  {
    label:
      "Scope 1 & 2 Get measurements from direct and indirect emissions, from on-site activity and energy usage",
    value:
      "Scope 1 & 2 Get measurements from direct and indirect emissions, from on-site activity and energy usage",
  },
  {
    label:
      "Scope 1 & 2 and scope 3 upstream Covers all scope 1 and 2 emissions and measurement for their supply chain",
    value:
      "Scope 1 & 2 and scope 3 upstream Covers all scope 1 and 2 emissions and measurement for their supply chain",
  },
  {
    label:
      "Scope 1 & 2 and full scope 3 Covers all scope 1, 2, and 3 measurements, including downstream emissions from their products and services",
    value:
      "Scope 1 & 2 and full scope 3 Covers all scope 1, 2, and 3 measurements, including downstream emissions from their products and services",
  },
  {
    label: "Custom Survey description",
    value: "Custom Survey description",
  },
];

export default function JoinParticipant(props) {
  const classes = useStyles();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectSurveyScope, setSelectSurveyScope] = React.useState("");
  const [formValues, setFormValues] = React.useState([
    {
      name: "",
      email: "",
    },
  ]);
  const [inviteField, setInviteField] = React.useState([
    {
      surveyType: selectSurveyScope,
      emailMessage: "",
    },
  ]);
  const [loadState, setloadState] = React.useState(true);
  const [columns, setColumns] = React.useState([]);
  const [columnsValues, setColumnsValues] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table
  const [viewDetails, setViewDetails] = React.useState({});
  const [newDetails, setNewDetails] = React.useState({});
  const [detailsState, setDetailsState] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [participantRows, setParticipantRows] = React.useState(false);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState(false);
  const [toaster, setToaster] = React.useState(false);
  const [selectObj, setSelectObj] = React.useState({});
  const [editPopup, setEditPopup] = React.useState(false);
  const [id, setId] = React.useState();
  const [edit, setEdit] = React.useState({
    user_id: cookies.user_id,
    PID: '',
    employeeId: '',
  });
  const [editFormValues, setEditFormValues] = React.useState([
    {
      key: "",
      value: "",
    },
  ]);
  // missing PID State
  const [missingPID, setMissingPID] = React.useState(true);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS(cookies.token);

  const handleDetailsObj = () => {
    setDetailsState(!detailsState);
  };

  const handleClearToaster = () => {
    setSuccessMsg();
    setErrorMsg();
  };

  const handleInviteInput = (event, i, name) => {
    console.log(event.target, "invite target value");
    let journey = inviteField;
    if (i && name) {
      journey[name] = i;
      console.log(journey, "invite input 1");
    } else {
      const journeyState = {
        ...inviteField,
        [event.target.name]: event.target.value,
      };
      journey = journeyState;
      console.log(journeyState, "invite input");
      setInviteField(journeyState);
    }
  };

  const handlebarSubmit = (event) => {
    event.preventDefault();
  };

  const handleChanges = (i, e) => {
    let newFormValues = [...formValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    console.log(newFormValues,"name & email here")
    setFormValues(newFormValues);
  };

  const handleEditChanges = (i, e) => {
    let newFormValues = [...editFormValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    console.log(newFormValues,"name & email here")
    setEditFormValues(newFormValues);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const removeEditFormFields = (i) => {
    let newFormValues = [...editFormValues];
    newFormValues.splice(i, 1);
    setEditFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { name: "", email: "" }]);
  };

  const addEditFormFields = () => {
    setEditFormValues([...editFormValues, { key: "", value: "" }]);
  };

  // useEffect
  React.useEffect(() => {
    // PID MISSING CHECK
    if(cookies.profileStatus==='complete'){
      setMissingPID(false);
    }
    getJoinedData();
  },[])

    // call an api to get invites data in db
    const getJoinedData = async () => {
      try {
        const { data } = await axios.get(
          API_URL + `/invite/getInviteData?Status=joined`, // add employee api later
          headers
        );
        data.error || data?.length < 0 ? setParticipantRows([]) : setParticipantRows(data);
        console.log(data, "data table");
        data.error || data?.length < 0 ? setParticipantRows([]) : setParticipantRows(data);
        let Allcolumns = [];
        let Allvalues = [];
  
        for (let j = 0; j < data.length; j++) {
          for (let key in data[j]) {
            if (
              key !== "__v" &&
              key !== "_id" &&
              key !== "PID" &&
              key !== "NID" &&
              key !== "Contact" && 
              key !== "user_id" &&
              Allcolumns.indexOf(key) === -1
            ) {
              Allcolumns.push(key);
            }
          }
          Allvalues.push(data[j]);
        }
        let temp = Allcolumns;
        temp.push({
          name: "action",
          label: "Remove",
          options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Stack direction="row" spacing={1} justifyContent="center">
                    {/* <Fab
                      size="small"
                      color="primary"
                      aria-label="edit"
                      onClick={() => handleEdit(data, tableMeta.rowIndex)}
                    >
                      <EditIcon />
                    </Fab> */}
  
                    <Fab
                      size="small"
                      // color="primary"
                      style={{ color: "white", backgroundColor: "#1976D2" }}
                      aria-label="delete"
                      onClick={() => deleteInviteData(data, tableMeta.rowIndex)}
                    >
                      <Delete />
                    </Fab>
                  </Stack>
                </div>
              </>
            ),
          },
        });
        setColumns(temp);
        setColumnsValues(Allvalues);
        setColumnsValues(Allvalues);
        setloadState(false);
      } catch (err) {
        setMissingPID(true); // PID MISSING STATE
        console.log(err);
      }
    };

  // CALL AN API TO INSERT INVITE DATA
  const insertInviteData = async () => {
    try {
      if(!inviteField.surveyType){
        setErrorMsg("Please Select A Survey");
        setToaster(true); // open toaster state
      }
      else if(!inviteField.emailMessage){
        setErrorMsg("Please Enter a Email Message");
        setToaster(true); // open toaster state
      }
      // define body of invite data
      const insertInvite = {
        // referId: cookies.email, // email from cookie
        // sender: cookies.user_id, // from cookie
        // receiver: state.email, // email from current
        // networkId: "1",
        ReferId: cookies.email,
        Sender: cookies.user_id,
        Type: inviteField.surveyType,
        Message: inviteField.emailMessage,
        Contact: formValues, // object
      };
      const data = await axios.post(
        API_URL + "/invite/create",
        insertInvite,
        headers
      );
      if(!data){
        setRows([]);
      } else {
        setRows(data);
        console.log(data,"api entered successfully");
        handleClose(); // modal close 
        setSuccessMsg("Data recorded successfully");
        setToaster(true); // open toaster state
        getJoinedData(); // call an get invites api
      }
    } catch (err) {
      console.log(err, "err in Invite Participant api");
      setIsLoading(false);
    }
  };

  const handlebarClick = (obj) => {
    setId(obj.id);
    setEditPopup(!editPopup);
    setSelectObj(obj);
  };

  const options = {
    filterType: "checkbox",
    print: false,
    onChangeRowsPerPage: (numberOfRows) => {
      console.log(numberOfRows, "num");
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    onRowSelectionChange: (k, l, selectedRow) => {
      setSelectedRow(selectedRow);
      let selectTemp = [];
      for (let i = 0; i < selectedRow.length; i++) {
        selectTemp.push(participantRows[selectedRow[i]]);
      }
      setSelectedRowObj(selectTemp);
    },
    selectedRow: selectedRow,
    // onRowClick: (rowData, rowState,a) => {
    //   setViewDetails(rows[rowState.dataIndex]);
    //   setNewDetails(rows[rowState.dataIndex]);
    //   handleDetailsObj();
    //   // handleInvoiceOpen(); // something to do with opening object
    // },
    onCellClick: async (d, rowState) => {
      if (rowState.colIndex !== columns.length - 1) {
        setViewDetails(participantRows[rowState.dataIndex]);
        setNewDetails(participantRows[rowState.dataIndex]);
        handleDetailsObj();
      }
    },
    onRowsDelete: async (e) => {
      console.log(e, "e");
      // await deleteMasterDataBatch(e.data);
    },
  };

  const handleEditSubmit = async () => {
    try {
      let temp = edit;
      console.log(temp, "temp here");
      let fv = editFormValues;
      for (let i = 0; i < fv.length; i++) {
        let key = editFormValues[i].key;
        temp[key] = editFormValues[i].value;
      }
      console.log("temp", temp);
      // setFullScreenLoader(true);
      const { data } = await axios.put(
        API_URL + `/invite/update`,
        temp,
        headers
      );
      console.log(temp,"update body here")
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        console.log(data,"updated api records successfully");
        getJoinedData();
        setSuccessMsg("Records updated successfully");
        setToaster(true); // open toaster state
        setEditPopup(!editPopup);
      }
      // setEditPopup(false);
      // setFullScreenLoader(false);
      setloadState(false);
    } catch (e) {
      setErrorMsg(
        "Unable to update records: Please check the inputs again " + e
      );
      console.log(e, "error in handle select");
      // setFullScreenLoader(true);
    }
  };

  const handleEdit = async (o, idx) => {
    let id = idx;
    console.log(id, "id here");
    let obj = o[id];
    setId(id);
    setEditPopup(!editPopup);
    let editObj = [];
    if (obj) {
      // obj.productId = o.productId;
      obj.user_id = cookies.user_id;
      obj.PID = cookies.PID;
      let keys = Object.keys(obj);
      let values = Object.values(obj);
      for (let i = 0; i < keys.length; i++) 
      if (
            keys[i] !== "__v" &&
            keys[i] !== "_id" &&
            keys[i] !== "ID" &&
            keys[i] !== "Contact" &&
            keys[i] !== "user_id" &&
            keys[i] !== "NID" &&
            keys[i] !== "PID"
         )
      {
        editObj.push({ key: [keys[i]], value: values[i] });
      }
      setEdit(obj);
      setFormValues(editObj);
    }
  };  
    // delete from journey data table
    const deleteInviteData = async (dataObj, index) => {
      try {
        let Id = dataObj[index].Receiver;
        const data = await axios.delete(
          API_URL + `/invite/delete?Receiver=${Id}`,
          headers
        );
        if (data.error) {
          setErrorMsg(data.error);
        } else {
          getJoinedData(); // call an get api
          setSuccessMsg("Deleted Participant Successfully");
          setToaster(true); // open toaster state
        }
      } catch (err) {
        // setErrorMsg(err);
        console.log(err);
      }
    };

  return (
    <>
    {missingPID && <NotFoundPID />}
    {loadState && <Toaster msg={"Fetching real-time data"} />}
      {participantRows && (
        <MUIDataTable
          title={
            <>
              {props.role !== "admin" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginTop: "14px" }}
                    >
                      Exisiting Participants
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ margin: "10px" }}
                      onClick={handleOpen}
                    >
                      Request Emissions
                    </Button>
                    <Fab
                      onClick={() => props.refresh()}
                      size="small"
                      color="primary"
                      aria-label="refresh"
                      style={{ margin: "10px" }}
                    >
                      <RefreshIcon />
                    </Fab>
                  </div>
                </>
              )}
            </>
          }
          data={columnsValues}
          columns={columns}
          options={options}
        />
      )}

        <div>
        {/* <div className={classes.root} style={{ paddingLeft: "5px" }}> */}
          {props.role !== "admin" && (
            <>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                  <IconButton
                    edge="end"
                    color="inherit"
                    sx={{ mr: 2 }}
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    style={{ marginLeft: "auto" }}
                    onClick={handleClose}
                  >
                    <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
                  </IconButton>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Improve Score By Asking Participants To Share Emissions
                    </Typography>
                    <br />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="surveyType"
                      onChange={(event, newValue) => {
                        console.log(newValue, "survey Type Value here");
                        handleInviteInput(event, newValue.value, "surveyType");
                        setSelectSurveyScope(newValue); // select scope hook
                      }}
                      options={selectSurvey}
                      // sx={{ width: 210 }}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} label="Select A Survey" />
                      )}
                    />
                    <br />
                    <TextField
                      name="emailMessage"
                      variant="outlined"
                      label="Email Message"
                      placeholder="Add a friendly message to the email"
                      value={inviteField.emailMessage || ""}
                      onChange={handleInviteInput}
                      multiline
                      rows={2}
                      maxRows={4}
                      fullWidth
                    />

                    {/* Start new form for key & value */}
                    <form onSubmit={handlebarSubmit}>
                      {formValues.map((element, index) => (
                        <div key={index}>
                          <TextField
                            label="Name"
                            name="name"
                            style={{ marginTop: "10px" }}
                            value={element.name || ""}
                            onChange={(e) => handleChanges(index, e)}
                          />

                          <TextField
                            label="Email"
                            type="email"
                            name="email"
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                            value={element.email || ""}
                            onChange={(e) => handleChanges(index, e)}
                          />

                          {index ? (
                            <DangerousIcon
                              style={{
                                color: "red",
                                cursor: "pointer",
                                marginTop: "20px",
                              }}
                              onClick={() => removeFormFields(index)}
                            />
                          ) : null}
                        </div>
                      ))}
                      <Button
                        style={{ margin: "10px" }}
                        variant="contained"
                        type="submit"
                        onClick={() => addFormFields()}
                      >
                        Add
                      </Button>
                    </form>
                    <br />

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        variant="contained"
                        onClick={insertInviteData}
                        type="submit"
                        style={{ margin: "5px" }}
                      >
                        Invite
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleClose}
                        style={{ margin: "5px" }}
                      >
                        Go Back
                      </Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </>
          )}
        </div>

        <div>
        <Modal
          open={detailsState}
          onClose={handleDetailsObj}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ textAlign: "center" }}
        >
          <Box sx={style}>
            <ParticipantPopUp
              close={handleDetailsObj}
              obj={viewDetails}
              id={id}
              load={getJoinedData}
              click={handlebarClick}
            />
          </Box>
        </Modal>
        {/* master data component start here */}
        {/* dailog box component start here */}
      </div>

      {/* edit modal pop up component start here */}
      <Modal
        open={editPopup}
        onClose={() => setEditPopup(!editPopup)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Participant Details
            </Typography>
            <br />

            <TextField
              id="outlined-basic"
              label="Employee ID"
              name="productId"
              value={edit.employeeId ?? "Not Found"}
              disabled
              // onChange={handleInput}
              variant="outlined"
            />
            <br />

            {/* Start new form for key & value */}
            <form onSubmit={handlebarSubmit}>
              {editFormValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <input
                    name="key"
                    value={element.key || ""}
                    onChange={(e) => handleEditChanges(index, e)}
                    required
                  />

                  <input
                    name="value"
                    value={element.value || ""}
                    onChange={(e) => handleEditChanges(index, e)}
                    required
                  />

                  {index ? (
                    <DangerousIcon
                      style={{ color: "red ", cursor: "pointer" }}
                      onClick={() => removeEditFormFields(index)}
                    />
                  ) : null}
                </div>
              ))}
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                type="submit"
                onClick={() => addEditFormFields()}
              >
                Add
              </Button>
            </form>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleEditSubmit}
                variant="contained"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                onClick={() => setEditPopup(!editPopup)}
                variant="contained"
                type="submit"
              >
                Go Back
              </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
      {/* edit modal pop up component ends here */}

      {/* error & success toaster toaster */}
      {(errormsg || successmsg) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={toaster}
            autoHideDuration={4000}
            onClose={handleClearToaster}
          >
            <Alert
              onClose={handleClearToaster}
              severity={errormsg ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </>
  );
}
