import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography'
import { makeStyles } from "@material-ui/core";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useCookies } from "react-cookie";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
    newaccount: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export const InviteUserDialog = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["user"]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [domainCheck, setDomainCheck] = useState(false);
  const [domainExists, setDomainExists] = useState(false);
  const [invalidEmailDomain, setInvalidEmailDomain] = useState(false);
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const handleSave = async (e) => {
    console.log('ia m here,', e)
    e.preventDefault();
    let result;
    if (e.target.checkValidity()) {
      if (domainCheck) {
        if (!domainExists && (email.split('@')[1] !== domain)) {
          setInvalidEmailDomain(true);
          return
        }
        result = await onSave({ Name: name, Email: email, DomainExists: domainExists });
        console.log("result of onsave in Invite : - ", result);
        handleCancel();
      } else {
        result = await onDomainCheck({ Domain: domain });
        // if (result.message == 'Domain Exists') {
        //   setDomainCheck(true);
        // }
      }
      // onSave({ Name:name, Email:email});
      // onClose();
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Only allow alphabets (A-Z and a-z) and spaces
    if (/^[A-Za-z\s]*$/.test(inputValue)) {
      setName(inputValue);
    }
  };
  const handleCancel = () => {
    // Close the dialog without saving
    onClose();
    setName('');
    setEmail('');
    setDomainCheck(false);
    setDomainExists(false);
    setDomain('');
    setInvalidEmailDomain(false);
  };

  const onDomainCheck = async (userData) => {
    try {
      console.log('User in onDomainCheck', userData);
      const checkDomainResult = await axios.get(API_URL + `/vendor/checkInvitedVendor?Domain=${userData.Domain}`, headers);
      console.log("checkDomainResult in inviteUserDialog : - ", checkDomainResult);

      if (!checkDomainResult) {
        setDomainExists(false);
        return false;
      } else {
        if (checkDomainResult.data.message === 'Domain Exists') {
          console.log("inside onDomain else If : - ", checkDomainResult);
          setEmail(checkDomainResult.data.participant.PrimaryMail);
          setName(checkDomainResult.data.participant.Name);
          setDomainCheck(true);
          setDomainExists(true);
        } else if (checkDomainResult.data.message === 'Vendor does not exists') {
          console.log("iside else if onDomaincheck");
          setDomainCheck(true);
          setDomainExists(false);
        }
      }
    } catch (error) {
      console.log("Error inside onDomainCheck function : - ", error.stack);
    }
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Invite Vendor</DialogTitle>
      <DialogContent>
        <form className={classes.root} onSubmit={handleSave}>
          {
            domainCheck && !domainExists ?
              <>
                <TextField
                  label="Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                  required
                />
                <TextField
                  type="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {
                  invalidEmailDomain ?
                    <Typography style={{ color: 'red' }} variant="subtitle2">
                      Enter a domain ending with: @{domain}
                    </Typography> : null
                }
              </> :
              <>
                <TextField
                  label="Enter Vendors Website (ex. domain.com)"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={domainCheck}
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  required
                />
                {
                  domainExists ?
                    <Typography variant="subtitle2">
                      Vendor already exists on Votan, Do you want to add to your list ?
                    </Typography> : null
                }
              </>
          }
          {/* <TextField
          select
          label="Role"
          fullWidth
          margin="normal"
          variant="outlined"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          {roles.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {role.label}
            </MenuItem>
          ))}
        </TextField> */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            {
              domainCheck ?
                <Button type="submit" variant="contained" color="primary" style={{ marginRight: '8px' }}>
                  Invite
                </Button> :
                <Button type="submit" variant="contained" color="primary" style={{ marginRight: '8px' }}>
                  Check
                </Button>
            }
            {/* <Button type="submit" variant="contained" color="primary" style={{ marginRight: '8px' }}>
              Invite
            </Button> */}
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
