import { Close } from '@material-ui/icons';
import {
  Typography,
  MenuItem,
  Button,
  Card,
  CardContent,
  Box,
  FormControl,
  Select,
  IconButton,
  TextField
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const useStyles = {
  button: {
    width: '100%',
    marginTop: 2,
  },
  formControl: {
    width: '100%',
    marginBottom: 2,
  },
};

const PdfDialog = (props) => {
  const [reportingStandard, setReportingStandard] = useState('');
  const [scope, setScope] = useState('');
  const [reportType, setReportType] = useState('');
  const [title, setTitle] = useState('GHG Report for 2021');
  const [description, setDescription] = useState('');
  const [cookies] = useCookies(['user']);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [opens, setOpens] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpens(false);
  };
  const API_URL = CONSTANTS.API_URL;
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const handleGenerateReport = () => {
    console.log('Generating report...');
    console.log("reportingStandard : - ", reportingStandard);
    console.log("scope : - ", scope);
    console.log("reportType : - ", reportType);

    let scopeForReport = "";

    if (reportingStandard === "higg") {
      scopeForReport = "HIGG";
    } else if (reportingStandard === "emissions" || reportingStandard === "activity") {
      scopeForReport = scope;
    }

    let result = submitGenerateReportRequest(scopeForReport);
    // Handle report generation logic here
  };
  React.useEffect(() => {
    if (reportingStandard === 'higg') {
      setScope('');
      setReportType('');
      setButtonEnabled(true);
    } else if (scope !== '' && reportType !== '') {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [reportingStandard, scope, reportType]);
  const submitGenerateReportRequest = async (scope) => {
    try {
      const dashboardBody = {
        from: cookies.from,
        to: cookies.to,
        scope: scope,
        title: title,
        description: description
      };
      const { data } = await axios.post(
        API_URL + `/activityReport/create`,
        dashboardBody,
        headers
      );
      console.log("submit generate report response : - ", data);
      if (data) {
        props.setOpens(true);
        props.success("Report generated successfully");
        props.close();
      }
    } catch (err) {
      console.log(err, "err in get dashboard emissions");
      props.setOpens(true);
      props.success(`Something went wrong, ${err.message}`);
    }
  };

  return (
    <>
    <Box display="flex" justifyContent="center" alignItems="center"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '20px', 
      p: 4,
    }}
    >
        <Card>
        <CardContent>
          <Typography align="center" gutterBottom>
            <strong>Generating for range {cookies.from} - {cookies.to}</strong>
          </Typography>
          <IconButton
            sx={{ position: 'absolute', top: 30, right: 30 }}
            onClick={props.close}
          >
            <Close />
          </IconButton>
          <TextField
            sx={{ width: '100%', marginBottom: 1 }}
            label="Title"
            variant="outlined"
            name="Title"
            value={title}
            onChange={(e) => {setTitle(e.target.value)}}
          />
          <TextField
            // id="quantity-input"
            sx={{ width: '100%', marginBottom: 1 }}
            label="Description"
            variant="outlined"
            name="Description"
            value={description}
            onChange={(e) => {setDescription(e.target.value)}}
          />
          <FormControl sx={useStyles.formControl}>
            <Select
              value={reportingStandard}
              onChange={(e) => setReportingStandard(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a reporting standard
              </MenuItem>
              <MenuItem value="higg">Higg</MenuItem>
              <MenuItem value="emissions">Emissions</MenuItem>
              <MenuItem value="activity">Activity</MenuItem>
            </Select>
          </FormControl>
          {reportingStandard === 'higg' && (
            <>
              <FormControl sx={useStyles.formControl}>
                <Select
                  value="Refrigerants and Purchased Electricity"
                  disabled
                  displayEmpty
                >
                  <MenuItem value="Refrigerants and Purchased Electricity">
                    Refrigerants and Purchased Electricity
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={useStyles.formControl}>
                <Select
                  value="SAC"
                  disabled
                  displayEmpty
                >
                  <MenuItem value="SAC">SAC</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          {reportingStandard !== 'higg' && (
            <>
              <FormControl sx={useStyles.formControl}>
                <Select
                  value={scope}
                  disabled={reportingStandard === 'higg'}
                  onChange={(e) => setScope(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select scope of report
                  </MenuItem>
                  <MenuItem value="all">All activities</MenuItem>
                  <MenuItem value="scope1_2">Scope 1 & 2</MenuItem>
                  <MenuItem value="scope1_2_3">Scope 1, 2 & 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={useStyles.formControl}>
                <Select
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  disabled={reportingStandard === 'higg'}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select report type
                  </MenuItem>
                  <MenuItem value="ISO">ISO</MenuItem>
                  <MenuItem value="GRI">GRI</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <Button
            onClick={handleGenerateReport}
            variant="contained"
            sx={useStyles.button}
            disabled={!buttonEnabled}
          >
            Generate
          </Button>
          <Button
            onClick={()=>props.close()}
            variant="contained"
            sx={useStyles.button}
          >
            Close
          </Button>
          </CardContent>
          </Card>
    </Box>
 {(success || error) && (
  <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
    <MuiAlert
      onClose={handleCloses}
      severity={success?"success":"error"}
      sx={{ width: "100%" }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {success||error}
    </MuiAlert>
  </Snackbar>
)}
</>
  );
};

export default PdfDialog;
