import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import axios library
import axios from "axios";
// import component
import UserAccessFormButton from "./UserAccessFormButton";
// import Cookie from react-cookie
import { useCookies } from "react-cookie";
import { CONSTANTS, HEADERS } from "../../component/utils/constants";
// import Loader compoenent
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// import React Chart Component
import ReactChart from "../../component/ReactOrgChart/ReactChart";
// tabs part
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Toaster from "../../component/Toaster/Toaster";
import JoinParticipant from "./JoinParticipant";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height:"100%"
  },
  container: {
    maxWidth: 1100,
    maxHeight: 400,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserAccess() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [refersh, setRefersh] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [pdata, setPData] = React.useState([]);
  const [role, setRole] = React.useState("admin");
  const [popUpstate, setPopUpState] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // Headers
  const headers = HEADERS(cookies.token);

  React.useEffect(() => {
    getParticipantData();
    getUserById();
  }, []);

  // call an api to get Participant data by Nid from db
  const getParticipantData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        API_URL + `/participant/getParticipant`,
        headers
      );
      data.error ? setRows([]) : setRows(data);
      console.log(data, "all participant data here");
      setIsLoading(false);
      setRefersh(true);
      setPopUpState(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // get User by id api
  const getUserById = async () => {
     try {
       const { data } = await axios.get(API_URL + `/user/id?user_id=${cookies.user_id}`, 
       headers
       );
      // const options = ['Admin', 'Member', 'Subscriber'];
      if(data && data.role){
        setRole(data.role)
      }
       data.error ? setPData([]) : setPData(data);
       console.log(data,"data by user id..")
     } catch(err){
      console.log(err,"participant by id api")
     }
  }

  return (
    <>
    {popUpstate && <Toaster msg={'Fetching real-time data'} />}
      {isLoading && <FullScreenLoader />}
      {!isLoading && (
        <Paper className={classes.root}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{ backgroundColor:'black' }}
                TabIndicatorProps={{style: { backgroundColor: "white" }}}
              >
                <Tab label="Invited" {...a11yProps(0)} style={{ color:"#fff" }} />
                <Tab label="Joined" {...a11yProps(1)} style={{ color:"#fff" }} />
                <Tab label="Graph View" {...a11yProps(2)} style={{ color:"#fff" }} />
              </Tabs>
            </Box>
            
            <TabPanel value={value} index={0}>
              <UserAccessFormButton refresh={getParticipantData} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <JoinParticipant />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <ReactChart obj={rows} />
            </TabPanel>
          </Box>
        </Paper>
      )}
    </>
  );
}
