import React from "react";
import { NavLink } from 'react-router-dom';
import NavItemHeader from "./NavItemHeader";
import style from "../NavItem/navItem.module.css";

const NavItem = (props) => {
  const { label, Icon, to, children } = props.item;
  if (children) {
    return <NavItemHeader item={props.item} clicker={() => props.clicker()}/>;
  }
  return (
    <>
      {!(props.url) && (<NavLink
        exact
        to={to}
        className={style.navItem}
        onClick={() => props.clicker()}
        activeClassName={style.activeNavItem}
      >
        <Icon className={style.navIcon} />
        <span className={style.navLabel}>{label}</span>
      </NavLink>)}
      {(props.url) && (<a
        href={props.url} 
        target="_blank"  
        rel="noopener noreferrer"
        // clicker={() => props.clicker()}
        className={`${style.navItem} `}  // Apply the same CSS classes as NavLink
      >
        <Icon className={style.navIcon} />
        <span className={style.navLabel}>{label}</span>
      </a>)}
    </>
  )
}

export default NavItem;