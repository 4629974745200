import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import axios
import axios from "axios";
// import React Cookie
import { useCookies } from "react-cookie";
// import headers
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import icon
import ClearIcon from "@mui/icons-material/Clear";
import ReorderIcon from "@mui/icons-material/Reorder";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import Toaster from "../../component/Toaster/Toaster";

export default function Notification() {
  const [rows, setRows] = React.useState([]);
  const [adminRows, setAdminRows] = React.useState([]);
  const [popUpstate, setPopUpState] = React.useState(true);
  const [cookies, setCookie] = useCookies(["user"]);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // get participant by id
  React.useEffect(() => {
    getParticipantById();
  }, []);

  const handleDeleteAll = async () => {
    try {
      if (adminRows.length > 0) {
        const { data } = await axios.get(
          API_URL + `/participant/PID/notifications/admin/deleteAll`,
          headers
        );
        console.log(data,"admin notifications data")
        if (data.error) {
          console.log("error found", data.error); // @TO-DO: Open this in toaster
        }
      } else {
        const { data } = await axios.get(
          API_URL + `/participant/PID/notifications/deleteAll`,
          headers
        );
        console.log(data,"user notification data")
        if (data.error) {
          console.log("error found", data.error); // @TO-DO: Open this in toaster
        }
      }
      getParticipantById();
    } catch (e) {
      console.log(e,"err in notifications api");
    }
  };

  const handleGeneralDelete = async (id, admin) => {
    try {
      if (admin) {
        const { data } = await axios.get(
          API_URL + `/participant/PID/notifications/admin/delete/${id}`,
          headers
        );
        if (data.error) {
          console.log("error found", data.error); // @TO-DO: Open this in toaster
        }
      } else {
        const { data } = await axios.get(
          API_URL + `/participant/PID/notifications/delete/${id}`,
          headers
        );
        if (data.error) {
          console.log("error found", data.error); // @TO-DO: Open this in toaster
        }
      }
      getParticipantById();
    } catch (e) {
      console.log(e);
    }
  };
  
  const getParticipantById = async () => {
    try {
      const { data } = await axios.get(API_URL + `/participant/id`, headers);
      console.log(data, "notification data here");
      let temp = [];
      console.log(data, "data here");
      if (
        data.error ||
        !data.Notifications ||
        data.Notifications.length === 0
      ) {
        temp = [];
      } else {
        temp = data.Notifications;
      }
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      console.log(user, "user notifications");
      if (user?.data?.Role === "admin") {
        setAdminRows(data.AdminNotifications);
      }
      setRows(temp);
      setPopUpState(false);
    } catch (err) {
      console.log(err, "err in participant by id api");
    }
  };

  return (
    <div>
      {popUpstate && <Toaster msg={"Fetching real-time data"} />}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography style={{ color: "black", fontWeight: "bold" }}>
            Notifications
          </Typography>
          <ClearAllIcon
            size="small"
            style={{ float: "right" }}
            onClick={() => handleDeleteAll()}
          />
        </CardContent>
        <CardContent style={{ width: "auto" }}>
          {rows?.map((m, i) => (
            <Card style={{ margin: "10px", padding: "14px" }} key={i}>
              <ReorderIcon style={{ margin: "2px", verticalAlign: "bottom" }} />

              {m}
              <ClearIcon
                style={{ float: "right" }}
                onClick={() => handleGeneralDelete(i, false)}
              />
            </Card>
          ))}
          {adminRows?.map((m, i) => (
            <Card style={{ margin: "10px", padding: "14px" }} key={i}>
              <ReorderIcon style={{ margin: "2px", verticalAlign: "bottom" }} />
              {m}
              <ClearIcon
                style={{ float: "right" }}
                onClick={() => handleGeneralDelete(i, true)}
              />
            </Card>
          ))}
        </CardContent>
      </Card>
    </div>
  );
}
