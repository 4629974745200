import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import { Box, Button, Card, CardContent, Modal  } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
// import { Box} from '@material-ui/core';

const MissingPIDLoader = () => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };
  React.useEffect(() => {
    setOpen(true);
  },[])

    return(
      <>
      <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
      <Box sx={style} 
          style={{ textAlign: "center" }}
      
      >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Missing Company ID
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Let's start by associating you with a company first
          </Typography>
          <br/>
          <Button variant='contained' onClick={()=> history.push('/admin/profile')}> Connect Company </Button>
        </Box>
        </Modal>

    </>
    );
}

export default MissingPIDLoader;
