import React from 'react';
import style from "../Sidebar/sidebar.module.css";
import NavItem from '../NavItem/NavItem';
import { sideMenu } from '../Menubar/menu.config.js';
// import style from "../NavItem/navItem.module.css";
const Sidebar = (props) => {
  return (
    <>
      <nav className={style.sidebar}>
        {sideMenu.map((item, index) => {
          return <React.Fragment key={index}>
            {item.to !== '/plan' && (
              <NavItem
              clicker={() => props.clicker()}
                // key={`${item.label}-${index}`}
                item={item}
              />
            )}
            {item.to === '/plan' && (
             <NavItem 
                // key={`${item.label}-${index}`}
                item={item}
                url={'https://www.votan.eco/contact-us/'}
              />
            )}
          </React.Fragment>
        })}
      </nav>
    </>
  )
}

export default Sidebar;