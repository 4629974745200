import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
// import image
import ListAltIcon from '@mui/icons-material/ListAlt';
// import axios
import axios from "axios";
// import Headers
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import React-Cookie
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    width: "100%",
  },
}));


const Track = () => {
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [selectedAttr, setSelectedAttr] = React.useState([]);
  const [selectedVal, setSelectedVal] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  let   { id } = useParams();

  // API URL
  const API_URL = CONSTANTS.API_URL
  
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // useEffect
  React.useEffect(() => {
    console.log(id,'hello')
    getBlockchainHistory(id);
  }, [selectedAttr]);

  // call an Api for Get Blockchain History
  const getBlockchainHistory = async (id) => {
    try {
      const { data } = await axios.get(
        API_URL+`/ledger/history/${id}`,
        headers
      );
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelected = async (i) => {
    try {
      setSelected(i);
      let attr = [];
      let val = [];
      for (let x in i.value.productAttributes) {
        attr.push(x);
        val.push(i.value.productAttributes[x]);
      }
      setSelectedAttr(attr);
      setSelectedVal(val);
    } catch (err) {
      console.log(err);
    }
  };

    return(
    <>
    <Grid container spacing={2} direction="row">
        <Grid xs={6}>
          <Item style={{ height:"auto" }}>
            <Timeline position="alternate">
              {rows.map((p, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {p.timestamp}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      style={{ backgroundColor: p.value.color }}
                      onClick={() => getSelected(p)}
                    >
                      <Grid xs={4}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Item>
                                <Typography color="inherit"></Typography>
                                {JSON.stringify(p)}
                              </Item>
                            </React.Fragment>
                          }
                        >
                         <ListAltIcon />
                        </HtmlTooltip>
                      </Grid>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                      {p.action}
                    </Typography>
                    <Typography>{p.value.user_id}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
            {/* hover */}
          </Item>
        </Grid>

        {selected?.action && (
          <Grid xs={6}>
            <Item style={{ height:"auto" }}>
              <h3>Select a product to view details</h3>
              {/* main div start here */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* fields start here */}
                <div style={{ textAlign: "left" }}>
                  {/* <Typography>
                    <strong>Action</strong>
                  </Typography> */}
                  <Typography>
                    <strong>Blockchain Action</strong>
                  </Typography>
                  <Typography>
                    <strong>Id</strong>
                  </Typography>
                  <Typography>
                    <strong>PID</strong>
                  </Typography>
                  <Typography>
                    <strong>User ID</strong>
                  </Typography>
                  <Typography>
                    <strong>Timestamp</strong>
                  </Typography>
                  {selectedAttr?.map((m, index) => (
                    <Typography>
                      <strong key={index}>{m}</strong>
                    </Typography>
                  ))}
                </div>
                {/* fields ends here */}
                {/* data fields start here */}
                <div style={{ textAlign: "left", marginLeft: "auto" }}>
                  {/* <Typography>{selected?.value.action}</Typography> */}
                  <Typography>{selected?.action}</Typography>
                  <Typography>{selected?.value.id}</Typography>
                  <Typography>{selected?.value.PID}</Typography>
                  <Typography>{selected?.value.user_id}</Typography>
                  <Typography>{selected?.timestamp}</Typography>
                  {selectedVal?.map((m, index) => (
                    <Typography key={index}>{m}</Typography>
                  ))}
                </div>
                {/* data fields ends here */}
              </div>
              {/* main div ends here */}
            </Item>
          </Grid>
        )}
      </Grid>
    </>
    )
}

export default Track;