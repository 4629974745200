import React from "react";
import styled from "@emotion/styled";
// import Cookie from react-cookie
import { useCookies } from "react-cookie";
import { Tree, TreeNode } from "react-organizational-chart";

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 12px;
  display: inline-block;
  border: 1px solid rgba(0, 171, 85, 0.24);
  background-color: rgba(0, 171, 85, 0.08);
  color: rgb(0, 82, 73);
  padding: 16px;
  margin-top: 10px;
  margin-bottom: 2px;
`;

const ReactChart = (props) => {
  const [cookies, setCookie] = useCookies(["user"]);

  React.useEffect(() => {
    console.log(props.obj);
  }, []);

  return (
    <>
      <Tree
        lineWidth={"2px"}
        lineColor={"green"}
        lineBorderRadius={"10px"}
        // label={<StyledNode>{cookies.Nid}</StyledNode>}
        label={<StyledNode>My Network</StyledNode>}
      >
        {props?.obj.map((t, index) => (
          <TreeNode
            key={index}
            label={<StyledNode>{t.company}</StyledNode>}
          ></TreeNode>
        ))}
      </Tree>
    </>
  );
};

export default ReactChart;
