import * as React from "react";
import { useCookies } from "react-cookie";
// import component
import Login from "../Login/Login";
import Signup from "../SignUp/Signup";
import RegisterCompany from "../RegisterCompany/RegisterCompany";
import Card from "@material-ui/core/Card";
// import API_URL and Cookies
import { CONSTANTS, HEADERS } from "../utils/constants";
// logo
import VotanLogo from "../../assets/images/votan_logo_black.png";
// import style from material-ui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import welcome C.S.S
import "./welcome.css";
import NewUser from "../../Pages/NewUser/newUser";
import NewVendor from "../../Pages/NewVendor/newVendor";
import NewSite from "../../Pages/NewSite/newSite"
import ForgetPassword from "../../Pages/Users/ForgetPassword";
import AcceptRejectRelation from "../../Pages/Vendors/AcceptRejectRelation";
import { Typography } from "@material-ui/core";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Welcome() {
  const [appid, setAppID] = React.useState(1);
  const [nid, setNid] = React.useState("");
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState(false);
  const [pageState, setPageState] = React.useState("login");
  const [page, setPage] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [checkNew, setCheckNew] = React.useState(false);
  const [networkId, setNetworkId] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // Headers
  const headers = HEADERS(cookies.token);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appid, nid);
    if (appid === "" || appid.length === 0) {
      setError("App Id is required");
    }
  };

  React.useEffect(() => {
    // calling an api
    const pathSegments = window.location.pathname.split('/');
    if (pathSegments[1] === 'verify') {
      setPageState('verify');
    } else if (pathSegments[1] === 'inviteVendor') {
      setPageState('inviteVendor');
    } else if (pathSegments[1] === 'forgetPassword') {
      setPageState('forgetPassword');
    } else if (pathSegments[1] === 'inviteSite') {
      setPageState('inviteSite');
    } else if (pathSegments[1] === 'addVendorToRelations') {
      setPageState('addVendorToRelations');
    }
  }, []);

  return (
    <>
      <div className="welcome-container">
        <div className="background-image"></div>
        <div className="centered-content">
          <Card className="contentCard">
            <>
              <div className="imageLogo">
                <img
                  style={{ width: "100%", alignSelf: "center" }}
                  src={VotanLogo}
                  alt="logo"
                />

              </div>
              <Typography id="heading-title">Know Your Emissions</Typography>

              {pageState === "login" &&
                <>
                  {!page ? (

                    <Login
                      page={() => setPage(!page)}
                      pstate={setPageState}
                      newUser={checkNew}
                    />

                  ) : (
                    <Signup
                      page={() => setPage(!page)}
                      pstate={setPageState}
                      PID={cookies.PID ? cookies.PID : ""}
                      checkNew={setCheckNew}
                    />
                  )
                  }
                </>

              }
              {pageState === "verify" && (
                <NewUser />
              )}
              {pageState === "inviteVendor" && (
                <NewVendor />
              )}
              {pageState === "forgetPassword" && (
                <ForgetPassword />
              )}
              {pageState === "inviteSite" && (
                <NewSite />
              )}
              {pageState === "addVendorToRelations" && (
                <AcceptRejectRelation />
              )}
            </>
      </Card>
        </div>
    </div >
    </>
  );
}
