import * as React from "react";
// import invoice c.s.s
import "../../component/invoice/invoice.css";
// import axios
import axios from "axios";
// import headers & constants
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
// pop up component
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toaster from "../../component/Toaster/Toaster";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@material-ui/core";
// IMPORT FullScreenLoader
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// table component start here
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// table ends here
// import image
import ProfileImg from "../../assets/images/profileIcon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f1f5f9",
  boxShadow: 24,
  width: "80vw",
  padding: "2rem",
  maxHeight: "100%",
  overflow: "auto",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ViewReport = (props) => {
  const [loader, setLoader] = React.useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [count, setCount] = React.useState(0);
  const [logoheader, setLogoHeader] = React.useState({});
  const [State, setStates] = React.useState({});
  // set incoming report view data state
  const [incomingReportData, setIncomingReportData] = React.useState([]);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // UPLOAD URL
  const UPLOAD_URL = CONSTANTS.UPLOAD_URL;
  // Headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // handle submit bar
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // React UseEffect
  React.useEffect(() => {
    getPurchaseOrderById();
    setIncomingReportData(props?.obj?.row);
    console.log(props?.obj, "reports obj new data");
    console.log(incomingReportData, "incoming Report Data");
  }, [setCount,incomingReportData]);

  // get participant by Vendor Id For Logo & headers
  const getPurchaseOrderById = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/participant/id`,
        headers
      );
      if (data.logo) {
        const getLogo = await axios.get(
          UPLOAD_URL + `/attachment/get/${data.id}`,
          headers
        );
        setLogoHeader(getLogo.data);
      }
      if (!data.error) {
        setStates(data);
      }
    } catch (err) {
      console.log(err, " err in participant by id for headers logo");
    }
  };

  return (
    <>
      <div>
        <Card
          variant="outlined"
          style={{
            width: "100%",
            textAlign: "center",
            height: "auto",
            marginTop: "1rem",
            borderRadius: "10px",
          }}
        >
          <CardContent>
           <Typography>Report is under process, we will notify you once the report is available to download</Typography>
          </CardContent>
        </Card>
        {loader && <FullScreenLoader style={{ height: "100vh" }} />}
      </div>
    </>
  );
};

export default ViewReport;
