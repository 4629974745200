import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// modal overlay pop up
import axios from "axios";
// import React cookie
import { useCookies } from "react-cookie";
// import API_URL and Cookies
import {
  CONSTANTS,
  HEADERS,
  HEADERS2,
} from "../../component/utils/constants";
// import CSS for master upload component
import "../../component/CSS/MasterUpload.css";
// dialog box component
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ThirdPartyUploadForm(props) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["user"]);
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [vehiclesField, setVehiclesField] = React.useState(
    {
      Name: "",
      Number: "",
      Description: "",
      Assignee: "",
      Others: "",
      NID: cookies.Nid,
      PID: cookies.PID,
      user_id: cookies.user_id
    },
  );

  // API_URL
  const API_URL = CONSTANTS.API_URL;

  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleVehiclesInput = (event) => {
    const vehiclesState = {
      ...vehiclesField,
      [event.target.name]: event.target.value,
    };
    setVehiclesField(vehiclesState);
  };

  // handling form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    let tempBody= vehiclesField
    console.log('call submit api here', vehiclesField)
    const { data } = await axios.post(
      API_URL + `/vehicle/create`,
      tempBody,
      headers
    );
    console.log(data, "In House vehicle data api calling");
    if (data.error) {
      props.setErrorMsg(data.error);
      props.close();
    } else {
      console.log(data);
      props.setSuccessMsg("Vehicle created successfully");
      props.close();
      props.state(); // get api calling
    }
  };


  return (
    <>
        <DialogTitle>Vehicles</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Register A New Vehicle
            </DialogContentText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Name"
                  name="Name"
                  value={vehiclesField.Name}
                  onChange={handleVehiclesInput}
                  variant="outlined"
                  style={{ padding: "8px" }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="Number"
                  name="Number"
                  value={vehiclesField.Number}
                  onChange={handleVehiclesInput}
                  variant="outlined"
                  style={{ padding: "8px" }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="outlined-basic"
                  label="Assignee"
                  name="Assignee"
                  value={vehiclesField.Assignee}
                  onChange={handleVehiclesInput}
                  variant="outlined"
                  style={{ padding: "8px" }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="outlined-basic"
                  label="Description"
                  name="Description"
                  value={vehiclesField.Description}
                  onChange={handleVehiclesInput}
                  variant="outlined"
                  style={{ padding: "8px" }}
                />
              </div>
              <TextField
                autoFocus
                margin="dense"
                id="outlined-basic"
                label="Other Details"
                name="Others"
                value={vehiclesField.Others}
                onChange={handleVehiclesInput}
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                style={{ padding: "8px" }}
              />
            </div>
            </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* @TODO: api to save */}
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="contained" onClick={()=>props.close()}>
              Cancel
            </Button>
          </DialogActions>
    </>
  );
}
