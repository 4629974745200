import { createChatBotMessage } from "react-chatbot-kit";
// import component
import DogPicture from "./DogPicture";
// import chat logo
import ChatLogo from "../assets/images/votan-512x512.png";

const botName = "Votan";
const config = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(`Hello I am ${botName}! \n\nWhat would you like to know?`, {
      widget: "FAQ",
    }),
    createChatBotMessage('My AI brain is getting ready, I will be responding very shortly.')
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#3a7b6e" ,
    },
    chatButton: {
      backgroundColor: "#3a7b6e" ,
    },
  },
  customComponents: {
    header: () => (
      <div
        style={{ color: "#F4F3ED", backgroundColor: "#3a7b6e" }}
        className="react-chatbot-kit-chat-header"
      >
        Votan A.I Assistant
      </div>
    ),
    botAvatar: (props) => (
      <img
        alt="chatbot"
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{
          //  border:"2px solid #000000",
          borderRadius: "50%",
        }}
        src={ChatLogo}
        width="40px"
        height="40px"
      />
    ),
    // Replaces the default user icon
    // userAvatar: (props) => <MyCustomAvatar {...props} />,
    // userChatMessage: (props) =>  <DogPicture {...props} />
// Replaces the default bot chat message container
// botChatMessage: (props) => <DogPicture {...props} />,
  },
  widgets: [
    {
      widgetName: "dogPicture",
      widgetFunc: (props) => <DogPicture {...props} />,
    },
  ],
  // widgets: [
  //     {
  //         widgetName: "FAQ",
  //         widgetFunc: (props) => <FAQ {...props} />,
  //         props: { user: userName, userId: user.userId }
  //     },
  //     {
  //         widgetName: "FullFAQ",
  //         widgetFunc: (props) => <FullFAQ {...props} />,
  //     },
  //     {
  //         widgetName: "faqSubCategory",
  //         widgetFunc: (props) => <CategorySubQuestions {...props} />,
  //     },
  //     {
  //         widgetName: "alert",
  //         widgetFunc: (props) => <Alert {...props} />,
  //     },
  //     {
  //         widgetName: "nextset",
  //         widgetFunc: (props) => <NextSet {...props} />
  //     }
  // ],
};

export default config;
