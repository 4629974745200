import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box, Modal } from "@mui/material";
import { Bars, Watch } from "react-loader-spinner";
import { Dialog } from "@material-ui/core";

const FullScreenLoader = () => {
  const [open, setOpen] = React.useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    height: "20%",
    bgcolor: "white",
    "&:focus": {
      outline: "none",
    },
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <Modal className="ft-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ textAlign: "center" }} >

          <Stack direction="column" alignItems="center">
            <Typography>Please Wait</Typography>
            <Watch
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="watch-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Stack>

        </Box>
      </Modal>
    </>
  );
};

export default FullScreenLoader;
