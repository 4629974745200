import React from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
// import Navlink
import { NavLink } from "react-router-dom";

const YourProfile = () => {
  return (
    <div>
      <Card style={{ padding: "20px" }}>
        <Typography style={{ textAlign: "center" }}>
          Your Profile is incomplete. please
          <NavLink
            to="/admin/profile"
            style={{
              // textDecoration: "none",
              color: "#000000",
              marginLeft: "5px",
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            click here
          </NavLink>
          and complete your profile.
        </Typography>
      </Card>
    </div>
  );
};

export default YourProfile;
