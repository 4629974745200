import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

const MessageParser = ({ children, actions }) => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["user"]);
  // chatbox state
  const [pst, setPst] = useState('');

  // handle bar message function pst naviagte dashboard
  const handlePstNavigate = () => {
    history.push(`/`); // navigate tracker page
    setPst(); // use later
  }

  // useEffect calling
  useEffect(() => {
    setCookie("pst", pst, { path: "/" });
    console.log("pst chatbox msg!");
    if(pst === 'dashboard'){
      handlePstNavigate(); // calling function
    }
  },[]);

  const parse = (message) => {
    console.log(message, "PARSE 123");
    if(message){
      actions.handleHello()
    }

    // if (message.includes("hello")) {
    //   actions.handleHello();
    // }
    // if (message.includes("dog")) {
    //   actions.handleDog();
    // }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
