import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Typography } from "@material-ui/core";

const QRCode = (props) => {
  return (
    <>
      <QRCodeSVG
      style={{ height:"50%" }}
        value={`https://vchained.com/vtracker/track.html?netid=${props.nid}&key=${props.id}`}
      />
      <Typography>
        <a style={{ textDecoration:"none", color:"#000" }}
          target="_blank"
          href={`https://vchained.com/vtracker/track.html?netid=${props.nid}&key=${props.id}`}
        >
          Go to VTracker
        </a>
      </Typography>
    </>
  );
};

export default QRCode;
