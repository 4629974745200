import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import "./componentPage.css";
// import Votan New Logo
import VotanLogo from "../../assets/images/votan_text_white.png";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import { useCookies } from "react-cookie";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const ComponentPage = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  // get emissions state
  const [emissionSummary, getEmissionSummary] = useState([]); // api state
  const Card = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#fff', 0.50),
    "&:hover": {
      backgroundColor: alpha('#fff', 0.75),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));
  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      backgroundColor: alpha(theme.palette.common.white, 0.50),
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.75),
      },
      "& fieldset": {
        border: `1px solid ${alpha(theme.palette.common.white, 0.15)}`, // Add border for a cleaner look
      },
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 4), // Adjust left padding
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("md")]: {
        width: "20ch", // Decreased the width for smaller screens
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.text.primary, // Match the text color
      fontSize: "20px",
      fontFamily: "Arial, sans-serif",
    },
  }));
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  // API URL
  const API_URL = CONSTANTS.API_URL;

  const handleSearch = () => {
    if (searchQuery) {
      history.push(`/tracker?query=${searchQuery}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const inputRef = React.useRef(null);
  const handleClick = () => {
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  };
  // get api for emissions summary
  const getEmissionsSummary = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/ef/emissions-summary`,
        headers
      );
      if (!data) {
        getEmissionSummary([]);
      } else {
        getEmissionSummary(data);
        console.log(data, "data found summary emission");
      }
    } catch (err) {
      console.log(err, "err in get emissions summary");
    }
  }
  window.addEventListener('resize', setContainerHeight);

function setContainerHeight() {
  const container = document.querySelector('.search-container');
  if (container) {
    const viewportHeight = window.innerHeight;
    container.style.minHeight = `${viewportHeight}px`;
  }
}

  useEffect(() => {
    getEmissionsSummary(); // call an api
    setContainerHeight();
  }, []);

  return (
    <>
      <div className="search-container">
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <img
            src={VotanLogo}
            style={{ width: "400px", height: "80px", marginTop: "50px" }}
            alt="votan-logo"
          />
          <Typography
            variant="h6"
            style={{
              color: "#fff",
              letterSpacing: "40px",
              marginBottom: "20px",
              marginLeft: "60px",
            }}
          >
            DISCLOSURE
          </Typography>
        </div>
        <Grid
          container
          spacing={3}
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            marginBottom: "20px",
            paddingTop: "50px",
          }}
        >
          <Grid item xs={4}>
  <Card
    sx={{
      borderRadius: "1rem",
      color: "#003E35",
      height: "100%",
      "@media (max-width: 600px)": {
        height: "200px", 
      },
      "@media (min-width: 601px) and (max-width: 960px)": {
        height: "250px", 
      },
    }}
  >
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" component="div" textAlign="center">
        <strong>Total Emission Disclosures</strong>
      </Typography>
      <Typography variant="body1" textAlign="center">
        {emissionSummary?.totalEmissions ?? ""} tCO2-eq
      </Typography>
    </CardContent>
  </Card>
</Grid>

          <Grid item xs={4}>
            <Card
              style={{
                borderRadius: "1rem",
                color: "#003E35",
                height: "100%",
                "@media (maxWidth: 600px)": {
                  height: "200px", // Adjust the height for small screens
                },
                "@media (minWidth: 601px) and (maxWidth: 960px)": {
                  height: "250px", // Adjust the height for medium screens
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Typography variant="h6" component="div">
                    <strong>Total Company Joined</strong>
                  </Typography>
                  <Typography variant="body1">
                    {emissionSummary?.companies ?? ""}
                  </Typography>
                </div>
              </CardContent>
            </Card>

          </Grid>

          {/* Scope 2 */}
          <Grid item xs={4}>
            <Card
              style={{
                borderRadius: "1rem",
                color: "#003E35",
                height: "100%",
                "@media (maxWidth: 600px)": {
                  height: "200px", // Adjust the height for small screens
                },
                "@media (minWidth: 601px) and (maxWidth: 960px)": {
                  height: "250px", // Adjust the height for medium screens
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Typography variant="h6" component="div">
                    <strong>EcoLables Issued</strong>
                  </Typography>

                  <Typography variant="body1">
                    {emissionSummary?.issuedEcoLabelCount ?? ""}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <div
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: "1rem" }}
        >
          <h1 className="logo-heading">FIND YOURS</h1>
          <p className="search-text" style={{fontFamily: "'Century Gothic Paneuropean', sans-serif",}}>
            Enter the Blockchain ID of the Report/Ecolabel you are looking for
          </p>
          <TextField
            label="Search.."
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              style: {
                backgroundColor: alpha('#fff', 0.50),
                borderRadius: "10px",
              },
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  className="search-button"
                >
                  <SearchIcon style={{ width: "100%" }} />
                </Button>
              ),
            }}
            InputLabelProps={{
              style: {
                color: "primary",
                fontSize: "20px",
                fontFamily: "Gill Sans MT",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ComponentPage;
