import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import { useCookies } from "react-cookie";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts";
import InviteVendorIllustration from "../../assets/images/InviteVendor.png";
import { useHistory } from "react-router-dom";
const SiteAnalysis = () => {
  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [sites, setSites] = useState([]);
  const columns = [
    
    {
      field: "Name",
      headerName: "Site Name",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
    {
      field: "Type",
      headerName: "Site Type",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "Emissions",
      headerName: "Emissions (TCO2 Eq.)",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "EmissionFactor",
      headerName: "Emissions Per Spend",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
    {
      field: "Revenue",
      headerName: "Revenue",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
  ];
  const chartSetting = {
    yAxis: [
      {
        width: 100,
        valueFormatter: formatNumber,
      },
    ],
    height: 400, // Adjust height to fit your layout
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0) rotate(-45deg)", // Rotate labels for better fit
        transformOrigin: "right center",
      },
    },
  };

  const EmissionDataBarGraph =
    sites.length > 0
      ? sites.map(({ Name, Emissions }) => {
          return { Name, Emissions };
        })
      : [
          {
            Name: "No data",
            Emissions: 0,
          },
        ];
  const EmissionFactorDataBarGraph =
    sites.length > 0
      ? sites.map(({ Name, EmissionFactor }) => {
          return { Name, EmissionFactor };
        })
      : [
          {
            Name: "No data",
            EmissionFactor: 0,
          },
        ];
  const valueFormatter = (value) => `${value.toFixed(2)} TCO2 Eq.`;

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const handleOpenReportModal = () => {
    setShowGenerateReportModal(true);
  };
  const handleCloseReportModal = () => {
    setShowGenerateReportModal(false);
  };
  // reports api by P.I.D data
  const getSitesByPID = async () => {
    try {
      const { data } = await axios.get(
        API_URL +
          `/participant/sitesEmissions?from=${cookies.from}&&to=${cookies.to}`,
        headers
      );
      console.log("data in getSitesByPID function : - ", data);
      if (!data) {
        setSites([]);
      } else {
        console.log("site data", data);
        setSites(data);
      }
    } catch (err) {
      console.log(err, "err in reports api");
    }
  };

  React.useEffect(() => {
    getSitesByPID(); // call an reports api
    // getAllValidatedReports(); // call an api
  }, []);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"2rem"}>
      <Box>
        <Typography variant="h4" style={{ margin: "1rem" }}>
          <strong>Sites Analysis</strong>
        </Typography>
        <Grid container spacing={6} sx={{ padding: "1rem" }}>
          <Grid item xs={6} sm={6} md={5}>
            <Card style={{ borderRadius: "1rem" }}>
              {/* Sites Emission Breakdown graph */}
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="h5" style={{}}>
                  <strong>Sites Emission Breakdown</strong>
                </Typography>
                <Box
                  display={"flex"}
                  flex={1}
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                  }}
                >
                  <PieChart
                    width={400}
                    height={200}
                    className="w-full flex justify-start relative left-[-150px]"
                    colors={[
                      "#BDD2CC",
                      "#0D6659",
                      "#7CA59C",
                      "#3b7b6f",
                      "#12574c",
                      "#143b34",
                    ]}
                    series={[
                      {
                        data: sites.map((site) => {
                          return {
                            id: site.id,
                            label: site.Name,
                            value: site.Emissions,
                          };
                        }),
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                        innerRadius: 80,
                        outerRadius: 100,
                        valueFormatter: (value) => {
                          return value.value + " T CO2 Eq.";
                        },

                        cx: 40,
                      },
                    ]}
                  >
                    {" "}
                    <PieCenterLabel
                      offesetX={40}
                      offesetY={0}
                      fontSize="1.5rem"
                    >
                      {Math.round(
                        sites.reduce((acc, site) => acc + site.Emissions, 0)
                      )}
                    </PieCenterLabel>
                    <PieCenterLabel
                      offesetX={40}
                      offesetY={20}
                      fontSize="0.8rem"
                    >
                      T.Co2 Eq.
                    </PieCenterLabel>
                  </PieChart>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={7}>
            {/* Generate Site Report Banner */}
            <Card
              sx={{
                flexGrow: 1,
                background: "white",
                borderRadius: "1rem",
                padding: "1rem",
                backgroundColor: "#143B34",
                color: "#fff",
                height: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography variant="h4" style={{}}>
                  <strong>
                    Generate Your Site's <br></br> Emission Report!
                  </strong>
                </Typography>
                <Typography variant="p" style={{}}>
                  <p>
                    Download a Detailed Report of Your Site's Emissions. Start
                    Making a Difference Today
                  </p>
                </Typography>
                <Box
                  display="flex"
                  justifyContent={"end"}
                  width={"100%"}
                  marginTop={"auto"}
                  alignSelf={"baseline"}
                >
                  <Button
                    onClick={handleOpenReportModal}
                    sx={{
                      backgroundColor: "#3B7B6F",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "#7CA59C",
                      },
                    }}
                  >
                    Generate Report{" "}
                  </Button>
                  <Modal
                    open={showGenerateReportModal}
                    onClose={handleCloseReportModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      width={"100%"}
                      height={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        width={"50%"}
                        borderRadius={"1rem"}
                        margin={"auto"}
                        alignSelf={"center"}
                        minWidth={"250px"}
                        display={"flex"}
                        flexDirection={"column"}
                        backgroundColor={"#fff"}
                        padding={"2rem"}
                      >
                        <GenerateReportForm
                          sites={sites}
                          closeModal={handleCloseReportModal}
                        />
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {
        <Grid container spacing={6}>
          {/* Sites Emission Benchmark Bar Graph */}
          <Grid item md={12} lg={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              marginInline={"1rem"}
              padding={"1rem"}
              backgroundColor={"#fff"}
              borderRadius={"1rem"}
            >
              <Typography variant="h4" style={{ margin: "1rem" }}>
                <strong>Sites Benchmark</strong>
              </Typography>
              <BarChart
                colors={["#3b7b6f"]}
                dataset={EmissionDataBarGraph}
                margin={{ left: 50 }}
                xAxis={[{ scaleType: "band", dataKey: "Name",  categoryGapRatio: 0.6}]}
                series={[
                  { dataKey: "Emissions", label: "Emissions", valueFormatter },
                ]}
                {...chartSetting}
              />
            </Box>
          </Grid>
          <Grid item md={12} lg={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              marginInline={"1rem"}
              padding={"1rem"}
              backgroundColor={"#fff"}
              borderRadius={"1rem"}
            >
              <Typography variant="h4" style={{ margin: "1rem" }}>
                <strong>Sites Emission Per Spend</strong>
              </Typography>
              <BarChart
                colors={["#3b7b6f"]}
                dataset={EmissionFactorDataBarGraph}
                xAxis={[{ scaleType: "band", dataKey: "Name",  categoryGapRatio: 0.6}]}
                series={[
                  { dataKey: "EmissionFactor", label: "Emissions per spend" },
                ]}
                height={400}
              />
            </Box>
          </Grid>
        </Grid>
      }
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginInline={"1rem"}
        padding={"1rem"}
        backgroundColor={"#fff"}
        borderRadius={"1rem"}
        height={"400px"}
      >
        <Typography variant="h5" style={{ margin: "1rem" }}>
          <strong>Sites Emission</strong>
        </Typography>
        <DataGrid
          getRowId={(row) => row.PID}
          rows={sites}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          className="w-full bg-white rounded-lg"
          pageSizeOptions={[5, 10]}
        />
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginInline={"1rem"}
        padding={"4rem"}
        backgroundColor={"#29564c"}
        borderRadius={"1rem"}
        color={"#fff"}
      >
        <Box
          width={"70%"}
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
        >
          <Typography variant="h3">
            <strong>Add More Sites to Votan</strong>
          </Typography>
          <Typography variant="body1">
            <p>
              Invite your site admin to join us and earn rewards by <br></br>{" "}
              sharing your eco-friendly journey on Votan GDP.
            </p>
          </Typography>
          <Button
            onClick={() => history.push("/manage/sites")}
            sx={{
              width: "fit-content",
              backgroundColor: "#3B7B6F",
              color: "#fff",
              ":hover": {
                backgroundColor: "#7CA59C",
              },
            }}
          >
            Invite Site Admin
          </Button>
        </Box>
        <Box width={"30%"}>
          <img style={{ width: "100%" }} src={InviteVendorIllustration}></img>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteAnalysis;

const GenerateReportForm = ({ sites = [], closeModal }) => {
  const [cookies] = useCookies(["user"]);
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  //Form States
  const [selectedSite, setSelectedSite] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isSucces, setSuccess] = useState(false);
  const [reporId, setReportId] = useState("");
  const handleChange = (event) => {
    setSelectedSite(event.target.value);
  };
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "from") {
      setFromDate(value);
    } else if (name === "to") {
      setToDate(value);
    }
  };
  const handleSubmit = () => {
    axios
      .post(
        `${API_URL}/ActivityReport/site/create`,
        { PID: selectedSite, from: fromDate, to: toDate },
        headers
      )
      .then((response) => {
        // Handle successful response
        console.log("Response:", response.data);
        if (response.data.status) {
          setSuccess(true);
          setReportId(response.data.data.id);
        } else {
          alert("Failed to generate report!");
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
        alert("Failed to generate report!");
      });
  };
  const handleViewReport = () => {
    //logic to redirect  to report view
  };
  if (isSucces)
    return (
      <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
        <Typography variant="h3">
          <strong>Report Generated Successfully!</strong>
        </Typography>
        <Typography variant="body1">
          <strong>Report id : {reporId}</strong>
        </Typography>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"1rem"}
        >
          <Button color="secondary" variant="outlined" onClick={closeModal}>
            Close
          </Button>
          <Button
            onClick={handleViewReport}
            sx={{
              backgroundColor: "#3B7B6F",
              color: "#fff",
              ":hover": {
                backgroundColor: "#7CA59C",
              },
            }}
          >
            View on explorer
          </Button>
        </Box>
      </Box>
    );
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Generate Report !
        </Typography>
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          Select site for which you want to generate report
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <FormControl fullWidth>
            <InputLabel id="Site-select-menu-label">Select Site</InputLabel>
            <Select
              labelId="Site-select-menu-label"
              id="Site-select-menu"
              value={selectedSite}
              label="Site"
              onChange={handleChange}
            >
              {sites.map(({ PID, Name }) => (
                <MenuItem value={PID}>{Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="from-date"
            name="from"
            label="From"
            type="date"
            value={fromDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="to-date"
            name="to"
            label="To"
            type="date"
            value={toDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={"1rem"}
      >
        <Button color="secondary" variant="outlined" onClick={closeModal}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#3B7B6F",
            color: "#fff",
            ":hover": {
              backgroundColor: "#7CA59C",
            },
          }}
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

function PieCenterLabel({
  children,
  offesetY = 0,
  offesetX = 0,
  fontSize = "1rem",
}) {
  const { width, height, left, top } = useDrawingArea();
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    fontSize,
    fontWeight: "bold",
  }));
  console.log({ width, height, left, top });
  return (
    <StyledText x={0 + offesetX} y={top + height / 2 + offesetY}>
      {children}
    </StyledText>
  );
}

//Converts Large Numbers to short
const formatNumber = (num) => {
  const unit = "t.CO2 Eq.";
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + num).length / 3);
  let shortValue = parseFloat(
    (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
};
