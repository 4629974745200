import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Modal, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Bar, Line } from "react-chartjs-2";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CONSTANTS, EUR_TO_INR, HEADERS2 } from "../../component/utils/constants";
import EditIcon from '@mui/icons-material/Edit';
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import PdfDialog from "../VCarbonPages/PdfDialog";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Overview = () => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["user"]);
  const [modalOpen, setModalOpen] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [reportDialog, setReportDialog] = React.useState(false);
  const [revenue, setRevenue] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [opens, setOpens] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  // participant api state
  const [participantData, setParticipantData] = React.useState([]);
  const handleEditClick = () => {
    setModalOpen(true);
  };
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpens(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = async () => {
    const editedData = {
      from: decodeURIComponent(cookies.from),
      to: decodeURIComponent(cookies.to),
      revenue: parseFloat(revenue).toFixed(2),
      expenses: parseFloat(expenses).toFixed(2),
    };

    console.log(editedData); // You can replace this with your logic to update the data
    const { data } = await axios.post(
      API_URL + `/participant/expenses/update`,
      editedData,
      headers
    );
    setExpenses(data.expenses)
    setRevenue(data.revenue)
    setOpens(true);
    setSuccess("Details updated successfully");
    getDashboardEmissions(fromDate, toDate);
    handleCloseModal(); // Close the modal after submitting
  };

  const [total, setTotal] = useState(0);
  const [industryBenchmark, setIndustryBenchmark] = useState(200)
  const [percentageDifference, setPercentageDifference] = useState(100)

  // Chart data
  let data = {
    labels: ['Your Emissions', 'Industry Standards'],
    datasets: [
      {
        label: 'Emissions',
        backgroundColor: ["#003E35", "#0C6659"],
        borderColor: ["rgba(0, 62, 53, 0.5)", "rgba(12, 102, 89,0.5)"],
        borderWidth: 1,
        data: [total, industryBenchmark],
      },
    ],
  };
  const [missingLoader, setMissingLoader] = useState(false);
  const [fromDate, setFromDate] = useState(decodeURIComponent(cookies.from));
  const [toDate, setToDate] = useState(decodeURIComponent(cookies.to));
  const [scopeImpact, setScopeImpact] = useState({
    "Scope 1": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 2": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Scope 3": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  let lineChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    //["#003E35", "#0C6659", "#46AD8D"],
    datasets: [
      {
        label: "Scope 1",
        data: scopeImpact["Scope 1"],
        backgroundColor: "rgba(0, 62, 53, 0.5)",
        borderColor: "#003E35",
        fill: true,
      },
      {
        label: "Scope 2",
        data: scopeImpact["Scope 2"],
        backgroundColor: "rgba(12, 102, 89,0.5)",
        borderColor: "#0C6659",
        fill: true,
      },
      {
        label: "Scope 3",
        data: scopeImpact["Scope 3"],
        backgroundColor: "rgba(0, 135, 132,0.5)",
        borderColor: "#46AD8D",
        fill: true,
      },
    ],
  };

  const fetchDataMapping = async (dataObj) => {
    try {

      const { data } = await axios.get(API_URL + `/participant/id?from=${cookies.from}&&to=${cookies.to}`, headers);
      let ef = 0
      if (!data) {
        setParticipantData([]);
      } else {
        setParticipantData(data);
        setExpenses(data.expenses)
        setRevenue(data.revenue)
        ef = EUR_TO_INR(data.emissionFactor || 0) * (data.expenses || 0)
        setIndustryBenchmark(ef)
      }
      if (dataObj) {
        if (dataObj.ImpactOverTime) {
          setScopeImpact(dataObj.ImpactOverTime);
        }
        let t = (dataObj?.Emissions?.Scope1 ?? 0) + (dataObj?.Emissions?.Scope2 ?? 0) + (dataObj?.Emissions?.Scope3 ?? 0)
        setTotal(t);
        setPercentageDifference(((ef - t) / ef) * 100)
      } else {
        console.log(data, "this is data");
        setMissingLoader(true);
      }
    } catch (err) {
      setMissingLoader(true);
      console.log(err, "err in get api");
    }
  };

  // post api for selected dates dashboard emissions
  const getDashboardEmissions = async (from, to) => {
    try {
      setBackdropOpen(true)
      const dashboardBody = {
        From: from,
        To: to,
      };
      const { data } = await axios.post(
        API_URL + `/ef/getDashboard`,
        dashboardBody,
        headers
      );
      console.log(data, "emission data found");
      if (data) {
        fetchDataMapping(data.data);
        console.log(data.data, "api entered successfully emission");
      }
    } catch (err) {
      console.log(err, "err in get dashboard emissions");
    }
    setBackdropOpen(false)
  };

  // handle navigate calculate function
  const handleNavigateCalculator = () => {
    history.push("/calculator");
  };
  const handleNewReport = () => {
    console.log('clicked')
    setReportDialog(!reportDialog)
  }


  // useEffect calling
  useEffect(() => {
    getDashboardEmissions(fromDate, toDate);
    console.log(revenue, expenses)
  }, []);

  return (
    <>
    {backdropOpen && <FullScreenLoader />}
    {!backdropOpen && <>
      {reportDialog && (
        <Modal open={reportDialog} onClose={handleNewReport}>
          <PdfDialog close={handleNewReport} setOpens={setOpens} success={setSuccess} error={setError} />
        </Modal>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <Typography
          variant="h4"
          style={{ marginTop: "1rem", marginLeft: "2rem" }}
        >
          <strong>{participantData?.Name ?? ""}</strong>
        </Typography>
        <div style={{ display: "flex", gap: "2rem" }}>
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              onClick={handleNavigateCalculator}
              style={{
                borderRadius: "20px",
              }}
            >
              <AddIcon style={{ fontSize: "1.1rem" }} />
              Record Emissions
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={handleNewReport}
            style={{
              borderRadius: "20px",
              marginRight: '1rem'
            }}
          >
            <AddIcon style={{ fontSize: "1.1rem" }} />
            Generate Report
          </Button>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }} ml={2} mr={2} paddingBottom={3}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={8}>
            <Card style={{ borderRadius: "1rem" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <strong>Emissions Insights</strong>
                </Typography>
                <div style={{ width: "100%", height: "300px" }}>
                  <Line
                    data={lineChartData}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ borderRadius: "10px", height: "100%", overflow: "hidden" }}>
              <>
                <Typography variant="h6" style={{ paddingLeft: "1.5rem", color: "#003E35" }}>
                  <strong>Industry Benchmarking</strong>
                </Typography>
                <div style={{ width: "70%", height: "300px", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Bar data={data} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }} />
                </div>
                <Typography variant="body1" style={{ textAlign: "left", color: "#003E35", paddingLeft: '8px' }}>
                  You are reporting {Math.abs(percentageDifference).toFixed(2)}%
                  {percentageDifference < 0 ? " more" : " less"} emissions
                  as compared to your industry standards.
                </Typography>
              </>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Item
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: '1.5rem',
                height: '195px',
                color: '#003E35',
                borderRadius: '10px',
              }}
            >
              <Typography variant="h6">
                <strong>Company Finances</strong>
              </Typography>
              <Typography variant="h6">
                <strong>Revenue</strong>
              </Typography>
              <Typography variant="h6">
                {revenue ?? 0}
              </Typography>
              <Typography variant="h6">
                <strong>Expenses</strong>
              </Typography>
              <Typography variant="h6">
                {expenses ?? 0}
              </Typography>
              <Typography variant="body1">
                <strong>
                  {/* Display profit data */}
                </strong>
              </Typography>
              {/* <IconButton
                onClick={handleEditClick}
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  zIndex: 1000,
                  backgroundColor: '#fff',
                }}
              >
                <EditIcon />
              </IconButton> */}

              <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="edit-finance-modal"
                aria-describedby="edit-finance-details"
              >
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  padding: '20px',
                  borderRadius: '5px',
                }}>
                  <Typography> Enter your financial details between {cookies.from} - {cookies.to}</Typography>
                  <TextField
                    label="Revenue"
                    type='Number'
                    value={revenue}
                    onChange={(e) => setRevenue(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Expenses"
                    type='Number'
                    value={expenses}
                    onChange={(e) => setExpenses(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <Button variant="contained" onClick={handleFormSubmit}>Submit</Button>
                </div>
              </Modal>
            </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Item
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: '1.5rem',
                height: '195px',
                color: '#003E35',
                borderRadius: '10px',
              }}
            >
              <Typography variant="h6">
                <strong>My Disclosures</strong>
              </Typography>


              {/* </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h6">
                  <strong>Disclosures</strong>
                </Typography>
                <Typography variant="h6">
                  {participantData?.Disclosures ?? 0}
                </Typography>
                <Typography variant="h6">
                  <strong>EcoLabels</strong>
                </Typography>
                <Typography variant="h6">
                  {participantData?.Ecolabels ?? 0}
                </Typography>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Item
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "1.5rem",
                height: "195px",
                borderRadius: "10px",
                backgroundColor: "#003E35",
                color: "#fff",
              }}
            >

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h6">
                  <strong>Total Emissions</strong>
                </Typography>
                <Typography variant="h6">
                  <strong>{total.toFixed(2)}</strong>
                </Typography>
                <br />
                <Typography variant="h6">
                  <strong>Emissions/Spend</strong>
                </Typography>
                <Typography variant="h6">
                  <strong>{(total / expenses).toFixed(2)}</strong>
                </Typography>

              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
      {(success || error) && (
        <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
          <MuiAlert
            onClose={handleCloses}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {success || error}
          </MuiAlert>
        </Snackbar>
      )}
    </>}
    </>
  );
};

export default Overview;
