import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Stack from "@mui/material/Stack";
// import icons
import Fab from "@mui/material/Fab";
import RefreshIcon from "@mui/icons-material/Refresh";
// import React cookie
import { useCookies } from "react-cookie";
// import API_URL and Cookies
import { CONSTANTS, HEADERS } from "../../component/utils/constants";
// import CSS for master upload component
import "../../component/CSS/MasterUpload.css";
// iMPORT Snackbar Pop up
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
import Toaster from "../../component/Toaster/Toaster";
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import Cancel Presentation Icon
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
// import icon button
import IconButton from "@mui/material/IconButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:"10px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function EcoLabelUploadForm(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [error, setError] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [uploadDialog, setUploadDialog] = React.useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [keyValue, setKeyValue] = React.useState([]);
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [errormsg, setErrorMsg] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState(false);
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [existingVehicle, setExistingVehicle] = React.useState([]);
  const [electricityField, setElectricityField] = React.useState([
    {
      oldReading: "",
      currentReading: "",
      unitConsume: "",
      amount: "",
      remarks: "",
      unitRate:""
    },
  ]);
  const [electricityInput, setElectricityInput] = React.useState({
    billId: "",
    date: "",
  })
  const [selectCategory, setSelectCategory] = React.useState([]);
  const [selectReportId, setSelectReportId] = React.useState([]);

  // API_URL
  const API_URL = CONSTANTS.API_URL;

  // headers
  const headers = HEADERS(cookies.token);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleElectricityInput = (event, i, name) => {
    console.log(event.target, "journey target value");
    let journey = electricityField;
    if (i && name) {
      journey[name] = i;
      console.log(journey, "journey input 1");
    } else {
      const journeyState = {
        ...electricityField,
        [event.target.name]: event.target.value,
      };
      journey = journeyState;
      console.log(journeyState, "journey input");
      setElectricityField(journeyState);
    }
  };

  const handleElectircInput = (event) => {
    const ElectricState = {
      ...electricityInput, [event.target.name]: event.target.value
    };
    console.log(ElectricState,"Electric State")
    setElectricityInput(ElectricState);
  }

  const handleUploadDialog = (event) => {
    setUploadDialog(!uploadDialog);
  };

  // error handling snackbar
  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  // useEffect
  React.useEffect(() => {
    getReportByPID(); // call an api to get report by pid
  }, []);


  // call an api to get report by PID
  const getReportByPID = async () => {
    try{
      console.log('in side')
       const { data } = await axios.get(
        API_URL + `/chgReport/getReportData/PID`,
        headers
       );
       if(!data) {
        setSelectReportId([]);
       } else {
        setSelectReportId(data);
        console.log(data,"all report data by pid")
       }
    }catch(err){
      console.log(err,"error in report api by pid");
    }
  }

  // CALL AN API TO INSERT ELECTRICITY BILL DATA
  const insertElectricityBillData = async () => {
    try {
      let insertElectricityBillBody = {
        PID: '',
        NID: '',
        id:"",
        data:"", // object 
        reportId: selectCategory, // report id 
        url:"",
        issuedDate:"",
        validityDate:""
      };
      const { data } = await axios.post(
        API_URL + "/ecoLabel/create",
        insertElectricityBillBody,
        headers
      );
      console.log("i am eco label bill data here", data);
      if (data.error) {
        setRows([]);
        setError(true);
      } else {
        setRows(data);
        console.log(data, "ecolabel api inserted successfully");
        setSuccessMsg("Eco Label Has Been Requested");
        setOpens(true);
        handleClose();
        handleDialogClose(); // dialog close
        props.state(); // get all data api
      }
    } catch (err) {
      console.log(err);
      setRows([]);
      setError(true);
    }
  };

  return (
    <>
      {props.loadState && <Toaster msg={"Fetching real-time data"} />}
      {props.rows && (
        <MUIDataTable
          title={
            <>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                {props.role !== "admin" && (
                  <>
                    <Typography
                      style={{ marginLeft: "10px", marginTop: "14px" }}
                    >
                      <strong>Ecolabels</strong>
                    </Typography>
                    <Button
                      onClick={handleDialogOpen}
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                      variant="contained"
                    >
                      Request New
                    </Button>
                  </>
                )}

                <Fab
                  onClick={props.state}
                  size="small"
                  color="primary"
                  aria-label="refresh"
                  style={{ margin: "10px" }}
                >
                  <RefreshIcon />
                </Fab>
              </Stack>
            </>
          }
          data={props.columnsValues}
          columns={props.columns}
          options={props.options}
        />
      )}

      <div className={classes.root}>
        {/* Journey dialog box open start here */}
        <div style={{ padding: "8px" }}>
          <div>
          <Modal
              open={dialogopen}
              onClose={handleDialogClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
              <IconButton
                  edge="end"
                  color="inherit"
                  sx={{ mr: 2 }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ marginLeft: "auto", float: "right" }}
                  onClick={handleDialogClose}
                >
                  <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
                </IconButton>
                <Typography
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  Please Select A Report To Continue
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ paddingRight: "8px" }}>
                    <Autocomplete
                    disablePortal
                    options={selectReportId}
                    getOptionLabel={(option) =>
                      option ? option.id : "Select A Report Id"
                    }
                    name="PID"
                    onChange={(event, newValue) => {
                      setSelectCategory(newValue.id);
                    }}
                    sx={{ width: 210, marginTop:"10px" , marginBottom: "10rem",}}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Report Id"
                        variant="outlined"
                        // fullWidth
                      />
                    )}
                  />
                      </div>
                  </div>
                </div>
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop:"20px"
                }}
              >
                <Button variant="contained" onClick={insertElectricityBillData}>
                  Submit
                </Button>
                <Button variant="contained" style={{ marginLeft:"10px" }} onClick={handleDialogClose}>
                  Cancel
                </Button>
              </div>
            </Box>
            </Modal>
          </div>
        </div>

        {/* Journey dialog box open ends here */}

        {(errormsg || successmsg) && (
          <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
            <Alert
              onClose={handleCloses}
              severity={successmsg ? "success" : "warning"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        )}
      </div>
    </>
  );
}
