import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { Grid } from "@material-ui/core";
import DangerousIcon from "@mui/icons-material/Dangerous";
// import axios
import axios from "axios";
// import Constants & Headers
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import React-Cookie
import { useCookies } from "react-cookie";
// import Snackbar Pop UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const FactoryDescriptionPopUp = (props) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [successmsg, setSuccessMsg] = React.useState("");
  const [errormsg, setErrorMsg] = React.useState("");
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [popUp, setPopUpState] = React.useState(true);
  const [columns, setColumns] = React.useState([]);
  const [edit, setEdit] = React.useState({
    user_id: cookies.user_id,
    PID: cookies.PID,
    Id: props.id,
  });
  const [formValues, setFormValues] = React.useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [state, setState] = React.useState({
    Id: "",
  });

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers2 = HEADERS2(cookies.token, cookies.Nid);

  React.useEffect(() => {
    console.log(props.obj, "in house description pop up");
    if (props.obj !== undefined) {
      getInHouseVehicleData();
    }
  }, [props]);

  const handleInput = (event) => {
    const newState = { ...state, [event.target.name]: event.target.value };
    setState(newState);
    console.log(newState);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handlebarSubmit = (event) => {
    event.preventDefault();
  };

  const addFormFields = () => {
    setFormValues([...formValues, { key: "", value: "" }]);
  };

  // error handling snackbar
  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  const handleEditSubmit = async () => {
    try {
      let temp = edit;
      let fv = formValues;
      for (let i = 0; i < fv.length; i++) {
        let key = formValues[i].key;
        temp[key] = formValues[i].value;
      }
      console.log("temp", temp);
      const { data } = await axios.put(
        API_URL + `/employee/update`,
        // API_URL + `/inventory/PID/${cookies.PID}/update`,
        temp,
        headers2
      );
      if (data.error) {
        setErrorMsg(data.error);
        setOpens(true);
      } else {
        console.log(data);
        setSuccessMsg("Records updated successfully");
        setOpens(true);
        handleClose();
        props.load(); // get all updated data api
      }
      setPopUpState(false);
    } catch (e) {
      setErrorMsg(
        "Unable to update records: Please check the inputs again " + e
      );
      setOpens(true);
      console.log(e, "error in handle select");
    }
  };

  const handleChanges = (i, e) => {
    let newFormValues = [...formValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    setFormValues(newFormValues);
  };

  const getInHouseVehicleData = async () => {
    try {
      console.log(props.obj, "this is props");
      setData(props.obj);
      let Allcolumns = [];
      let columnNames = [];
      for (let key in props.obj.row) {
        if (
          key !== "__v" &&
          key !== "_id" &&
          key !== "PID" &&
          columnNames.indexOf(key) === -1
        )
          Allcolumns.push({
            id: key,
            label: key,
            minWidth: 100,
          });
        columnNames.push(key);
      }
      Allcolumns.push({
        id: "action",
        label: "action",
        minWidth: 100,
      });
      // console.log(Allcolumns, "Allcolumns");
      setColumns(Allcolumns);
      let value = [];
      for (let key in props.obj.row) {
        if (value.indexOf(key) === -1) {
          value.push({
            key: key,
            value: props.obj.row[key],
          });
        }
      }
      let editObj = [];
      let obj = props.obj.row;
      if (obj) {
        obj.user_id = cookies.user_id;
        obj.PID = cookies.PID;
        let keys = Object.keys(obj);
        let values = Object.values(obj);
        for (let i = 0; i < keys.length; i++)
          if (
            keys[i] !== "__v" &&
            keys[i] !== "_id" &&
            keys[i] !== "ID" &&
            keys[i] !== "employeeId" &&
            keys[i] !== "user_id" &&
            keys[i] !== "NID" &&
            keys[i] !== "PID"
          ) {
            editObj.push({ key: [keys[i]], value: values[i] });
          }
        setEdit(obj);
        setFormValues(editObj);
        setPopUpState(false);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg(`failed to fetch data: ${err.message}`);
    }
  };

  return (
    <>
      <div>
        <EditIcon
          onClick={handleOpen}
          style={{ cursor: "pointer", float: "right" }}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid
              container
              spacing={1}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Factory Employee View Details
              </Typography>
              <br />

              <TextField
                id="outlined-basic"
                label="ID"
                name="Id"
                value={data?.row?.employeeId}
                disabled
                onChange={handleInput}
                variant="outlined"
              />
              <br />

              {/* Start new form for key & value */}
              <form onSubmit={handlebarSubmit}>
                {formValues.map((element, index) => (
                  <div className="form-inline" key={index}>
                    <input
                      name="key"
                      value={element.key || ""}
                      onChange={(e) => handleChanges(index, e)}
                      required
                    />

                    <input
                      name="value"
                      value={element.value || ""}
                      onChange={(e) => handleChanges(index, e)}
                      required
                    />

                    {index ? (
                      <DangerousIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => removeFormFields(index)}
                      />
                    ) : null}
                  </div>
                ))}
                <Button
                  style={{ margin: "10px" }}
                  variant="contained"
                  type="submit"
                  onClick={() => addFormFields()}
                >
                  Add
                </Button>
              </form>
              <br />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleEditSubmit}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  variant="contained"
                  type="submit"
                  onClick={() => handleClose()}
                >
                  Go Back
                </Button>
              </div>
            </Grid>
          </Box>
        </Modal>
      </div>
      {(errormsg || successmsg) && (
        <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
          <Alert
            onClose={handleCloses}
            severity={successmsg ? "success" : "warning"}
            sx={{ width: "100%" }}
          >
            {errormsg ? errormsg : successmsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default FactoryDescriptionPopUp;
