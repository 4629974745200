import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function EmptyTable(props) {
  const [msg, setMsg] = React.useState('');   

  React.useEffect(() => {
    if(props.msg){
      setMsg(props.msg)
    }else{
      setMsg("Requested details haven't met in the blockchain yet or does not exists..\n Waiting for the indexers to pick it up")
    }
  },[setMsg])

  return (
    <Card variant="outlined"
      style={{
        width: "100%",
        textAlign: "center",
        height: "50%",
        marginTop: "1rem",
        borderRadius: "10px" 
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 18 }} >
            {msg}
        </Typography>
      </CardContent>
    </Card>
  );
}
