import { BarChart } from "@mui/x-charts/BarChart";
import { Button, FormControlLabel, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { axisClasses } from "@mui/x-charts";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { CompareArrows } from "@mui/icons-material";
import InviteVendorIllustration from "../../assets/images/InviteVendor.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import { useCookies } from "react-cookie";
const VendorSimulationReport = () => {
  const [cookies] = useCookies(["user"]);
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const history = useHistory();
  const [showFeaturedVendors, setShowFeaturedVendors] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [report, setReport] = useState({
    productName: "",
    price: 0,
    currency: "INR",
    quantity: 0,
    vendorID: "",
    preferredVendor: "",
    EmissionFactor: 0,
    estPrice: 0,
    estQuantity: 0,
    estEmission: 0,
    suggestedVendor: [
      {
        PID: "asswer45wefsdaf",
        Name: "Tesla",
        Emissions: 1566320,
        EmissionFactor: 452.33,
        Accuracy: "Low",
      },
      {
        PID: "asswdefer45wefsdaf",
        Name: "Ola",
        Emissions: 1231230,
        EmissionFactor: 113.33,
        Accuracy: "High",
      },
    ],
  });
  const chartSetting = {
    yAxis: [
      {
        width: 100,
        valueFormatter: formatNumber,
      },
    ],
    height: 400, // Adjust height to fit your layout
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0) rotate(-45deg)", // Rotate labels for better fit
        transformOrigin: "right center",
      },
    },
  };
  const columns = [
    {
      field: "PID",
      headerName: "PID",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "Name",
      headerName: "Vendor Name",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "Emissions",
      headerName: "Emissions (TCO2 Eq.)",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "EmissionFactor",
      headerName: "Emissions Per Spend",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
    {
      field: "Accuracy",
      headerName: "Accuracy",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "Action",
      headerName: "Action",
      colSpan: 1,
      cellClassName: "w-full",
      width: 150,
      renderCell: () => {
        return (
          <Button onClick={handleSelectVendor} color="success">
            Select <CompareArrows />
          </Button>
        );
      },
    },
  ];
  const EmissionDataBarGraph =
    report.suggestedVendor.length > 0
      ? report.suggestedVendor.map(({ Name, Emissions }) => {
          return { Name, Emissions };
        })
      : [
          {
            Name: "No data",
            Emissions: 0,
          },
        ];
  const valueFormatter = (value) => `${value.toFixed(2)} TCO2 Eq.`;
  const handleSelectVendor = ()=>{
    //logic to select new vendor
  }
  const getVendors = async() => {
    await axios.get(`${API_URL}/vendor/id`, headers).then((res) => {
      console.log("vendor", res.data.Vendors);
      if (res?.data?.Vendors) {
        setVendors([...res?.data?.Vendors]);
        setReport({...report, suggestedVendor: res?.data?.Vendors})
      }
    });
  };
  const getParticipant = async () => {
    try {
      const { data } = await axios.get(API_URL + `/participant/id`, headers);
      if (!data) {
        return [];
      } else {
        return data;
      }
    } catch (err) {
      console.log("err in participant by id api");
    }
  };
  const handleLoad = async(searchParams)=>{
    let temp = {...report}
    let preferredPID = searchParams.get("v")
    let price = searchParams.get("amt")
    let quantity = searchParams.get("q")
    temp.productName = searchParams.get("p")
    temp.vendorID = preferredPID
    // temp.preferredVendor = preferredPID
    temp.quantity = quantity
    temp.price = price

    //
    // const PByID = await getParticipant();
    // console.log(PByID,'hello 123')
    let vendors = []
    await axios.get(`${API_URL}/vendor/id`, headers).then((res) => {
      console.log("vendor", res.data.Vendors);
      if (res?.data?.Vendors) {
        vendors = res?.data?.Vendors
        const preferredVendor = res.data.Vendors.find(vendor => vendor.PID === preferredPID);
        temp.preferredVendor = preferredVendor.Name
        temp.EmissionFactor = preferredVendor.EmissionFactor
        temp.estPrice = price
        temp.estQuantity = quantity
      }
    });
    temp.suggestedVendor = vendors
    //
    setReport(temp)
  }
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    handleLoad(searchParams)
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      paddingInline={"6rem"}
      gap={"2rem"}
    >
      <Typography variant="h4" marginBottom={"1rem"} marginTop={"2rem"}>
        Vendor Comparision
      </Typography>
      {/*Overview */}
      <Grid gridRow={1} container columnSpacing={"1rem"}>
        <Grid xs={4} item>
          <Box
            sx={{ backgroundColor: "#fff" }}
            padding={"1.5rem"}
            borderRadius={"1rem"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={"1rem"}
            height={"100%"}
          >
            <Typography variant="h5">Order Summary</Typography>
            <Typography variant="h3">{report.productName}</Typography>
            <Box display={"flex"} gap={"1rem"}>
              <Box>
                <Typography variant="body1">Price</Typography>
                <Typography variant="h4">₹{report.price}</Typography>
              </Box>
              <Box>
                <Typography variant="body1">Quantity</Typography>
                <Typography variant="h4">{report.quantity}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid xs={8} item>
          <Box
            sx={{ backgroundColor: "#fff" }}
            padding={"1.5rem"}
            borderRadius={"1rem"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={"1rem"}
          >
            <Typography variant="h5">Preferred Vendor</Typography>
            <Typography variant="h3">{report.preferredVendor}</Typography>
            <Box display={"flex"} gap={"3rem"}>
              <Box>
                <Typography variant="body1">Emission / Spend</Typography>
                <Typography variant="h4">
                  {report.EmissionFactor}{" "}
                  <span style={{ fontSize: "1rem" }}>TCO2 Eq. / ₹</span>{" "}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1">Price Estd.</Typography>
                <Typography variant="h4">₹{report.estPrice} </Typography>
              </Box>
              <Box>
                <Typography variant="body1">Emission Estd.</Typography>
                <Typography variant="h4">
                  {report.estEmission}{" "}
                  <span style={{ fontSize: "1rem" }}>TCO2 Eq.</span>{" "}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1">
              We have found {report.suggestedVendor.length} vendors offering less
              emission than {report.preferredVendor} 
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/*Vendor Comparision Graph */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginInline={"1rem"}
        padding={"1rem"}
        backgroundColor={"#fff"}
        borderRadius={"1rem"}
        height={"100%"}
      >
        <Typography variant="h4" style={{ margin: "1rem" }}>
          <strong>Vendor Comparision</strong>
        </Typography>
        <BarChart
          colors={["#3b7b6f"]}
          dataset={EmissionDataBarGraph}
          margin={{ left: 50 }}
          xAxis={[
            { scaleType: "band", dataKey: "Name", categoryGapRatio: 0.6 },
          ]}
          series={[
            { dataKey: "Emissions", label: "Emissions", valueFormatter },
          ]}
          {...chartSetting}
        />
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        marginInline={"1rem"}
        padding={"1rem"}
        backgroundColor={"#fff"}
        borderRadius={"1rem"}
        height={"400px"}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5" style={{ margin: "1rem" }}>
            <strong>Sites Emission</strong>
          </Typography>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Show Featured Vendors"
          />
        </Box>
        <DataGrid
          getRowId={(row) => row.PID}
          rows={report.suggestedVendor}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          className="w-full bg-white rounded-lg"
          pageSizeOptions={[5, 10]}
        />
      </Box>

      <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginInline={"1rem"}
          padding={"4rem"}
          backgroundColor={"#29564c"}
          borderRadius={"1rem"}
          color={"#fff"}
        >
          <Box
            width={"70%"}
            display={"flex"}
            flexDirection={"column"}
            gap={"1rem"}
          >
            <Typography variant="h3">
              <strong>
                Invite Your Vendor to <br /> Collaborate in detail
              </strong>
            </Typography>
            <Typography variant="h5">
              <p>
              Invite your vendor to join us and earn rewards! <br/> Share your eco-friendly journey on Votan GDP. 
              </p>
            </Typography>
            <Button
              onClick={() => history.push("/manage/vendors")}
              sx={{
                width: "fit-content",
                backgroundColor: "#3B7B6F",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#7CA59C",
                },
              }}
            >
              Invite Vendors
            </Button>
          </Box>
          <Box width={"30%"}>
            <img style={{ width: "100%" }} src={InviteVendorIllustration}></img>
          </Box>
        </Box>
    </Box>
  );
};

export default VendorSimulationReport;
//Converts Large Numbers to short
const formatNumber = (num) => {
  const unit = "t.CO2 Eq.";
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + num).length / 3);
  let shortValue = parseFloat(
    (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
};
