import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import GHGReportDetails from './ReportDetails';

const ReportCopy = (props) => {
  const [data, setData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedSiteRowData, setSelectedSiteRowData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [GHGEmissionsTableData, setGHGEmissionsTableData] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const API_URL = CONSTANTS.API_URL;
  const [opens, setOpens] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpens(false);
  };
  const fetchData = async () => {
    try {
      setData(props.data);
      setSiteData(props.siteData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [props.data]);

  const handleDiscloseReport = async () => {
    try {
      let reqObj = { ...selectedRowData };
      reqObj.status = "disclosed";
      console.log("selectedRowData : - ", reqObj);

      const data = await axios.put(API_URL + `/activityReport/update`, reqObj, headers);
      if (data) {
        console.log("Table data : - ", data.data, "Data end");
        setSelectedRowData(reqObj);
        setOpens(true);
        setSuccess("Report disclosed publicly");
        handleCloseDialog()
      }
    } catch (e) {
      setOpens(true);
      setError("Error: Report could not be disclosed");
    }
  }

  const PDFGenerator = async () => {
    console.log("props.data : - ", props.data);

    var elementToSkip = document.getElementById("test1");
    if (elementToSkip) {
      elementToSkip.style.display = "none";
    }

    // Trigger the print dialog
    window.print();

    // Show the element again after printing
    if (elementToSkip) {
      elementToSkip.style.display = "flex"; // Restore its original display property
    }

  };

  const makeAllEmissionsTableData = async (data) => {
    let tableData = [];
    let initialObject = {
      "Scope 1": [],
      "Scope 2": [],
      "Scope 3": [],
    }
    let res = data.GHGActivities.reduce((acc, curr) => {
      if (!(acc[curr.scope].includes(curr.category))) {
        acc[curr.scope].push(curr.category);
      } else {
        acc = acc;
      }
      return acc;
    }, initialObject);

    tableData = [
      ["Scope 1", res["Scope 1"].toString(), "Intergovernmental Panel on Climate Change – IPCC: Guidelines for National Greenhouse Gas Inventories (2006)"],
      ["Scope 2", res["Scope 2"].toString(), "United States – EPA eGRID 2020 State File released, 2022-01-27 International – International Energy Agency (IEA) 2020 released 2020 United Kingdom – Department for Environment, Food & Rural Affairs (DEFRA) Greenhouse Gas Reporting: Conversion Factors 2021 Residual Mix - Association of Issuing Bodies (AIB) European Residual Mixes 2020 - Version 1.1, 2021-05-31"],
      ["Scope 3", res["Scope 3"].toString(), "The Climate Registry's General Reporting Protocol, v1.1, May 2008, Table 12.1"],
    ]
    console.log("response for reduce : - ", tableData);
    setGHGEmissionsTableData(tableData);
  }

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "from",
      label: "From",
    },
    {
      name: "to",
      label: "To",
    },
  ];

  const GHGEmissionsTableHeaders = ['Emissions Scope', 'Emissions Source', 'Emissions Factor Employed'];

  const options = {
    filter: true,
    selectableRows: 'single', // Changed to allow selecting a single row
    onRowClick: async (rowData, rowMeta) => {
      // rowData contains the indices of the selected row
      const selectedIndex = rowMeta.dataIndex;
      setSelectedRowData(data[selectedIndex]); // Get the full object based on the index
      setOpenDialog(true); // Open the dialog on row click
      makeAllEmissionsTableData(data[selectedIndex]);
    },
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    // sortOrder: {
    //   name: 'id', // The column you want to sort by initially
    //   direction: 'desc', // 'asc' for ascending, 'desc' for descending
    // },
  };
  const options2 = {
    filter: true,
    selectableRows: 'single', // Changed to allow selecting a single row
    onRowClick: async (rowData, rowMeta) => {
      // rowData contains the indices of the selected row
      const selectedIndex = rowMeta.dataIndex;
      setSelectedRowData(siteData[selectedIndex]); // Get the full object based on the index
      setOpenDialog(true); // Open the dialog on row click
      makeAllEmissionsTableData(siteData[selectedIndex]);
    },
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.refresh();
    // window.location.reload();
  };
  const formatColumnName = (columnName) => {
    const formattedName = columnName.replace(/([a-z]+)([0-9]+)/i, (_, prefix, number) => {
      return prefix.charAt(0).toUpperCase() + prefix.slice(1) + ' ' + number;
    });
    return formattedName;
  };
  return (
    <>
      <MUIDataTable
        title={
          <>
            {props.role !== "admin" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginTop: "14px" }}
                  >
                    Generated Reports
                  </Typography>
                </div>
              </>
            )}
          </>
        }
        data={data}
        columns={columns}
        options={options}
      />
      {siteData.length>0 && 
      <> <br /><br />
      <MUIDataTable
        title={
          <>
            {props.role !== "admin" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginTop: "14px" }}
                  >
                    Sites Reports
                  </Typography>
                </div>
              </>
            )}
          </>
        }
        data={siteData}
        columns={columns}
        options={options2}
      /></>}
      {/* Dialog to display JSON stringified value of the selected row */}
      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedSiteRowData.title}</DialogTitle>
        {/* <DialogActions>
        </DialogActions> */}
        <DialogContent>
          <>

            <div id="test1" style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}>
              <Button
                variant="contained"
                style={{ width: "100px" }}
                onClick={PDFGenerator}
              >
                Download
              </Button>
              {/* <Button
                variant="contained"
                style={{ width: "100px", marginLeft: "10px" }}
                onClick={handleDiscloseReport}
              >
                Disclose
              </Button> */}
              <Button variant="contained" style={{ width: "100px", marginLeft: "10px" }} onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </div>
            <GHGReportDetails data={selectedRowData} />
          </>
        </DialogContent>
      </Dialog>
      {(success || error) && (
        <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
          <MuiAlert
            onClose={handleCloses}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {success || error}
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
};

export default ReportCopy;
