import React from "react";
import style from "../Layout/layout.module.css";
// imported Component
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

const Layout = (props) => {
  const [state, setState] = React.useState(true);
  const handleClick = () => {
    setState(!state)
  };
  return (
    <>
      <div className={style.layout} style={{ background: "#F4F3EE" }}>
        <header className={style.header}>
          <Header clicker={setState} state={state} />
        </header>
        <aside
          style={{ display: state ? "none" : true }}
          className={style.aside}
        >
          <Sidebar clicker={handleClick}/>
        </aside>
        <main className={state ? style.main2 : style.main}>
          {props.children}
        </main>
      </div>
    </>
  );
};

export default Layout;
