import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Component
import ProductOverview from "../ProductOverview";
import { Typography } from "@material-ui/core";
// import React Cookie
import { useCookies } from "react-cookie";
import QRCode from "../QRCode/QRCode";
// import Loader component
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// add c.s.s file
import "../../Pages/DynamicTable.css";
import ReportView from "./ReportView";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ReportSummary = (props) => {
  const [records, setRecords] = React.useState([]);
  const [attribute, setAttribute] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const jsonDomInternal = (json) => {
    return Object.keys(json || {}).map((child, c) => {
      if (child !== "color") {
        if (typeof json[child] === "object" && !Array.isArray(json[child])) {
          return jsonDomInternal(json[child]);
        } else {
          return (
            <div className="full" key={c}>
              <div className="right" style={{ float: "right" }}>
                {Array.isArray(json[child])
                  ? JSON.stringify(json[child])
                  : json[child]}
              </div>
            </div>
          );
        }
      }
    });
  };
  // useEffect
  React.useEffect(() => {
    console.log(props.obj?.row, "journey summary data");
    props.obj?.row ? setRecords(props.obj.row) : setRecords([]);
    setAttribute(props.obj?.row?.productAttributes);
    setIsLoading(false);
  }, [props.obj]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {!isLoading && (
        <Box sx={{ flexGrow: 1 }}>
          {!show && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    {records.length > 0 && <ProductOverview />}
                    <Typography style={{ textAlign: "center" }}>
                      <strong>Report Details</strong>
                    </Typography>
                    <div className="full">
                      <div className="left">
                        <Typography>
                          <strong>From</strong>
                        </Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {records?.From ?? ""}
                      </div>
                    </div>
                    <div className="full"></div>
                    <div className="full">
                      <div className="left">
                        <Typography>
                          <strong>To</strong>
                        </Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {records?.To ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography>
                          <strong>Scopes Covered</strong>
                        </Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {jsonDomInternal(records.scopes) ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography>
                          <strong>Reporting Standard</strong>
                        </Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {records.reportStandard ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography>
                          <strong>Status</strong>
                        </Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {records?.status ?? ""}
                      </div>
                    </div>
                    {/* <div className="full">
                      <div className="left">
                        <Typography><strong>Start Point</strong></Typography>
                      </div>
                      <div
                        className="right"
                        style={{ float: "right" }}
                      >
                        {records?.startPoint ?? ""}
                      </div>
                    </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  {/* <Item> */}
                  <ReportView obj={props.obj} />
                  {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          height: "100%",
                        }}
                      >
                        <QRCode
                          id={records?.id ? records?.id : "Loading Please Wait"}
                          nid={cookies.Nid}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Typography><strong>ID Number</strong></Typography>
                        <Typography><strong>ID Type</strong></Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <strong>
                          {props.obj?.id ? props.obj.id : "Loading Please Wait"}
                        </strong>
                        <strong>QR Code</strong>
                      </div>
                    </div> */}
                  {/* </Item> */}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ReportSummary;
