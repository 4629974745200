import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';

const Toaster = (props) => {
    const [state, setState] = React.useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
      });
    const [msg, setMsg] = React.useState('Your transaction is submitted and waiting for confirmation')
    const { vertical, horizontal, open } = state;
    
    const handleClose = () => {
      console.log('hello')
        setState({ ...state, open: false });
      };
      React.useEffect(() => {
        if(props.msg){
          setMsg(props.msg)
        }
      },[props.msg])

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        // autoHideDuration={4000}
        onClose={()=>handleClose()}
        message={msg}
        key={vertical + horizontal}
      />
    </div>
  )
}

export default Toaster;
