import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import { Container } from "@material-ui/core";
import EmptyTable from "../../component/EmptyTable/EmptyTable";
import ProductOverview from "../../Pages/ProductOverview";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import IconButton from "@mui/material/IconButton";
import UploadDocs from "../../component/Blockchain/UploadDocs";
// import Product Details C.S.S
import styles from "../../component/CSS/Product.module.css";
// import component
import EcoLabelSummary from "./EcoLabelSummary";
import EcoLabelDescriptionPopUp from "./EcoLabelDescriptionPopUp";
// import Eco Label C.S.S
import style from "./EcoLabel.module.css";
// import image logo
import VotanLogo from "../../assets/images/votan_text_white.png";

import { Doughnut, Line } from "react-chartjs-2";
import LinearProgress from "@material-ui/core/LinearProgress";
// styling component
import { withStyles } from "@material-ui/core/styles";
import ListingPage from "../ListingPage/ListingPage";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useHistory } from "react-router-dom";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 3,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    margin: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  secondcard: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    justifyContent: "space-around",
  },
  products: {
    marginTop: 2,
    textAlign: "center",
    fontWeight: "bolder",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

let donutChartData = {
  labels: ["Scope 1", "Scope 2", "Scope 3"],
  datasets: [
    {
      data: [100,200,300],
      backgroundColor: ["#336699", "#3377AA", "#3388CC"],
    },
  ],
};

const EcoLabelDataPopUp = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    total: 10,
    inventory: 13,
    incoming: 15,
    outgoing: 30,
    image:"https://picsum.photos/200/300",
    title:"Offsetting Via",
    type:"Type - Plantation",
    location:"Location - Rajasthan",
    description:`We are professionally run group of ordinary people
                  extraordinarily determined to protect the enviroment not just
                  by themselves`
  });

  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState();
  const [productIddata, setProductIdData] = React.useState([]);
  const [image, setImage] = React.useState(false);
  const [ecoLabelParticipant, setEcoLabelParticipant] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const InventoryDataById = (id) => {
    setProductIdData(props.obj);
  };

  // useEffect
  React.useEffect(async () => {
    if (props.state) {
      setState(props.state);
    }
  }, [props]);

  React.useEffect(() => {
    InventoryDataById();
    console.log(props.participant, "eco label participant");
    setEcoLabelParticipant(props.participant ?? "");
    console.log(props.obj, "props obj master pop up");
  }, []);

  React.useEffect(() => {
    if (props.obj !== undefined) {
      setData({
        id: props.obj.productId,
        row: props.obj,
        obj: props.obj,
      });
      setProductIdData(props.obj);
    }
  }, []);

  const jsonDomInternal = (json) => {
    return Object.keys(json).map((child, c) => {
      if (child !== "color") {
        if (typeof json[child] === "object" && !Array.isArray(json[child])) {
          return jsonDomInternal(json[child]);
        } else {
          if (
            child.startsWith("_") ||
            (Array.isArray(json[child]) && json[child].length === 0) ||
            child === "user_id" ||
            child === "PID" ||
            child === "manfacturer" ||
            json[child] === "" ||
            child === "blockchainId"
          ) {
            return;
          }
          return (
            <div className="full" key={c}>
              <div className="left">
                <strong>{child}</strong>
              </div>
              <div className="right" style={{ float: "right" }}>
                {Array.isArray(json[child])
                  ? JSON.stringify(json[child])
                  : json[child]}
              </div>
            </div>
          );
        }
      }
    });
  };

  // handle bar Tracker navigation
  const handleNavigateTrackerPage = () => {
    // add history push method to redirect to new page
    history.push(`/tracker`); // navigate tracker page
  };

  const printQRDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" style={{ backgroundColor:'black' }}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              EcoLabel Details
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto" }}
              onClick={props.close}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ backgroundColor:'black' }}
            TabIndicatorProps={{style: { backgroundColor: "white" }}}
          >
            <Tab label="EcoLabel Summary" {...a11yProps(0)} />
            <Tab label="Description" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} key={0}>
          <div style={{ marginTop: "14px" }}>
            {/*new component calling here */}
            <EcoLabelSummary obj={data} />
            {/* component ends here */}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} key={1}>
            <div id="divToPrint" 
             style={{ 
                width:"60%", 
                marginLeft:"auto", 
                marginRight:"auto" 
             }}>
              <ListingPage />
            </div>

            <Button 
              variant="contained"
              onClick={handleNavigateTrackerPage}
            > 
              View Details
            </Button>
            
            <Button 
              style={{ marginLeft:"20px" }}
              variant="contained"
              onClick={printQRDocument} 
              >
                Download
            </Button>  
          
        </TabPanel>
      </div>
    </>
  );
};

export default EcoLabelDataPopUp;
