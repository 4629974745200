import React, { useRef } from "react";
// import invoice c.s.s
import "../../component/invoice/invoice.css";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
import RefreshIcon from "@mui/icons-material/Refresh";
// import axios
import axios from "axios";
// Fab for styling
import Fab from "@mui/material/Fab";
// import headers & constants
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
// pop up component
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// table component start here
import { makeStyles } from "@material-ui/core/styles";
import Toaster from "../../component/Toaster/Toaster";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { TextField } from "@mui/material";
// import Icon
import DangerousIcon from "@mui/icons-material/Dangerous";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
// IMPORT FullScreenLoader
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// import MasterUploadForm from "../../Pages/MasterUploadForm";
import ThirdPartyUploadForm from "./ThirdPartyUploadForm";
import MasterDataPopUp from "../MasterDataPopUp/MasterDataPopUp";
// open dialog box
import Dialog from "@mui/material/Dialog";
// import Upload Docs Component here
import UploadDocs from "../../component/Blockchain/UploadDocs";
// import Product Details C.S.S
import styles from "../../component/CSS/Product.module.css";
// import component
// import ProductOverview from "../../Pages/ProductOverview";
import ProductOverview from "../../Pages/ProductOverview";
import IssuePoMaster from "../MasterDataPopUp/IssuePoMaster";
import StepperOrder from "../PurchaseOrderTab/StepperOrder";
import ThirdPartyVehiclePopUp from "./ThirdPartyVehiclePop";
// import NOT FOUND PID Component
import NotFoundPID from "../../component/NotFoundPID/NotFoundPID";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  container: {
    width: "sticky",
    maxWidth: "100%",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f1f5f9",
  boxShadow: 24,
  width: "80vw",
  padding: "2rem",
  // maxHeight: "100%",
  height: "80vh",
  backgroundColor: "#fff",
  borderRadius: "20px",
  overflow: "auto",
};

export default function MasterData(props) {
  const isInitialMount = useRef(true);
  const [fullScreenLoader, setFullScreenLoader] = React.useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  // HEADERS two
  const headers2 = HEADERS2(cookies.token, cookies.Nid);

  const [id, setId] = React.useState();
  const [rows, setRows] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [columns, setColumns] = React.useState([]);
  const [columnsValues, setColumnsValues] = React.useState([]);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState(false);
  const [selectObj, setSelectObj] = React.useState({});
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table

  const [loadState, setloadState] = React.useState(true);
  const [editPopup, setEditPopup] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState({});
  const [newDetails, setNewDetails] = React.useState({});
  const [detailsState, setDetailsState] = React.useState(false);
  const [submitState, setSubmitState] = React.useState({
    Id: "",
  });
  const [formValues, setFormValues] = React.useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [edit, setEdit] = React.useState({
    user_id: cookies.user_id,
    PID: cookies.PID,
    Id: id,
  });
  // this is for add button dialog
  const [dialogopen, setDialogOpen] = React.useState(false);
  // MISSING PID STATE
  const [missingPID, setMissingPID] = React.useState(true);
  
  const handleDetailsObj = () => {
    setDetailsState(!detailsState);
  };

  // error handling snackbar
  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  const handleClearToaster = () => {
    setSuccessMsg();
    setErrorMsg();
  };

  const handleDailogOpen = () => {
    setDialogOpen(true);
    handleDetailsObj();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInput = (event) => {
    const newState = {
      ...submitState,
      [event.target.name]: event.target.value,
    };
    setSubmitState(newState);
    console.log(newState);
  };

  const handleChanges = (i, e) => {
    let newFormValues = [...formValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    setFormValues(newFormValues);
  };

  // React useEffect Calling here
  React.useEffect(() => {
    // console.log(viewDetails,"viewDetails edit master")
    // PID MISSING CHECK
    if(cookies.profileStatus==='complete'){
      setMissingPID(false);
    }
    if (isInitialMount.current) {
      console.log("once");
      getVehicleData();
      isInitialMount.current = false;
    }
  });

  // call an api to get master data in db
  const getVehicleData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/vehicle/getVehiclesData/pid`,
        headers
      );
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      let Allcolumns = [];
      let Allvalues = [];
      for (let j = 0; j < data.length; j++) {
        for (let key in data[j]) {
          if (
            key !== "__v" &&
            key !== "_id" &&
            key !== "NID" &&
            key !== "PID" &&
            Allcolumns.indexOf(key) === -1
          ) {
            Allcolumns.push(key);
          }
        }
        Allvalues.push(data[j]);
      }
      let temp = Allcolumns;
      temp.push({
        name: "action",
        label: "Action",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center">

                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    onClick={() => handleEdit(data, tableMeta.rowIndex)}
                  >
                    <EditIcon />
                  </Fab>

                  <Fab
                    size="small"
                    color="primary"
                    aria-label="delete"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    onClick={() => deleteThirdPartyVehicle(data, tableMeta.rowIndex)}
                  >
                    <Delete />
                  </Fab>
                </Stack>
              </div>
            </>
          ),
        },
      });
      setColumns(temp);
      setColumnsValues(Allvalues);
      setColumnsValues(Allvalues);
      setloadState(false);
      setMissingPID(true); // PID MISSING STATE
    } catch (err) {
      setMissingPID(true); // PID MISSING STATE
      console.log(err);
      setErrorMsg(`failed to fetch data: ${err.message}`);
    }
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handlebarSubmit = (event) => {
    event.preventDefault();
  };

  const addFormFields = () => {
    setFormValues([...formValues, { key: "", value: "" }]);
  };

  const handlebarClick = (obj) => {
    setId(obj.id);
    setEditPopup(!editPopup);
    setSelectObj(obj);
  };

  const handleEditSubmit = async () => {
    try {
      let temp = edit;
      console.log(temp, "temp here");
      let fv = formValues;
      for (let i = 0; i < fv.length; i++) {
        let key = formValues[i].key;
        temp[key] = formValues[i].value;
      }
      console.log("temp", temp);
      // setFullScreenLoader(true);
      const { data } = await axios.put(
        API_URL + `/vehicle/update`,
        temp,
        headers2
      );
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        console.log(data);
        getVehicleData();
        setSuccessMsg("Records updated successfully");
        setOpens(true);
        setEditPopup(!editPopup);
      }
      // setEditPopup(false);
      // setFullScreenLoader(false);
      setloadState(false);
    } catch (e) {
      setErrorMsg(
        "Unable to update records: Please check the inputs again " + e
      );
      console.log(e, "error in handle select");
      // setFullScreenLoader(true);
    }
  };

  // @To-Do: edit form is only adding new keys and not removing old if changing key name
  //send or sub parts two button
  const handleEdit = async (o, idx) => {
    let id = idx;
    console.log(id, "id here");
    if (page > 0) {
      // @To-Do: Add this check to every pagination page to avoid mess
      id = page * rowsPerPage + idx;
    }
    let obj = o[id];
    setId(id);
    setEditPopup(!editPopup);
    let editObj = [];
    if (obj) {
      // obj.productId = o.productId;
      obj.user_id = cookies.user_id;
      obj.PID = cookies.PID;
      let keys = Object.keys(obj);
      let values = Object.values(obj);
      for (let i = 0; i < keys.length; i++) 
      if (
        keys[i] !== "__v" &&
        keys[i] !== "_id" &&
        keys[i] !== "Id" &&
        keys[i] !== "Timestamp" &&
        keys[i] !== "user_id" &&
        keys[i] !== "NID" &&
        keys[i] !== "PID"
      )
      {
        editObj.push({ key: [keys[i]], value: values[i] });
      }
      setEdit(obj);
      setFormValues(editObj);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    // handleDetailsObj();
  };

  const deleteThirdPartyVehicleBatch = async (dataObj) => {
    try {
      console.log(dataObj, "obj");
      let indexes = [];
      for (let o of dataObj) {
        console.log(rows[o.dataIndex], o.dataIndex, "obj");
        indexes.push(rows[o.dataIndex].productId);
      }
      console.log(indexes, " these are indexes");
      const { data } = await axios.delete(
        API_URL + `/masterdata/PID/${cookies.PID}/delete/batch`,
        { ...headers, data: indexes }
      );
      console.log(data, "inv datat");
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getVehicleData();
        setSuccessMsg("Deleted from master data");
        setOpens(true);
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  // delete from master data table
  const deleteThirdPartyVehicle = async (dataObj, index) => {
    try {
      let Id = dataObj[index].Id;
      const data = await axios.delete(
        API_URL +
          `/vehicle/PID/delete?Id=${Id}`,
        headers
      );
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getVehicleData();
        setSuccessMsg("Deleted from Third Vehicle");
        setOpens(true);
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  const options = {
    filterType: "checkbox",
    print: false,
    onChangeRowsPerPage: (numberOfRows) => {
      console.log(numberOfRows, "num");
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    onRowSelectionChange: (k, l, selectedRow) => {
      setSelectedRow(selectedRow);
      let selectTemp = [];
      for (let i = 0; i < selectedRow.length; i++) {
        selectTemp.push(rows[selectedRow[i]]);
      }
      setSelectedRowObj(selectTemp);
    },
    selectedRow: selectedRow,
    // onRowClick: (rowData, rowState,a) => {
    //   setViewDetails(rows[rowState.dataIndex]);
    //   setNewDetails(rows[rowState.dataIndex]);
    //   handleDetailsObj();
    //   // handleInvoiceOpen(); // something to do with opening object
    // },
    onCellClick: async (d, rowState) => {
      if (rowState.colIndex !== columns.length - 1) {
        setViewDetails(rows[rowState.dataIndex]);
        setNewDetails(rows[rowState.dataIndex]);
        handleDetailsObj();
      }
    },
    onRowsDelete: async (e) => {
      console.log(e, "e");
      await deleteThirdPartyVehicleBatch(e.data);
    },
  };

  return (
    <>
      {missingPID && <NotFoundPID />}
      {loadState && <Toaster msg={"Fetching real-time data"} />}
      {rows && (
        <MUIDataTable
          title={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {props.role !== "admin" && (
                  <>
                    <Typography style={{ marginTop: "10px" }}>
                      <strong>Third Party Vehicles</strong>
                    </Typography>
                    <Button
                      onClick={handleDialogOpen}
                      style={{ marginLeft: "10px", marginTop: "5px" }}
                      variant="contained"
                    >
                      Add New
                    </Button>
                  </>
                )}

                <Fab
                  onClick={props.state}
                  size="small"
                  color="primary"
                  aria-label="refresh"
                  style={{ marginLeft: "10px", marginTop: "5px" }}
                >
                  <RefreshIcon />
                </Fab>
              </div>
            </>
          }
          data={columnsValues}
          columns={columns}
          options={options}
        />
      )}
      <div>
        <Modal
          open={detailsState}
          onClose={handleDetailsObj}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ textAlign: "center" }}
        >
          <Box sx={style}>
            <ThirdPartyVehiclePopUp
              close={handleDetailsObj}
              obj={viewDetails}
              id={id}
              load={getVehicleData}
              click={handlebarClick}
            />
          </Box>
        </Modal>
        {/* master data component start here */}
        {/* dailog box component start here */}

      {/* edit modal pop up component start here */}
      <Modal
        open={editPopup}
        onClose={() => setEditPopup(!editPopup)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Third Party Vehicle Details
            </Typography>
            <br />

            <TextField
              id="outlined-basic"
              label="ID"
              name="Id"
              value={edit.Id ?? "Not Found"}
              disabled
              onChange={handleInput}
              variant="outlined"
            />
            <br />

            {/* Start new form for key & value */}
            <form onSubmit={handlebarSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <input
                    name="key"
                    value={element.key || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  <input
                    name="value"
                    value={element.value || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  {index ? (
                    <DangerousIcon
                      style={{ color: "red ", cursor: "pointer" }}
                      onClick={() => removeFormFields(index)}
                    />
                  ) : null}
                </div>
              ))}
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                type="submit"
                onClick={() => addFormFields()}
              >
                Add
              </Button>
            </form>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleEditSubmit}
                variant="contained"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                onClick={() => setEditPopup(!editPopup)}
                variant="contained"
                type="submit"
              >
                Go Back
              </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
      {/* edit modal pop up component ends here */}

        {/* dialog box component ends here */}
        {fullScreenLoader && <FullScreenLoader />}
        {(errormsg || successmsg) && (
          <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
            <Alert
              onClose={handleCloses}
              severity={successmsg ? "success" : "warning"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        )}
        <Dialog open={dialogopen} onClose={handleDialogClose}>
          <ThirdPartyUploadForm
            state={() => getVehicleData()}
            load={(e) => setFullScreenLoader(e)}
            close={() => setDialogOpen(!dialogopen)}
            dialogopen={dialogopen}
            setErrorMsg={setErrorMsg}
            setSuccessMsg={setSuccessMsg}
          />
        </Dialog>

        {/* master data component ends here */}
        {(errormsg || successmsg) && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={true}
              autoHideDuration={4000}
              onClose={handleClearToaster}
            >
              <Alert
                onClose={handleClearToaster}
                severity={errormsg ? "warning" : "success"}
                sx={{ width: "100%" }}
              >
                {errormsg ? errormsg : successmsg}
              </Alert>
            </Snackbar>
          </Stack>
        )}
      </div>
    </>
  );
}
