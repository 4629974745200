import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
// import TextField from "@material-ui/core/TextField";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
// for password eye
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// snackbar notifications
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import axios
import axios from "axios";
// import login component
import Login from "../Login/Login";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
//component register company
import RegisterCompany from "../RegisterCompany/RegisterCompany";
// import API_URL and Cookies
import { CONSTANTS, HEADERS } from "../utils/constants";
// import Loader
import FullScreenLoader from "../Loader/FullScreenLoader";
// import css
import "./signup.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0.5),

    "& .MuiTextField-root": {
      margin: theme.spacing(0.5),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const options = ["admin", "member", "subscriber"];

const Signup = (props) => {
  const classes = useStyles();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [tnc, setTnc] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [pid, setPid] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [response, setResponse] = useState();
  const [responseCode, setResponseCode] = useState();
  const [redirect, setRedirect] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [hideInfo, setHideInfo] = React.useState(false);
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [fullscreenloader, setFullScreenLoader] = React.useState(false);
  const [values, setValues] = useState({
    showPassword: false,
  });

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // headers
  const headers = HEADERS(cookies.token);

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(firstname, lastname, email, password);
  };

  const handleError = async () => {
    try {
      if (firstname.length === 0) {
        setErrMsg("First Name must not be empty");
      } else if (lastname.length === 0) {
        setErrMsg("Last Name must not be empty");
      } else if (email.length === 0) {
        setErrMsg("Email is required");
      } else if (password.length === 0) {
        setErrMsg("Password is required");
      } else if (password.length < 6) {
        setErrMsg("Password should be minimun 6 characters");
      } else {
        setFullScreenLoader(true);
        const { data, status } = await axios.post(API_URL + "/user/register", {
          firstname,
          lastname,
          email,
          PID: pid,
          password,
          tnc
        });
        console.log(data, "user sign up data");
        console.log(status, "user sign up status");
        setResponseCode(status);
        setResponse(data);
        setOpen(true);
        if (status === 200) {
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e, "err");
      setErrMsg(`Error ${JSON.parse(JSON.stringify(e)).status}`);
      setResponseCode(JSON.parse(JSON.stringify(e)).status);
      setResponse(
        "Unable to reach the server, please check your internet connection"
      );
      setOpen(true);
      setFullScreenLoader(false);
    }
  };

  // handle Register function
  const handleRegister = () => {
    removeCookie("PID", { path: "/" });
    props.pstate("register");
    window.location.reload();
  };

  React.useEffect(() => {
    console.log("i am signup");
    getParticipentData();
    console.log(props.PID, "hi");
    if (cookies.PID && cookies.PID !== "") {
      console.log("PID found");
      setPid(cookies.PID);
      setHideInfo(true);
    }
  }, []);

  // call an participent api
  const getParticipentData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/participant/getParticipant`,
        headers
      );
      if (data.error) {
        //@To-do display error
        setErrMsg("Error Data Not found");
        setRows([]);
      } else {
        setRows(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {fullscreenloader && <FullScreenLoader />}
      {!fullscreenloader && (
        <>
          <form className={classes.root} onSubmit={handleSubmit}>
            <h1 className="title">Join Launchpad</h1>
            <br />
            <Typography
              onClick={() => setState(false)}
              className="sub_title"
            >
              Enter Following Details Create An Account
            </Typography>
            <br />


            <TextField
              label="First Name"
              variant="outlined"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />

            <TextField
              label="Last Name"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              required
            />

            <TextField
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              variant="outlined"
              label="Password"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ htmlFor: 'outlined-adornment-password' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              required
              style={{ width: "19rem", marginTop: "10px", height: "50px" }}
            />

            {errMsg && (
              <Typography style={{ color: "red" }}>{errMsg}</Typography>
            )}
            <div>
              <Button
                type="submit"
                onClick={handleError}
                variant="contained"
                style={{ marginTop: "10px" }}
              // color="primary"
              >
                Signup
              </Button>
            </div>


            {response && !errMsg && (
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                  open={open}
                  autoHideDuration={10000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity={
                      responseCode === 200 || responseCode === 201
                        ? "success"
                        : "warning"
                    }
                    sx={{ width: "100%" }}
                  >
                    {responseCode === 200 || responseCode === 201
                      ? "Signed In Successfully! Please Login to Continue."
                      : "Failed to Login"}
                  </Alert>
                </Snackbar>
              </Stack>
            )}
          </form>
          <div
            style={{ display: "grid", placeItems: "center",marginBottom:'5px' }}
          >
            <Typography>
              Already existing account?
              <Link
                style={{
                  margin: "5px",
                  cursor: "pointer",
                  fontSize: "18px",
                  color: "#3A7B6E",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.page();
                  props.checkNew(false);
                }}
              >
                Login
              </Link>
            </Typography>
          </div>
          {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
         
        </>
      )}
      {redirect && <Login redirect={() => setRedirect(!redirect)} />}
    </>
  )
}

export default Signup;
