import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Fab, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Card, CardContent } from "@mui/material";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import Report component
import ReportCopy from "../VCarbonPages/ReportCopy";
// import Eco Label component
import EcoLabelPage from "../EcoLabelPages/EcoLabelPage";
import axios from "axios";
// import Headers and API URL
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import MUIDataTable from "mui-datatables";
import { BulkInvite } from "./BulkInvite";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { InviteUserDialog } from "./InviteUserDialog";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import MissingPIDLoader from "../../component/Loader/MissingPIDLoader";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Users = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [data, setData] = React.useState([]);
  const [ecoLabelData, setEcoLabelData] = React.useState([]); // ecolabel api state
  const [total, setTotal] = React.useState(0); // report api state
  const [totalAdmin, setTotalAdmin] = React.useState(0); // report api state

  const [validatedData, setValidatedData] = React.useState(0);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [profileStatus, setProfileStatus] = React.useState(false);

  const handleSnackbarClose = async () => {
    setSnackbarOpen(false);
  }
  const handleInviteUser = () => {
    // Open the invite user dialog
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    // Close the invite user dialog
    setOpenInviteDialog(false);
  };

  const handleSaveUser = async (userData) => {
    try {
      // Handle saving the user data (call your API, etc.)
      console.log('Saving user:', userData);
      const uploadVendorsResult = await axios.post(API_URL + `/mailer/sendInvite`, userData, headers);
      if (!uploadVendorsResult) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to invite, make sure it is in the correct format or user does not already exists on Votan");
        return [];
      } else {
        console.log("upload vendor result : - ", uploadVendorsResult);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("File uploaded and vendors invited successfully");
        getUsersByPID();
      }
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to upload file, make sure it is in the correct format");
    }
  };
  const columns = [
    // Define your columns here based on your data structure
    { name: 'id', label: 'ID' },
    { name: 'name', label: 'Name' },
    { name: 'email', label: 'Email' },
    { name: 'updatedAt', label: 'Last Update' },
    // Add more columns as needed
  ];
  const options = {
    filter: true,
    selectableRows: 'single',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
  };

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // eco label api by P.I.D data
  const getEcoLabelDataByPID = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/ecoLabel/getAllEcoLabel/PID`,
        headers
      );
      if (!data) {
        setEcoLabelData([]);
      } else {
        setEcoLabelData(data);
        console.log(data, "found eco label data");
      }
    } catch (err) {
      console.log(err, "err in eco label data");
    }
  }

  // reports api by P.I.D data
  const getUsersByPID = async () => {
    try {
      setBackdropOpen(true)
      const { data } = await axios.get(
        API_URL + `/invitedusers/getall`,
        headers
      );
      console.log("data in getUsersByPID function : - ", data);
      if (!data) {
        setData([]);
      } else {
        if (data.data.length > 0) {
          let dataRes = data.data.reduce((acc, curr) => {
            let arr = [curr._id, curr.Name, curr.Email, curr.updatedAt];
            acc.push(arr);
            return acc;
          }, []);
          setData(dataRes);
          setTotal(data.total)
          setTotalAdmin(data.admin)
        } else {
          setData([]);
        }
      }
    } catch (err) {
      console.log(err, "err in reports api");
    }
    setBackdropOpen(false)
  }

  React.useEffect(() => {
    // getEcoLabelDataByPID(); // call an eco label api
    checkMissingPID();
    getUsersByPID(); // call an reports api
    // getAllValidatedReports(); // call an api 
  }, []);
  const checkMissingPID = async () => {
    try {
      const user = await axios.get(
        API_URL + `/user/id?user_id=${cookies.user_id}`,
        headers
      );
      if (user?.data?.PID && user?.data?.PID !== "") {
        setProfileStatus(true);
      }
    } catch (error) {
      console.log("Error inside checkMissingPID function : - ", error.stack);
    }
  }

  return (
    <div>
      {backdropOpen && <FullScreenLoader />}
      {!profileStatus && <MissingPIDLoader />}
      <Typography variant="h5" style={{ margin: "1rem" }}>
        <strong>Employee Management</strong>
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} ml={1}>
          <Grid item xs={4}>
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <Typography variant="h6" component="div">
                    <strong> Total</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {`${total ?? 0}`}
                  </Typography>
                </div>
          </Grid>

          <Grid item xs={4}>
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <Typography variant="h6" component="div">
                    <strong>Admin</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {totalAdmin ?? ''}
                  </Typography>
                </div>
          </Grid>
          <Grid item xs={4}>
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <Typography variant="h6" component="div">
                    <strong>Users</strong>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(0, 62, 53)", textAlign: "center" }}
                  >
                    {total - totalAdmin ?? 0}
                  </Typography>
                </div>
          </Grid>

          {/* Cumulative Reports start here */}
          <Grid item xs={12}>
            <Item style={{ borderRadius: "10px", padding: "0rem" }}>
              <MUIDataTable
                title={
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                    <strong style={{ margin: '0', padding: '0' }}>Employee Invites</strong>
                    <Button
                      variant="contained"
                      style={{ marginLeft: '8px', marginRight: '8px' }}
                      startIcon={<PersonAddIcon />}
                      onClick={handleInviteUser}
                    >
                      Invite
                    </Button>
                    <BulkInvite refresh={getUsersByPID} />
                  </div>
                }
                data={data}
                columns={columns}
                options={options}
              />
            </Item>
          </Grid>
          <InviteUserDialog
            open={openInviteDialog}
            onClose={handleCloseInviteDialog}
            onSave={handleSaveUser}
          />
        </Grid>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Users;
