import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { Card, TextField, Typography, Box, CardContent, Paper, useMediaQuery, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import classes from "../../calculator/company.module.css";
import { Button } from "@mui/material";
// import icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import CompanyStepper from "./CompanyStepper";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import React cookie
import { useCookies } from "react-cookie";
// import icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import Grid from '@mui/material/Grid';
import { makeStyles, styled } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Profile = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["user"]);
  const [show, setShow] = React.useState(true);
  const [change, setChange] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [regionState, setRegionState] = React.useState([]); // api state
  const [opens, setOpens] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  // const [companyId, setCompanyId] = React.useState('')
  const [sectorOperateState, setsectorOperateState] = React.useState([]); // api state
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const valuesx = 3;
  const inputsx = 4
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [industries, setIndustries] = React.useState()

  const [companyField, setCompanyField] = React.useState({
    companyName: "",
    PID: "",
    companyLocated: "",
    sectorOperate: "",
    primaryMail: "",
    domain: "",
    address: "",
    description: "",
    industry: ""
  });
  const [companyId, setCompanyId] = React.useState({
    PID: "",
    user_id: cookies.user_id,
  });
  const handleChangePID = (event) => {
    let changePID = {
      ...companyId,
      [event.target.name]: event.target.value,
    };
    console.log(changePID, "change PID");
    setCompanyId(changePID);
  };
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpens(false);
  };
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const handleSubmit = async () => {
    try {
      const temp = {
        // Type: "calculate", // admin/viewer/listed
        Name: companyField.companyName,
        Region: companyField.companyLocated,
        Sector: companyField.sectorOperate,
        PrimaryMail: companyField.primaryMail,
        Domain: companyField.domain,
        Address: companyField.address,
        Description: companyField.description,
        Industry: companyField.industry,
      };
      if (change) {
        const { data, status } = await axios.put(
          API_URL + "/participant/update",
          temp,
          headers
        );
        console.log("data from update", data);
        // }
        setShow(false);
        if (data) {
          setOpens(true);
          setSuccess("Your Profile Updated Successfully");
        }
      }
    } catch (err) {
      setOpens(true);
      setError(`Something went wrong, ${err.message}`);
      console.log(err, "err in update user api");
    }

  };
  // } ;

  // handle bar show function
  const handleSubmitPID = async () => {
    // const PID = {
    //   Type: "calculate", // admin/viewer/listed
    //   Name: companyField.companyName,
    //   Region: companyField.companyLocated,
    //   Sector: companyField.sectorOperate,
    // };
    // const { data, status } = await axios.put(
    //   API_URL + "/participant/update",
    //   temp,
    //   headers
    // );
    // console.log("data", data);
    try {

      const { data } = await axios.put(
        API_URL + "/user/update",
        {
          PID: companyId,
        },
        headers
      );
      console.log(data, "user update api data here");
      if (data) {
        setOpens(true);
        setSuccess("Your Profile Updated Successfully");
      }
    } catch (err) {
      setOpens(true);
      setError(`Something went wrong, ${err.message}`);
      console.log(err, "err in update user api");
    }
  };

  const handleCompanyInput = (event, i, name) => {
    let company = companyField;
    if (i && name) {
      console.log(company, "company input 1");
      setCompanyField((prevCompanyField) => ({
        ...prevCompanyField,
        [name]: i,
      }));
    } else {
      setCompanyField((prevCompanyField) => ({
        ...prevCompanyField,
        [event.target.name]: event.target.value,
      }));

    }
    setChange(true)
  };

  // useEffect
  React.useEffect(() => {
    if (!load) {
      getParticipentById();
    }
  }, [change]);
  const forceUpdate = React.useRef(setCompanyField);

  // Use the ref in the useEffect to trigger a re-render
  React.useEffect(() => {
    forceUpdate.current(companyField);
  }, [companyField]);
  // call an api to get regions
  const getCompanyRegions = async () => {
    try {
      const { data } = await axios.get(API_URL + `/ef/region/get`, headers);
      console.log(data, "region data");
      if (!data) {
        setRegionState([]);
      } else {
        setRegionState(data);
        console.log(data, "data get successfully");
      }
    } catch (err) {
      console.log(err, "err in api");
    }
  };

  const getParticipentById = async () => {
    try {
      setBackdropOpen(true)
      getCompanyRegions(); // call an api
      getSectorOperate(); // call an api
      getIndustries();
      const { data } = await axios.get(API_URL + `/participant/id`, headers);
      if (data.error) {
        console.log("error handling");
      }
      let temp = {
        companyName: data?.Name ?? data.Name,
        companyLocated: data?.Region ?? data.Region,
        sectorOperate: data?.Sector ?? data.Region,
        PID: data?.PID,
        primaryMail: data?.PrimaryMail,
        domain: data?.Domain,
        address: data?.Address,
        description: data?.Description,
        industry: data?.Industry
      };
      setCompanyField(temp);
      setLoad(true);
      setBackdropOpen(false)
    } catch (err) {
      console.log(err, "error in participant api by id");
      setBackdropOpen(false)
    }

  };

  // call an api to get regions
  const getSectorOperate = async () => {
    try {
      const { data } = await axios.get(API_URL + `/ef/category/get`, headers);
      console.log(data, "activity data");
      if (!data) {
        setsectorOperateState([]);
      } else {
        setsectorOperateState(data);
        console.log(data, "data get successfully");
      }
    } catch (err) {
      console.log(err, "err in api");
    }
  };
  const getIndustries = async () => {
    try {
      const { data } = await axios.get(API_URL + `/gdp/industries`, headers);
      if (!data) {
        setIndustries([]);
      } else {
        setIndustries(data);
      }
    } catch (err) {
      console.log(err, "err in api");
    }
  };
  const updateCompanyProfile = async () => {
    try {
      let temp = companyId;
      const { data } = await axios.put(
        API_URL + `/user/update/PID`,
        {
          PID: temp.PID,
        },
        headers
      );
      console.log(data, "update data here..");
      if (data) {
        setOpens(true);
        setSuccess("PID Updated Successfully");
        setCookie("PID", temp.PID, { path: "/" });
        getParticipentById();
      } else {
        setOpens(true);
        setError("Error: User not authorized");
      }
    } catch (err) {
      setOpens(true);
      setError("Error: User not authorized");
      console.log(err, "err in update participant company profile");
    }
  };
  return (
    <>
      {backdropOpen && <FullScreenLoader />}
      {(cookies.PID || companyField.PID) && <Card>
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" marginTop='1rem'>
          <Grid item xs={12}>
            <Typography variant="h5">Company Profile</Typography>
          </Grid>
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            {/* <Grid cointainer direction="row" spacing={2} justifyContent="center" alignItems="center" marginTop='1rem'> */}
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Company Name
              </Typography>
            </Grid>
            <Grid item xs={inputsx}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="companyName"
                label="Company Name"
                variant="outlined"
                className={classes.text_field}
                value={companyField.companyName ?? ""}
                onChange={handleCompanyInput}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Company Location
              </Typography>
            </Grid>
            <Grid item xs={inputsx}>
              {regionState?.regions && (
                <Autocomplete
                  disablePortal
                  name="companyLocated"
                  options={regionState?.regions}
                  // options={regionState?.regions.map((option) => option)}
                  // getOptionLabel={(option) => (option ? option.name : "")}
                  onChange={(event, newValue) => {
                    handleCompanyInput(event, newValue, "companyLocated");
                  }}
                  value={companyField?.companyLocated ?? ""}
                  required
                  className={classes.text_field}
                  renderInput={(params) => <TextField {...params} label="Company Location" />}
                />
              )}
            </Grid>
          </Grid>
          {sectorOperateState?.activities?.length && (
            <>
              <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
                <Grid item xs={valuesx}>
                  <Typography variant="h6" className={classes.company_name}>
                    Sector of Operation
                  </Typography>
                </Grid>
                <Grid item xs={inputsx}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="sectorOperate"
                    options={sectorOperateState?.activities}
                    getOptionLabel={(option) => {
                      return option?.name ? option.name : option
                    }}
                    onChange={(event, newValue) => {
                      handleCompanyInput(event, newValue, "sectorOperate");
                    }}
                    // sx={{ width: 400 }}
                    value={{ name: companyField?.sectorOperate ?? "" } || null}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    className={classes.text_field}
                    renderInput={(params) => (
                      <TextField {...params} label="Sector Operate" />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {industries?.industries?.length && (
            <>
              <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
                <Grid item xs={valuesx}>
                  <Typography variant="h6" className={classes.company_name}>
                    Industry
                  </Typography>
                </Grid>
                <Grid item xs={inputsx}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="industry"
                    options={industries?.industries}
                    getOptionLabel={(option) => {
                      return option?.name ?? option
                    }}
                    onChange={(event, newValue) => {
                      handleCompanyInput(event, newValue, "industry");
                    }}
                    // sx={{ width: 400 }}
                    value={{ name: companyField?.industry ?? "" } || null}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    className={classes.text_field}
                    renderInput={(params) => (
                      <TextField {...params} label="Industry" />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Primary Mail
              </Typography>
            </Grid>
            <Grid item xs={inputsx}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="Primary Email"
                label="Primary Mail"
                variant="outlined"
                className={classes.text_field}
                value={companyField.primaryMail ?? ""}
                onChange={(event, newValue) => {
                  handleCompanyInput(event, event.target.value, "primaryMail");
                }}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Domain
              </Typography>
            </Grid>
            <Grid item xs={inputsx}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="Domain"
                label="Domain"
                variant="outlined"
                className={classes.text_field}
                value={companyField.domain ?? ""}
                onChange={(event) => {
                  handleCompanyInput(event, event.target.value, "domain");
                }}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={inputsx + 1}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="Address"
                label="Address"
                variant="outlined"
                className={classes.text_field}
                value={companyField.address ?? ""}
                onChange={(event) => {
                  handleCompanyInput(event, event.target.value, "address");
                }}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm container direction={isSmallScreen ? 'column' : 'row'} spacing={1} justifyContent="flex-start" alignItems="center" margin='0.25rem'>
            <Grid item xs={valuesx}>
              <Typography variant="h6" className={classes.company_name}>
                Description
              </Typography>
            </Grid>
            <Grid item xs={inputsx + 3}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="Description"
                label="Description"
                variant="outlined"
                className={classes.text_field}
                value={companyField.description ?? ""}
                onChange={(event) => {
                  handleCompanyInput(event, event.target.value, "description");
                }}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={
                companyField.companyLocated !== "" &&
                  companyField.companyName !== "" &&
                  companyField.sectorOperate !== ""
                  ? false
                  : true
              }
            >
              Save Changes
            </Button>
          </Grid>
          <div className={classes.ctn}>
          </div>
        </Grid>

      </Card>
      }
      {!(cookies.PID || companyField.PID) &&
        <Card>
          <div style={{ textAlign: "center", height: '80vh' }}>
            <Typography style={{ fontWeight: "bold", margin: "8px" }}>
              Already have a Company ID?
            </Typography>
            <TextField
              label="Company Id"
              variant="outlined"
              name="PID"
              id="outlined-size-small"
              value={companyId.PID || ""}
              style={{ marginLeft: "20px" }}
              onChange={handleChangePID}
            />
            <Button
              variant="contained"
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "20px",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={() => updateCompanyProfile()}
            >
              Activate
            </Button>

          </div>
        </Card>
      }
      {(success || error) && (
        <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
          <MuiAlert
            onClose={handleCloses}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
            anchororigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {success || error}
          </MuiAlert>
        </Snackbar>
      )}

    </>
  );
};

export default Profile;
