import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Card, Container } from "@material-ui/core";
// imoprt component
import Office from "../Sites/Office";
import Factory from "../Sites/Factory";
import Warehouse from "../Sites/Warehouse";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Sites() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Card> */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ backgroundColor:'black' }}
            TabIndicatorProps={{style: { backgroundColor: "white" }}}
          >
            <Tab label="Office" {...a11yProps(0)} style={{ color:"#fff" }} />
            <Tab label="Factory" {...a11yProps(1)} style={{ color:"#fff" }} />
            <Tab label="Warehouse" {...a11yProps(2)} style={{ color:"#fff" }} />
          </Tabs>
        </Box>
      {/* </Card> */}
      <TabPanel value={value} index={0}>
        <Office />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Factory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Warehouse />
      </TabPanel>
    </Box>
  );
}
