import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(1),
    },
    newaccount: {
      display: "flex",
      alignItems: "center",
    },
  },
}));
export const InviteUserDialog = ({ open, onClose, onSave }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [size, setSize] = useState(0);
  const types = ['Office','Warehouse','Factory']
  const [renewableEnergy, setRenewableEnergy] = useState(false);
  const handleCheckboxChange = (event) => {
    setRenewableEnergy(event.target.checked);
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      onSave({ Name:name, Email:email, PType: selectedType, Size: size, isRenewable: renewableEnergy});
      onClose();
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Only allow alphabets (A-Z and a-z) and spaces
    if (/^[A-Za-z\s]*$/.test(inputValue)) {
      setName(inputValue);
    }
  };
  const handleCancel = () => {
    // Close the dialog without saving
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Add A Site</DialogTitle>
      <DialogContent>
      <form className={classes.root} onSubmit={handleSave}>
        <TextField
          label="Site Name"
          fullWidth
          required
          margin="normal"
          variant="outlined"
          value={name}
          onChange={(e) => handleNameChange(e)}
        />
        <TextField
          label="Site Admin Email"
          fullWidth
          type="email"
          required
          margin="normal"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Size of Premises (*optional)"
          fullWidth
          type="number"
          margin="normal"
          variant="outlined"
          value={size}
          onChange={(e) => setSize(e.target.value)}
        />
        <TextField
          select
          label="Type (*optional)"
          fullWidth
          margin="normal"
          variant="outlined"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {types.map((t) => (
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
        control={
          <Checkbox
            checked={renewableEnergy}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="100% renewable energy is used? Check this"
      />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Button type="submit" variant="contained" color="primary" style={{ marginRight: '8px' }}>
            Add
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
