import React, { useRef } from "react";
import axios from "axios";
import Toaster from "../../component/Toaster/Toaster";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
// import headers & constants
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import Stack from "@mui/material/Stack";
// import Cancel Presentation Icon
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
// import icon button
import IconButton from "@mui/material/IconButton";
// import snackbar toaster material ui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Card, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
// start stepper component
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
// import icons
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import component
import ReportDataPopUp from "../ReportAssessment/ReportDataPopUp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NotFoundPID from "../../component/NotFoundPID/NotFoundPID";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  overflow: "auto",
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    typography: {
      textAlign: "center",
    },
  })
);

const SignedContract = (props) => {
  const classes = useStyles();
  const isInitialMount = useRef(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [id, setId] = React.useState();
  const [rows, setRows] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [columns, setColumns] = React.useState([]);
  const [otherColumns, setOtherColumns] = React.useState([]);

  const [columnsValues, setColumnsValues] = React.useState([]);
  const [columnsStatusCompleted, setColumnsStatusCompleted] = React.useState(
    []
  ); // status basis hook
  const [columnsStatusCancelled, setColumnsStatusCancelled] = React.useState(
    []
  ); // status basis hook
  const [errormsg, setErrorMsg] = React.useState("");
  const [successmsg, setSuccessMsg] = React.useState("");
  const [selectObj, setSelectObj] = React.useState({});
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table

  const [toaster, setToaster] = React.useState(false);
  const [loadState, setloadState] = React.useState(true);
  const [editPopup, setEditPopup] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState({});
  const [newDetails, setNewDetails] = React.useState({});
  const [detailsState, setDetailsState] = React.useState(false);
  // dialog state
  const [dialogopen, setDialogOpen] = React.useState(false);
  // report state
  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const handleReportOpen = () => setReportModalOpen(true);
  const handleReportClose = () => setReportModalOpen(false);
  // checkbox state
  const [scopeCheckbox, setScopeCheckbox] = React.useState({
    checked: false,
    checkedTwo: false,
    checkedThree: false,
  });

  const [image, setImage] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [cancelOrder, setCancelOrder] = React.useState([]);

  const [reportAssessment, setReportAssessment] = React.useState({
    from: "",
    to: "",
    reportStandard: "",
  });
  // post api employee create state
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // missing PID Check
  const [missingPID, setMissingPID] = React.useState(true);

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const selectReportingStandard = [
    { label: "IPCC", value: "IPCC" },
    { label: "GRI", value: "GRI" },
  ];

  const handleScopeChange = (event) => {
    console.log("got event", scopeCheckbox);
    let ch = scopeCheckbox;
    ch[event.target.name] = event.target.checked ? true : false;
    setScopeCheckbox(ch);
    setCount(count + 1);
  };

  const handleDetailsObj = () => {
    setDetailsState(!detailsState);
  };

  const handleClearToaster = () => {
    setSuccessMsg();
    setErrorMsg();
  };

  // React useEffect Calling here
  React.useEffect(() => {
    console.log(id, "id here");
    console.log(image, "images section");
    console.log(columnsValues, "columnsValues");
    console.log(columns, "columns data here");
    console.log(cookies.profileStatus === "complete", "view details here");
    console.log(count, "count");
    // PID MISSING CHECK
    if (cookies.profileStatus === "complete") {
      setMissingPID(false);
    }
    if (isInitialMount.current) {
      console.log("once");
      getReportData();
      isInitialMount.current = false;
    }
  }, []);

  // handle bar function report
  const handleReportInput = (event, i, name) => {
    let emp = reportAssessment;
    if (i && name) {
      emp[name] = i;
      console.log(emp, "select report input");
    } else {
      const empState = {
        ...reportAssessment,
        [event.target.name]: event.target.value,
      };
      emp = empState;
      console.log(empState, "select report input");
    }
    setReportAssessment(emp);
  };
  const downloadHandle = async (a, b) => {
    console.log("inputs", a, b);
  };

  // call an api to get master data in db
  const getReportData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/chgReport/getReportData/PID`,
        headers
      );
      console.log(data, "get report data");
      let Allcolumns = [];
      let Allcolumns2 = [];

      let Allvalues = [];
      let AddStatus = [];
      let cancelledStatus = [];
      let completedStatus = [];
      for (let j = 0; j < data?.length; j++) {
        for (let key in data[j]) {
          if (
            key !== "__v" &&
            key !== "_id" &&
            key !== "PID" &&
            key !== "NID" &&
            key !== "Remarks" &&
            key !== "attachment" &&
            key !== "user_id" &&
            Allcolumns.indexOf(key) === -1
          ) {
            Allcolumns.push(key);
            Allcolumns2.push(key);
            console.log(key, "key report here");
          }
        }
        setOtherColumns(Allcolumns2);
        if (data[j].status === "completed") {
          completedStatus.push(data[j]);
        } else if (data[j].status === "cancelled") {
          cancelledStatus.push(data[j]);
        } else {
          Allvalues.push(data[j]);
        }
      }

      let temp = Allcolumns;
      temp.push({
        name: "cancel",
        label: "Cancel",
        options: {
          filter: true,
          customBodyRender: (v, tableMeta, updateValue) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center">
                  <Fab
                    size="small"
                    color="primary"
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    aria-label="edit"
                    // onClick={() => handleEdit(data, tableMeta.rowIndex)}
                    onClick={() =>
                      handleCancelReport(
                        value === 0 ? Allvalues : completedStatus,
                        tableMeta.rowIndex
                      )
                    }
                  >
                    <CancelIcon />
                  </Fab>
                </Stack>
              </div>
            </>
          ),
        },
      });
      temp.push({
        name: "download",
        label: "Download",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center">
                  <Fab
                    size="small"
                    color="primary"
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    aria-label="download"
                    onClick={() => downloadHandle(data, tableMeta.rowIndex)}
                  >
                    <DownloadForOfflineIcon />
                  </Fab>
                </Stack>
              </div>
            </>
          ),
        },
      });
      setColumns(temp);
      setColumnsValues(Allvalues);
      setColumnsValues(Allvalues);
      setColumnsStatusCompleted(completedStatus); // status completed basis state
      setColumnsStatusCancelled(cancelledStatus); // status cancelled basis state
      setloadState(false);
    } catch (err) {
      setMissingPID(true); // MISSING PID STATE
      console.log(err);
      console.log(err, "err in report api");
      // setErrorMsg(`failed to fetch data: ${err.message}`);
    }
  };

  const handlebarClick = (obj) => {
    setId(obj.id);
    setEditPopup(!editPopup);
    setSelectObj(obj);
  };

  // const handleDelete = async (dataObj) => {
  //   try {
  //     console.log(props.obj.id, "1obj");
  //     let indexes = [];

  //     for (let o of dataObj) {
  //       console.log(attachmentImage[o.dataIndex], o.dataIndex, "obj");
  //       indexes.push(attachmentImage[o.dataIndex]['Upload Id']);
  //     }
  //     console.log(indexes, " these are indexes");

  //     const response = await axios.delete(API_URL+`/chgReport/detach/${props.obj.id}`, {
  //       ...headers,data: { attachments: indexes },
  //     });
  //     console.log('this is response', response.data)
  //   }catch(e){
  //     console.log(e,'this is e')
  //   }
  // }

  const options = {
    filterType: "checkbox",
    print: false,
    onChangeRowsPerPage: (numberOfRows) => {
      console.log(numberOfRows, "num");
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    onRowSelectionChange: (k, l, selectedRow) => {
      setSelectedRow(selectedRow);
      let selectTemp = [];
      for (let i = 0; i < selectedRow.length; i++) {
        selectTemp.push(rows[selectedRow[i]]);
      }
      setSelectedRowObj(selectTemp);
    },
    selectedRow: selectedRow,
    onCellClick: async (d, rowState) => {
      if (rowState.colIndex < columns.length - 2) {
        let detailObj = {};
        if (value === 0) {
          detailObj = columnsValues[rowState.dataIndex];
        } else if (value === 1) {
          detailObj = columnsStatusCompleted[rowState.dataIndex];
        } else if (value === 2) {
          detailObj = columnsStatusCancelled[rowState.dataIndex];
        } else {
          return;
        }

        setViewDetails(detailObj);
        setNewDetails(detailObj);
        handleDetailsObj();
      }
    },
    // onRowsDelete: async (e) => {
    //   console.log(e, "e");
    //   await deleteMasterDataBatch(e.data);
    // },
  };

  //HEADERS
  const header = HEADERS2(cookies.token, cookies.Nid);

  // call an api
  const insertReport = async () => {
    try {
      let scopesC = [];
      if (scopeCheckbox.checked) {
        scopesC.push("Scope1");
      }
      if (scopeCheckbox.checkedTwo) {
        scopesC.push("Scope2");
      }
      if (scopeCheckbox.checkedThree) {
        scopesC.push("Scope3");
      }
      const insertBody = {
        NID: "",
        PID: "",
        user_id: cookies.user_id,
        From: reportAssessment.from,
        To: reportAssessment.to,
        // attachment: image,
        scopes: scopesC, // object
        reportStandard: reportAssessment.reportStandard, // object
      };
      const carbonFootprint = await axios.post(
        API_URL + `/chgReport/create`,
        insertBody,
        header
      );
      console.log(insertBody, "body entered successfully");
      if (!carbonFootprint.data) {
        setErrorMsg("Report request could not be created");
        setToaster(true);
      } else {
        setReportModalOpen(false);
        setSuccessMsg("Report request successfully created");
        setToaster(true);
        getReportData(); // call an get report api
      }
    } catch (err) {
      console.log(err, "h33lo3");

      console.log(err, "error in carbon footprint api");
    }
  };

  // handle Cancel Report function update api setCancel Report
  const handleCancelReport = async (dataObj, index) => {
    try {
      let obj = dataObj[index];
      console.log(dataObj[index], "I am report");
      // let poOrder = selectObj.row;
      obj.status = "cancelled";
      const { data } = await axios.put(
        API_URL + `/chgReport/update`,
        obj,
        headers
      );
      console.log(data, "update data");
      if (!data) {
        // setCancelOrder([]);
        setToaster(true);
        setErrorMsg("Report could not be cancelled at this stage");
      } else {
        // setCancelOrder(data);
        handleReportClose(); // close modal overlay
        // getReportData(); // call an get report api
        setCount(count + 1);
        setToaster(true);
        setSuccessMsg("Report Request cancelled");
      }
    } catch (err) {
      console.log(err, "err in cancel order");
      setErrorMsg(`Sorry, something went wrong, ${err.message}`);
      setToaster(true);
    }
  };

  // stepper ends here

  // footprint calculator ends here

  return (
    <React.Fragment>
      {missingPID && <NotFoundPID />}
      {loadState && <Toaster msg={"Fetching real-time data"} />}
      <div>
        <Modal
          open={reportModalOpen}
          onClose={handleReportClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto", float: "right" }}
              onClick={handleReportClose}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>

            <Typography
              style={{
                marginTop: "1rem",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <strong>Request New Assessment</strong>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "600px",
                  marginBottom: "1rem",
                }}
              >
                {/* // PID, NID, year, month, scope1, scope2, scope3, total */}
                <TextField
                  id="outlined-basic"
                  label="From"
                  variant="outlined"
                  name="from"
                  type="date"
                  InputLabelProps={{ shrink: true, required: true }}
                  inputProps={{
                    max: new Date().toISOString().substring(0, 10),
                  }}
                  style={{ flexBasis: "48%" }}
                  value={reportAssessment.from}
                  onChange={handleReportInput}
                />
                <TextField
                  id="outlined-basic"
                  label="To"
                  InputLabelProps={{ shrink: true, required: true }}
                  variant="outlined"
                  name="to"
                  type="date"
                  inputProps={{ min: reportAssessment.from }}
                  style={{ flexBasis: "48%" }}
                  value={reportAssessment.to}
                  onChange={handleReportInput}
                />
              </div>

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="reportStandard"
                onChange={(event, newValue) => {
                  console.log(newValue, "report standard Value here");
                  handleReportInput(event, newValue.value, "reportStandard");
                }}
                options={selectReportingStandard}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Reporting Type" />
                )}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "600px",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <Checkbox
                  name="checked"
                  checked={scopeCheckbox.checked}
                  onChange={handleScopeChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography style={{ marginTop: "10px" }}>Scope 1</Typography>
                <Checkbox
                  name="checkedTwo"
                  checked={scopeCheckbox.checkedTwo}
                  onChange={handleScopeChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography style={{ marginTop: "10px" }}>Scope 2</Typography>
                <Checkbox
                  name="checkedThree"
                  checked={scopeCheckbox.checkedThree}
                  onChange={handleScopeChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography style={{ marginTop: "10px" }}>Scope 3</Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2rem",
                width: "90%",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={insertReport}
              >
                Request Validation
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={handleReportClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {/* tabs panel start here */}
      {/* <Paper className={classes.root}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              // style={{ backgroundColor: "black" }}
              // TabIndicatorProps={{ style: { backgroundColor: "white" } }}
            >
              <Tab
                label="Requested"
                {...a11yProps(0)}
                // style={{ color: "#fff" }}
              />
              <Tab
                label="Completed"
                {...a11yProps(1)}
                // style={{ color: "#fff" }}
              />
              <Tab
                label="Cancelled"
                {...a11yProps(2)}
                // style={{ color: "#fff" }}
              />
            </Tabs>
          </Box>
        </Box>
      </Paper> */}

      <TabPanel value={value} index={0}>
        {/* tabs panel ends here */}
        <div style={{ borderRadius:"14px" }}>
        {columnsValues && (
          <MUIDataTable
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="body1" style={{ marginTop: "1rem" }}>
                    <strong>Signed Contracts</strong>
                  </Typography>
                  <Button
                    style={{
                      margin: "10px",
                      // backgroundColor: "black",
                      color: "white",
                    }}
                    variant="contained"
                    onClick={handleReportOpen}
                  >
                    New Contract
                  </Button>
                  <Fab
                    onClick={getReportData}
                    size="small"
                    // color="#0d6efd" //@TODO change the colour
                    aria-label="refresh"
                    style={{
                      margin: "10px",
                      color: "#fff",
                      backgroundColor: "#003E35",
                    }}
                  >
                    <RefreshIcon />
                  </Fab>
                </div>
              </>
            }
            data={columnsValues}
            columns={columns}
            options={options}
          />
        )}
        </div>

        {value === 0 && (
          <div>
            <Modal
              open={detailsState}
              onClose={handleDetailsObj}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ textAlign: "center" }}
            >
              <Box sx={style}>
                <ReportDataPopUp
                  close={handleDetailsObj}
                  obj={viewDetails}
                  id={id}
                  load={getReportData}
                  click={handlebarClick}
                />
              </Box>
            </Modal>
          </div>
        )}
        {/* dailog box component start here */}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {columnsStatusCompleted && (
          <MUIDataTable
            title={<></>}
            data={columnsStatusCompleted}
            columns={otherColumns}
            options={options}
          />
        )}
        {value === 1 && (
          <div>
            <Modal
              open={detailsState}
              onClose={handleDetailsObj}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ textAlign: "center" }}
            >
              <Box sx={style}>
                <ReportDataPopUp
                  close={handleDetailsObj}
                  obj={viewDetails}
                  id={id}
                  load={getReportData}
                  click={handlebarClick}
                />
              </Box>
            </Modal>
          </div>
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {columnsStatusCancelled && (
          <>
            <MUIDataTable
              title={<></>}
              data={columnsStatusCancelled}
              columns={otherColumns}
              options={options}
            />
          </>
        )}
      </TabPanel>

      {/* error & success toaster toaster */}
      {(errormsg || successmsg) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={toaster}
            autoHideDuration={4000}
            onClose={handleClearToaster}
          >
            <Alert
              onClose={handleClearToaster}
              severity={errormsg ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default SignedContract;
