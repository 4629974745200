import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Card,
} from "@mui/material";
import TextField from "@mui/material/TextField";
// import icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// radio button
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
// import Create new Invoice Start here
import Stack from "@mui/material/Stack";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import Icon
import DangerousIcon from "@mui/icons-material/Dangerous";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { ArrowDownwardOutlined } from "@material-ui/icons";
// import image
// import LeafImage from "../assets/images/LeafImage.png";
// import Progress Bar
import { styled } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const selectMeterSquare = [
  { label: "sq mt", value: "sq mt" },
  { label: "sq ft", value: "sq ft" },
];

const selectExpenseCurrency = [
  { label: "₹ (INR)", value: "INR" },
  { label: "$ (USD)", value: "USD" },
  { label: "€ (EURO)", value: "EURO" },
];

const travelMode = [
  { label: "Flights", value: "Flights" },
  { label: "Land Travel", value: "Land travel" },
  { label: "Sea Travel", value: "Sea travel" },
];
const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: "#000000",
  },
  stepLabel: {
    color: "#ffffff",
  },
  customAccordion: {
    backgroundColor: "#003E35",
    color: "red", // Change this color to your desired background color
  },
  // stepIcon: {
  //   color: "#cccc", // Gray color for inactive step text
  //   "&$active": {
  //     color: "#ffffff", // White color for active step text
  //   },
  //   "&$completed": {
  //     color: "#ffffff", // White color for completed step text
  //   },
  // },
  stepIcon: {
    color: "#cccc", // Gray color for inactive dots
  },
  active: {
    color: "#ffffff !important", // White color for active step text
  },
  completed: {
    color: "#ffffff !important", // White color for active step text
  },
}));

function CompanyStepper(props) {
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["user"]);
  const [activeStep, setActiveStep] = useState(0);
  const [subStep, setSubStep] = React.useState(cookies.terms ? 2 : 0);
  const [count, setCount] = React.useState(0);
  const [expanded, setExpanded] = useState(null);
  const [companySiteValue, setCompanySiteValue] = React.useState([]);
  const [Participant, setParticipant] = React.useState({});
  const MachineryState = {
    Diesel: { Type: "", Quantity: "", Unit: "", SiteID: "" },
    Gasoline: { Type: "", Quantity: "", Unit: "", SiteID: "" },
    LNG: { Type: "", Quantity: "", Unit: "", SiteID: "" },
    LPG: { Type: "", Quantity: "", Unit: "", SiteID: "" },
    Petrol: { Type: "", Quantity: "", Unit: "", SiteID: "" },
  };
  const [state, setState] = React.useState({
    Sites: [],
    Machinery: [],
    Fuel: {
      Diesel: { Quantity: "", Unit: "", SiteID: "" },
      Gasoline: { Quantity: "", Unit: "", SiteID: "" },
      LNG: { Quantity: "", Unit: "", SiteID: "" },
      LPG: { Quantity: "", Unit: "", SiteID: "" },
      Petrol: { Quantity: "", Unit: "", SiteID: "" },
    },
    From: "",
    To: "",
    Revenue: 0,
    Expenses: 0,
    Currency: "INR",
    Employees: 0,
    BusinessTravel: [],
    HotelStay: [],
    Transport: [],
    PurchasedGoods: [],
    WasteTreatment: [],
  });
  const [regions, setRegions] = React.useState([]); // api state
  const [fuelStepperState, setFuelStepperState] = React.useState({
    companyLeases: "",
    // petrolLitres: "",
    diesel: "",
    Gasoline: "",
    LNG: "",
    LPG: "",
    companyVehicle: "",
    companyMachinery: "",
    companyDiesel: "",
    electricityName: "",
    electricitySize: "",
    fuelName: "",
    heatingName: "",
    heatingNameInfo: "",
    // companyLitres: "",
    quantity: "",
    garageLitres: "",
    meterSquare: "",
    warehouseOptions: "",
    electricityConsume: "",
    electricityDropdown: "",
    electricityOptions: "",
    electricityBills: "",
    companyHeating: "",
    electricityHeating: "",
    heatingConsume: "",
    heatingDropdown: "",
    heatingOptions: "",
  });
  // const [expensesStepperState, setExpensesStepperState] = React.useState({
  //   totalExpenses: "",
  //   expensesDropdown: "",
  //   expensesCategories: "",
  //   businessExpenses: "",
  //   businessDropdown: "",
  //   transportExpenses: "",
  //   transportDropdown: "",
  //   materialExpenses: "",
  //   materialDropdown: "",
  //   furnitureExpenses: "",
  //   furnitureDropdown: "",
  //   placticExpenses: "",
  //   placticDropdown: "",
  //   metalExpenses: "",
  //   metalDropdown: "",
  //   capitalExpenses: "",
  //   capitalDropdown: "",
  // });
  const [expensesStepperInfo, setExpensesStepperInfo] = React.useState([
    {
      activity: "",
      subActivity: "",
      unit: "",
      cost: "",
      quantity: "",
    },
  ]);
  const [expensesHotelInfo, setExpenesHotelInfo] = React.useState([
    {
      activity: "Hotel Stay",
      numberDaysOfStay: "",
      expenses: "",
    },
  ]);
  const [transportFreightInfo, setTransportFreightInfo] = React.useState([
    {
      region: "",
      activity: "",
      subActivity: "",
      unit: "",
      cost: "",
      quantity: "",
    },
  ]);
  const [materialInventoryInfo, setMaterialInventoryInfo] = React.useState([
    {
      site: "",
      activity: "",
      subActivity: "",
      unit: "",
      cost: "",
      quantity: "",
    },
  ]);
  const [officeEquipmentsInfo, setOfficeEquipmentsInfo] = React.useState([
    {
      activity: "",
      subActivity: "",
      unit: "",
      cost: "",
      quantity: "",
    },
  ]);
  const [wasteTreatmentStepperInfo, setWasteTreatmentStepperInfo] =
    React.useState([
      {
        site: "",
        activity: "",
        subActivity: "",
        unit: "",
        cost: "",
        quantity: "",
      },
    ]);
  const [emissionData, setEmissionData] = React.useState([]); // emission api state
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [siteLength, setSiteLength] = React.useState(0);
  const [travelActivities, setTravelActivities] = React.useState([]);
  const [hotelActivities, setHotelActivities] = React.useState([]);
  const [transportActivities, setTransportActivities] = React.useState([]);
  const [materialActivities, setMaterialActivities] = React.useState([]);
  const [officeEquipmentActivities, setOfficeEquipmentActivities] =
    React.useState([]);
  const [wasteTreatmentActivities, setWasteTreatmentActivities] =
    React.useState([]);

  const [purchaseSubActivities, setPurchaseSubActivities] = React.useState([]);
  const [purchaseActivities, setPurchaseActivities] = React.useState([]);
  const [materialSubActivities, setMaterialSubActivities] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState("India");
  const [opens, setOpens] = React.useState(false);
  const [inventoryData, setInventoryData] = React.useState([]);
  const [transportData, setTransportData] = React.useState([]);
  const [wasteData, setWasteData] = React.useState([]);
  // balance state
  const [balance, setBalance] = React.useState(0);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // Headers
  const headers = HEADERS2(cookies.token, cookies.Nid);

  // handle balance function
  const handleBalance = () => {
    const totalExpenseAmount =
      expensesStepperInfo.cost +
      transportFreightInfo.cost +
      materialInventoryInfo.cost +
      wasteTreatmentStepperInfo.cost;
    if (totalExpenseAmount > state.Expenses) {
      setError("Expense should not be greater than Purchase Expense");
      setOpens(true);
    } else {
      const totalBalance = state.Expenses - totalExpenseAmount;
      setBalance(totalBalance);
    }
  };

  const generateTextFields = (event) => {
    const count = parseInt(event.target.value, 10) || 0;
    setSiteLength(count);
    let sites = [];
    for (let i = 0; i < count; i++) {
      sites.push({
        id: i,
        isRenewable: false,
        name: "",
        size: "",
        region: "",
        machinery: 0,
        fuel: 0,
        electricity: 0,
        heating: 0,
      });
    }
    setState({ ...state, Sites: sites });
    setCompanySiteValue(sites);
  };

  // const handleSubmit = () => {
  //   let s = { ...state };
  //   s.BusinessTravel = expensesStepperInfo;
  //   s.HotelStay = expensesHotelInfo;
  //   s.PurchasedGoods = materialInventoryInfo;
  //   s.WasteTreatment = wasteTreatmentStepperInfo;
  //   s.Transport = transportFreightInfo;
  //   // history.push(`/reports`); // navigate tracker page
  // };

  const handleHandover = () => {
    setSubStep(0);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleHandoverBack = () => {
    setSubStep(0);
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubBack = () => {
    setSubStep((prevStep) => prevStep - 1);
  };

  const handleSubNext = () => {
    setSubStep((prevStep) => prevStep + 1);
  };

  // HANDLE NEXT QUESTIONS
  const handleNextQuestions = () => {
    setSubStep((prevStep) => prevStep + 1);
  };

  // HANDLE BACK QUESTIONS
  const handleBackQuestions = () => {
    setSubStep((prevStep) => prevStep + 2);
  };

  const steps = ["Company Data", "Fuel & Energy", "Expenses"];

  const handleStateChange = (event, name, value) => {
    let newState = { ...state };
    newState[name ?? event.target.name] = value ?? event.target.value;
    setState(newState);
  };

  // handle bar Fuel Stepper 2
  const handleFuelStepperInput = (event, i, name) => {
    let fuelStepper = fuelStepperState;
    if (i && name) {
      fuelStepper[name] = i;
    } else {
      const fuelState = {
        ...fuelStepperState,
        [event.target.name]: event.target.value,
      };
      fuelStepper = fuelState;
      setFuelStepperState(fuelState);
    }
  };

  const handleScopes = (event, type, idx, i) => {
    if (type === "Machinery") {
      // let machinery = [...MachineryState]
      let fuelStepper = { ...state };
      let machinery = fuelStepper.Machinery;
      if (!machinery[i]) {
        machinery[i] = MachineryState;
      }
      machinery[i][event.target.name] = {
        Quantity: event.target.value,
        Unit: "Liters",
        SiteID: idx ?? "",
      };
      // setMachineryState(machinery)
      // let fuelStepper = { ...state };
      fuelStepper.Machinery = machinery;
      setState(fuelStepper);
    } else {
      let fuelStepper = { ...state };
      fuelStepper[type][event.target.name] = {
        Quantity: event.target.value,
        Unit: "Liters",
        SiteID: idx ?? "",
      };
      setState(fuelStepper);
    }
    setCount(count + 1);
  };

  // handle bar Fuel Stepper 2
  const handleSites = (event, i) => {
    let st = companySiteValue;
    let value = event.target.value;
    if (event.target.name === "isRenewable") {
      value = event.target.value === "true" ? true : false;
    }
    st[i][event.target.name] = value;
    setCompanySiteValue(st);
    setCount(count + 1);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  // handle bar Expenses Stepper 3
  // const handleExpensesStepperInput = (event, i, name) => {
  //   let expensesStepper = expensesStepperState;
  //   if (i && name) {
  //     expensesStepper[name] = i;
  //   } else {
  //     const expensesState = {
  //       ...expensesStepperState,
  //       [event.target.name]: event.target.value,
  //     };
  //     expensesStepper = expensesState;
  //     setExpensesStepperState(expensesState);
  //   }
  // };

  const handleExpensesStepperInfo = (event, i, name, v) => {
    let expensesStepper = expensesStepperInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      if (name === "activity") {
        handleDropdownsTravelActivity(v, selectedRegion);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
      setCount(count + 1);
    }
    setExpensesStepperInfo(expensesStepper);
  };

  const addExpensesFormFields = () => {
    setExpensesStepperInfo([
      ...expensesStepperInfo,
      {
        activity: "",
        subActivity: "",
        unit: "",
        quantity: "",
        cost: "",
      },
    ]);
  };

  const removeExpensesFormFields = (i) => {
    let newFormValues = [...expensesStepperInfo];
    newFormValues.splice(i, 1);
    setExpensesStepperInfo(newFormValues);
  };

  const handleHotelStepperInfo = (event, i, name, v) => {
    let expensesStepper = expensesHotelInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      if (name === "location") {
        handleDropdownsHotelActivity(v, selectedRegion);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
      setCount(count + 1);
    }
    setExpenesHotelInfo(expensesStepper);
  };

  const addExpensesHotelFormFields = () => {
    setExpenesHotelInfo([
      ...expensesHotelInfo,
      {
        activity: "Hotel Stay",
        region: "",
        numberDaysOfStay: "",
        expenses: "",
      },
    ]);
  };

  const removeExpensesHotelFormFields = (i) => {
    let newFormValues = [...expensesHotelInfo];
    newFormValues.splice(i, 1);
    setExpenesHotelInfo(newFormValues);
  };

  // handle bar Transport Stepper Info
  // const handleTransportStepperInfo = (event, i, name) => {
  //   let expensesStepper = transportFreightInfo;
  //   if (i && name) {
  //     expensesStepper[name] = i;
  //   } else {
  //     const tranportFrightStateInfo = {
  //       ...transportFreightInfo,
  //       [event.target.name]: event.target.value,
  //     };
  //     expensesStepper = tranportFrightStateInfo;
  //     setExpenesHotelInfo(tranportFrightStateInfo);
  //   }
  // };

  const handleTransportStepperInfo = (event, i, name, v) => {
    let expensesStepper = transportFreightInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      // if (name === "transport") {
      //   handleDropdownsTransportActivity(v, selectedRegion);
      // }
      if (name === "activity") {
        handleDropdownsTransportActivity(selectedRegion, v);
      } else if (name === "subActivity") {
        
        handleDropdownsTransportActivity(
          selectedRegion,
          expensesStepper.activity,
          v
        );
      } else {
        handleDropdownsTransportActivity(selectedRegion);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
      setCount(count + 1);
    }
    setTransportFreightInfo(expensesStepper);
  };

  const addTransportFreightFormFields = () => {
    setTransportFreightInfo([
      ...transportFreightInfo,
      {
        region: "",
        activity: "",
        subActivity: "",
        unit: "",
        cost: "",
        quantity: "",
      },
    ]);
  };

  const removeTransportFreightFormFields = (i) => {
    let newFormValues = [...transportFreightInfo];
    newFormValues.splice(i, 1);
    setTransportFreightInfo(newFormValues);
  };

  const handleMaterialStepperInfo = (event, i, name, v) => {
    let expensesStepper = materialInventoryInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      //@TODO
      if (name === "activity") {
        handleDropdownsMaterialActivity(selectedRegion, v);
        setCount(count + 1);
      } else if (name === "subActivity") {
       
        handleDropdownsMaterialActivity(
          selectedRegion,
          expensesStepper.activity,
          v
        );
        setCount(count + 1);
      } else {
        handleDropdownsMaterialActivity(selectedRegion);
        setCount(count + 1);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
      setCount(count + 1);
    }
    setMaterialInventoryInfo(expensesStepper);
  };

  const addMaterialFormFields = () => {
    setMaterialInventoryInfo([
      ...materialInventoryInfo,
      {
        site: "",
        activity: "",
        subActivity: "",
        unit: "",
        cost: "",
        quantity: "",
      },
    ]);
  };

  const removeMaterialFormFields = (i) => {
    let newFormValues = [...materialInventoryInfo];
    newFormValues.splice(i, 1);
    setMaterialInventoryInfo(newFormValues);
  };

  const handleOfficeEquipmentStepperInfo = (event, i, name, v) => {
    let expensesStepper = officeEquipmentsInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      if (name === "transport") {
        handleDropdownsOfficeEquiments(v, selectedRegion);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
    }
    setOfficeEquipmentsInfo(expensesStepper);
  };

  const addFurnitureFormFields = () => {
    setOfficeEquipmentsInfo([
      ...officeEquipmentsInfo,
      {
        activity: "",
        subActivity: "",
        unit: "",
        cost: "",
        quantity: "",
      },
    ]);
  };

  const removeFurnitureFormFields = (i) => {
    let newFormValues = [...officeEquipmentsInfo];
    newFormValues.splice(i, 1);
    setOfficeEquipmentsInfo(newFormValues);
  };

  const handleWasteTreatmentStepperInfo = (event, i, name, v) => {
    let expensesStepper = wasteTreatmentStepperInfo;
    if (name && v) {
      expensesStepper[i][name] = v;
      
      if (name === "activity") {
        handleDropdownsWasteTreatment(selectedRegion, v);
      } else if (name === "subActivity") {
       
        handleDropdownsWasteTreatment(
          selectedRegion,
          expensesStepper.activity,
          v
        );
      } else {
        handleDropdownsWasteTreatment(selectedRegion);
      }
    } else {
      expensesStepper[i][event.target.name] = event.target.value;
      setCount(count + 1);
    }
    setWasteTreatmentStepperInfo(expensesStepper);
  };

  const addWasteTreatmentFormFields = () => {
    setWasteTreatmentStepperInfo([
      ...wasteTreatmentStepperInfo,
      {
        site: "",
        activity: "",
        subActivity: "",
        unit: "",
        cost: "",
        quantity: "",
      },
    ]);
  };

  const removeWasteTreatmentFormFields = (i) => {
    let newFormValues = [...wasteTreatmentStepperInfo];
    newFormValues.splice(i, 1);
    setWasteTreatmentStepperInfo(newFormValues);
  };

  const handleDropdownsTravelActivity = async (mode, region) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Business travel",
        activity: mode,
        region: region,
      };

      const scope = await getDropdownsDatas(query);
      setTravelActivities(scope);
    } catch (err) {
      console.log(err, "err in all get emission");
    }
  };

  const handleDropdownsHotelActivity = async (mode, region) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Hotel Stay",
        location: mode,
        region: region,
      };
      const scope = await getDropdownsDatas(query);
      setHotelActivities(scope);
    } catch (err) {
      console.log(err, "err hotel dropdown in all get emission");
    }
  };

  const handleDropdownsTransportActivity = async (
    region,
    details,
    subDetails
  ) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Upstream transportation and distribution",
        region: region,
      };
      if (details) {
        query["activity"] = details;
        if (subDetails) {
          query["subActivity"] = subDetails;
          const scope = await getDropdownsDatas(query);
          setMaterialSubActivities(scope);
        } else {
          const scope = await getDropdownsDatas(query);
          setTransportActivities(scope);
        }
      } else {
        const scope = await getDropdownsDatas(query);
        setTransportData(scope);
      }
    } catch (err) {
      console.log(err, "err transport dropdown in all get emission");
    }
  };

  const handleDropdownsMaterialActivity = async (
    region,
    details,
    subDetails
  ) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Purchased goods and services",
        region: region,
      };
      if (details) {
        query["activity"] = details;
        if (subDetails) {
          query["subActivity"] = subDetails;
          const scope = await getDropdownsDatas(query);
          setMaterialSubActivities(scope);
        } else {
          const scope = await getDropdownsDatas(query);
          setMaterialActivities(scope);
        }
      } else {
        const scope = await getDropdownsDatas(query);
        setInventoryData(scope);
      }
    } catch (err) {
      console.log(err, "err transport dropdown in all get emission");
    }
  };

  const handleDropdownsOfficeEquiments = async (mode, region) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Purchased goods and services",
        location: mode,
        region: region,
      };
      const scope = await getDropdownsDatas(query);
      setOfficeEquipmentActivities(scope);
    } catch (err) {
      console.log(err, "err transport dropdown in all get emission");
    }
  };

  const handleDropdownsWasteTreatment = async (region, details, subDetails) => {
    try {
      let query = {
        scope: "Scope 3",
        category: "Waste generated in operations",
        region: region,
      };
      if (details) {
        query["activity"] = details;
        if (subDetails) {
          query["subActivity"] = subDetails;
          const scope = await getDropdownsDatas(query);
          setMaterialSubActivities(scope);
        } else {
          const scope = await getDropdownsDatas(query);
          setWasteTreatmentActivities(scope);
        }
      } else {
        const scope = await getDropdownsDatas(query);
        setWasteData(scope);
      }
     
    } catch (err) {
      console.log(err, "err transport dropdown in all get emission");
    }
  };


  // get api
  const getDropdownsDatas = async (obj) => {
    try {
      // let obj={region, scope,category, activity, subActivity}
      const { data } = await axios.post(API_URL + `/ef/get-data`, obj, headers);
      if (!data) {
        return [];
      } else {
        return data.data;
      }
    } catch (err) {
      console.log(err, "err in all get emission");
    }
  };
  // call an api to get regions
  const getCompanyRegions = async () => {
    try {
      const { data } = await axios.get(API_URL + `/ef/region/get`, headers);
      if (!data) {
        setRegions([]);
      } else {
        setRegions(data.regions);
      }
    } catch (err) {
      console.log(err, "err in api");
    }
  };
  // React useEffect
  React.useEffect(() => {
    getParticipant();
    setOpens(true);
    getCompanyRegions();
    if (props.state && count === 0) {
      setState(props.state);
      setExpensesStepperInfo(props.state.BusinessTravel);
      setExpenesHotelInfo(props.state.HotelStay);
      setMaterialInventoryInfo(props.state.PurchasedGoods);
      setWasteTreatmentStepperInfo(props.state.WasteTreatment);
      setTransportFreightInfo(props.state.Transport);
      setSiteLength(props.state.Sites?.length);
      setCompanySiteValue(props.state.Sites);
    }
  }, [state, count]);

  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  // call an post api to create invoice
  const createCompanyData = async () => {
    try {
      if (!state.BusinessTravel) {
        setError("Business Travel Field is Required");
        setOpens(true);
      } else if (!state.HotelStay) {
        setError("Hotel Stay Field is Required");
        setOpens(true);
      } else if (!state.PurchasedGoods) {
        setError("Purchase Goods Field is Required");
        setOpens(true);
      } else if (!state.WasteTreatment) {
        setError("Waste Treatment Field is Required");
        setOpens(true);
      } else if (!state.Transport) {
        setError("Transport Field is Required");
        setOpens(true);
      }

      const insertCompanyData = {
        PID: Participant.PID, // Replace with the actual participant ID
        Sites: state.Sites, // Replace with the actual site ID
        activity: "activity_type", // Type of activity (heating, fuel, purchasedGoods, electricity, expense)
        subActivity: "sub_activity_type", // Name of the sub-activity (optional)
        scope: "Scope1", // Scope of emissions (Scope1, Scope2, Scope3)
        category: "category_name", // Category of emissions (e.g., direct, indirect, purchasedGoods)
        sourceQuantity: 100, // Unified source quantity (e.g., heatingAmount, quantityConsumed, quantity, consumption)
        sourceType: "source_type", // Unified source type (e.g., fuelType, productCategory)
        expense: state.Expenses, // Unified expense (e.g., cost, amount)
        emissionFactor: 0.5, // Emission factor (for expense activity)
        From: state.From, // Start date
        To: state.To, // End date,
        BusinessTravel: expensesStepperInfo,
        HotelStay: expensesHotelInfo,
        PurchasedGoods: materialInventoryInfo,
        WasteTreatment: wasteTreatmentStepperInfo,
        Transport: transportFreightInfo,
        Employees: Number(state.Employees || 0),
      };
      // For saving body of calculator
      const data = await axios.post(
        API_URL + "/ef/selfAssessment",
        insertCompanyData,
        headers
      );

      if (data.error) {
        setOpens(true);
        setError("Data is not Transfered due to an Error!");
      } else {
        // setInvoiceDatas(data);
        setOpens(true);
        setError(null);
        setSuccess("Company Data is Created Successfully");
      }
    } catch (err) {
      setOpens(true);
      setError("Data is not Transfered due to an Error!");
      console.log(err, "err in invoice api");
    }
  };
  const getParticipant = async () => {
    try {
      const { data } = await axios.get(API_URL + `/participant/id`, headers);
      if (!data) {
        setParticipant([]);
      } else {
        setParticipant(data);
      }
    } catch (err) {
      console.log("err in participant by id api");
    }
  };
  const handleSubmit = () => {
    createCompanyData(); // call an post api
    //history.push(`/selfReport`); // navigate tracker page
    setTimeout( ()=> {
      window.location.href = '/selfAsessment';
    }, "1000");
  };
  const handleChange = (ranges) => {
    setDate(ranges.selection);
    let newState = { ...state };
    newState.From = moment(ranges.selection.startDate).format("DD/MM/YYYY");
    newState.To = moment(ranges.selection.startDate).format("DD/MM/YYYY");
    setState(newState);
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            {subStep === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "left",
                  padding: "20px",
                }}
              >
                <h1>Emissions estimates are all about the data</h1>
                <Typography>Here’s what you’ll need to gather:</Typography>
                <Typography>
                  1. Number of employees, size of facilities, annual revenue{" "}
                </Typography>
                <Typography>
                  2. Company expenses in areas like business travel, transport /
                  freight, capital goods and more
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ marginRight: "20px", marginTop: "1rem" }}
                    onClick={props.goBackPage}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginRight: "20px", marginTop: "1rem" }}
                    onClick={handleSubNext}
                  >
                    Next <ArrowForwardIcon />
                  </Button>
                </div>
                {/* <img
                  style={{ width: "100%", height: "400px", marginTop: "1rem" }}
                  src={LeafImage}
                  alt="company_logo"
                /> */}
              </div>
            )}

            {/* second card start here */}
            {subStep === 1 && (
              <Card
                style={{
                  marginTop: "2rem",
                  backgroundColor: "#000000",
                  color: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>1.</h1>
                    <div>
                      <Typography variant="h5">
                        Thorough means accurate
                      </Typography>
                      <Typography variant="body2">
                        No need to be perfect, but the more data you put into
                        the calculator, the more accurate and credible your
                        emissions estimate will be.
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>2.</h1>
                    <div>
                      <Typography variant="h5">
                        Nothing extra, it all factors in
                      </Typography>
                      <Typography variant="body2">
                        Votan's comprehensive emissions engine will attach CO2
                        equivalents to the business expenses you provide (large
                        and small). All data that we ask for either factors into
                        your overall emissions estimate or industry
                        benchmarking.
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>3.</h1>
                    <div>
                      <Typography variant="h5">
                        Save the planet, we’ll save your data
                      </Typography>
                      <Typography variant="body2">
                        Your profile saves as you go, so you can pick up where
                        you left off or edit what you previously entered.
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ marginLeft: "20px", marginTop: "1rem" }}
                    onClick={handleSubBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginRight: "20px", marginTop: "1rem" }}
                    onClick={handleSubNext}
                  >
                    Start <ArrowForwardIcon />
                  </Button>
                </div>
                {/* <img
                  style={{ width: "100%", height: "400px", marginTop: "1rem" }}
                  src={LeafImage}
                  alt="company_logo"
                /> */}
              </Card>
            )}
            {/* second card ends here */}

            {/*third card start here  */}
            {subStep === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "75vh",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    height: "20%",
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "#fff",
                  }}
                >
                  <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                    Time Period
                  </h1>
                  <Typography>
                    What is the time period you want to calculate emissions for?
                  </Typography>
                </Card>
                <Card
                  style={{
                    height: "55%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "1rem",
                      height: "20%",
                      justifyContent: "center",
                    }}
                  >
                    {/* <TextField
                      name="From"
                      label="From"
                      type="date"
                      style={{ width: "200px" }}
                      value={state.From || ""}
                      inputProps={{
                        max: new Date().toISOString().substring(0, 10),
                      }}
                      onChange={handleStateChange}
                      InputLabelProps={{ shrink: true, required: true }}
                    />
                    <TextField
                      name="To"
                      label="To"
                      type="date"
                      inputProps={{
                        min: state.From,
                        max: new Date().toISOString().substring(0, 10),
                      }}
                      style={{ marginLeft: "1rem", width: "200px" }}
                      value={state.To || ""}
                      onChange={handleStateChange}
                      InputLabelProps={{ shrink: true, required: true }}
                    /> */}
                    <div className="container">
                      <span className="calender"></span>
                      <DateRangePicker
                        className="dateRange"
                        onChange={handleChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[date]}
                        maxDate={new Date()}
                        direction="horizontal"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                      />
                      ;
                    </div>
                  </div>
                </Card>
                <Card
                  style={{
                    height: "15%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "10px",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: "20px",
                        marginTop: "1rem",
                        width: "100px",
                      }}
                      onClick={handleSubBack}
                    >
                      Back
                    </Button>

                    <Button
                      disabled={state.From && state.To ? false : true}
                      variant="contained"
                      style={{ marginLeft: "20px", marginTop: "1rem" }}
                      onClick={handleSubNext}
                    >
                      Next Question <ArrowForwardIcon />
                    </Button>
                  </div>
                </Card>
              </div>
            )}
            {/* third card ends here */}

            {/* fourth card start here */}
            {subStep === 3 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "85vh",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    height: "20%",
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "#fff",
                  }}
                >
                  <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                    Employees
                  </h1>
                  {(state.From || state.To) && (
                    <Typography>
                      From {state.From} to {state.To}
                    </Typography>
                  )}
                  <Typography>
                    How many people worked at your company at the end of this
                    time period?
                  </Typography>
                </Card>
                <Card
                  style={{
                    height: "70%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "1rem",
                      height: "50%",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      name="Employees"
                      label="Employee Strength"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      style={{ marginTop: "1rem" }}
                      value={state.Employees || ""}
                      onChange={handleStateChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      height: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        width: "100px",
                      }}
                      onClick={handleSubBack}
                    >
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      style={{ marginLeft: "20px" }}
                      disabled={state.Employees ? false : true}
                      onClick={handleSubNext}
                    >
                      Next Question <ArrowForwardIcon />
                    </Button>
                  </div>
                </Card>
              </div>
            )}
            {/* fourth card ends here */}

            {/* fifth card start here */}
            {subStep === 4 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "20%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Employees
                    </h1>

                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      How many facilities or sites do you own?
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "1rem",
                        height: "50%",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        name="companyFacilities"
                        label="Company Owned Sites"
                        variant="outlined"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        style={{ marginTop: "1rem", width: "200px" }}
                        value={siteLength || ""}
                        onChange={generateTextFields}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          width: "100px",
                        }}
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>

                      <Button
                        variant="contained"
                        disabled={siteLength ? false : true}
                        style={{ marginLeft: "20px" }}
                        onClick={handleHandover}
                      >
                        Next Question <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}
            {/* fifth card ends here */}
          </>
        );

      case 1:
        return (
          <>
       {/*      {subStep === 10 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "20%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Company vehicles
                    </h1>

                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      Did your company own or maintain long-term leases on
                      vehicles?
                    </Typography>
                    <Typography>Example: Cars or trucks</Typography>
                  </Card>
                  <Card
                    style={{
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        justifyContent: "left",
                      }}
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="companyLeases"
                          value={fuelStepperState.companyLeases || "yes"}
                          onChange={handleFuelStepperInput}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                            onClick={handleNextQuestions}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="i don't know"
                            control={<Radio />}
                            label="I don't know"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          width: "100px",
                        }}
                        variant="contained"
                        onClick={handleHandoverBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{ marginLeft: "30px" }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )} */}

            {subStep === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "20%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Company vehicles
                    </h1>

                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      How much fuel did your company's vehicles use?
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        justifyContent: "left",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            name="Diesel"
                            label="Diesel"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            style={{ width: "200px", marginTop: "1rem" }}
                            value={state.Fuel.Diesel.Quantity || 0}
                            onChange={(e) => handleScopes(e, "Fuel")}
                          />
                          <TextField
                            id="outlined-basic"
                            name="Gasoline"
                            label="Gasoline"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            style={{ width: "200px", marginTop: "1rem" }}
                            value={state.Fuel.Gasoline.Quantity || 0}
                            onChange={(e) => handleScopes(e, "Fuel")}
                          />
                          <TextField
                            id="outlined-basic"
                            name="LNG"
                            label="LNG"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            style={{ width: "200px", marginTop: "1rem" }}
                            value={state.Fuel?.LNG.Quantity || 0}
                            onChange={(e) => handleScopes(e, "Fuel")}
                          />
                          <TextField
                            id="outlined-basic"
                            name="LPG"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            label="LPG"
                            style={{ width: "200px", marginTop: "1rem" }}
                            value={state.Fuel.LPG.Quantity || 0}
                            onChange={(e) => handleScopes(e, "Fuel")}
                          />

                          <TextField
                            id="outlined-basic"
                            name="Petrol"
                            label="PETROL"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            style={{
                              width: "200px",
                              marginTop: "1rem",
                              marginLeft: "1rem",
                            }}
                            value={state.Fuel?.Petrol.Quantity || 0}
                            onChange={(e) => handleScopes(e, "Fuel")}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          width: "100px",
                        }}
                        variant="contained"
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{ marginLeft: "30px" }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}
           
            {subStep === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "20%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Machinery
                    </h1>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      How many litres of petrol and diesel fuel did your
                      company’s machinery use?
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        justifyContent: "left",
                        overflow: "auto",
                      }}
                    >
                      {companySiteValue?.map((cInfos, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "2rem",
                                  marginRight: "1rem",
                                }}
                              >
                                {cInfos.name !== "" ? cInfos.name : cInfos.id}
                              </p>

                              <TextField
                                id="outlined-basic"
                                name="Diesel"
                                label="Diesel"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{ width: "200px", margin: "0.5rem" }}
                                value={
                                  state.Machinery[index]?.Diesel?.Quantity || 0
                                }
                                onChange={(e) =>
                                  handleScopes(e, "Machinery", cInfos.id, index)
                                }
                              />
                              <TextField
                                id="outlined-basic"
                                name="Gasoline"
                                label="Gasoline"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{ width: "200px", margin: "0.5rem" }}
                                value={
                                  state.Machinery[index]?.Gasoline?.Quantity ||
                                  0
                                }
                                onChange={(e) =>
                                  handleScopes(e, "Machinery", cInfos.id, index)
                                }
                              />

                              <TextField
                                id="outlined-basic"
                                name="LNG"
                                label="LNG"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{ width: "200px", margin: "0.5rem" }}
                                value={
                                  state.Machinery[index]?.LNG?.Quantity || 0
                                }
                                onChange={(e) =>
                                  handleScopes(e, "Machinery", cInfos.id, index)
                                }
                              />
                              <TextField
                                id="outlined-basic"
                                name="LPG"
                                label="LPG"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{ width: "200px", margin: "0.5rem" }}
                                value={
                                  state.Machinery[index]?.LPG?.Quantity || 0
                                }
                                onChange={(e) =>
                                  handleScopes(e, "Machinery", cInfos.id, index)
                                }
                              />
                              {/* Add new field */}
                              <TextField
                                id="outlined-basic"
                                name="Petrol"
                                label="Petrol"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{ width: "200px", margin: "0.5rem" }}
                                value={
                                  state.Machinery[index]?.Petrol?.Quantity || 0
                                }
                                onChange={(e) =>
                                  handleScopes(e, "Machinery", cInfos.id, index)
                                }
                              />
                            </div>
                          </div>
                          {/* </div> */}
                        </React.Fragment>
                      ))}
                    </div>

                    {companySiteValue.length > 3 && (
                      <Typography style={{ textAlign: "right" }}>
                        Scroll down for more <ArrowDownwardOutlined />
                      </Typography>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "40%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ width: "100px" }}
                        variant="contained"
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{ marginLeft: "30px" }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}
            {/* new card ends here */}

            {/* new card start here */}
            {subStep === 2 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "20%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Define Your Sites
                    </h1>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      Define Your Sites, How large were your company’s
                      facilities and your region of operation?
                    </Typography>
                    <Typography>
                      Tip: is includes warehouses and garages
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        alignItems: "left",
                        overflow: "auto",
                        justifyContent: "left ",
                      }}
                    >
                      {companySiteValue?.map((eInfo, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ marginTop: "2rem" }}>{eInfo.id}</p>
                              <TextField
                                id="outlined-basic"
                                name="name"
                                label="Name"
                                variant="outlined"
                                style={{
                                  width: "200px",
                                  marginTop: "1rem",
                                  marginLeft: "1rem",
                                }}
                                value={eInfo.name || ""}
                                onChange={(e) => handleSites(e, index)}
                              />
                              <TextField
                                id="outlined-basic"
                                name="size"
                                label="Size in Sq Ft"
                                variant="outlined"
                                style={{
                                  width: "200px",
                                  marginTop: "1rem",
                                  marginLeft: "1rem",
                                }}
                                value={eInfo.size || ""}
                                onChange={(e) => handleSites(e, index)}
                              />
                              {
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  name="region"
                                  options={
                                    props.allRegionData
                                      ? props.allRegionData
                                      : regions
                                  }
                                  getOptionLabel={(option) =>
                                    option ? option?.name : "Select Location"
                                  }
                                  onChange={(event, newValue) => {
                                    event.target = {
                                      name: "region",
                                      value: newValue.name,
                                    };
                                    handleSites(
                                      event,
                                      index,
                                      "region",
                                      newValue.name
                                    );
                                  }}
                                  sx={{ width: 200 }}
                                  style={{
                                    marginTop: "1rem",
                                    marginLeft: "1rem",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Location" />
                                  )}
                                />
                              }
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    {companySiteValue.length > 3 && (
                      <Typography style={{ textAlign: "right" }}>
                        Scroll down for more <ArrowDownwardOutlined />
                      </Typography>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "40%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ width: "100px" }}
                        variant="contained"
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          marginLeft: "30px",
                        }}
                        disabled={
                          companySiteValue[companySiteValue.length - 1]?.name
                            ? false
                            : true
                        }
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}

            {subStep === 3 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "40%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Electricity
                    </h1>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>Why we are asking this?</Typography>
                    <Typography>
                      The kWh value will be paired with emissions factor in your
                      company’s country of operation
                    </Typography>
                    <Typography>
                      How much electricity did your company consume?
                    </Typography>
                    <Typography>
                      Tip: Should be available in your electricity bills
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "60%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        alignItems: "left",
                        overflow: "auto",
                        justifyContent: "left ",
                      }}
                    >
                      {companySiteValue.map((eInfo, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "flex-start",
                              // alignItems: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                // width:"50%"
                              }}
                            >
                              <p style={{ marginTop: "2rem" }}>
                                {eInfo.name
                                  ? `Site ${eInfo.id}, ${eInfo.name}`
                                  : `Site ${eInfo.id}`}
                              </p>

                              <TextField
                                id="outlined-basic"
                                name="electricity"
                                label="Units Consumed"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                variant="outlined"
                                style={{
                                  width: "200px",
                                  marginTop: "1rem",
                                  // marginLeft: "1rem",
                                }}
                                value={eInfo.electricity || ""}
                                onChange={(e) => handleSites(e, index)}
                              />

                              <Typography
                                style={{
                                  marginTop: "2rem",
                                  marginLeft: "1rem",
                                }}
                              >
                                Kilowatt-hour (kWh)
                              </Typography>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    {companySiteValue.length > 3 && (
                      <Typography style={{ textAlign: "right" }}>
                        Scroll down for more <ArrowDownwardOutlined />
                      </Typography>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "40%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ width: "100px" }}
                        variant="contained"
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          marginLeft: "30px",
                        }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}
           
            {subStep === 4 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "40%",
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Heating
                    </h1>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>How much heating did you consume?</Typography>
                    <Typography>
                      If heating included in the electricity bill?, we won’t ask
                      you for separate bill and assume it is already covered in
                      your expenses.
                    </Typography>
                    <Typography>
                      You can just skip this question if no heating was consumed
                    </Typography>
                    <Typography>
                      Tips: Your landlord or office manger would know this
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "60%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        alignItems: "left",
                        overflow: "auto",
                        justifyContent: "left ",
                      }}
                    >
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        {companySiteValue.map((eInfos, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // justifyContent: "flex-start",
                                // alignItems: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  width: "50%",
                                }}
                              >
                                <p style={{ marginTop: "2rem" }}>
                                  {eInfos.name
                                    ? `Site ${eInfos.id}, ${eInfos.name}`
                                    : `Site ${eInfos.id}`}
                                </p>
                                <TextField
                                  id="outlined-basic"
                                  name="heating"
                                  label="Unit Consumed"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  variant="outlined"
                                  style={{
                                    width: "200px",
                                    marginTop: "1rem",
                                    // marginLeft: "1rem",
                                  }}
                                  value={eInfos.heating || 0}
                                  onChange={(e) => handleSites(e, index)}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </FormControl>
                    </div>

                    {companySiteValue.length > 3 && (
                      <Typography style={{ textAlign: "right" }}>
                        Scroll down for more <ArrowDownwardOutlined />
                      </Typography>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "40%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ width: "100px" }}
                        variant="contained"
                        onClick={handleSubBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          marginLeft: "30px",
                        }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}

            {/* new card start here */}
            {subStep ===  5 && (
              <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "85vh",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    height: "50%",
                    textAlign: "left",
                    backgroundColor: "black",
                    color: "#fff",
                  }}
                >
                  <h1 style={{ fontSize: "40px", marginRight: "8px" }}>
                    Let's Start With Your Purchases/Expenses
                  </h1>
                  <div style={{ marginLeft: "20px" }}>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                        1.
                      </h1>
                      <div>
                        <Typography variant="h5">
                          Start with the big stuff
                        </Typography>
                        <Typography variant="body2">
                          Focus on your biggest expenses first as they’ll likely
                          have most impact on your emissions
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                        2.
                      </h1>
                      <div>
                        <Typography variant="h5">
                          Feel free to jump around
                        </Typography>
                        <Typography variant="body2">
                          Our categories may not fit your expense structure.
                          Skip around if you need to and match with the best of
                          your ability
                        </Typography>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                        3.
                      </h1>
                      <div>
                        <Typography variant="h5">Phone a friend</Typography>
                        <Typography variant="body2">
                          You may want to fill these details with your company’s
                          finance head
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{ width: "100px" }}
                      onClick={handleSubBack}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: "30px" }}
                      onClick={handleHandover}
                    >
                      Start <ArrowForwardIcon />
                    </Button>
                  </div>
                </Card>
                <Card style={{ height: "50%" }}>
                  <div style={{ height: "100%" }}>
                    {/* <img
                      style={{ width: "100%", height: "100%" }}
                      src={LeafImage}
                      alt="company_logo"
                    /> */}
                  </div>
                </Card>
              </div>
              </>
            )}
            {/* new card ends here */}
          </>
        );
      case 2:
        return (
          <>
            {subStep === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "85vh",
                  }}
                >
                  <Card
                    style={{
                      width: "100%",
                      height: "40%",
                      textAlign: "left",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                  >
                    <h1 style={{ fontSize: "50px", marginRight: "8px" }}>
                      Purchases / Expenses
                    </h1>
                    {(state.From || state.To) && (
                      <Typography>
                        From {state.From} to {state.To}
                      </Typography>
                    )}
                    <Typography>
                      Purchases made by your company result in indirect
                      emissions. These are commonly considered to be supply
                      chain emissions and are part of your company’s footprint.
                      Exclude any heating, electricity and fuel costs that are
                      already covered in the previous questions
                    </Typography>
                    <Typography>
                      What were your company’s total expenses during this
                      duration?
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      height: "60%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1rem",
                        marginLeft: "2rem",
                        height: "50%",
                        alignItems: "center",
                        overflow: "auto",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Total Revenue"
                        name="Revenue"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        value={state.Revenue || 0}
                        onChange={handleStateChange}
                      />
                      <TextField
                        variant="outlined"
                        label="Total Expenses"
                        name="Expenses"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        value={state.Expenses || 0}
                        onChange={handleStateChange}
                      />
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="Currency"
                        defaultValue={selectExpenseCurrency[0]}
                        options={selectExpenseCurrency}
                        getOptionLabel={(option) =>
                          option ? option.label : "Select Unit"
                        }
                        onChange={(event, newValue) => {
                          handleStateChange(event, "Currency", newValue.value);
                        }}
                        sx={{ width: 200 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select A Currency" />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        height: "40%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          width: "100px",
                        }}
                        variant="contained"
                        onClick={handleHandoverBack}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          marginLeft: "30px",
                        }}
                        variant="contained"
                        onClick={handleSubNext}
                      >
                        Next <ArrowForwardIcon />
                      </Button>
                    </div>
                  </Card>
                </div>
              </>
            )}

            {subStep === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "85vh",
                }}
              >
                {/* <div style={{ width:"90%", marginLeft:"auto", marginRight:"auto" }}>
                    <BorderLinearProgress variant="determinate" value={50} />
                  </div> */}
                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "#000000",
                    color: "#fff",
                    padding: "1rem",
                  }}
                >
                  <h1 style={{ fontSize: "40px", marginRight: "8px" }}>
                    Purchases / Expenses
                  </h1>
                  <Typography>
                    The more categories you fill out the more accurate your
                    carbon footprint estimation will be.
                  </Typography>
                  <Typography>
                    Your Total Spend Should Sum up to {state.Expenses || 0}
                  </Typography>
                  {/* <LinearProgress variant="buffer" color="white" value={80}/> */}
                </Card>

                {/* <TextField
                  variant="outlined"
                  label="Expenses"
                  name="expensesCategories"
                  style={{ 
                     marginTop: "1rem", 
                     backgroundColor:"#fff", 
                     color:"#000000", 
                     marginBottom:"1rem" 
                  }}
                  value={state.Expenses || ""}
                  onChange={handleStateChange}
                /> */}
                <div>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleAccordionChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Business travel</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          // width: "50%",
                        }}
                      >
                        {/* <TextField
                          variant="outlined"
                          label="Business Travel"
                          name="businessExpenses"
                          value={expensesStepperState.businessExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="businessDropdown"
                          options={selectBusinessTravelCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "businessDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        /> */}
                        {expensesStepperInfo?.map((expensesInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="activity"
                                options={travelMode ?? []}
                                value={expensesInfo.activity || ""}
                                onChange={(event, newValue) => {
                                  handleExpensesStepperInfo(
                                    event,
                                    index,
                                    "activity",
                                    newValue.value
                                  );
                                }}
                                sx={{
                                  width: 200,
                                  marginRight: "1rem",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Travel Mode" />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="subActivity"
                                options={travelActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Travel Mode"
                                }
                                value={expensesInfo.subActivity || ""}
                                onChange={(event, newValue) => {
                                  handleExpensesStepperInfo(
                                    event,
                                    index,
                                    "subActivity",
                                    newValue
                                  );
                                }}
                                sx={{
                                  width: 200,
                                  marginRight: "1rem",
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Travel Details"
                                  />
                                )}
                              />

                              <TextField
                                name="quantity"
                                label="Travel Distance"
                                sx={{
                                  width: 200,
                                  marginRight: "1rem",
                                }}
                                value={expensesInfo.quantity || ""}
                                onChange={(event) =>
                                  handleExpensesStepperInfo(event, index)
                                }
                              />

                              <TextField
                                variant="outlined"
                                label="Travel Cost"
                                name="cost"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                value={expensesInfo.cost || 0}
                                onChange={(event) =>
                                  handleExpensesStepperInfo(event, index)
                                }
                              />
                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    removeExpensesFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <Button
                        onClick={() => addExpensesFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          style={{ marginLeft: "20px" }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Button>Save & Exit</Button>
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={handleNext}
                          >
                            Calculate Emissions <ArrowForwardIcon />
                          </Button>
                        </div>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleAccordionChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Hotel Stay</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        {expensesHotelInfo?.map((hotelInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="region"
                                options={
                                  props.allRegionData
                                    ? props.allRegionData
                                    : regions
                                }
                                getOptionLabel={(option) =>
                                  option?.name ?? "Select Location"
                                }
                                // value={hotelInfo.location || ""}
                                isOptionEqualToValue={(option, value) =>
                                  option.code === value
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleHotelStepperInfo(
                                    event,
                                    index,
                                    "region",
                                    newValue.name
                                  );
                                }}
                                sx={{
                                  width: 300,
                                  marginLeft: "1rem",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Location" />
                                )}
                              />
                              <TextField
                                variant="outlined"
                                label="Enter No Of Days Of Your Stay"
                                name="numberDaysOfStay"
                                style={{
                                  width: "300px",
                                  marginLeft: "1rem",
                                }}
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                value={hotelInfo.numberDaysOfStay || ""}
                                onChange={(event) =>
                                  handleHotelStepperInfo(event, index)
                                }
                              />
                              <TextField
                                name="expenses"
                                label="Expenses"
                                sx={{
                                  width: 200,
                                  marginLeft: "1rem",
                                }}
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                value={hotelInfo.expenses || 0}
                                onChange={(event) =>
                                  handleHotelStepperInfo(event, index)
                                }
                              />
                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    // marginTop: "1.5rem",
                                  }}
                                  onClick={() =>
                                    removeExpensesHotelFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <Button
                        onClick={() => addExpensesHotelFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleAccordionChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Transport/Freight</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        {transportFreightInfo?.map((transportInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="region"
                                options={
                                  props.allRegionData
                                    ? props.allRegionData
                                    : regions
                                }
                                getOptionLabel={(option) =>
                                  option.name ?? "Select Region"
                                }
                                onChange={(event, newValue) => {
                                  handleTransportStepperInfo(
                                    event,
                                    index,
                                    "region",
                                    newValue.name
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Region"
                                  />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="activity"
                                options={
                                  transportData ?? [
                                    {
                                      category: "Waste generated in operations",
                                    },
                                  ]
                                }
                                getOptionLabel={(option) =>
                                  option ?? "Select Category"
                                }
                                onChange={(event, newValue) => {
                                  handleTransportStepperInfo(
                                    event,
                                    index,
                                    "activity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Mode" />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="subActivity"
                                options={transportActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Sub Category"
                                }
                                onChange={(event, newValue) => {
                                  handleTransportStepperInfo(
                                    event,
                                    index,
                                    "subActivity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Type" />
                                )}
                              />
                              <TextField
                                variant="outlined"
                                label="Distance"
                                name="quantity"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                style={{ marginLeft: "1rem" }}
                                value={transportInfo.quantity || 0}
                                onChange={(event) =>
                                  handleTransportStepperInfo(event, index)
                                }
                              />
                              <TextField
                                variant="outlined"
                                label="Expenses"
                                name="cost"
                                style={{ marginLeft: "1rem" }}
                                value={transportInfo.cost || 0}
                                onChange={(event) =>
                                  handleTransportStepperInfo(event, index)
                                }
                              />
                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    marginTop: "1.5rem",
                                  }}
                                  onClick={() =>
                                    removeTransportFreightFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <Button
                        onClick={() => addTransportFreightFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1.5rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>

                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          style={{ marginLeft: "20px" }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Button>Save & Exit</Button>
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={handleNext}
                          >
                            Calculate Emissions <ArrowForwardIcon />
                          </Button>
                        </div>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleAccordionChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Materials/Inventory</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        {materialInventoryInfo?.map((materialInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="site"
                                options={companySiteValue ?? []}
                                getOptionLabel={(option) =>
                                  option.name ?? "Select Site"
                                }
                                onChange={(event, newValue) => {
                                  handleMaterialStepperInfo(
                                    event,
                                    index,
                                    "site",
                                    newValue.id
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Site" />
                                )}
                              />
                              {/*  <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="activity"
                                options={inventoryData ?? [{"category": "Category 1"}]}
                                getOptionLabel={(option) =>
                                  option ?? "Select Category"
                                }
                                onChange={(event, newValue) => {
                                  handleMaterialStepperInfo(
                                    event,
                                    index,
                                    "activity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Category"
                                  />
                                )}
                              /> */}

                              <TextField
                                id="combo-box-demo"
                                name="activity"
                                value="Building materials"
                                label="Select Category"
                              />

                              {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="subActivity"
                                options={materialActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Sub Category"
                                }
                                onChange={(event, newValue) => {
                                  handleMaterialStepperInfo(
                                    event,
                                    index,
                                    "subActivity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select item" />
                                )}
                              /> */}

                              <TextField
                                id="combo-box-demo"
                                name="subActivity"
                                value="Wood"
                                label="Select Sub Category"
                              />

                              {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="cost"
                                options={materialSubActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Unit"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleMaterialStepperInfo(
                                    event,
                                    index,
                                    "cost",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Unit" />
                                )}
                              /> */}
                              <TextField
                                variant="outlined"
                                label="Quantity"
                                name="quantity"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                style={{ marginLeft: "1rem" }}
                                value={materialInfo.quantity || 0}
                                onChange={(event) =>
                                  handleMaterialStepperInfo(event, index)
                                }
                              />
                              <TextField
                                variant="outlined"
                                label="Expenses"
                                name="cost"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                style={{ marginLeft: "1rem" }}
                                value={materialInfo.cost || 0}
                                onChange={(event) =>
                                  handleMaterialStepperInfo(event, index)
                                }
                              />

                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    marginTop: "1.5rem",
                                  }}
                                  onClick={() =>
                                    removeMaterialFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))}
                        {/* <TextField
                          variant="outlined"
                          label="Materials Inventory"
                          name="materialExpenses"
                          value={expensesStepperState.materialExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="materialDropdown"
                          options={selectMaterialCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "materialDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        /> */}
                      </div>
                      <Button
                        onClick={() => addMaterialFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1.5rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>

                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          style={{ marginLeft: "20px" }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Button>Save & Exit</Button>
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={handleNext}
                          >
                            Calculate Emissions <ArrowForwardIcon />
                          </Button>
                        </div>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Office Equipments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Example: Chairs., table and other office interior
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        {officeEquipmentsInfo?.map((equipmentInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="activity"
                                options={officeEquipmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Region"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleOfficeEquipmentStepperInfo(
                                    event,
                                    index,
                                    "activity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Site" />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="subActivity"
                                options={officeEquipmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Category"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleOfficeEquipmentStepperInfo(
                                    event,
                                    index,
                                    "subActivity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Category"
                                  />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="unit"
                                options={officeEquipmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Sub Category"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleOfficeEquipmentStepperInfo(
                                    event,
                                    index,
                                    "unit",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Kc" />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="cost"
                                options={officeEquipmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Unit"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleOfficeEquipmentStepperInfo(
                                    event,
                                    index,
                                    "cost",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Unit" />
                                )}
                              />
                              <TextField
                                variant="outlined"
                                label="Quantity"
                                name="quantity"
                                style={{ marginLeft: "1rem" }}
                                value={equipmentInfo.quantity || ""}
                                onChange={handleOfficeEquipmentStepperInfo}
                              />
                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    marginTop: "1.5rem",
                                  }}
                                  onClick={() =>
                                    removeFurnitureFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))} */}
                  {/* <TextField
                          variant="outlined"
                          label="Furniture"
                          name="furnitureExpenses"
                          value={expensesStepperState.furnitureExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="furnitureDropdown"
                          options={selectFurnitureCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "furnitureDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        /> */}
                  {/* </div>
                      <Button
                        onClick={() => addFurnitureFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>
                    </AccordionDetails>
                  </Accordion> */}

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Plastic Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Example: Office supplies, packaging or merchandise
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "50%",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          label="Services"
                          name="placticExpenses"
                          value={expensesStepperState.placticExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="placticDropdown"
                          options={selectPlasticProductsCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "placticDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion> */}

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Metal Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Example: Steel beams, staplers or metals
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "50%",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          label="Services"
                          name="metalExpenses"
                          value={expensesStepperState.metalExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="metalDropdown"
                          options={selectMetalProductsCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "metalDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion> */}

                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleAccordionChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Waste Treatment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          // width: "50%",
                        }}
                      >
                        {wasteTreatmentStepperInfo?.map((wasteInfo, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="site"
                                options={companySiteValue ?? []}
                                getOptionLabel={(option) =>
                                  option.name ?? "Select Site"
                                }
                                onChange={(event, newValue) => {
                                  handleWasteTreatmentStepperInfo(
                                    event,
                                    index,
                                    "site",
                                    newValue.id
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Site" />
                                )}
                              />
                              {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="activity"
                                options={wasteData ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Category"
                                }
                                onChange={(event, newValue) => {
                                  handleWasteTreatmentStepperInfo(
                                    event,
                                    index,
                                    "activity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Category"
                                  />
                                )}
                              /> */}
                              <TextField
                                id="combo-box-demo"
                                name="activity"
                                value="Building materials"
                                label="Select Category"
                              />
                              <TextField
                                id="combo-box-demo"
                                name="subActivity"
                                value="Wood"
                                label="Select Sub Category"
                              />

                              {/* 

                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="subActivity"
                                options={wasteTreatmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Sub Category"
                                }
                                onChange={(event, newValue) => {
                                  handleWasteTreatmentStepperInfo(
                                    event,
                                    index,
                                    "subActivity",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Sub Category"
                                  />
                                )}
                              /> */}
                              {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                name="cost"
                                options={wasteTreatmentActivities ?? []}
                                getOptionLabel={(option) =>
                                  option ?? "Select Unit"
                                }
                                onChange={(event, newValue) => {
                                  
                                  handleWasteTreatmentStepperInfo(
                                    event,
                                    index,
                                    "cost",
                                    newValue
                                  );
                                }}
                                sx={{ width: 200, marginLeft: "1rem" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Unit" />
                                )}
                              /> */}
                              <TextField
                                variant="outlined"
                                label="Quantity"
                                name="quantity"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                style={{ marginLeft: "1rem" }}
                                value={wasteInfo.quantity || 0}
                                onChange={(event) =>
                                  handleWasteTreatmentStepperInfo(event, index)
                                }
                              />
                              <TextField
                                variant="outlined"
                                label="Expenses"
                                name="cost"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                style={{ marginLeft: "1rem" }}
                                value={wasteInfo.cost || 0}
                                onChange={(event) =>
                                  handleWasteTreatmentStepperInfo(event, index)
                                }
                              />
                              {index ? (
                                <DangerousIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    marginTop: "1.5rem",
                                  }}
                                  onClick={() =>
                                    removeWasteTreatmentFormFields(index)
                                  }
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                        ))}
                        {/* <TextField
                          variant="outlined"
                          label="Capital Goods"
                          name="capitalExpenses"
                          value={expensesStepperState.capitalExpenses || ""}
                          onChange={handleExpensesStepperInput}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="capitalDropdown"
                          options={selectCapitalGoodsCurrency}
                          getOptionLabel={(option) =>
                            option ? option.value : "Select Currency"
                          }
                          onChange={(event, newValue) => {
                            handleExpensesStepperInput(
                              event,
                              newValue.value,
                              "capitalDropdown"
                            );
                          }}
                          sx={{ width: 300, marginLeft: "1rem" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Kc" />
                          )}
                        /> */}
                      </div>
                      <Button
                        onClick={() => addWasteTreatmentFormFields()}
                        variant="contained"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginLeft: "1rem",
                          marginBottom: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        Add
                      </Button>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          style={{ marginLeft: "20px" }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <Button>Save & Exit</Button>
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Calculate Emissions <ArrowForwardIcon />
                          </Button>
                        </div>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ width: "100px" }}
                    onClick={handleSubBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: "1rem" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Card style={{ backgroundColor: "#000000", color: "#ffff" }}>
        <Stepper
          style={{ margin: "20px", color: "white" }}
          activeStep={activeStep}
          className={classes.stepper}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  label: classes.stepLabel,
                  icon: classes.stepIcon,
                  active: classes.active,
                  completed: classes.completed,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>{renderStepContent(activeStep)}</div>
      </Card>
      {(error || success) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={opens}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={4000}
            onClose={handleCloses}
          >
            <Alert
              onClose={handleCloses}
              severity={error ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {error
                ? "failed to submit Company Data"
                : "Company Data is Created Successfully"}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </div>
  );
}

export default CompanyStepper;
