import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// axios from npm library
import axios from "axios";
// IMPORT Cookie from react cookie
import { useCookies } from "react-cookie";
// alert box
import Stack from "@mui/material/Stack";
// IMPORT API_URL and Cookies
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// IMPORT C.S.S
import "../../component/invoice/invoice.css";
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Autocomplete, TextField } from "@mui/material";
import { Card, CardContent } from "@material-ui/core";
// import Icon
import DangerousIcon from "@mui/icons-material/Dangerous";
// IMPORT Vchained logo svg
// import VchainLogo from "../../assets/images/VChained-Logos.svg";
// import Number to Words
import { ToWords } from 'to-words';
// IMPORT FullScreenLoader
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// table component start here
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// table ends here
// import image
import ProfileImg from "../../assets/images/profileIcon.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f1f5f9",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100%",
  overflow: "auto",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const IssuePoMaster = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]); // selected user
  const [vendor, setVendor] = React.useState({});
  const [row, setRow] = React.useState([]); // selected address
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [purchaseOrderData, setPurchaseOrderData] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState({
    invoiceNumber: "",
    date: "January 1, 2022",
    dueDate: "January 15, 2022",
    senderAddress: "Loading your details\n",
    recieverAddress: "Select A participant\n from dropdown to continue",
    objects: [],
    amounts: [],
    Total: 0,
    Discount: 0,
    NetTotal: 0,
    Taxes: 0,
    Currency: "$",
  });
  const [cookies, setCookie] = useCookies(["user"]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [opens, setOpens] = React.useState(false);
  const [subTotal, setSubTotal] = React.useState(0);
  const [response, setResponse] = React.useState();
  const [count, setCount]= React.useState(0);
  const [responseCode, setResponseCode] = React.useState();
  const [success, setSuccess] = React.useState("");
  const [logoheader, setLogoHeader] = React.useState({});
  const [State, setStates] = React.useState({});
  const [state, setState] = React.useState({
    purchaseId:"",
    createDate:"",
    dueDate:""
  })
  const [amount, setAmount] = React.useState({
    tax:0,
    discount:0,
    Total:0,
    totalAmt: 0,
    balance:0
  })
  const [inputField, setInputField] = React.useState([
    {
      // serialNo: "",
      product_description: "",
      type: "",
      quantity: 0,
      units: "",
      unit_rate: 0,
      amount: 0
    },
  ]);

  // API URL
  const API_URL = CONSTANTS.API_URL;
  // UPLOAD URL
  const UPLOAD_URL = CONSTANTS.UPLOAD_URL;
  // headers
  const headers = HEADERS2(cookies.token, cookies.Nid);
  
  // number to words logic start here 
  const toWords = new ToWords();
 // number to words logic ends here 

  // handle submit bar
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(state,"state form data")
  }

  const sumEvent = async(newFormValues) => {
    let sum0 = 0;
    console.log(newFormValues,"inputField here")
    for(let i=0; i<newFormValues.length; i++){
      sum0= sum0 + newFormValues[i].amount
    }
    setSubTotal(sum0);
    setAmount(sum0);
    setCount(count + 1);
    console.log(sum0,"sum here")
  }

  // handle form change function
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name] : value
    }));
  };

  const handlePercentChange = (v) =>{
    let sum = v
    let amtx = amount
    console.log(sum,'sum1')
    if(amtx.tax>0){
      sum =  sum + (sum * amtx.tax/100)  
      console.log(sum,'sum after tax')
    }
    if(amtx.discount>0){
      sum =  sum - (sum * amtx.discount/100)
      console.log(sum,'sum after discount')
    }
    amtx.totalAmt = sum
    setAmount(amtx)
  }

  const handleAmtChange = (i, element) =>{
    let v = element.quantity * element.unit_rate
    let temp = inputField
    temp[i].amount = v
    setInputField(temp)
    sumEvent(temp)
    handlePercentChange(v)
  }

  const handleRateInputChange = (i, event, element) => {
    let newFormValues = [...inputField];
    let newVal = event.target.value;
    newFormValues[i][event.target.name] = newVal;
    setInputField(newFormValues);
    setCount(count + 1);
    handleAmtChange(i,element)
  };

  const handleInputChange = (i, event) => {
    let newFormValues = [...inputField];
    let newVal = event.target.value;
    newFormValues[i][event.target.name] = newVal;
    setInputField(newFormValues);
    setCount(count + 1);
  };

  const addFormField = () => {
    setInputField([
      ...inputField,
      {
        product_description: "",
        type: "",
        quantity: 0,
        units: "",
        unit_rate: 0,
        amount: 0,
      },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...inputField];
    newFormValues.splice(i, 1);
    setInputField(newFormValues);
  };

  const handleCloses = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpens(false);
  };

  // React UseEffect
  React.useEffect(() => {
    getInvoice();
    getPurchaseOrderById();
    if(props.obj){
        setInputField(props.obj)
    }
    console.log(rows,"rows.. data")
    console.log(rowData,"all rows data")
    console.log(inputField,"inputField")
    console.log(props.logoheader,'props.logoheader?.data')
  }, [setCount]);

    // get participant by Vendor Id For Logo & headers
    const getPurchaseOrderById = async () => {
      try{
          const { data } = await axios.get(
            API_URL + `/participant/id`,
            headers
        );
        if(data.logo){
          const getLogo = await axios.get(
            UPLOAD_URL + `/attachment/get/${data.logo}`,
            headers
          );
          setLogoHeader(getLogo.data)
        }
        if(!data.error){
          setStates(data);
          // console.log(data,"headers data")
        }
      }catch(err){
          console.log(err, " err in participant by id for headers logo")
      }
    }

  // Call an Get_Invoice Api
  const getInvoice = async () => {
    console.log("hello2");
    let amts = [];
    let sum = 0;
    let senderAddress = "";
    if(count===0){
    const { data } = await axios.get(
      API_URL + `/participant/getParticipant`,
      // API_URL + `/participant/getOParticipant/${cookies.PID}`,
      headers
    );
    console.log(data, "p data");
    let otherParticipants = [];
    if (!data.error) {
      for (let d of data) {
        if (cookies.PID !== d.PID) {
          otherParticipants.push(d);
        } else {
          let temp = d.company + "\n" + d.city + "\n" + d.country;
          senderAddress = temp;
          setRowData(d);
        }
      }
      setRows(otherParticipants);
    }
    setInvoiceData({
      ...invoiceData,
      objects: [],
      amounts: amts,
      Total: sum,
      NetTotal: sum,
      senderAddress: senderAddress,
    });}
  };

  // call an api to get participent data
  const getParticipentData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/participant/getParticipant`,
        headers
      );
      console.log(data, "participant data");
      let otherParticipants = [];
      if (!data.error) {
        for (let d of data) {
          if (cookies.PID !== d.PID) {
            otherParticipants.push(d);
          } else {
            let temp = d.company + "\n" + d.city + "\n" + d.country;
            setInvoiceData({ ...invoiceData, senderAddress: temp });
          }
        }
      }
      setRows(otherParticipants); // setting other participants
    } catch (err) {
      console.log(err);
    }
  };

  // Handle Transfer Function
  const handleTransfer = async (row) => {
    try {
      if (!invoiceData.invoiceNumber || invoiceData.invoiceNumber === "") {
        setError("Please enter a invoice number");
        setResponseCode(400);
        setOpen(true);
      } else {
        if (row && row.PID) {
          setLoader(true);
          let body = props.selected;
          body.map((o, i) => {
            o.claimRequest = row.PID;
            o.action = "Claim request raised towards " + row.PID;
          });
          const { data, status } = await axios.put(
            API_URL + `/inventory/PID/${cookies.PID}/update/batch`,
            body,
            headers
          );
          console.log(data, "data here...");
          if (data.error) {
            setError("Data is not Transfered due to an Error!");
          } else {
            console.log("inside data ..");
            getParticipentData();
          }
          setResponseCode(status);
          setResponse(data);
          setOpen(true);
          setLoader(false);
          setOpens(true);
          props.close();
        } else {
          setError("Please select a participant to continue");
          setResponseCode(400);
          setOpen(true);
        }
      }
    } catch (err) {
      setLoader(false);
      setResponseCode(JSON.parse(JSON.stringify(err)).status);
      setResponse(
        "Unable to reach the server, please check your internet connection"
      );
      setError(`Error ${JSON.parse(JSON.stringify(err)).status}`);
      setOpen(true);
      console.log(err);
    }
  };
 
  // call an api to transfer invoice
  const handleTransferInvoice = async () => {
    try{
      if(!state.purchaseId){
        setError("Please Enter a Purchase Order Number");
        setOpens(true);
      }
      else if(!state.createDate){
        setError("Please Enter a Purchase Order Date");
        setOpens(true);
      }
      else if(!state.dueDate){
        setError("Please Enter a Purchase Order Due Date");
        setOpens(true);
      }
      const insertInvoice = {
      Vendor:rowData?.company, // receiver
      VendorId:cookies.PID, // PID OF SENDING ORDER
      id:state.purchaseId,  // purchase id
      data:inputField, // object 
      amount:amount.totalAmt.toFixed(2),  
      status:"issued",
      balancePayable:amount.totalAmt.toFixed(2),
      quantity:"50",
      NID:cookies.Nid, 
      PID:cookies.PID,
      productOrderStatus:"order successfully",
      address:rowData?.shippingAddress ?? "",
      shippingAddress:rowData?.shippingAddress ?? "",
      GSTIN:rowData?.TaxDetails ? rowData?.TaxDetails : "",
      supplierName:vendor?.company ? vendor?.company: "",
      supplierAddress:vendor?.address ? vendor?.address : "",
      supplierContact:vendor?.taxDetails ? vendor?.taxDetails : "",
      discount:amount.discount,
      terms:rowData?.poTerm? rowData?.poTerm : "",
      taxes:amount.tax,
      subTotal:subTotal
      }
      console.log(insertInvoice,"insert invoice")
      const data = await axios.post(API_URL + "/po/create", insertInvoice, headers)
      console.log(data,"new po data")
      if(!data){
        setPurchaseOrderData([])
      } else{
        setPurchaseOrderData(data);
        setSuccess("Transfer in Purchase Order Successfully");
        setOpens(true);
        console.log(data,"inserted invoice");
      }
      props.close(); // for closing a modal
    }catch(err){
      console.log(err,"err in transfer invoice")
    }
  } 

  return (
    <>
      <Typography style={{ textAlign: "center", fontSize: "1.5rem" }}>
        Send Invoice
      </Typography>
      <Card
        variant="outlined"
        style={{
          width: "100%",
          textAlign: "center",
          height: "auto",
          marginTop: "1rem",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <div id="divToPrint">
          <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ border: "1px solid black" }}>
                  {/* <div>Main Header</div> */}
                  {/* header start here */}
                  {logoheader?.data && (
                      <embed
                        src={`data:${logoheader.contentType};base64,${logoheader.data}`}
                        className="image_upload"
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "50%",
                          height: "100px",
                          width: "100px",
                          margin: "10px",
                          marginLeft: "2rem",
                        }}
                        alt="images"
                      />
                    )}
                    {!logoheader?.data && (
                      <embed
                        src={ProfileImg}
                        className="image_upload"
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "50%",
                          height: "100px",
                          width: "100px",
                          margin: "10px",
                          marginLeft: "2rem",
                        }}
                        alt="images"
                      />
                    )}
                    {/* header ends here */}
                </Grid>
                <Grid item xs={12} style={{ border: "1px solid black" }}>
                  <Typography variant="h5" style={{ fontWeight:"bold" }}>Purchase Order</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={8}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "left",
                        }}
                      >
                        <Typography style={{ fontWeight:"bold" }}>
                          Name: {rowData?.company ?? "NA"}
                        </Typography>
                        <Typography style={{ fontWeight: "bold" }}>
                          Address: {rowData?.shippingAddress ?? "NA"}
                        </Typography>
                        {(rowData?.TaxDetails) && (
                        <Typography style={{ fontWeight: "bold" }}>
                          GSTIN: {rowData?.TaxDetails}
                        </Typography>
                        )}
                      </div>
                      <Grid>
                        <div
                          style={{ marginTop: "0.8rem", textAlign: "left" }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            Shipped To
                          </Typography>

                          <Typography style={{ fontWeight: "bolder" }}>
                            Address: {rowData?.shippingAddress ?? "NA"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "right",
                          // border: "1px solid black",
                        }}
                      >
                        <Typography style={{ fontWeight: "bolder", marginRight:"10px", textAlign:"left" }}>
                          Purchase Order Number
                        </Typography>
                        <TextField
                          style={{ marginRight:"10px" }}
                          name="purchaseId"
                          label="Purchase Number"
                          variant="outlined"
                          value={state.purchaseId}
                          onChange={handleFormChange}
                        />
                        <Typography style={{ fontWeight: "bolder", marginRight:"10px", textAlign:"left", margin:"5px" }}>
                          Purchase Order Create Date
                        </Typography>
                        <TextField
                          style={{ marginRight:"10px" }}
                          name="createDate"
                          label="Created Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{ shrink: true, required: true }}
                          value={state.createDate}
                          onChange={handleFormChange}
                        />
                        <Typography style={{ fontWeight: "bolder", marginRight:"10px", textAlign:"left" }}>
                          Purchase Order Due Date
                        </Typography>
                        <TextField
                          style={{ marginRight:"10px", marginBottom:"5px", marginTop:"5px" }}
                          name="dueDate"
                          label="Due Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{ shrink: true, required: true }}
                          value={state.dueDate}
                          onChange={handleFormChange}
                        />
                      </div>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={6} style={{ border: "1px solid black" }}>
                  <Typography variant="h6">Supplier/Vendor Details</Typography>
                  <Autocomplete
                    disablePortal
                    options={rows}
                    getOptionLabel={(option) =>
                      option ? option.company : "Select A Participant"
                    }
                    name="PID"
                    onChange={(event, newValue) => {
                      console.log(newValue, " this is  new value");
                      let temp =
                        newValue.company +
                        "\n" +
                        newValue.city +
                        "\n" +
                        newValue.country;
                      setInvoiceData({
                        ...invoiceData,
                        recieverAddress: temp,
                      });
                      setRow(newValue); // use??
                      setVendor(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select A Participant"
                        variant="outlined"
                        // fullWidth
                        style={{ 
                        display:"flex", 
                        flexDirection:"column", 
                        justifyContent:"flex-start", 
                        alignItems:"flex-start",
                        width:"70%" 
                        }}
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    <Typography style={{ fontWeight: "bolder" }}>
                      Name: {vendor?.company ?? "NA"}
                    </Typography>
                    <Typography style={{ fontWeight: "bolder" }}>
                      Address: {vendor?.address ?? ""}
                    </Typography>
                    <Typography style={{ fontWeight:"bolder" }}>
                      Contact No: {vendor?.contactNumber ?? ""}{" "}
                    </Typography>
                    {(vendor?.taxDetails) && (
                    <Typography style={{ fontWeight: "bolder" }}>
                      GSTIN: {vendor?.taxDetails}
                    </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} style={{ border: "1px solid black" }}>
                  <Typography variant="h6">Shipping Address</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                      height: "77%",
                    }}
                  >
                    <Typography style={{ fontWeight:"bolder" }}>
                      Name: {rowData?.company ?? "NA"}</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      Address: {rowData?.shippingAddress ?? "NA"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ border: "1px solid black" }}>
                  <Item>
                    <TableContainer
                      component={Paper}
                      style={{ border: "1px solid black" }}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bolder" }}>
                              S.NO
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              Product Description
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              MOC
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              QTY
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              Unit Type
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              Unit Rate
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontWeight: "bolder" }}
                            >
                              Amount (in INR)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inputField?.map((element, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {/* <TextField
                                    name="serialNo"
                                    label="S.No"
                                    variant="outlined"
                                    value={index + 1}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                  /> */}
                                  <Typography>{index+1}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="product_description"
                                    label="Product Description"
                                    variant="outlined"
                                    value={element.productId}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="type"
                                    label="MOC"
                                    variant="outlined"
                                    value={element.type}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="quantity"
                                    label="Quantity"
                                    variant="outlined"
                                    type="number"
                                    value={element.quantity}
                                    InputProps={{
                                     inputProps: { min: 0 }
                                    }}
                                    onChange={(e) =>
                                      handleRateInputChange(index,e,element)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="units"
                                    label="Unit Type"
                                    variant="outlined"
                                    value={element.units}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="unit_rate"
                                    label="Unit Rate"
                                    variant="outlined"
                                    type="number"
                                    value={element.unit_rate}
                                    InputProps={{
                                      inputProps: { min: 0 }
                                    }}
                                    onChange={(e) =>
                                      handleRateInputChange(index,e,element)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    name="amount"
                                    label="Amount"
                                    variant="outlined"
                                    value={element.quantity * element.unit_rate}
                                    onChange={() =>
                                      handleAmtChange(index,element)
                                    }
                                  />
                                </TableCell>
                                
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    
                    </TableContainer>
                  </Item>
                </Grid>
                {/* new grid start here */}
                <Grid item xs={6} style={{ border: "1px solid black" }}>
                  <div style={{ textAlign: "left" }}>
                  <Typography
                      style={{
                        textAlign: "left",
                        fontWeight: "bold"
                      }}
                    >
                      Sub Total: {subTotal.toFixed(2)}
                    </Typography>
                    
                    <Typography
                      style={{
                        fontWeight: "bolder",
                        textAlign: "left"
                      }}
                    >
                      Total Amount: {amount.totalAmt.toFixed(2)}
                    </Typography>
                    <Typography 
                       style={{ 
                        fontWeight: "bolder", 
                        textAlign:"left"
                      }}
                    >
                      Balance Payable: {amount.totalAmt.toFixed(2)}
                    </Typography>
                    {(amount.totalAmt > 0) && (
                    <Typography style={{ fontWeight: "bolder" }}>
                      Rupees in Words: {toWords.convert(amount.totalAmt.toFixed(2), { currency: true })} 
                    </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      border: "1px solid black",
                    }}
                  >
                  <TextField
                      name="tax"
                      label="Tax %"
                      variant="outlined"
                      type="number"
                      value={amount.tax}
                      InputProps={{
                                      inputProps: { min: 0 }
                                    }}
                      style={{ width: "50%", margin: "0.5rem" }}
                      onChange={(e) => {
                              let tAmount = subTotal;
                              let taxes =
                                (Number(e.target.value) * tAmount) / 100;
                              tAmount = tAmount + taxes;
                              if(amount.discount){
                                tAmount = tAmount - (tAmount*amount.discount/100)
                              }
                              setAmount({
                                ...amount,
                                tax: e.target.value,
                                totalAmt: tAmount,
                              });
                            }}
                  />
                  {/* <TextField
                      name="discount"
                      label="Discount"
                      variant="outlined"
                      type="number"
                      value={amount.discount}
                      style={{ width: "50%", margin: "0.5rem" }}
                      onChange={(e) => {
                        console.log("discount amount",e.target.value);
                        let tAmount = subTotal;
                        let discount = (Number(e.target.value) * tAmount) / 100;
                        tAmount = tAmount - discount;
                        if(amount.tax){
                                tAmount = tAmount + (tAmount*amount.tax/100)
                              }
                        setAmount({
                          ...amount,
                          discount: e.target.value,
                          totalAmt: tAmount
                        })
                      }}
                  /> */}
                  </div>
                </Grid>
                {/* new grid ends here */}
                <Grid item xs={6} style={{ border: "1px solid black" }}>
                  <Typography variant="h6" style={{ textAlign: "left" }}>
                    Thanks for doing business with us
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Bank Details:
                    </Typography>
                    <Typography>
                      Bank Name: {rowData?.bankDetails?.bankName ?? "NA"}
                    </Typography>
                    <Typography>
                      Bank Account No: {rowData?.bankDetails?.accountNo ?? "NA"}
                    </Typography>
                    <Typography>
                      IFSC Code: {rowData?.bankDetails?.ifsc ?? "NA"}
                    </Typography>
                    <Typography>
                      Account Holder Name:
                      {rowData?.bankDetails?.accountHolder ?? "NA"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} style={{ border: "1px solid black" }}>
                    <div>
                      <Typography
                        style={{ marginTop: "8rem", fontWeight: "bolder" }}
                      >
                        Authorised signatory
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            </form>
          </div>
          <div sx={style}>
            {step === 0 && (
              <>
                <div
                  variant="outlined"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    height: "50%",
                    marginTop: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  {error && (
                    <Typography style={{ color: "red", textAlign: "center" }}>
                      {error}
                    </Typography>
                  )}

                  {(response || error) && (
                    <Stack spacing={2} sx={{ width: "100%" }}>
                      <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity={
                            responseCode === 200 || responseCode === 201
                              ? "success"
                              : "warning"
                          }
                          sx={{ width: "100%" }}
                        >
                          {responseCode === 200 || responseCode === 201
                            ? "Transfer request raised successfully!"
                            : error}
                        </Alert>
                      </Snackbar>
                    </Stack>
                  )}
                  <Button
                    onClick={() => {
                      // setError("");
                      // handleTransfer(row);
                      handleTransferInvoice()
                    }}
                    style={{ marginLeft: "60%", margintop: "20px" }}
                    variant="contained"
                  >
                    Send POP
                  </Button>
                  <Button variant="contained" onClick={props.close} style={{ marginLeft:"10px" }}>
                    Go Back
                  </Button>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
      {loader && <FullScreenLoader style={{ height: "100vh" }} />}
      {(error || success) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar open={opens} autoHideDuration={4000} onClose={handleCloses}>
            <Alert
              onClose={handleCloses}
              severity={error ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {error ? error : success}
            </Alert>
          </Snackbar>
      {/* {success && (
          <Snackbar open={opens} autoHideDuration={4000} onClose={handleCloses}>
            <Alert onClose={handleCloses} severity="success" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
      )}  */}
      </Stack>
      )}
    </>
  );
};
export default IssuePoMaster;

