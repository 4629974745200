import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Autocomplete } from '@mui/material';
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import { CONSTANTS, HEADERS } from '../../component/utils/constants';
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { width } from '@mui/system';
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),

        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "300px",
        },
        "& .MuiButtonBase-root": {
            margin: theme.spacing(2),
        },
        newaccount: {
            display: "flex",
            alignItems: "center",
        },
    },
    cardContainer: {
        width: '400px',
        height: 'auto',
        margin: 'auto',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));
const NewSite = () => {
    const classes = useStyles();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [state, setState] = useState({})
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [PID, setPID] = useState('');
    const [token, setToken] = useState('');
    const [errMsg, setErrMsg] = useState();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [regionState, setRegionState] = React.useState([]);
    const [sectorOperateState, setsectorOperateState] = React.useState([]); // api state
    const [selectedRegion, setSelectedRegion] = React.useState("");
    const [selectedSector, setSelectedSector] = React.useState("");
    // API_URL
    const API_URL = CONSTANTS.API_URL;
    // headers


    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        let t = searchParams.get('c')
        console.log(t)
        setToken(t)
        const pathSegments = window.location.pathname.split('/');
        console.log(pathSegments[2])
        setPID(pathSegments[2])
        handleGetDetails(t, pathSegments[2])
    }, []);
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password1') {
            setPassword1(value);
            setIsPasswordMatch(password2 === value);
        } else if (name === 'password2') {
            setPassword2(value);
            setIsPasswordMatch(password1 === value);
        }
    };
    const handleSnackbarClose = async () => {
        setSnackbarOpen(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const handleGetDetails = async (t, p) => {
        try {
            const headers = HEADERS(t);
            // console.log('this is the body to send',{password:password1,PID:PID,token:token})
            const { data } = await axios.get(
                API_URL + `/vendor/getForInvitation?c=${p}`,
                headers
            );
            setState(data);
            getCompanyRegions();
            getSectorOperate();
            console.log(data, 'this is data')
        } catch (e) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to fetch details, ask the sender to share the invite again if this issue persists");
            console.log(e, 'error')
        }

    }

    const getCompanyRegions = async () => {
        try {
            const headers = HEADERS(token);
            const { data } = await axios.get(API_URL + `/ef/region/get`, headers);
            console.log(data, "region data");
            if (!data) {
                setRegionState([]);
            } else {
                setRegionState(data);
                console.log(data, "data get successfully");
            }
        } catch (err) {
            console.log(err, "err in api");
        }
    };

    const getSectorOperate = async () => {
        try {
            const headers = HEADERS(token);
            const { data } = await axios.get(API_URL + `/ef/category/get`, headers);
            console.log(data, "activity data");
            if (!data) {
                setsectorOperateState([]);
            } else {
                setsectorOperateState(data);
                console.log(data, "data get successfully");
            }
        } catch (err) {
            console.log(err, "err in api");
        }
    };

    const handleSetPassword = async () => {
        try {
            const headers = HEADERS(token);
            console.log('this is the body to send', { password: password1, PID: PID, token: token })

            let reqObj = {
                firstname: state.Name,
                email: state.PrimaryMail,
                password: password1,
                PID: PID,
                requestType: "vendor",
                region: selectedRegion,
                sector: selectedSector
            }

            console.log("reqObj ", reqObj);
            const { data, status } = await axios.post(API_URL + "/user/register", reqObj);

            if (status === 200) {
                setSnackbarOpen(true);
                setSnackbarSeverity("success");
                setSnackbarMessage("Password set successfully, redirecting...");
                console.log(window.location.origin,'origin')
                window.location = window.location.origin
            } else {
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage("Failed to set password, ask the sender to share the invite again if this issue persists");
            }
        } catch (e) {
            console.log(e)
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to set password, ask the sender to share the invite again if this issue persists");
        }
    };

    return (
        <>
           
                <form style={{padding:'20px'}} onSubmit={handleSubmit}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Welcome Onboard!
                    </Typography>

                    <Typography variant="body1" align="center" paragraph>
                        You have been by invited to become Site Admin of your organization, please set up your new password:
                    </Typography>

                    {state && (
                        <>
                            <TextField
                                name="email"
                                label="Email"
                                type="email"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={state?.PrimaryMail}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.textField}
                            />
                        </>
                    )}

                   


                    {regionState?.regions && (
                        <Autocomplete
                            disablePortal
                            name="companyLocated"
                            options={regionState?.regions}
                            onChange={(event, newValue) => {
                                setSelectedRegion(newValue);
                            }}
                            value={selectedRegion ?? ''}
                            required
                            className={classes.textField}
                            renderInput={(params) => (
                                <TextField {...params} label="Company Location" />
                            )}
                        />
                    )}

                    {sectorOperateState?.activities && (
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            name="sectorOperate"
                            options={sectorOperateState?.activities}
                            onChange={(event, newValue) => {
                                setSelectedSector(newValue);
                            }}
                            value={selectedSector ?? null}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            className={classes.textField}
                            renderInput={(params) => (
                                <TextField {...params} label="Sector Operate" />
                            )}
                        />
                    )}
                     <TextField
                        name="password1"
                        label="Create A New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={password1}
                        onChange={handlePasswordChange}
                        className={classes.textField}
                    />

                    <TextField
                        name="password2"
                        label="Reenter Your Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={password2}
                        onChange={handlePasswordChange}
                        className={classes.textField}
                    />

                    {errMsg && (
                        <Typography style={{ color: 'red', marginBottom: '8px' }}>
                            {errMsg}
                        </Typography>
                    )}
                    <div>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!isPasswordMatch}
                        onClick={handleSetPassword}
                        className={classes.button}
                    >
                        Confirm
                    </Button>
                    </div>
                </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default NewSite;
