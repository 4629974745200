import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
// import React Cookie
import { useCookies } from "react-cookie";
import Layout from "./component/Layout/Layout";
// import icon
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "./assets/images/CircleLogo.png";
// All imported component
import Dashboard from "./Pages/VCarbonPages/Dashboard";
// import Home from "./Pages/Home";
// import Products from "./Pages/Products";
// import Crm from "./Pages/Crm";
import Adminstration from "./Pages/Adminstration";
// import Transportation from "./Pages/Transportation/Transportation";
import ProfileRoute from "./Pages/AdminProfile/ProfileRoute";
import Reports from "./Pages/VCarbonPages/Reports";
// import EcoLabel from "./Pages/EcoLabel/EcoLabel";
// import EcoProduct from "./Pages/EcoProduct/EcoProduct";
// import PurchaseOrder from "./Pages/PurchaseOrder/PurchaseOrder";
// import c.s.s
import "./App.css";
// welcome page
import Welcome from "./component/Welcome/Welcome";
// Track Page component
import Track from "./Pages/Track/Track";
import TrackerPage from "./Pages/TrackerPage/TrackerPage";
import Notification from "./Pages/Notifications/Notification";
import QuestionnairePage from "./Pages/ListingPage/ListingPage copy";
import ComponentPage from "./Pages/ComponentPage/ComponentPage";
// import React Chat box
import Chatbot from "react-chatbot-kit";
import { Button } from "@mui/material";
// import React Chat Box Component start here
import config from "./ReactChatBox/config";
import MessageParser from "./ReactChatBox/MessageParser";
import ActionProvider from "./ReactChatBox/ActionProvider";
import "react-chatbot-kit/build/main.css";
// ends here
// import Component here
import Overview from "./Pages/Overview/Overview";
import Disclosures from "./Pages/Disclosures/Disclosures";
import Users from "./Pages/Users/Users";
import Vendors from "./Pages/Vendors/Vendors"
import NewUser from "./Pages/NewUser/newUser";
import Sites from "./Pages/Sites/Sites";
// import SiteAnalysis from "./Pages/SiteAnalysis/SiteAnalysis";
import SiteAnalysis from "./Pages/Analysis/SiteAnalysis";
import VendorAnalysis from "./Pages/Analysis/VendorAnalysis";
import VendorSimulationReport from "./Pages/Analysis/VendorSimulationReport";
import AcceptRejectRelation from "./Pages/Vendors/AcceptRejectRelation";

function App() {
  React.useEffect(() => {
    function handleResize() {
      console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
  });

  const [cookies, setCookie] = useCookies(["user"]);
  const [isOpen, setIsOpen] = useState(false);

  const validator = (input) => {
    if (!input.replace(/\s/g, '').length) //check if only composed of spaces
      return false;
    if (input.length > 1) //check if the message is empty
      return true;
    return false
  }

  let id = useParams();
  return (
    <div>
      {!cookies.token && <>
        <Welcome />
      </>
      }
      {/* {!cookies.token && <Login />} */}
      {cookies.token && (
        <>
          <Router>
            <div>
              <Switch>
                <Route path="/track/:id">
                  <Track />
                </Route>
                <Layout>
                  <Route exact path="/">
                    <Overview />
                  </Route>
                  <Route path="/monitor/emission">
                    <Dashboard />
                  </Route>
                  <Route path="/monitor/vendors">
                    <VendorAnalysis />
                  </Route>
                  
                  <Route path="/monitor/sites">
                    <SiteAnalysis />
                  </Route>
                  <Route path="/disclosures">
                    <Disclosures />
                  </Route>
                  <Route path="/manage/employees">
                    <Users />
                  </Route>
                  <Route path="/manage/vendors">
                    <Vendors />
                  </Route>
                  <Route path="/manage/sites">
                    <Sites />
                  </Route>
                  <Route path="/simulate/vendors">
                    <VendorSimulationReport />
                  </Route>
                  <Route path="/validations">
                    <Reports />
                  </Route>
                  <Route path="/adminstration">
                    <Adminstration />
                  </Route>
                  <Route path="/admin">
                    <ProfileRoute />
                  </Route>

                  <Route path="/tracker">
                    <TrackerPage />
                  </Route>
                  <Route path="/calculate">
                    <QuestionnairePage />
                  </Route>
                  <Route path="/exploreGDP">
                    <ComponentPage />
                  </Route>
                  <Route path="/notification">
                    <Notification />
                  </Route>
                  <Route path="/addVendorToRelations">
                    <AcceptRejectRelation />
                  </Route>
                </Layout>
              </Switch>
            </div>
            <div
              // className="chatbot"
              style={{
                //  border:"2px solid red",
                top: 190,
                right: 10,
                position: "fixed",
                overflow: "auto",
                zIndex: 100,
              }}
            >
              {isOpen && (
                <Chatbot
                  config={config}
                  actionProvider={ActionProvider}
                  messageParser={MessageParser}
                  validator={validator}
                // runInitialMessagesWithHistory={true}
                // messageHistory={'123'}
                // saveMessages={saveMessages}
                />
              )}
            </div>
            <div style={{ display: "grid", placeItems: "end" }}>
              <Button className="clear-btn" onClick={() => setIsOpen(!isOpen)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
                <div
                  style={{
                    position: "fixed",
                    width: "60px",
                    height: "60px",
                    bottom: "30px",
                    right: "15px",
                    backgroundColor: "#000",
                    color: "#FFF",
                    borderRadius: "50px",
                    textAlign: "center",
                    fontSize: "30px",
                    zIndex: 100,
                  }}
                >
                  {!isOpen ? (
                    <img
                      height="40"
                      width="40"
                      style={{ color: "#fff" }}
                      src={CircleIcon}
                    />
                  ) : (
                    <CancelIcon />
                  )}
                </div>
              </Button>
              {/* <Button className="bot-btn" onClick={() => setIsOpen(!isOpen)}>
            Close
          </Button> */}
            </div>
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
