import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
    newaccount: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export const InviteUserDialog = ({ open, onClose, onSave }) => {

  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const roles = [{ value: 'user', label: 'User' }, { value: 'admin', label: 'Admin' }]
  const handleSave = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      onSave({ name, email, role });
      onClose();
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Only allow alphabets (A-Z and a-z) and spaces
    if (/^[A-Za-z\s]*$/.test(inputValue)) {
      setName(inputValue);
    }
  };
  const handleCancel = () => {
    // Close the dialog without saving
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <form className={classes.root} onSubmit={handleSave}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(e) => handleNameChange(e)}
            required
          />
          <TextField
            type="email"
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            select
            label="Role"
            fullWidth
            margin="normal"
            variant="outlined"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {roles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </TextField>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Button type="submit" variant="contained" color="primary" style={{ marginRight: '8px' }}>
              Invite
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
