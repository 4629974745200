import React from "react";
import { create } from "ipfs-http-client";
// import Image Upload CSS
import "./ImageUpload.css";
// image upload section
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../utils/constants";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));

const client = create("https://ipfs.infura.io:5001/api/v0");

const UploadDocs = (props) => {
  const [load, setLoad] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [imageUrls, setImageUrls] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  let   [count, setCount] = React.useState(0);


  let header = HEADERS2(cookies.token, cookies.Nid);

  const handleRemove = (i) => {
    let document = docs;
    document.splice(i, 1);
    setDocs(document);
    setCount(count + 1);
  };
  const handleReportUpdate = async (val) => {
    try {
      const { data } = await axios.put(
        CONSTANTS.API_URL + `/chgReport/attach`,
        {attachments:docs, id: props.id},
        header
      );
      console.log(data, 'this is data updated')
    }catch(e){
      console.log(e)
    }
  }
  const handleSubmit = async () => {
    try {
      if(!props.id){
        return 
      }
      setLoad(true);
      const formData = new FormData();
      Object.values(files).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("fileName", docs);
      formData.append("user_id", cookies.user_id);
      if(props.table==="master"){
        formData.append("table", "master");
      }
      let h = header;
      h.headers["enctype"] = "multipart/form-data";
      const { data } = await axios.post(
        CONSTANTS.UPLOAD_URL + `/attachment/uploadAll`,
        // CONSTANTS.UPLOAD_URL + `/attachment/uploadAll/${props.id}` // before
        formData,
        h
      );
      // setLoad(true);
      if(props.type&& props.type==='report'){
        handleReportUpdate(data.result)
      }
      props.setData(data.result);
      props.back();
    } catch (e) {
      setLoad(false);
    }
  };

  React.useEffect(() => {
    console.log("selected docs", count);
  }, [count]);

  async function onChange(e) {
    try {
      const newFile = e.target.files[0];
      let img = files;
      let document = docs;
      document.push(newFile.name);
      img.push(newFile);
      setFiles(img);
      setDocs(document);
      setCount(count + 1);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  return (
    <>
      <div className="row" style={{ textAlign: "center" }}>
        <h2>Upload Your Documents</h2>
        <h5>in JPEG, PDF or PNG </h5>
        <h5>max 100mb or 5 files supported </h5>
        {!load && (
          <>
          <div style={{ display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center" }}>
            <Button
              style={{ margin: "10px" }}
              variant="contained"
              component="label"
            >
              Upload A File
              <input
                hidden
                accept="image/*"
                type="file"
                id="get_file"
                name="files"
                onChange={onChange}
              />
            </Button>
            <Button
              style={{ margin: "10px" }}
              variant="contained"
              type="submit"
              onClick={() => props.back()}
            >
              Go Back
            </Button>
            </div>
          </>
        )}
        {load && (
          <>
            <Button
              style={{ margin: "10px" }}
              variant="contained"
              component="label"
            >
              Upload in progress
            </Button>
          </>
        )}
        <br />
        <br />
        {docs && (
          <>
            <Typography>Documents selected</Typography>
            {docs.map((d, i) => (
              <React.Fragment key={i}>
                <Button
                  key={i}
                  style={{ margin: "10px" }}
                  variant="outlined"
                  disabled={load}
                >
                  {d}
                  <CloseIcon onClick={() => handleRemove(i)} />
                </Button>
                <br />
              </React.Fragment>
            ))}
            <br />
            <br />
            <Button
              style={{  width:"20%", marginLeft:"auto", marginRight:"auto" }}
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              disabled={load}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default UploadDocs;
