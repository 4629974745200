import React, { useRef } from "react";
import axios from "axios";
import Toaster from "../../component/Toaster/Toaster";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
// import headers & constants
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import Stack from "@mui/material/Stack";
// import snackbar toaster material ui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import component
import FactorySitePopUp from "../SitesPopUp/FactorySitePopUp";
// import icons
import Fab from "@mui/material/Fab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import Grid from "@mui/material/Grid";
import DangerousIcon from "@mui/icons-material/Dangerous";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Cancel Presentation Icon
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
// import icon button
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
// import NOT Found PID Component
import NotFoundPID from "../../component/NotFoundPID/NotFoundPID";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflow:"auto"
};

const selectStatus = [
  { label: "office", value: "office" },
  { label: "factory", value: "factory" },
  { label: "warehouse", value: "warehouse" },
];

const Factory = (props) => {
  const isInitialMount = useRef(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [id, setId] = React.useState();
  const [rows, setRows] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [columns, setColumns] = React.useState([]);
  const [columnsValues, setColumnsValues] = React.useState([]);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState(false);
  const [selectObj, setSelectObj] = React.useState({});
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table
  // modal state
  const [officeModalOpen, setOfficeModalOpen] = React.useState(false);
  const handleModalOpen = () => setOfficeModalOpen(true);
  const handleModalClose = () => setOfficeModalOpen(false);
  const [officeSiteData, setOfficeSiteData] = React.useState({
    id: "",
    factoryName: "",
    factoryAddress: "",
    factoryLocation: "",
    totalWorkers: "",
    productLines: "",
    capacity: "",
    status: "",
    certifications: "",
  });
  // post api office create state
  const [officeData, setOfficeData] = React.useState({});
  const [plcLineId, setPlcLineId] = React.useState([]); // plc api get hook

  const [toaster, setToaster] = React.useState(false);
  const [loadState, setloadState] = React.useState(true);
  const [editPopup, setEditPopup] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState({});
  const [newDetails, setNewDetails] = React.useState({});
  const [detailsState, setDetailsState] = React.useState(false);
  // dialog state
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [edit, setEdit] = React.useState({
    user_id: cookies.user_id,
    PID: "",
    Id: "",
  });
  const [formValues, setFormValues] = React.useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [submitState, setSubmitState] = React.useState({
    Id: "",
  });
  // MISSING PID STATE
  const [missingPID, setMissingPID] = React.useState(true);

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  
  const handlebarSubmit = (event) => {
    event.preventDefault();
  };

  const addFormFields = () => {
    setFormValues([...formValues, { key: "", value: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleEditSubmit = async () => {
    try {
      let temp = edit;
      console.log(temp, "temp here");
      let fv = formValues;
      for (let i = 0; i < fv.length; i++) {
        let key = formValues[i].key;
        temp[key] = formValues[i].value;
      }
      console.log("temp", temp);
      // setFullScreenLoader(true);
      const { data } = await axios.put(
        API_URL + `/sites/update`,
        temp,
        headers
      );
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        console.log(data);
        getFactorySiteData(); // call an get api
        setSuccessMsg("Records updated successfully");
        setToaster(true); // open toaster state
        setOpens(true);
        setEditPopup(!editPopup);
      }
      // setEditPopup(false);
      // setFullScreenLoader(false);
      setloadState(false);
    } catch (e) {
      setErrorMsg(
        "Unable to update records: Please check the inputs again " + e
      );
      console.log(e, "error in handle select");
      // setFullScreenLoader(true);
    }
  };

  const handleInput = (event) => {
    const newState = {
      ...submitState,
      [event.target.name]: event.target.value,
    };
    setSubmitState(newState);
    console.log(newState);
  };

  const handleChanges = (i, e) => {
    let newFormValues = [...formValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    setFormValues(newFormValues);
  };

  const handleDetailsObj = () => {
    setDetailsState(!detailsState);
  };

  const handleClearToaster = () => {
    setSuccessMsg();
    setErrorMsg();
  };

  const handleDailogOpen = () => {
    setDialogOpen(true);
    handleDetailsObj();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // React useEffect Calling here
  React.useEffect(() => {
    // PID MISSING CHECK
    if(cookies.profileStatus==='complete'){
      setMissingPID(false);
    }
    if (isInitialMount.current) {
      getFactorySiteData(); // call an get api
      isInitialMount.current = false;
    }
  });

  React.useEffect(() => {
    getPlcData(); // call an get api
  },[]);

  // handle bar function of production line
  const handleOfficeInput = (event, i, name) => {
    let office = officeSiteData;
    if (i && name) {
      office[name] = i;
      console.log(office, "select status input");
    } else {
      const officeState = {
        ...officeSiteData,
        [event.target.name]: event.target.value,
      };
      office = officeState;
      console.log(officeState, "select status input");
    }
    setOfficeSiteData(office);
  };

  // call an post api
  const createOfficeData = async () => {
    try {
      const insertOfficeBody = {
        id: officeSiteData.id,
        NID: cookies.Nid,
        PID: cookies.PID,
        factoryName: officeSiteData.factoryName,
        factoryAddress: officeSiteData.factoryAddress,
        factoryLocation: officeSiteData.factoryLocation,
        totalWorkers: officeSiteData.totalWorkers,
        productLines: officeSiteData.productLines,
        capacity: officeSiteData.capacity,
        status: officeSiteData.status,
        certifications: officeSiteData.certifications,
      };
      console.log(insertOfficeBody, "successfully enterd insert Office Body");
      const data = await axios.post(
        API_URL + `/sites/create`,
        insertOfficeBody,
        headers
      );
      if (!data) {
        setOfficeData({});
      } else {
        setOfficeData(data);
        console.log(data, "sites api created successfully");
        handleModalClose(); // close modal overlay
        getFactorySiteData(); // get factory site data
        setSuccessMsg("Factory Data Created Successfully"); // open toaster message
        setToaster(true); // open toaster state
      }
    } catch (err) {
      console.log(err, "err in office sites data");
    }
  };

  // call an api to get master data in db
  const getFactorySiteData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/sites/allSite/PID?status=factory`, //
        headers
      );
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      console.log(data, "data table");
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      let Allcolumns = [];
      let Allvalues = [];

      for (let j = 0; j < data.length; j++) {
        for (let key in data[j]) {
          if (
            key !== "__v" &&
            key !== "_id" &&
            key !== "PID" &&
            key !== "NID" &&
            key !== "user_id" &&
            key !== "address" &&
            key !== "vehicles" &&
            Allcolumns.indexOf(key) === -1
          ) {
            Allcolumns.push(key);
          }
        }
        Allvalues.push(data[j]);
      }
      let temp = Allcolumns;
      temp.push({
        name: "action",
        label: "Action",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => <>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center">

                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    onClick={() => handleEdit(data, tableMeta.rowIndex)}
                  >
                    <EditIcon />
                  </Fab>

                  <Fab
                    size="small"
                    color="primary"
                    aria-label="delete"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    onClick={() =>
                      deleteOfficeSitesData(data, tableMeta.rowIndex)
                    }
                  >
                    <Delete />
                  </Fab>
                </Stack>
              </div>
          </>,
        },
      });
      setColumns(temp);
      setColumnsValues(Allvalues);
      setColumnsValues(Allvalues);
      setloadState(false);
    } catch (err) {
      setMissingPID(true); // PID MISSING STATE
      console.log(err);
      setErrorMsg(`failed to fetch data: ${err.message}`);
    }
  };

  // call an api to get master data in db
  const getPlcData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/plc/get/PID`,
        headers
      );
      if (!data) {
        setPlcLineId([]);
      } else {
        setPlcLineId(data);
        console.log(data, "plc get data api");
      }
    } catch (err) {
      console.log(`failed to fetch data: ${err.message}`);
    }
  };

  const handlebarClick = (obj) => {
    setId(obj.id);
    setEditPopup(!editPopup);
    setSelectObj(obj);
  };

  const handleEdit = async (o, idx) => {
    let id = idx;
    console.log(id, "id here");
    let obj = o[id];
    setId(id);
    setEditPopup(!editPopup);
    let editObj = [];
    if (obj) {
      // obj.productId = o.productId;
      obj.user_id = cookies.user_id;
      obj.PID = cookies.PID;
      let keys = Object.keys(obj);
      let values = Object.values(obj);
      for (let i = 0; i < keys.length; i++) 
      if (
        keys[i] !== "__v" &&
        keys[i] !== "_id" &&
        keys[i] !== "id" &&
        keys[i] !== "employeeId" &&
        keys[i] !== "user_id" &&
        keys[i] !== "NID" &&
        keys[i] !== "PID"
      )
      {
        editObj.push({ key: [keys[i]], value: values[i] });
      }
      setEdit(obj);
      setFormValues(editObj);
    }
  };

  // delete from office sites data table
  const deleteOfficeSitesData = async (dataObj, index) => {
    try {
      let Id = dataObj[index].id;
      const data = await axios.delete(
        API_URL + `/sites/PID/delete?id=${Id}`,
        headers
      );
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getFactorySiteData();
        setSuccessMsg("Deleted Successfully Factory Sites");
        setOpens(true);
        setToaster(true); // open toaster state
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  const deleteMasterDataBatch = async (dataObj) => {
    try {
      console.log(dataObj, "obj");
      let indexes = [];
      for (let o of dataObj) {
        console.log(rows[o.dataIndex], o.dataIndex, "obj");
        indexes.push(rows[o.dataIndex].productId);
      }
      console.log(indexes, " these are indexes");
      const { data } = await axios.delete(
        API_URL + `/masterdata/PID/delete/batch`,
        { ...headers, data: indexes }
      );
      console.log(data, "inv data");
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getFactorySiteData();
        setSuccessMsg("Deleted from master data");
        setOpens(true);
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  const options = {
    filterType: "checkbox",
    print: false,
    onChangeRowsPerPage: (numberOfRows) => {
      console.log(numberOfRows, "num");
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    onRowSelectionChange: (k, l, selectedRow) => {
      setSelectedRow(selectedRow);
      let selectTemp = [];
      for (let i = 0; i < selectedRow.length; i++) {
        selectTemp.push(rows[selectedRow[i]]);
      }
      setSelectedRowObj(selectTemp);
    },
    selectedRow: selectedRow,
    onCellClick: async (d, rowState) => {
      if (rowState.colIndex !== columns.length - 1) {
        setViewDetails(rows[rowState.dataIndex]);
        setNewDetails(rows[rowState.dataIndex]);
        handleDetailsObj();
      }
    },
    onRowsDelete: async (e) => {
      console.log(e, "e");
      await deleteMasterDataBatch(e.data);
    },
  };

  return (
    <React.Fragment>
      {missingPID && <NotFoundPID />}
      {loadState && <Toaster msg={"Fetching real-time data"} />}
      {rows && (
        <MUIDataTable
          title={
            <>
              {props.role !== "admin" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginTop: "14px" }}
                    >
                      Factory
                    </Typography>
                    <Button
                      variant="contained"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                      onClick={handleModalOpen}
                    >
                      Create New
                    </Button>
                    <Fab
                      onClick={getFactorySiteData}
                      size="small"
                      color="primary"
                      aria-label="refresh"
                      style={{ margin: "10px" }}
                    >
                      <RefreshIcon />
                    </Fab>
                  </div>
                </>
              )}
            </>
          }
          data={columnsValues}
          columns={columns}
          options={options}
        />
      )}

      <div>
        <Modal
          open={detailsState}
          onClose={handleDetailsObj}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ textAlign: "center" }}
        >
          <Box sx={style}>
            <FactorySitePopUp
              close={handleDetailsObj}
              obj={viewDetails}
              id={id}
              load={getFactorySiteData}
              click={handlebarClick}
            />
          </Box>
        </Modal>
        {/* factory site pop up data component start here */}
        {/* dailog box component start here */}
      </div>

      {/* create new modal office overlay start here */}
      <div>
        <Modal
          open={officeModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto", float: "right" }}
              onClick={handleModalClose}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
            <Typography style={{ margin: "1rem", textAlign: "center" }}>
              Enter Your Factory Details
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Site Id"
                  variant="outlined"
                  name="id"
                  style={{ width: 300, marginRight: "1rem" }}
                  value={officeSiteData.id}
                  onChange={handleOfficeInput}
                />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="status"
                  onChange={(event, newValue) => {
                    console.log(newValue, "new status Value here");
                    handleOfficeInput(event, newValue.value, "status");
                  }}
                  options={selectStatus}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Status" />
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Site Name"
                  variant="outlined"
                  name="factoryName"
                  style={{ width: 300, marginRight: "1rem" }}
                  value={officeSiteData.factoryName}
                  onChange={handleOfficeInput}
                />
                <TextField
                  id="outlined-basic"
                  label="Site Location"
                  variant="outlined"
                  name="factoryLocation"
                  style={{ width: 300 }}
                  value={officeSiteData.factoryLocation}
                  onChange={handleOfficeInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Site Address"
                  variant="outlined"
                  name="factoryAddress"
                  style={{ width: 300, marginRight: "1rem" }}
                  value={officeSiteData.factoryAddress}
                  onChange={handleOfficeInput}
                />
                <TextField
                  id="outlined-basic"
                  label="Total Workers"
                  variant="outlined"
                  name="totalWorkers"
                  style={{ width: 300 }}
                  value={officeSiteData.totalWorkers}
                  onChange={handleOfficeInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Total Capacity"
                  variant="outlined"
                  name="capacity"
                  style={{ width: 300, marginRight: "1rem" }}
                  value={officeSiteData.capacity}
                  onChange={handleOfficeInput}
                />
                {/* <TextField
                  id="outlined-basic"
                  label="Product Lines"
                  variant="outlined"
                  name="productLines"
                  style={{ width: 300 }}
                  value={officeSiteData.productLines}
                  onChange={handleOfficeInput}
                /> */}
                <Autocomplete
                  disablePortal
                  options={plcLineId}
                  getOptionLabel={(option) =>
                    option ? option.lineName : "Select A Production Line Id"
                  }
                  name="productLines"
                  onChange={(event, newValue) => {
                    // setProductionLineId(newValue.lineName);
                    handleOfficeInput(event, newValue.value, "productLines");
                  }}
                  sx={{ width: 300 }}
                  required
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Lines"
                      variant="outlined"
                      // style={{ width: "250px", marginTop: "1rem" }}
                      // fullWidth
                    />
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Certifications"
                  variant="outlined"
                  name="certifications"
                  style={{ width: 300, marginRight: "1rem" }}
                  value={officeSiteData.certifications}
                  onChange={handleOfficeInput}
                />
                {/* Add an empty div to maintain the spacing */}
                <div style={{ width: 300 }}></div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                width: "90%",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={createOfficeData}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {/* create new modal office overlay ends here */}

      {/* edit modal pop up component start here */}
      <Modal
        open={editPopup}
        onClose={() => setEditPopup(!editPopup)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Factory Details
            </Typography>
            <br />

            <TextField
              id="outlined-basic"
              label="ID"
              name="productId"
              value={edit.id ?? "Not Found"}
              disabled
              onChange={handleInput}
              variant="outlined"
            />
            <br />

            {/* Start new form for key & value */}
            <form onSubmit={handlebarSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <input
                    name="key"
                    value={element.key || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  <input
                    name="value"
                    value={element.value || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  {index ? (
                    <DangerousIcon
                      style={{ color: "red ", cursor: "pointer" }}
                      onClick={() => removeFormFields(index)}
                    />
                  ) : null}
                </div>
              ))}
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                type="submit"
                onClick={() => addFormFields()}
              >
                Add
              </Button>
            </form>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleEditSubmit}
                variant="contained"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                onClick={() => setEditPopup(!editPopup)}
                variant="contained"
                type="submit"
              >
                Go Back
              </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
      {/* edit modal pop up component ends here */}

      {/* error & success toaster toaster */}
      {(errormsg || successmsg) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={toaster}
            autoHideDuration={4000}
            onClose={handleClearToaster}
          >
            <Alert
              onClose={handleClearToaster}
              severity={errormsg ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Factory;
