import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const NotFoundPID = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // navigate company profile page
  const handleRedirectCompanyPage = () => {
    history.push("/admin/profile");
  }   

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display:"grid", placeItems:"center" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Missing Company ID
            </Typography>
            <Typography style={{ margin:"1rem" }}>
                Click below to register a new company or join existing
            </Typography>
            <Button
              onClick={handleRedirectCompanyPage}
              variant="contained"
              style={{
                marginTop: "1rem",
              }}
            >
              Register/Enroll
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NotFoundPID;
