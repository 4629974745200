import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { CONSTANTS, HEADERS2 } from '../component/utils/constants';
import { useCookies } from 'react-cookie';
import axios from 'axios';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
    overflow: 'hidden',
    width: '60%',
  },
  text: {
    marginLeft:"auto",
    marginRight:"auto"
  }
}));

function SwipeableTextMobileStepper(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);
  const [tutorialSteps, setTutorialSteps] = React.useState([]);
  const [imgs, SetImgs] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);

  // API_URL
  const API_URL = CONSTANTS.UPLOAD_URL
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleImages = async() => {
    let images=[]
    if(props.images){
      for(let i of props.images){
        console.log(i,'this is i')
        let { data } = await axios.get(
          API_URL + `/attachment/get/${i}`,
          headers
        );
        console.log(data,'hello123')
        images.push(data)
      }
    }
    SetImgs(images)
    setTutorialSteps(props.images?props.images:[])
    setMaxSteps(props.images.length?props.images.length:0)
    setActiveStep(0);
  };
  
  React.useEffect(() => {
    console.log(props.images,'we have images')
    handleImages();
  },[]);

  return (
   <>
      {imgs.length>0 &&
        <div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
        <Typography className={classes.text}>
          {imgs.length>0? imgs[activeStep]?.id:'No images attached'}
          {/* {tutorialSteps.length>0? tutorialSteps[activeStep].label :'No Images attached'} */}
        </Typography>
      </Paper>
        <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imgs.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <embed className={classes.img} src={`data:${step.contentType};base64,${step.data}`} alt={step.id} />
              // <img className={classes.img} src={`data:${step.contentType};base64,${step.data}`} alt={step.id} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
      </div>}
      </>
  );
}

export default SwipeableTextMobileStepper;
