import React, { useRef } from "react";
import axios from "axios";
import Toaster from "../../component/Toaster/Toaster";
// import npm library for mui-datatables
import MUIDataTable from "mui-datatables";
// import headers & constants
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import Stack from "@mui/material/Stack";
// import Cancel Presentation Icon
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
// import icon button
import IconButton from "@mui/material/IconButton";
// import snackbar toaster material ui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Card, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import OfficeEmployeePopUp from "./OfficeEmployeePopUp";
import RefreshIcon from "@mui/icons-material/Refresh";
// Fab for styling
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import NOT FOUND PID Component
import NotFoundPID from "../../component/NotFoundPID/NotFoundPID";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const selectOffice = [
  { label: "Temporary", value: "Temporary" },
  { label: "Permanent", value: "Permanent" },
];

const selectTypeofWorker = [
  { label: "Office", value: "Office" },
  { label: "Factory", value: "Factory" },
];

const OfficeEmployees = (props) => {
  const isInitialMount = useRef(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [id, setId] = React.useState();
  const [rows, setRows] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [columns, setColumns] = React.useState([]);
  const [columnsValues, setColumnsValues] = React.useState([]);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState(false);
  const [selectObj, setSelectObj] = React.useState({});
  const [opens, setOpens] = React.useState(false); // snackbar state for error & success
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table

  const [toaster, setToaster] = React.useState(false);
  const [loadState, setloadState] = React.useState(true);
  const [editPopup, setEditPopup] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState({});
  const [newDetails, setNewDetails] = React.useState({});
  const [detailsState, setDetailsState] = React.useState(false);
  const [image, setImage] = React.useState(false);
  // dialog state
  const [dialogopen, setDialogOpen] = React.useState(false);
  // modal state
  const [employeeModalOpen, setEmployeeModalOpen] = React.useState(false);
  const handleModalOpen = () => setEmployeeModalOpen(true);
  const handleModalClose = () => setEmployeeModalOpen(false);
  const [officeEmployeeData, setOfficeEmployeeData] = React.useState({
    user_id: cookies.user_id,
    PID: cookies.PID,
    employeeName: "",
    employeeId: "",
    departmentId: "",
    employmentType: "",
    workerType: "",
    siteId: "",
    address: "",
    distance: "",
  });
  // post api employee create state
  const [employeeData, setEmployeeData] = React.useState({});
  const [edit, setEdit] = React.useState({
    user_id: cookies.user_id,
    PID: cookies.PID,
    employeeId: officeEmployeeData.employeeId,
  });
  const [formValues, setFormValues] = React.useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [submitState, setSubmitState] = React.useState({
    employeeId: "",
  });
  // MISSING PID STATE
  const [missingPID, setMissingPID] = React.useState(true);

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);

  const handleDetailsObj = () => {
    setDetailsState(!detailsState);
  };

  const handleClearToaster = () => {
    setSuccessMsg();
    setErrorMsg();
  };

  const handleDailogOpen = () => {
    setDialogOpen(true);
    handleDetailsObj();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handlebarSubmit = (event) => {
    event.preventDefault();
  };

  const addFormFields = () => {
    setFormValues([...formValues, { key: "", value: "" }]);
  };

  const handleEditSubmit = async () => {
    try {
      let temp = edit;
      console.log(temp, "temp here");
      let fv = formValues;
      for (let i = 0; i < fv.length; i++) {
        let key = formValues[i].key;
        temp[key] = formValues[i].value;
      }
      console.log("temp", temp);
      // setFullScreenLoader(true);
      const { data } = await axios.put(
        API_URL + `/employee/update`,
        temp,
        headers
      );
      console.log(temp,"update body here")
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        console.log(data,"updated api records successfully");
        getEmployeesData();
        setSuccessMsg("Records updated successfully");
        setToaster(true); // open toaster state
        setOpens(true);
        setEditPopup(!editPopup);
      }
      // setEditPopup(false);
      // setFullScreenLoader(false);
      setloadState(false);
    } catch (e) {
      setErrorMsg(
        "Unable to update records: Please check the inputs again " + e
      );
      console.log(e, "error in handle select");
      // setFullScreenLoader(true);
    }
  };

  const handleInput = (event) => {
    const newState = {
      ...submitState,
      [event.target.name]: event.target.value,
    };
    setSubmitState(newState);
    console.log(newState);
  };

  const handleChanges = (i, e) => {
    let newFormValues = [...formValues];
    let newVal = e.target.value;
    newFormValues[i][e.target.name] = newVal;
    setFormValues(newFormValues);
  };

  // React useEffect Calling here
  React.useEffect(() => {
    // PID MISSING CHECK
    if(cookies.profileStatus==='complete'){
      setMissingPID(false);
    }
    if (isInitialMount.current) {
      console.log("once");
      getEmployeesData();
      isInitialMount.current = false;
    }
  });

  // handle bar function of employee
  const handleEmployeeInput = (event, i, name) => {
    let emp = officeEmployeeData;
    if (i && name) {
      emp[name] = i;
      console.log(emp, "select status input");
    } else {
      const aqlState = {
        ...officeEmployeeData,
        [event.target.name]: event.target.value,
      };
      emp = aqlState;
      console.log(aqlState, "select status input");
    }
    setOfficeEmployeeData(emp);
  };

  // call an post api
  const createEmployeeData = async () => {
    try {
      if (!officeEmployeeData.employeeName) {
        setErrorMsg("Please Enter Employee Name");
        setToaster(true);
      } else if (!officeEmployeeData.employeeId) {
        setErrorMsg("Please Enter Employee Id");
        setToaster(true);
      } else if (!officeEmployeeData.departmentId) {
        setErrorMsg("Please Enter Department Id");
        setToaster(true);
      } else if (!officeEmployeeData.employmentType) {
        setErrorMsg("Please Enter Employment Type");
        setToaster(true);
      } else if (!officeEmployeeData.workerType) {
        setErrorMsg("Please Enter Worker Type");
        setToaster(true);
      } else if (!officeEmployeeData.siteId) {
        setErrorMsg("Please Enter Site Id");
        setToaster(true);
      } else if (!officeEmployeeData.address) {
        setErrorMsg("Please Enter Address");
        setToaster(true);
      } else if (!officeEmployeeData.distance) {
        setErrorMsg("Please Enter Distance from Office");
        setToaster(true);
      }
      const insertEmployeeBody = {
        user_id: cookies.user_id,
        PID: "",
        employeeName: officeEmployeeData.employeeName,
        employeeId: officeEmployeeData.employeeId,
        departmentId: officeEmployeeData.departmentId,
        employmentType: officeEmployeeData.employmentType,
        workerType: officeEmployeeData.workerType,
        siteId: officeEmployeeData.siteId,
        address: officeEmployeeData.address,
        distance: officeEmployeeData.distance,
      };
      console.log(insertEmployeeBody, "successfully enterd insert Office Body");
      const data = await axios.post(
        API_URL + `/employee/create`,
        insertEmployeeBody,
        headers
      );
      if (!data) {
        setEmployeeData({});
      } else {
        setEmployeeData(data);
        console.log(data, "employee api created successfully");
        handleModalClose(); // close modal overlay
        getEmployeesData(); // get employee data api calling
        setSuccessMsg("Employee Data Created Successfully"); // open toaster message
        setToaster(true); // open toaster state
      }
    } catch (err) {
      console.log(err, "err in employee data");
    }
  };

  // call an api to get master data in db
  const getEmployeesData = async () => {
    try {
      const { data } = await axios.get(
        API_URL + `/employee/allEmployee/PID?workerType=Office`, // add employee api later
        headers
      );
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      console.log(data, "data table");
      data.error || data?.length < 0 ? setRows([]) : setRows(data);
      let Allcolumns = [];
      let Allvalues = [];

      for (let j = 0; j < data.length; j++) {
        for (let key in data[j]) {
          if (
            key !== "__v" &&
            key !== "_id" &&
            key !== "PID" &&
            key !== "NID" &&
            key !== "user_id" &&
            Allcolumns.indexOf(key) === -1
          ) {
            Allcolumns.push(key);
          }
        }
        Allvalues.push(data[j]);
      }
      let temp = Allcolumns;
      temp.push({
        name: "action",
        label: "Action",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center">

                  <Fab
                    size="small"
                    color="primary"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    aria-label="edit"
                    onClick={() => handleEdit(data, tableMeta.rowIndex)}
                  >
                    <EditIcon />
                  </Fab>

                  <Fab
                    size="small"
                    color="primary"
                    // style={{ color: "white", backgroundColor: "#1976D2" }}
                    style={{ color: "white", backgroundColor: "#003E35" }}
                    aria-label="delete"
                    onClick={() => deleteEmployeeData(data, tableMeta.rowIndex)}
                  >
                    <Delete />
                  </Fab>
                </Stack>
              </div>
            </>
          ),
        },
      });
      setColumns(temp);
      setColumnsValues(Allvalues);
      setColumnsValues(Allvalues);
      setloadState(false);
    } catch (err) {
      setMissingPID(true); // MISSING PID STATE
      console.log(err);
      setErrorMsg(`failed to fetch data: ${err.message}`);
    }
  };

  const handlebarClick = (obj) => {
    setId(obj.id);
    setEditPopup(!editPopup);
    setSelectObj(obj);
  };

  const handleEdit = async (o, idx) => {
    let id = idx;
    console.log(id, "id here");
    let obj = o[id];
    setId(id);
    setEditPopup(!editPopup);
    let editObj = [];
    if (obj) {
      // obj.productId = o.productId;
      obj.user_id = cookies.user_id;
      obj.PID = cookies.PID;
      let keys = Object.keys(obj);
      let values = Object.values(obj);
      for (let i = 0; i < keys.length; i++) 
      if (
            keys[i] !== "__v" &&
            keys[i] !== "_id" &&
            keys[i] !== "ID" &&
            keys[i] !== "employeeId" &&
            keys[i] !== "user_id" &&
            keys[i] !== "NID" &&
            keys[i] !== "PID"
         )
      {
        editObj.push({ key: [keys[i]], value: values[i] });
      }
      setEdit(obj);
      setFormValues(editObj);
    }
  };

  const deleteMasterDataBatch = async (dataObj) => {
    try {
      console.log(dataObj, "obj");
      let indexes = [];
      for (let o of dataObj) {
        console.log(rows[o.dataIndex], o.dataIndex, "obj");
        indexes.push(rows[o.dataIndex].employeeId);
      }
      console.log(indexes, " these are indexes");
      const { data } = await axios.delete(
        API_URL + `/employee/PID/${cookies.PID}/delete/batch`,
        { ...headers, data: indexes }
      );
      console.log(data, "inv datat");
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getEmployeesData();
        setSuccessMsg("Deleted from master data");
        setOpens(true);
        setToaster(true); // open toaster state
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  // delete from journey data table
  const deleteEmployeeData = async (dataObj, index) => {
    try {
      let Id = dataObj[index].employeeId;
      const data = await axios.delete(
        API_URL + `/employee/PID/delete?employeeId=${Id}`,
        headers
      );
      if (data.error) {
        setErrorMsg(data.error);
      } else {
        getEmployeesData();
        setSuccessMsg("Deleted Successfully Office Employee");
        setOpens(true);
        setToaster(true); // open toaster state
      }
    } catch (err) {
      setErrorMsg(err);
      console.log(err);
    }
  };

  const options = {
    filterType: "checkbox",
    print: false,
    onChangeRowsPerPage: (numberOfRows) => {
      console.log(numberOfRows, "num");
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    onRowSelectionChange: (k, l, selectedRow) => {
      setSelectedRow(selectedRow);
      let selectTemp = [];
      for (let i = 0; i < selectedRow.length; i++) {
        selectTemp.push(rows[selectedRow[i]]);
      }
      setSelectedRowObj(selectTemp);
    },
    selectedRow: selectedRow,
    // onRowClick: (rowData, rowState,a) => {
    //   setViewDetails(rows[rowState.dataIndex]);
    //   setNewDetails(rows[rowState.dataIndex]);
    //   handleDetailsObj();
    //   // handleInvoiceOpen(); // something to do with opening object
    // },
    onCellClick: async (d, rowState) => {
      if (rowState.colIndex !== columns.length - 1) {
        setViewDetails(rows[rowState.dataIndex]);
        setNewDetails(rows[rowState.dataIndex]);
        handleDetailsObj();
      }
    },
    onRowsDelete: async (e) => {
      console.log(e, "e");
      await deleteMasterDataBatch(e.data);
    },
  };

  return (
    <React.Fragment>
      {missingPID && <NotFoundPID /> }
      {loadState && <Toaster msg={"Fetching real-time data"} />}
      {rows && (
        <MUIDataTable
          title={
            <>
              {props.role !== "admin" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginTop: "14px" }}
                    >
                      Office Employee
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ margin: "10px" }}
                      onClick={handleModalOpen}
                    >
                      Create New
                    </Button>
                    <Fab
                      onClick={getEmployeesData}
                      size="small"
                      color="primary"
                      aria-label="refresh"
                      style={{ margin: "10px" }}
                    >
                      <RefreshIcon />
                    </Fab>
                  </div>
                </>
              )}
            </>
          }
          data={columnsValues}
          columns={columns}
          options={options}
        />
      )}

      {/* create new modal employee overlay start here */}
      <div>
        <Modal
          open={employeeModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto", float: "right" }}
              onClick={handleModalClose}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
            <Typography style={{ marginTop: "1rem", textAlign: "center" }}>
              Enter Employees Details
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Employee Name"
                  variant="outlined"
                  name="employeeName"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.employeeName}
                  onChange={handleEmployeeInput}
                />
                <TextField
                  id="outlined-basic"
                  label="Employee ID"
                  variant="outlined"
                  name="employeeId"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.employeeId}
                  onChange={handleEmployeeInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Department ID"
                  variant="outlined"
                  name="departmentId"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.departmentId}
                  onChange={handleEmployeeInput}
                />
                <TextField
                  id="outlined-basic"
                  label="Site ID"
                  variant="outlined"
                  name="siteId"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.siteId}
                  onChange={handleEmployeeInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="employmentType"
                  onChange={(event, newValue) => {
                    handleEmployeeInput(
                      event,
                      newValue.value,
                      "employmentType"
                    );
                  }}
                  options={selectOffice}
                  style={{ width: "calc(50% - 0.5rem)" }}
                  // sx={{ width: 300, marginLeft: "5px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Employment Type" />
                  )}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="workerType"
                  onChange={(event, newValue) => {
                    handleEmployeeInput(event, newValue.value, "workerType");
                  }}
                  options={selectTypeofWorker}
                  style={{ width: "calc(50% - 0.5rem)" }}
                  // sx={{ width: 300, marginLeft: "5px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Worker Type" />
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  name="address"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.address}
                  onChange={handleEmployeeInput}
                />
                <TextField
                  id="outlined-basic"
                  label="Distance To office"
                  variant="outlined"
                  name="distance"
                  style={{ width: "calc(50% - 0.5rem)" }}
                  value={officeEmployeeData.distance}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEmployeeInput}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                width: "90%",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={createEmployeeData}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {/* create new modal employee overlay ends here */}

      <div>
        <Modal
          open={detailsState}
          onClose={handleDetailsObj}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ textAlign: "center" }}
        >
          <Box sx={style}>
            <OfficeEmployeePopUp
              close={handleDetailsObj}
              obj={viewDetails}
              id={id}
              load={getEmployeesData}
              click={handlebarClick}
            />
          </Box>
        </Modal>
        {/* master data component start here */}
        {/* dailog box component start here */}
      </div>

      {/* edit modal pop up component start here */}
      <Modal
        open={editPopup}
        onClose={() => setEditPopup(!editPopup)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Office Employee Details
            </Typography>
            <br />

            <TextField
              id="outlined-basic"
              label="Employee ID"
              name="productId"
              value={edit.employeeId ?? "Not Found"}
              disabled
              onChange={handleInput}
              variant="outlined"
            />
            <br />

            {/* Start new form for key & value */}
            <form onSubmit={handlebarSubmit}>
              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <input
                    name="key"
                    value={element.key || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  <input
                    name="value"
                    value={element.value || ""}
                    onChange={(e) => handleChanges(index, e)}
                    required
                  />

                  {index ? (
                    <DangerousIcon
                      style={{ color: "red ", cursor: "pointer" }}
                      onClick={() => removeFormFields(index)}
                    />
                  ) : null}
                </div>
              ))}
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                type="submit"
                onClick={() => addFormFields()}
              >
                Add
              </Button>
            </form>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleEditSubmit}
                variant="contained"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                onClick={() => setEditPopup(!editPopup)}
                variant="contained"
                type="submit"
              >
                Go Back
              </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
      {/* edit modal pop up component ends here */}

      {/* error & success toaster toaster */}
      {(errormsg || successmsg) && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={toaster}
            autoHideDuration={4000}
            onClose={handleClearToaster}
          >
            <Alert
              onClose={handleClearToaster}
              severity={errormsg ? "warning" : "success"}
              sx={{ width: "100%" }}
            >
              {errormsg ? errormsg : successmsg}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default OfficeEmployees;
