import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, setRef } from "@mui/material";
import { Container } from "@material-ui/core";
import EmptyTable from "../../component/EmptyTable/EmptyTable";
import ProductOverview from "../../Pages/ProductOverview";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import IconButton from "@mui/material/IconButton";
import UploadDocs from "../../component/Blockchain/UploadDocs";
// import Product Details C.S.S
import styles from "../../component/CSS/Product.module.css";
// import component
import ReportSummary from "./ReportSummary";
import ReportDescriptionPopUp from "./ReportDescriptionPopUp";
import ReportView from "./ReportView";
import ViewReport from "../Lifecycle/ViewReport";
import MUIDataTable from "mui-datatables";
import Fab from "@mui/material/Fab";
// refersh icon
import RefreshIcon from "@mui/icons-material/Refresh";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import axios from "axios";
// import Cookie from react cookie
import { useCookies } from "react-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ReportDataPopUp = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState();
  const [productIddata, setProductIdData] = React.useState([]);
  const [image, setImage] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRow, setSelectedRow] = React.useState([]); // selected row for Mui Data Table
  const [selectedRowObj, setSelectedRowObj] = React.useState([]); // selected row for Mui Data Table

  const [attachmentImage, setAttachmentImage] = React.useState([]);
  const [cookies, setCookie] = useCookies(["user"]);

  // Headers
  const headers = HEADERS2(cookies.token, cookies.Nid);
  // API URL
  const API_URL = CONSTANTS.API_URL;
  const API_URLS = CONSTANTS.UPLOAD_URL
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const InventoryDataById = (id) => {
    setProductIdData(props.obj);
  };

  React.useEffect(() => {
    InventoryDataById();
    console.log(props.obj, "props obj master pop up");
    console.log(props.orders, "props row orders");
  }, []);

  React.useEffect(() => {
    // call an api
    console.log(props.obj.attachment,'this is props obj')
    if (props.obj !== undefined) {
      // getAllAttachmentById();
      let temp = props.obj.attachment.map((item, index) => ({
        'Upload Id': item}))
      setAttachmentImage(temp);
      setData({
        id: props.obj.id,
        row: props.obj,
        obj: props.obj,
      });
      setProductIdData(props.obj);
    }
  }, [refresh,setRefresh]);

  // get attachment 
  // const getAllAttachmentById = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       API_URLS + `/attachment/report?id=${props?.obj?.id}`,
  //       headers
  //     );
  //     console.log(data, "data here")
  //     if (!data) {
  //       setAttachmentImage([]);
  //     } else {
  //       setAttachmentImage(data);
  //       console.log(data, "all attachment data");
  //     }
  //   } catch (err) {
  //     setAttachmentImage([]);
  //     console.log(err, " err in chgReport attachment");
  //   }
  // };
  const handleDelete = async (dataObj) => {
    try {
      console.log(props.obj.id, "1obj");
      let indexes = [];
      for (let o of dataObj) {
        console.log(attachmentImage[o.dataIndex], o.dataIndex, "obj");
        indexes.push(attachmentImage[o.dataIndex]['Upload Id']);
      }
      console.log(indexes, " these are indexes");
      
      const response = await axios.delete(API_URL+`/chgReport/detach/${props.obj.id}`, {
        ...headers,data: { attachments: indexes },
      });
      console.log('this is response', response.data)
    }catch(e){
      console.log(e,'this is e')
    }
  }
const handleRefresh = () =>{
  console.log('refreshing');
  setRefresh(true);
  props.load()
}
  // const jsonDomInternal = (json) => {
  //   return Object.keys(json).map((child, c) => {
  //     if (child !== "color") {
  //       if (typeof json[child] === "object" && !Array.isArray(json[child])) {
  //         return jsonDomInternal(json[child]);
  //       } else {
  //         if (
  //           child.startsWith("_") ||
  //           (Array.isArray(json[child]) && json[child].length === 0) ||
  //           child === "user_id" ||
  //           child === "PID" ||
  //           child === "manfacturer" ||
  //           json[child] === "" ||
  //           child === "blockchainId"
  //         ) {
  //           return;
  //         }
  //         return (
  //           <div className="full" key={c}>
  //             <div className="left">
  //               <strong>{child}</strong>
  //             </div>
  //             <div className="right" style={{ float: "right" }}>
  //               {Array.isArray(json[child])
  //                 ? JSON.stringify(json[child])
  //                 : json[child]}
  //             </div>
  //           </div>
  //         );
  //       }
  //     }
  //   });
  // };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" style={{ backgroundColor: 'black' }}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Assessment Report Details
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: "auto" }}
              onClick={props.close}
            >
              <CancelPresentationIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ backgroundColor: 'black' }}
            TabIndicatorProps={{ style: { backgroundColor: "white" } }}
          >
            <Tab label="Report Summary" {...a11yProps(0)} />
            <Tab label="Report View" {...a11yProps(1)} />
            <Tab label="Attachment" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} key={0}>
          <div style={{ marginTop: "14px" }}>
            {/*new component calling here */}
            <ReportSummary obj={data} />
            {/* component ends here */}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} key={1}>
          <div style={{ marginTop: "14px" }}>
            {/*new component calling here */}
            {/* <ReportSummary obj={data} /> */}
            {/* <ReportView obj={data} /> */}
            <ViewReport obj={data} />
            {/* component ends here */}
          </div>
        </TabPanel>

        <TabPanel value={value} index={2} key={2}>
          <div>
            <Grid container spacing={2} direction="row" style={{ marginBottom: '8px' }}>
              {/* {!image && (
                  <Grid xs={6}>
                    <Item>
                      <ProductOverview
                        images={[]}
                      />
                      <Button
                        style={{ margin: "10px" }}
                        variant="contained"
                        onClick={() => setImage(true)}
                      >
                        Upload Docs
                      </Button>
                    </Item>
                  </Grid>
                )} */}

              {image && (
                <Grid xs={6} className={styles.product_card} style={{ marginTop: '8px' }}>
                  <Item className={styles.info_item}>
                    <UploadDocs
                      back={() => {
                        setImage(false);
                      }}
                      setData={handleRefresh}
                      id={props?.obj?.id}
                      type = 'report'
                    // table="master"
                    />
                  </Item>
                </Grid>
              )}
            </Grid>
            {!image && <MUIDataTable
              title={
                <>
                  <strong>Attachments</strong>
                  <Button
                    variant="contained"
                    style={{ margin: "8px" }}
                    onClick={() => setImage(true)}
                  >
                    Insert New
                  </Button>
                  <Fab
                    onClick={handleRefresh}
                    size="small"
                    color="primary"
                    aria-label="refresh"
                    style={{ margin: "10px" }}
                  >
                    <RefreshIcon />
                  </Fab>
                </>
              }
              data={attachmentImage}
            columns={['Upload Id']}
            options = {{
              filterType: "checkbox",
              print: false,
              onChangeRowsPerPage: (numberOfRows) => {
                console.log(numberOfRows, "num");
                setRowsPerPage(numberOfRows);
              },
              rowsPerPage: rowsPerPage,
              onRowSelectionChange: (k, l, selectedRow) => {
                setSelectedRow(selectedRow);
                let selectTemp = [];
                for (let i = 0; i < selectedRow.length; i++) {
                  selectTemp.push(attachmentImage[i]);
                }
                setSelectedRowObj(selectTemp);
              },
              selectedRow: selectedRow,
              onRowsDelete: async (e) => {
                console.log(e, "e");
                await handleDelete(e.data);
              },
            }}
            />}
            {/* <CardActions style={{ marginTop: "10px" }}>
              <Grid container spacing={2} direction="row">
                {!image && (
                  <Grid xs={6}>
                    <Item>
                      <ProductOverview
                        images={props.obj?.attachment ? props.obj.attachment : []}
                      />
                      <Button
                        style={{ margin: "10px" }}
                        variant="contained"
                        onClick={() => setImage(true)}
                      >
                        Upload Docs
                      </Button>
                    </Item>
                  </Grid>
                )}

                {image && (
                  <Grid xs={6} className={styles.product_card}>
                    <Item className={styles.info_item}>
                      <UploadDocs
                        back={() => {
                          setImage(false);
                        }}
                        setData={setImage}
                        id={props?.obj?.id}
                        // table="master"
                      />
                    </Item>
                  </Grid>
                )}

                {!props.obj && <EmptyTable />}
                {props.obj && (
                  <Grid xs={6}>
                    <Item style={{ width: "38vw" }}>
                      <div style={{ float: "right" }}>
                        <ReportDescriptionPopUp obj={data} id={props.id} load={props.load} />
                      </div>
                      <br />
                      <Typography>
                        <strong>View Report Data Details</strong>
                      </Typography>
                      <div>{jsonDomInternal(props.obj)}</div>
                    </Item>
                  </Grid>
                )}
              </Grid>
            </CardActions> */}
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default ReportDataPopUp;
