import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { CONSTANTS, HEADERS2 } from "../../component/utils/constants";
import { useCookies } from "react-cookie";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts";
import InviteVendorIllustration from "../../assets/images/InviteVendor.png";
import { useHistory } from "react-router-dom";
const VendorAnalysis = () => {
  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [sites, setSites] = useState([]);
  const columns = [
    {
      field: "vendor",
      headerName: "Vendor ID",
      colSpan: 1,
      cellClassName: "w-full",
      width: 300,
    },
    {
      field: "vendor",
      headerName: "Name",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
    {
      field: "type",
      headerName: "Type",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "emissions",
      headerName: "Emissions (TCO2 Eq.)",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "spend",
      headerName: "Expense",
      colSpan: 1,
      cellClassName: "w-full",
      width: 200,
    },
    {
      field: "source",
      headerName: "Source",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      colSpan: 1,
      cellClassName: "w-full",
      width: 250,
    },
  ];
  const chartSetting = {
    yAxis: [
      {
        width: 100,
        valueFormatter: formatNumber,
      },
    ],
    height: 400, // Adjust height to fit your layout
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0) rotate(-45deg)", // Rotate labels for better fit
        transformOrigin: "right center",
      },
    },
  };

  const EmissionDataBarGraph =
    sites?.activeParticipantsInsights?.length > 0
      ? sites.activeParticipantsInsights.map(({ Name, total }) => {
        return { Name, Emissions: total };
      })
      : [
        {
          Name: "No data",
          Emissions: 0,
        },
      ];
  const EmissionFactorDataBarGraph =
    sites?.activeParticipantsInsights?.length > 0
      ? sites?.activeParticipantsInsights?.map(({ Name, emissionFactor }) => {
        return { Name, EmissionFactor: emissionFactor };
      })
      : [
        {
          Name: "No data",
          EmissionFactor: 0,
        },
      ];
  const valueFormatter = (value) => `${value.toFixed(2)} TCO2 Eq.`;

  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const handleOpenSimulateModal = () => {
    setShowGenerateReportModal(true);
  };
  const handleCloseReportModal = () => {
    setShowGenerateReportModal(false);
  };
  // reports api by P.I.D data
  const getSitesByPID = async () => {
    try {
      const { data } = await axios.get(
        API_URL +
        `/participant/vendorEmissions?from=${cookies.from}&&to=${cookies.to}`,
        headers
      );
      console.log("data in getSitesByPID function : - ", data);
      if (!data) {
        setSites([]);
      } else {
        console.log("site data", data);
        setSites(data);
      }
    } catch (err) {
      console.log(err, "err in reports api");
    }
  };

  React.useEffect(() => {
    getSitesByPID(); // call an reports api
    // getAllValidatedReports(); // call an api
  }, []);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"2rem"}>
      <Box>
        <Typography variant="h4" style={{ margin: "1rem" }}>
          <strong>Vendors Analysis</strong>
        </Typography>
        <Grid container spacing={6} sx={{ padding: "1rem" }}>
          <Grid item xs={6} sm={6} md={5}>
            <Card style={{ borderRadius: "1rem" }}>
              {/* Sites Emission Breakdown graph */}
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="h5" style={{}}>
                  <strong>Vendors Emission Breakdown</strong>
                </Typography>
                <Box
                  display={"flex"}
                  flex={1}
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                  }}
                >
                  <PieChart
                    width={400}
                    height={200}
                    className="w-full flex justify-start relative left-[-150px]"
                    colors={[
                      "#BDD2CC",
                      "#0D6659",
                      "#7CA59C",
                      "#3b7b6f",
                      "#12574c",
                      "#143b34",
                    ]}
                    series={[
                      {
                        data:
                          sites?.activeParticipantsInsights?.map((site) => {
                            console.log({
                              id: site.PID + "_1",
                              label: site.Name,
                            });
                            return {
                              id: site.PID + "_1",
                              label: site.Name,
                              value: site.total,
                            };
                          }) ?? [],
                        // data: [{id:'active',label:'active', value:sites.activeCount},{id:'inActive',label:'In Active', value:sites.inactiveCount}],
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                        innerRadius: 80,
                        outerRadius: 100,
                        valueFormatter: (value) => {
                          return value.value + " T CO2 Eq.";
                        },
                        cx: 40,
                      },
                    ]}
                  >
                    {" "}
                    <PieCenterLabel
                      offesetX={40}
                      offesetY={0}
                      fontSize="1.5rem"
                    >
                      {Math.round(
                        sites?.activeParticipantsInsights?.reduce(
                          (acc, site) => acc + site.total,
                          0
                        )
                      )}
                    </PieCenterLabel>
                    <PieCenterLabel
                      offesetX={40}
                      offesetY={20}
                      fontSize="0.8rem"
                    >
                      T.Co2 Eq.
                    </PieCenterLabel>
                  </PieChart>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={7}>
            {/* Generate Site Report Banner */}
            <Card
              sx={{
                flexGrow: 1,
                background: "white",
                borderRadius: "1rem",
                padding: "1rem",
                backgroundColor: "#143B34",
                color: "#fff",
                height: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography variant="h4" style={{}}>
                  <strong>
                    Simulate Vendors's Emission <br></br> for your next order
                  </strong>
                </Typography>
                <Typography variant="p" style={{}}>
                  <p>
                    Let us help you compare and select vendor for your next
                    order to make least emission
                  </p>
                </Typography>
                <Box
                  display="flex"
                  justifyContent={"end"}
                  width={"100%"}
                  marginTop={"auto"}
                  alignSelf={"baseline"}
                >
                  <Button
                    onClick={handleOpenSimulateModal}
                    sx={{
                      backgroundColor: "#3B7B6F",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "#7CA59C",
                      },
                    }}
                  >
                    Simulate
                  </Button>
                  <Modal
                    open={showGenerateReportModal}
                    onClose={handleCloseReportModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      width={"100%"}
                      height={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        width={"50%"}
                        borderRadius={"1rem"}
                        margin={"auto"}
                        alignSelf={"center"}
                        minWidth={"250px"}
                        display={"flex"}
                        flexDirection={"column"}
                        backgroundColor={"#fff"}
                        padding={"2rem"}
                      >
                        <VendorSimulattionForm
                          sites={sites}
                          closeModal={handleCloseReportModal}
                        />
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {
        <Grid container spacing={6}>
          {/* Sites Emission Benchmark Bar Graph */}
          <Grid item md={12} lg={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              marginInline={"1rem"}
              padding={"2rem"}
              paddingTop={"1rem"}
              backgroundColor={"#fff"}
              borderRadius={"1rem"}
            >
              <Typography variant="h4" style={{ margin: "1rem" }}>
                <strong>Vendors Benchmark</strong>
              </Typography>
              <BarChart
                colors={["#3b7b6f"]}
                dataset={EmissionDataBarGraph}
                margin={{ left: 50 }}
                xAxis={[{ scaleType: "band", dataKey: "Name", barGapRatio: 8 }]}
                series={[
                  { dataKey: "Emissions", label: "Emissions", valueFormatter },
                ]}
                {...chartSetting}
              />
            </Box>
          </Grid>
          <Grid item md={12} lg={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              marginInline={"1rem"}
              padding={"2rem"}
              paddingTop={"1rem"}
              backgroundColor={"#fff"}
              borderRadius={"1rem"}
            >
              <Typography variant="h4" style={{ margin: "1rem" }}>
                <strong>Vendors Emission Per Spend</strong>
              </Typography>
              <BarChart
                colors={["#3b7b6f"]}
                dataset={EmissionFactorDataBarGraph}
                xAxis={[{ scaleType: "band", dataKey: "Name" }]}
                series={[
                  { dataKey: "EmissionFactor", label: "Emissions per spend" },
                ]}
                height={400}
              />
            </Box>
          </Grid>
        </Grid>
      }
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginInline={"1rem"}
        padding={"1rem"}
        backgroundColor={"#fff"}
        borderRadius={"1rem"}
        height={"400px"}
      >
        <Typography variant="h5" style={{ margin: "1rem" }}>
          <strong>Vendors Emission</strong>
        </Typography>
        <DataGrid
          getRowId={(row) => row.id}
          rows={
            sites?.highestImpactingVendors?.map((row, idx) => ({
              ...row,
              id: idx,
            })) ?? []
          }
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          className="w-full bg-white rounded-lg"
          pageSizeOptions={[5, 10]}
        />
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginInline={"1rem"}
        padding={"4rem"}
        backgroundColor={"#29564c"}
        borderRadius={"1rem"}
        color={"#fff"}
      >
        <Box
          width={"70%"}
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
        >
           <Typography variant="h3">
              <strong>
                Invite Your Vendor to <br /> Collaborate in detail
              </strong>
            </Typography>
            <Typography variant="h5">
              <p>
              Invite your Vendor to join us and earn rewards! <br/> Share your eco-friendly journey on Votan GDP. 
              </p>
            </Typography>
          <Button
            onClick={() => history.push("/manage/vendors")}
            sx={{
              width: "fit-content",
              backgroundColor: "#3B7B6F",
              color: "#fff",
              ":hover": {
                backgroundColor: "#7CA59C",
              },
            }}
          >
            Invite Vendors
          </Button>
        </Box>
        <Box width={"30%"}>
          <img style={{ width: "100%" }} src={InviteVendorIllustration}></img>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorAnalysis;

const VendorSimulattionForm = ({ closeModal }) => {
  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  // API_URL
  const API_URL = CONSTANTS.API_URL;
  // HEADERS
  const headers = HEADERS2(cookies.token, cookies.Nid);
  const [state, setState] = useState({
    product: '',
    vendor: '',
    price: 0,
    quantity: 0,
  })
  //Form States
  const [products, setProducts] = useState([
  ]);
  const [validated, setValidated] = useState(false);
  const [vendors, setVendors] = useState([]);
  const handleValidate = () => {
    if (state && state.product !== '' && state.vendor !== '' && state.price > 0 && state.quantity > 0) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log('i am here', name, value)
    handleValidate();
    let temp = { ...state, [name]: value };
    setState(temp)
  };
  const handleSubmit = () => {
    console.log('check if entered,', validated)
    if (validated) {
      console.log('success, sending request')
      history.push(`/simulate/vendors?p=${state.product}&&v=${state.vendor}&&amt=${state.price}&&q=${state.quantity}`);
    }
    /* axios
      .post(
        `${API_URL}/ActivityReport/site/create`,
        { PID: selectedVendor, price, quantity,product:selectedProduct },
        headers
      )
      .then((response) => {
        // Handle successful response
        console.log("Response:", response.data);
        if (response.data.status) {
          setSuccess(true);
          setReportId(response.data.data.id);
        } else {
          alert("Failed to generate report!");
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
        alert("Failed to generate report!");
      }); */
  };
  const handleViewReport = () => {
    //logic to redirect  to report view
  };
  const getSectorOperate = async () => {
    try {
      const { data } = await axios.get(API_URL + `/ef/category/get`, headers);
      console.log(data, "activity data");
      if (!data) {
        setProducts([]);
      } else {
        setProducts([...data.activities]);
        console.log(data.activities, "data get successfully");
      }
    } catch (err) {
      console.log(err, "err in api");
    }
  };
  const getVendors = () => {
    axios.get(`${API_URL}/vendor/id`, headers).then((res) => {
      console.log("vendor", res.data.Vendors);
      if (res?.data?.Vendors) setVendors([...res?.data?.Vendors]);
    });
  };
  useEffect(() => {
    getSectorOperate();
    getVendors();
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Enter Order Details
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={products}
            value={state.product ?? ''}
            name='product'
            onChange={(event, newValue) => handleChange({ ...event, target: { name: 'product', value: newValue } })}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Products / Services" />}
          />
        </Grid>
        <Grid xs={6} item>
          <FormControl fullWidth>
            <InputLabel id="Site-select-menu-label">
              Preferred Vendor
            </InputLabel>
            <Select
              labelId="Site-select-menu-label"
              id="Site-select-menu"
              name="vendor"
              value={state.vendor}
              label="Select A Vendor"
              onChange={(e) => handleChange(e)}
            >
              {vendors.map(({ PID, Name }) => (
                <MenuItem value={PID}>{Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="price"
            name="price"
            label="Price"
            type="text"
            value={state.price}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}

            placeholder="Enter Price"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="quantity"
            name="quantity"
            label="Quantity"
            type="text"
            value={state.quantity}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Enter Quantity"
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={"1rem"}
      >
        {!(validated) && <Typography style={{ color: 'red' }}> Please select all options to continue</Typography>}
        <Button color="secondary" variant="outlined" onClick={closeModal}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: (validated) ? "#3B7B6F" : "#ccc",
            color: "#fff",
            ":hover": {
              backgroundColor: "#7CA59C",
            },
          }}
          disabled={!(validated) ?? false}
        >
          Simulate
        </Button>
      </Box>
    </Box>
  );
};

function PieCenterLabel({
  children,
  offesetY = 0,
  offesetX = 0,
  fontSize = "1rem",
}) {
  const { width, height, left, top } = useDrawingArea();
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    fontSize,
    fontWeight: "bold",
  }));
  console.log({ width, height, left, top });
  return (
    <StyledText x={0 + offesetX} y={top + height / 2 + offesetY}>
      {children}
    </StyledText>
  );
}

//Converts Large Numbers to short
const formatNumber = (num) => {
  const unit = "t.CO2 Eq.";
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + num).length / 3);
  let shortValue = parseFloat(
    (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
};
