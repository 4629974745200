import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
// import Cookie from react cookie
import { useCookies } from "react-cookie";
import VotanLogo from "../../assets/images/votan_logo_black.png";
import classes from "./ReportStyles.css";
const GHGReportDetails = (props) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [GHGEmissionsTableData, setGHGEmissionsTableData] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        /**
   * use effect to use setSelectedRowData(data[selectedIndex]); and makeAllEmissionsTableData(data[selectedIndex]); setData(props.data);
   */
        makeAllEmissionsTableData(props.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [props.data]);

  const PDFGenerator = async () => {
    // const generatePDF = () => {
    // Create a new jsPDF instance
    console.log("props.data : - ", props.data);
    const pdfDoc = new jsPDF({
      orientation: 'portrait', // or 'landscape'
      // unit: 'mm', // measurement unit: 'mm', 'cm', 'in', 'pt'
      format: 'a4', // page format: 'a0', 'a1', 'a2', 'a3', 'a4', 'a5', 'a6', etc.
    });

    let input = document.getElementById("reportData");
    console.log("input : - ", input);
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgProperties = pdfDoc.getImageProperties(imgData);
        const pdfWidth = pdfDoc.internal.pageSize.getWidth();
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;
        pdfDoc.addImage(imgData, 'PNG', 10, 2, pdfWidth - 20, pdfHeight);
        pdfDoc.addPage('a4', 'p');

        let ghgTableElement = document.getElementById("GHGEmissionTable");

        html2canvas(ghgTableElement)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgProperties = pdfDoc.getImageProperties(imgData);
            const pdfWidth = pdfDoc.internal.pageSize.getWidth();
            const pdfHeight =
              (imgProperties.height * pdfWidth) / imgProperties.width;
            pdfDoc.addImage(imgData, 'PNG', 10, 2, pdfWidth - 20, pdfHeight);
            pdfDoc.addPage('a4', 'p');

            let GHGEmissionsTableElement = document.getElementById("GHGEmissionsTable");

            html2canvas(GHGEmissionsTableElement)
              .then((canvas) => {
                console.log("Inside html to canvas");
                const imgData = canvas.toDataURL('image/png');
                const imgProperties = pdfDoc.getImageProperties(imgData);
                const pdfWidth = pdfDoc.internal.pageSize.getWidth();
                const pdfHeight =
                  (imgProperties.height * pdfWidth) / imgProperties.width;
                pdfDoc.addImage(imgData, 'PNG', 10, 2, pdfWidth - 20, pdfHeight);
                pdfDoc.addPage('a4', 'p');
                pdfDoc.save("download.pdf");
              })
          })
      })
  };

  const makeAllEmissionsTableData = async (data) => {
    try {
      if (!data.GHGActivities) {
        return
      }
      let tableData = [];
      let initialObject = {
        "Scope 1": [],
        "Scope 2": [],
        "Scope 3": [],
      }
      let res = data.GHGActivities?.reduce((acc, curr) => {
        if (!acc[curr.scope].includes(curr.category)) {
          acc[curr.scope].push(curr.category);
        } else {
          acc = acc;
        }
        return acc;
      }, initialObject);
      tableData = [
        ["Scope 1", res["Scope 1"].toString(), "Intergovernmental Panel on Climate Change – IPCC: Guidelines for National Greenhouse Gas Inventories (2006)"],
        ["Scope 2", res["Scope 2"].toString(), "United States – EPA eGRID 2020 State File released, 2022-01-27 International – International Energy Agency (IEA) 2020 released 2020 United Kingdom – Department for Environment, Food & Rural Affairs (DEFRA) Greenhouse Gas Reporting: Conversion Factors 2021 Residual Mix - Association of Issuing Bodies (AIB) European Residual Mixes 2020 - Version 1.1, 2021-05-31"],
        ["Scope 3", res["Scope 3"].toString(), "The Climate Registry's General Reporting Protocol, v1.1, May 2008, Table 12.1"],
      ]
      console.log("response for reduce : - ", tableData);
      setGHGEmissionsTableData(tableData);
      // setSelectedRowData(data);
      setData(data);
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "from",
      label: "From",
    },
    {
      name: "to",
      label: "To",
    },
  ];

  const GHGEmissionsTableHeaders = ['Emissions Scope', 'Emissions Source(if any)', 'Emissions Factor Employed'];


  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };
  const formatColumnName = (columnName) => {
    const formattedName = columnName.replace(/([a-z]+)([0-9]+)/i, (_, prefix, number) => {
      return prefix.charAt(0).toUpperCase() + prefix.slice(1) + ' ' + number;
    });
    return formattedName?? columnName;
  };
  return (
    <>
      <div id='reportData'>
        <img
          style={{ width: "180px", height: "40px" }}
          src={VotanLogo}
          alt="logo"
        />
        <div className="reportFrontPage">
          <p>The Board of Directors and Management {data.reportingCompany}</p> <br />
          We have reviewed the accompanying Statement of
          Greenhouse Gas (GHG) Emissions and notes (GHG Statement) of {data.reportingCompany} (the Corporation) for the
          period of {(new Date(data.from)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} to {(new Date(data.to)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}. The Corporation's management is responsible for presenting the GHG Statement in
          accordance with the criteria of the World Resources Institute/World Business Council for Sustainable Development
          (WRI/WBCSD) Greenhouse Gas Protocol: A Corporate Accounting and Reporting Standard, Revised Edition, the WRI/WBCSD
          Greenhouse Gas Protocol Scope 2 Guidance: An amendment to the GHG Protocol Corporate Standard, and
          the WRI/WBCSD Greenhouse Gas Protocol: Corporate Value Chain (Scope 3), Accounting and Reporting
          Standard (collectively, the GHG Protocol). Our responsibility is to express a conclusion on the GHG Statement
          based on our review. <br /><br />
          Our review was conducted in accordance with standards established by the American Institute of
          Certified Public Accountants. Those standards require that we plan and perform the review to obtain limited
          assurance about whether any material modifications should be made to the GHG Statement in order for it to be
          in accordance with the criteria. The procedures performed in a review vary in nature and timing from and are
          substantially less in extent than, an examination, the objective of which is to obtain reasonable assurance about
          whether the GHG Statement is in accordance with the criteria, in all material respects, in order to express an
          opinion. Accordingly, we do not express such an opinion. Because of the limited nature of the engagement, the
          level of assurance obtained in a review is substantially lower than the assurance that would have been
          obtained had an examination been performed. We believe that the review evidence obtained is sufficient and
          appropriate to provide a reasonable basis for our conclusion.<br /><br />
          We are required to be independent and to meet our other ethical responsibilities in accordance with relevant
          ethical requirements related to the engagement.<br /><br />
          The procedures we performed were based on our professional judgment and consisted primarily of inquiries of
          management to obtain an understanding of the methodology applied, evaluation of the entity's application of
          the stated methodology for deriving the greenhouse gas emissions, recalculations of the greenhouse gas
          emissions for a selection of locations, and analytical procedures comparing changes in greenhouse gas
          emissions trends.<br /><br />
          As described in Note 1, environmental and energy use data are subject to measurement uncertainties resulting
          from limitations inherent in the nature and methods of determining such data. Obtaining sufficient appropriate
          review evidence to support our conclusion does not reduce the inherent uncertainty in the data. The selection
          by management of different but acceptable measurement techniques could have resulted in materially different
          measurements. The precision of different measurement techniques may also vary.<br /><br />
          Based on our review, we are not aware of any material modifications that should be made to the Statement of
          Greenhouse Gas Emissions for the year ended December 31, 2021 in order for it to be in accordance with the
          GHG Protocol.<br /><br />
        </div>
      </div>
      <div id='GHGEmissionTable'>
        <h6 className="reportHeader">Statement of Greenhouse Gas Emissions</h6>
        <h6 className="reportFrontPage">For the time period {data?.from} {data?.to}</h6>
        <h6 className="reportFrontPage">CO2 Emissions in kg eq.</h6>
        {data?.emissions && (<table className='emissionsTable'>
          <thead>
            <tr>
              {Object.keys(data?.emissions).map((key) => (
                <th className='the'>
                   {formatColumnName(key)}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.values(data?.emissions).map((value) => (
                <td className='tde'>{value.toFixed(2)}</td>
              ))}
            </tr>
          </tbody>
        </table>)}
        <h4 className='normalHeader'>Notes to the Statement of Greenhouse Gas Emissions</h4>
        {/* <p className='reportSubHeader'>Note: THE COMPANY</p> */}
        <p className='reportSubHeader'>Organization</p>
        <p className='reportFrontPage'>The following report was generated by Votan, fetched directly from Blockchain in encrypted format in
          accordance to Q42 standards complimentary to Arc42 architecture. The data collected is in accordance to globally recognized
          GRI & ISO-14064 standards, and can be used for any kind of reportings: GHG, ESG, HIGG, BRSR and Sustainability
          Reporting.</p>
        <div className='reportSubHeader'>Basis of Presentation</div>
        <div className='reportFrontPage'>
          Scope 1 GHG emissions information has been prepared in accordance with the World Resources Institute (WRI)/World
          Business Council for Sustainable
          Development (WBCSD) Greenhouse Gas Protocol: A Corporate Accounting and Reporting Standard, Revised Edition.
          Scope 1 represents direct GHG
          emissions that occur from sources that are owned or controlled by the Corporation.
        </div>
        <div className='reportFrontPage'>
          Scope 2 GHG emissions information has been prepared in accordance with the WRI/WBCSD GHG Protocol Scope 2
          Guidance: An amendment to the
          GHG Protocol Corporate Standard. Scope 2 accounts for GHG emissions from the generation of purchased electricity
          consumed by the Corporation.
        </div>
        <div className='reportFrontPage'>
          Scope 3 GHG emissions information has been prepared in accordance with the WRI/WBCSD Greenhouse Gas Protocol:
          Corporate Value Chain (Scope 3),
          Accounting and Reporting Standard. Scope 3 includes indirect GHG emissions (not included in Scope 2) that occur
          in the value chain of the Corporation,
          including both upstream and downstream emissions. Upstream emissions are indirect GHG emissions related to
          purchased or acquired goods and
          services while downstream emissions are indirect GHG emissions related to sold goods and services.
        </div>
        <div className='reportFrontPage'>
          Collectively, the Greenhouse Gas Protocol: A Corporate Accounting and Reporting Standard, Revised Edition, the
          GHG Protocol Scope 2 Guidance: An
          amendment to the GHG Protocol Corporate Standard and the GHG Protocol: Corporate Value Chain (Scope 3),
          Accounting and Reporting Standard are
          referred to as the “GHG Protocol” in this document.
        </div>
      </div>
      <div id='GHGEmissionsTable'>
        <h4 className='notesHeading'>GHG Emissions Table</h4>
        <table className='emissionsTable'>
          <thead>
            <tr>
              {GHGEmissionsTableHeaders.map((key, i) => (
                <th className='the' key={i}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              GHGEmissionsTableData?.map((value) => (
                <tr>
                  {value.map((subValue) => (
                    <td className='tde'>{subValue}</td>
                  ))}
                </tr>
              ))
            }
          </tbody>
        </table>
        <h6 className="reportHeader">Gases Used</h6>
        <h6 className="reportFrontPage">CO2eq. used for gases below</h6>
        {data?.gasesIncluded && (<table className='emissionsTable'>
          <thead>
            <tr>
                <th className='the'>
                   Gases Used
                  </th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td className='tde'>
            {data.gasesIncluded}
              </td>
            </tr>
          </tbody>
        </table>)}
        <h6 className="reportHeader">Statement of Other Activities</h6>
        <h6 className="reportFrontPage">Other Env Activities caclulated for the time period {data?.from} {data?.to}</h6>
        {data?.OtherActivities && (<table className='emissionsTable'>
          <thead>
            <tr>
              {Object.values(data?.OtherActivities).map((key) => (
                <th className='the'>
                   {key.category}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.values(data?.OtherActivities).map((value) => (
                <td className='tde'>{value.sourceQuantity.toFixed(2)+` ${value.units}`}</td>
              ))}
            </tr>
          </tbody>
        </table>)}
      </div>

    </>
  );
};

export default GHGReportDetails;
