import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";
import { CONSTANTS, HEADERS } from '../../component/utils/constants';
// IMPORT Snackbar POP UP
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),

        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "300px",
        },
        "& .MuiButtonBase-root": {
            margin: theme.spacing(2),
        },
        newaccount: {
            display: "flex",
            alignItems: "center",
        },
    },
}));
const ForgetPassword = () => {
    const classes = useStyles();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [state, setState] = useState({})
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [PID, setPID] = useState('');
    const [token, setToken] = useState('');
    const [errMsg, setErrMsg] = useState();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("info");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    // API_URL
    const API_URL = CONSTANTS.API_URL;
    // headers


    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        let t = searchParams.get('c')
        console.log(t)
        setToken(t)
        const pathSegments = window.location.pathname.split('/');
        console.log(pathSegments[2])
        setPID(pathSegments[2])
        handleGetDetails(t, pathSegments[2])
    }, []);
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password1') {
            setPassword1(value);
            setIsPasswordMatch(password2 === value);
        } else if (name === 'password2') {
            setPassword2(value);
            setIsPasswordMatch(password1 === value);
        }
    };
    const handleSnackbarClose = async () => {
        setSnackbarOpen(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const handleGetDetails = async (t, p) => {
        try {
            const headers = HEADERS(token);
            console.log("Headers in handle get details : - ", headers);
            console.log("PID in handle get details : - ", t);
            // console.log('this is the body to send',{password:password1,PID:PID,token:token})
            const { data } = await axios.post(
                API_URL + `/user/getUserByPID`,
                { code: t },
                headers
            );
            setState(data)
            console.log(data, 'this is data')
        } catch (e) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to fetch details, ask the sender to share the invite again if this issue persists");
            console.log(e, 'error')
        }

    }
    const handleSetPassword = async () => {
        try {
            const headers = HEADERS(token);
            console.log('this is the body to send', { password: password1, PID: PID, token: token })

            let reqObj = {
                firstname: state.FirstName,
                email: state.Email,
                password: password1,
                code: token,
                requestType: "resetPassword",
            }

            console.log("reqObj ", reqObj);
            const { data, status } = await axios.post(API_URL + "/user/register", reqObj);

            if (status === 200) {
                setSnackbarOpen(true);
                setSnackbarSeverity("success");
                setSnackbarMessage("Password set successfully, redirecting...");
                console.log(window.location.origin,'origin')
                window.location = window.location.origin
            } else {
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage("Failed to set password, Please try again!");
            }
        } catch (e) {
            console.log(e)
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to set password, ask the sender to share the invite again if this issue persists");
        }
    };

    return (
        <>
           
                <form className={classes.root} onSubmit={handleSubmit}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Forgot Password?
                    </Typography>

                    <Typography variant="body1" align="center" padding={2} paragraph>
                        Don't worry, set a new one here
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                    </Typography>
                    {state && <>
                        <TextField
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={state?.Email}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            name="Name"
                            label="Name"
                            type="text"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={state?.FirstName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled
                        />
                    </>
                    }
                    <TextField
                        name="password1"
                        label="Create A New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={password1}
                        onChange={handlePasswordChange}
                    />
                    <TextField
                        name="password2"
                        label="Reenter Your Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={password2}
                        onChange={handlePasswordChange}
                    />

                    {errMsg && (
                        <Typography style={{ color: "red" }}>{errMsg}</Typography>
                    )}
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!isPasswordMatch}
                            onClick={handleSetPassword}
                        >
                            Set Password
                        </Button>
                    </div>

                </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default ForgetPassword;
