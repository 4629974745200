import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@mui/material/CardActions";
// import styling component
import { makeStyles } from "@material-ui/core/styles";
import FullScreenLoader from "../../component/Loader/FullScreenLoader";
// import Navlink 
import { NavLink, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    minWidth: "200px",
    margin: "5px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: 800,
  },
  pos: {
    textAlign: "center",
    fontWeight: "bold",
  },
  cardAction: {
    display: "flex",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
    justifyContent: "space-around",
  },
});


const Dashboard = (props) => {
const classes = useStyles();
const history = useHistory();
const [state, setState] = React.useState({});

// useEffect
React.useEffect(() => {
  setState(props.state);
}, [props.state]);

// handle bar Total User Function
const handleBarTotalUser = () => {
  // add history push method to redirect to new page
  history.push('/adminstration/userAccess'); // navigate adminstration user Access page
}

// handle bar Total Employee
const handleBarTotalEmployee = () => {
  // add history push method to redirect to new page
  history.push('/adminstration/employees'); // navigate adminstration employees page
}

// handle bar No of Sites
const handleBarSites = () => {
  // add history push method to redirect to new page
  history.push('/adminstration/sites'); // navigate adminstration sites page
}

  return (
    <Card>
    <div>
      <CardActions className={classes.cardAction}>
        {/* First card start here */}
        <Card className={classes.card} onClick={handleBarTotalUser}>
          <CardContent>
            {state?.loaded === false && <FullScreenLoader />}
            <Typography className={classes.pos} color="textSecondary">
              Total Users
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {state?.participants ?? 0}
            </Typography>
          </CardContent>
        </Card>
        {/* First card ends here */}

        {/* Second card start here */}
        <Card className={classes.card} style={{ cursor:"pointer" }}>
          <CardContent>
            <Typography className={classes.pos} color="textSecondary">
              All Units
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {state?.total ?? 0}
            </Typography>
          </CardContent>
        </Card>
        {/* Second Card ends here */}

        {/* Third card start here */}
        <Card className={classes.card} onClick={handleBarTotalEmployee} style={{ cursor:"pointer" }}>
          <CardContent>
            <Typography className={classes.pos} color="textSecondary">
              Total Employees
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {state?.total ?? 0}
            </Typography>
          </CardContent>
        </Card>
        {/* Third Card ends here */}

        {/* Fourth card start here */}
        <Card className={classes.card} onClick={handleBarSites} style={{ cursor:"pointer" }}>
          <CardContent>
            <Typography className={classes.pos} color="textSecondary">
              Sites(Total Factories & warehouses)
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {state?.total ?? 0}
            </Typography>
          </CardContent>
        </Card>
        {/* Fourth Card ends here */}
      </CardActions>

    </div>
    </Card>
  );
};

export default Dashboard;