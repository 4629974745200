import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Component
import ProductOverview from "../ProductOverview";
import { Typography } from "@material-ui/core";
// import React Cookie
import { useCookies } from "react-cookie";
import QRCode from "../QRCode/QRCode";
// import Loader component
import FullScreenLoader from "../../component/Loader/FullScreenLoader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OfficeSummary = (props) => {
  const [records, setRecords] = React.useState([]);
  const [attribute, setAttribute] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [cookies, setCookie] = useCookies(["user"]);

  // useEffect
  React.useEffect(() => {
    console.log(props.obj?.row,"third party summary")
    props.obj?.row ? setRecords(props.obj.row) : setRecords([]);
    setAttribute(props.obj?.row?.productAttributes);
    setIsLoading(false);
  }, [props.obj]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {!isLoading && (
        <Box sx={{ flexGrow: 1 }}>
          {!show && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    {records.length > 0 && <ProductOverview />}
                    <Typography style={{ textAlign: "center" }}>
                      <strong>All Description</strong>
                    </Typography>
                    <div className="full">
                      <div className="left">
                        <Typography><strong>Name</strong></Typography>
                      </div>
                      <div className="right" style={{ float: "right" }}>
                        {records?.factoryName ?? ""}
                      </div>
                    </div>
                    <div className="full">
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography><strong>Factory Location</strong></Typography>
                      </div>
                      <div
                        className="right"
                        style={{ float: "right" }}
                      >
                        {records?.factoryLocation ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography><strong>Capacity</strong></Typography>
                      </div>
                      <div
                        className="right"
                        style={{ float: "right" }}
                      >
                        {records?.capacity ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography><strong>Total Worker</strong></Typography>
                      </div>
                      <div
                        className="right"
                        style={{ float: "right" }}
                      >
                        {records?.totalWorkers ?? ""}
                      </div>
                    </div>
                    <div className="full">
                      <div className="left">
                        <Typography><strong>Worker Type</strong></Typography>
                      </div>
                      <div
                        className="right"
                        style={{ float: "right" }}
                      >
                        {records?.status ?? ""}
                      </div>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  {/* <Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          height: "100%",
                        }}
                      >
                        <QRCode
                          id={records?.id ? records?.id : "Loading Please Wait"}
                          nid={cookies.Nid}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Typography><strong>ID Number</strong></Typography>
                        <Typography><strong>ID Type</strong></Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <strong>
                          {props.obj?.id ?? "Loading Please Wait"}
                        </strong>
                        <strong>QR Code</strong>
                      </div>
                    </div>
                  </Item> */}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default OfficeSummary;